import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Goal, RecommendedProduct } from '@core/dialogs/goal-setting-workflow/types/goal-setting.types';
import { Product } from '@core/enums/product.enum';

@Injectable()
export class GoalSettingService {
  constructor(private apiService: ApiService) {}

  getGoals(): Observable<Goal[]> {
    return this.apiService.get(`onboarding/goals`).pipe(map((res) => res.result));
  }

  setGoals(goals: { selectedGoals: string[] }): Observable<RecommendedProduct[]> {
    return this.apiService.post(`onboarding/goals-recommendation`, goals).pipe(map((res) => res.result));
  }

  setProduct(selectedRecommendation: { selectedProduct: Product }): Observable<string> {
    return this.apiService
      .post(`onboarding/selected-recommendation`, selectedRecommendation)
      .pipe(map((res) => res.status));
  }
}
