<div class="content">
  <div class="header"
    [class.header--shadow]="canScrollUp && unsavedChangesState !== 'visible' && confirmSubmitState !== 'visible'"
    [class.footer--hidden]="headerConfig?.hidden">
    <div *ngIf="headerConfig || canGoBack" class="header__config">
      <button
        *ngIf="canGoBack"
        class="header__back"
        coreIconButton
        icon="back"
        [disabled]="actionInProgress || unsavedChangesState === 'visible' || confirmSubmitState === 'visible'"
        (click)="back.emit()">
      </button>
      <core-icon
        *ngIf="headerConfig?.icon && !headerConfig?.avatarSrc"
        size="lg"
        [icon]="headerConfig?.icon">
      </core-icon>
      <core-avatar *ngIf="headerConfig?.avatarSrc" [src]="headerConfig?.avatarSrc" size="md">
      </core-avatar>
      <div class="header__title">
        <h5>
          <strong *ngIf="headerConfig?.pretitle">{{ headerConfig.pretitle }}</strong>
          <span *ngIf="headerConfig?.title">{{ headerConfig.title }}</span>
          <strong *ngIf="headerConfig?.subtitle">{{ headerConfig.subtitle }}</strong>
        </h5>
      </div>
    </div>
    <div class="header__actions">
      <ng-content select="[coreDialogHeader]"></ng-content>
      <button
        *ngIf="headerConfig?.showSupport"
        coreIconButton
        icon="support"
        (click)="support()">
      </button>
      <button
        *ngIf="headerConfig?.showClose"
        coreIconButton
        icon="close"
        [disabled]="actionInProgress || unsavedChangesState === 'visible' || confirmSubmitState === 'visible'"
        (click)="onDismiss()">
      </button>
    </div>
  </div>
  <core-divider></core-divider>

  <core-overlay
    class="body"
    [visible]="unsavedChangesState === 'visible' || confirmSubmitState === 'visible'"
    variant="default"
    level="4"
    roundness="none"
    padding="none">
    <div
      coreOverlayProtectedContent
      class="body__content"
      #body
      (scroll)="checkScrollPosition(body)">
      <ng-content></ng-content>
    </div>
    <div class="confirmation" *ngIf="unsavedChangesState === 'visible'">
      <div class="confirmation__content" [@zoomInOutAnimation]="unsavedChangesState">
        <core-svg [svg]="changesConfig?.icon || 'error'"></core-svg>
        <h5>{{ changesConfig?.title || ('DIALOG.UNSAVED_CHANGES.TITLE' | translate) }}</h5>
        <p [innerHTML]="changesConfig?.text || ('DIALOG.UNSAVED_CHANGES.TEXT' | translate)"></p>
      </div>
    </div>
    <div class="confirmation" *ngIf="confirmSubmitState === 'visible'">
      <div class="confirmation__content" [@zoomInOutAnimation]="confirmSubmitState">
        <core-svg [svg]="confirmSubmitConfig?.icon || 'check_circle'"></core-svg>
        <h5>{{ confirmSubmitConfig?.title || ('DIALOG.CONFIRM_SUBMIT.TITLE' | translate) }}</h5>
        <p [innerHTML]="confirmSubmitConfig?.text || ('DIALOG.CONFIRM_SUBMIT.TEXT' | translate)"></p>
      </div>
    </div>
  </core-overlay>

  <div class="footer"
    [class.footer--shadow]="canScrollDown && unsavedChangesState !== 'visible' && confirmSubmitState !== 'visible'"
    [class.footer--hidden]="footerConfig?.hidden">
    <button
      *ngIf="canGoBack"
      class="footer__back"
      coreButton
      icon="back"
      variant="text"
      [disabled]="actionInProgress"
      (click)="back.emit()">
      {{ footerConfig?.backLabel || ('ACTION.BACK' | translate) }}
    </button>
    <div class="d-flex flex-column flex-md-row flex-grow-1 justify-content-md-between">
      <ng-content select="[coreDialogFooter]"></ng-content>
      <div></div>
      <core-form-actions
        class="flex-grow-1 flex-md-grow-0"
        *ngIf="footerConfig?.submitLabel"
        [variant]="footerConfig?.variant || 'primary'"
        [submitLabel]="footerConfig?.submitLabel"
        [cancelLabel]="footerConfig?.cancelLabel"
        [cancelDisabled]="footerConfig?.cancelDisabled"
        [submitDisabled]="footerConfig?.submitDisabled"
        [actionInProgress]="actionInProgress"
        (cancelClick)="onDismiss()"
        (submitClick)="onSubmit(confirmSubmit)">
      </core-form-actions>
    </div>
    <div [@bottomSheetAnimation]="unsavedChangesState" class="footer footer--bottom-sheet">
      <core-form-actions
        *ngIf="unsavedChangesState === 'visible'"
        class="flex-grow-1 justify-content-end"
        variant="danger"
        [cancelLabel]="changesConfig?.keepLabel || ('ACTION.KEEP' | translate)"
        [submitLabel]="changesConfig?.discardLabel || ('ACTION.DISCARD' | translate)"
        (cancelClick)="continueEditing()"
        (submitClick)="closeDialog()">
      </core-form-actions>
    </div>
    <div [@bottomSheetAnimation]="confirmSubmitState" class="footer footer--bottom-sheet">
      <core-form-actions
        *ngIf="confirmSubmitState === 'visible'"
        class="flex-grow-1 justify-content-end"
        [variant]="confirmSubmitConfig?.variant || 'primary'"
        [cancelLabel]="confirmSubmitConfig?.cancelLabel || ('ACTION.CANCEL' | translate)"
        [submitLabel]="confirmSubmitConfig?.submitLabel || ('ACTION.CONFIRM' | translate)"
        [actionInProgress]="actionInProgress"
        (cancelClick)="cancelConfirm()"
        (submitClick)="onSubmit()">
      </core-form-actions>
    </div>
  </div>
  <core-divider></core-divider>
</div>
