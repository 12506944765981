<core-dialog
  [actionInProgress]="actionInProgress"
  [unsavedChanges]="false"
  [canGoBack]="false"
  [headerConfig]="{
    title: selectedStore?.name,
    avatarSrc: selectedStore?.images?.[0]?.file
  }"
  [footerConfig]="{
    submitLabel: 'INTEGRATION_DIALOG.NAVIGATION.TRY_AGAIN' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate
  }"
  (dismiss)="onCancel()"
  (submit)="onSubmit()">
  <div class="row mx-n2 flex-grow-1">
    <div
      class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-0">
      <core-svg [svg]="svg"></core-svg>
    </div>

    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <h5>{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_PERMISSIONS_ERROR.TITLE' | translate }}</h5>
      <p [innerHTML]="errorText | translate"></p>
    </div>
  </div>
</core-dialog>
