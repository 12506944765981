<core-radio-group (change)="radioButtonChangeHandler($event.value)">
  <div class="store-item" *ngFor="let account of accounts; let i = index">
    <core-radio-button
      [value]="account.providerId"
      [checked]="account.providerId === preselectedId"
      labelPosition="before"
      [labelPadding]="isHandset ? { y: 'sm', right: 'md', left: 'sm' } : { y: 'none', right: 'lg', left: 'md' }"
      [disabled]="!account?.state?.selectable"
      [matTooltip]="!account?.state?.selectable && account.state.reason
        ? ('INTEGRATION_DIALOG.STATES.TOOLTIP.' + account.state.reason | translate : { providerName: account.provider | titlecase })
        : null"
      [matTooltipPosition]="'above'"
      #matTooltip="matTooltip"
      (touchstart)="matTooltip.toggle()"
      class="store-item__details">
      <core-provider-account-mobile-item
        *ngIf="isHandset; else desktopTemplate"
        [account]="account"
        [bestFit]="account.providerId === preselectedId"
        [visibleColumns]="visibleColumns">
      </core-provider-account-mobile-item>
      <ng-template #desktopTemplate>
        <core-provider-account-desktop-item
          [account]="account"
          [bestFit]="account.providerId === preselectedId"
          [visibleColumns]="visibleColumns">
        </core-provider-account-desktop-item>
      </ng-template>
    </core-radio-button>
  </div>
</core-radio-group>
