import { BrandConfigFiles } from '@core/interfaces/brand-config-files.interface';

import configLight from './config.light';
import configDark from './config.dark';
import chartLight from './chart.light';
import chartDark from './chart.dark';

const files: BrandConfigFiles = {
  config: {
    light: configLight,
    dark: configDark,
  },
  chart: {
    light: chartLight,
    dark: chartDark,
  },
};

export default files;
