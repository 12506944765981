import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from '@core/ui/avatar/avatar.module';
import { ScrollModule } from '@core/ui/scroll/scroll.module';
import { StoreSelectorIconsComponent } from './store-selector-icons.component';

@NgModule({
  declarations: [StoreSelectorIconsComponent],
  imports: [CommonModule, ScrollModule, AvatarModule],
  exports: [StoreSelectorIconsComponent],
})
export class StoreSelectorIconsModule {}
