import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { CardModule } from '@core/ui/card/card.module';
import { ButtonModule } from '../button/button.module';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';

@NgModule({
  declarations: [EmptyStateComponent],
  imports: [CommonModule, SvgModule, CardModule, ButtonModule, TranslateModule],
  exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
