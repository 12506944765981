<div class="actions-container p-1 pb-2 mx-auto mt-auto">
  <div class="actions-container__navigate">
    <button
      class="mr-2" [disabled]="data.first" coreIconButton variant="light" icon="chevron_left"
      (keyup)="onKeyUp($event)"
      (click)="data.onIndexChange(data.index - 1)">
    </button>
    <button
      class="mr-2" [disabled]="data.last" coreIconButton variant="light" icon="chevron_right"
      (keyup)="onKeyUp($event)"
      (click)="data.onIndexChange(data.index + 1)">
    </button>
  </div>
  <button coreIconButton variant="light" icon="close" (click)="removeOutlet()"></button>
</div>
<div class="preview-container mx-auto mb-auto">
  <photo-media-card
    [photo]="data.photo"
    [repostLoading]="repostLoading"
    (repostClick)="onRepostClick($event)">
  </photo-media-card>
</div>
