import { Injectable } from '@angular/core';
import { PageType } from '@core/enums/page.enum';
import { Adapter } from '@core/interfaces/adapter.interface';
import { DocumentBase } from '@core/interfaces/document.interface';
import { Links, Page } from '@core/interfaces/page.interface';

/**
 * Frontend model for page document
 */
export class PageDocument implements DocumentBase, Page {
  _id!: string;

  createdAt?: string;

  updatedAt?: string;

  type!: PageType;

  storeId!: string;

  urlSlug!: string;

  headerImageUrl?: string;

  logoUrl?: string;

  links?: Links;

  constructor(object: PageDocument) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for page document
 */
@Injectable({
  providedIn: 'root',
})
export class PageDocumentAdapter implements Adapter<PageDocument> {
  clientAdapt(object: any): PageDocument {
    return new PageDocument(object);
  }

  serverAdapt(object: PageDocument): any {
    return object;
  }
}
