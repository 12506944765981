import { Nullable } from '@core/types/nullable.type';
import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { instagramAccountUrl } from '@core/variables/external-links.variable';
import { MediaVariant } from '@core/ui/media/types/media.type';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { MediaProductType, RawMediaType } from '@social/common/enums/provider-account-post.enum';
import { ManagedPostType } from '@core/dialogs/post-manager/enums/managed-post-type.enum';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { ProviderAccountPostStatus } from '@social/common/enums/provider-account-post-status.enum';
import { Interactions, Resource } from '@core/dialogs/post-manager/models/managed-post.model';
import { ResourceType } from '@core/dialogs/post-manager/enums/resource-type.enum';
import { ManagedPostStatus } from '@core/dialogs/post-manager/enums/managed-post-status.enum';
import { Post } from '@core/dialogs/post-manager/models/post.model';
import { environment } from '@env/environment';

export class ProviderAccountPost {
  callToAction: Nullable<number> = null;

  resources: Resource[] = [];

  type!: ManagedPostType;

  caption!: {
    text: string;
  };

  _id!: string;

  provider!: ManagedPostProvider;

  providerAccountId!: string;

  providerPostId!: string;

  status!: ProviderAccountPostStatus;

  url: Nullable<string> = null;

  postCreatedAt!: string;

  postUpdatedAt!: string;

  interactions: Nullable<Interactions> = null;

  permissions!: Permissions;

  raw?: {
    owner: {
      id: string;
    };
    comments_count: number;
    id: string;
    ig_id: string;
    is_comment_enabled: boolean;
    like_count: number;
    media_product_type: MediaProductType;
    media_type: RawMediaType;
    media_url: string;
    permalink: string;
    shortcode: string;
    timestamp: string;
    username: string;
    children?: {
      data: {
        media_url: string;
        id: string;
        media_type: RawMediaType.Image | RawMediaType.Video;
        thumbnail_url?: string;
        timestamp: string;
        shortcode: string;
      }[];
    };
  };

  constructor(object: ProviderAccountPost) {
    Object.assign(this, object);
  }

  static toUIPost(
    object: ProviderAccountPost,
    queryParams: { useMediaProxy?: boolean; storeId?: string } = {},
  ): Post<ProviderAccountPost> {
    const getMediaUrl = (resource: Resource, index: number): string => {
      if (queryParams?.useMediaProxy && queryParams?.storeId && resource.type === ResourceType.Photo) {
        return `${environment.baseUrlV1}stores/${queryParams?.storeId}/socials/posts/${object._id}/medias/${index}`;
      }

      return resource.media.url;
    };

    const slides: MediaConfig[] = (object?.resources || []).map((resource: Resource, index: number) => ({
      src: getMediaUrl(resource, index),
      variant: resource.type === ResourceType.Video ? 'VIDEO' : 'IMAGE',
      thumbnail: resource.media.thumbnail,
      duration: resource.meta.duration,
      imageSrcVariant: queryParams?.useMediaProxy ? 'url' : undefined,
    }));

    let status = ManagedPostStatus.Published;
    switch (object.status) {
      case ProviderAccountPostStatus.Rejected:
        status = ManagedPostStatus.Rejected;
        break;
      case ProviderAccountPostStatus.Processing:
      case ProviderAccountPostStatus.Live:
      default:
        status = ManagedPostStatus.Published;
        break;
    }

    let mediaVariant: Nullable<MediaVariant> = null;
    switch (object.raw?.media_type) {
      case 'CAROUSEL_ALBUM':
        mediaVariant = 'CAROUSEL';
        break;
      case 'VIDEO':
        mediaVariant = 'VIDEO';
        break;
      case 'IMAGE':
        mediaVariant = 'IMAGE';
        break;
      default:
        mediaVariant = null;
        break;
    }

    return new Post({
      id: `provider-account-${object.providerPostId}`,
      date: object.postCreatedAt,
      owner: {
        avatar: null,
        username: object.raw?.username!,
        link: object.raw?.username ? instagramAccountUrl(object.raw.username) : null,
        followers: null,
      },
      counts: {
        likes: object.interactions?.likes!,
        comments: object.interactions?.comments!,
        views: object.interactions?.views!,
      },
      caption: object.caption.text,
      mediaVariant,
      slides,
      status,
      type: object.type,
      raw: object,
    });
  }
}

/**
 * Adapter for the ProviderAccountPost
 */
@Injectable({
  providedIn: 'root',
})
export class ProviderAccountPostAdapter implements Adapter<ProviderAccountPost> {
  clientAdapt(object: any): ProviderAccountPost {
    return new ProviderAccountPost(object);
  }

  serverAdapt(object: ProviderAccountPost): any {
    return object;
  }
}
