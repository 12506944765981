import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@core/ui/button/button.module';
import { CardModule } from '@core/ui/card/card.module';

import { EmojiPickerComponent } from './emoji-picker.component';

const components = [EmojiPickerComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, OverlayModule, TranslateModule, ButtonModule, CardModule],
  exports: [...components],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EmojiPickerModule {}
