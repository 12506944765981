import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { REQUESTHEADER_APPNAME, APP_VERSION } from '@core/variables/injection-tokens.variable';
import { environment } from '@env/environment';

@Injectable()
export class AppInformationInterceptor implements HttpInterceptor {
  origin = window?.ReactNativeWebView ? 'native' : 'web';

  scope = environment.microfrontend ? 'microfrontend' : 'app';

  constructor(
    @Inject(REQUESTHEADER_APPNAME) protected appName: string,
    @Inject(APP_VERSION) protected version: string,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let interceptedRequest = request;
    const [versionNumber] = this.version.split('-');
    if (request.url.includes('freachly') || request.url.includes('alphin')) {
      interceptedRequest = request.clone({
        headers: request.headers
          .set('app-name', this.appName || '')
          .set('version', versionNumber || '')
          .set('request-origin', this.origin || '')
          .set('scope', this.scope || ''),
      });
    }

    return next.handle(interceptedRequest);
  }
}
