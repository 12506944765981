import _isEqual from 'lodash/isEqual';
import { Nullable } from '@core/types/nullable.type';
import {
  Component,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  NgZone,
} from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, {
  Lazy,
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
  EffectCube,
  EffectCoverflow,
  EffectFlip,
} from 'swiper';
import { ImgixAspectRatio } from '@core/services/image.service';
import { CarouselEffect, MediaConfig, AutoplaySwiperOptions, PaginationSwiperOptions } from '../../types/carousel.type';
import { MediaErrorVariant } from '../../types/media.type';
import { ImageFit } from '../../types/image.type';

SwiperCore.use([Lazy, Navigation, Pagination, EffectFade, Autoplay, EffectCube, EffectCoverflow, EffectFlip]);

@Component({
  selector: 'core-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaCarouselComponent implements AfterViewInit, OnChanges {
  @ViewChild('swiper', { static: false })
  swiper?: SwiperComponent;

  mediaConfig: MediaConfig[] = [];

  @Input()
  slides: MediaConfig[] = [];

  @Input()
  errorVariant: MediaErrorVariant = 'image-text';

  @Input()
  slideIndex = 0;

  @Input()
  lazyLoading = {
    loadPrevNext: true,
    loadPrevNextAmount: 1,
  };

  @Input()
  loop = true;

  @Input()
  autoplay: AutoplaySwiperOptions = {
    disableOnInteraction: true,
    delay: 5000,
  };

  @Input()
  aspectRatio: Nullable<ImgixAspectRatio> = null;

  @Input()
  autoplayVideo = false;

  @Input()
  navigation = false;

  @Input()
  grabCursor = true;

  @Input()
  pagination: PaginationSwiperOptions = {
    dynamicBullets: true,
    clickable: true,
  };

  @Input()
  imageFit: ImageFit = 'contain';

  @Input()
  effect: CarouselEffect = 'slide';

  @Input()
  spaceBetween = 30;

  @Output()
  slideChange = new EventEmitter<{ index: number }>();

  @Output()
  actionChange = new EventEmitter();

  @Output()
  error = new EventEmitter();

  @Output()
  loaded = new EventEmitter();

  @Output()
  fallbackLoaded = new EventEmitter();

  loadSwiper = false;

  activeVideo: Nullable<Plyr> = null;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadSwiper = true;
      this.updateLayout();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slideIndex?.previousValue !== changes.slideIndex?.currentValue) {
      this.swiper?.swiperRef?.slideTo(this.slideIndex);
    }

    if (changes.slides?.currentValue && !_isEqual(changes.slides?.currentValue, this.mediaConfig)) {
      this.mediaConfig = changes.slides.currentValue;
      this.updateLayout();
    }

    if (changes.slides && this.swiper) {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.emitLoadedEvent();
        }, 100);
      });
    }

    if (changes.aspectRatio) {
      this.updateLayout();
    }
  }

  updateLayout(swiper: SwiperComponent = this.swiper!): void {
    if (this.aspectRatio && swiper) {
      const { w, h } = this.aspectRatio;
      const currentWidth = swiper.swiperRef.width;
      this.swiper!.swiperRef.wrapperEl.style.height = `${(h / w) * currentWidth}px`;
    }
    swiper?.swiperRef?.update?.();
  }

  onMediaLoaded(plyrEvent: Event): void {
    if ((plyrEvent?.target as any)?.plyr) {
      this.activeVideo = (plyrEvent?.target as any)?.plyr;
    }
  }

  emitLoadedEvent(swiper: SwiperComponent = this.swiper!, event?: any): void {
    this.loaded.emit({ index: this.slideIndex, event, swiperElement: swiper?.swiperRef?.el });
  }

  stopVideo(): void {
    this.activeVideo?.stop();
    this.activeVideo = null;
  }
}
