<core-dialog
  [actionInProgress]="actionInProgress"
  [style.height]="'unset'"
  [canGoBack]="false"
  [overlayStateTrigger$]="overlayStateTrigger$"
  [confirmSubmit]="isEmailRegistered"
  [confirmSubmitConfig]="{
    icon: 'warning',
    title: 'USER.INVITE.ACCOUNT_EXISTS.TITLE' | translate,
    text: 'USER.INVITE.ACCOUNT_EXISTS.TEXT' | translate: { email: formGroup.controls?.email?.value },
    cancelLabel: 'ACTION.NO' | translate,
    submitLabel: 'ACTION.YES' | translate
  }"
  [headerConfig]="{
    title: 'USER.INVITE.DIALOG.TITLE' | translate,
    subtitle: 'USER.INVITE.DIALOG.SUBTITLE' | translate
  }"
  [footerConfig]="{
    submitLabel: 'USER.INVITE.FORM.SUBMIT' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate
  }"
  (confirmCancel)="isEmailRegistered = false"
  (dismiss)="removeOutlet()"
  (submit)="submit()">
  <div class="row my-auto mx-n2">
    <div class="col-12 col-sm-4 px-2 d-flex flex-column justify-content-flex-start align-items-center mb-3 mb-sm-0">
      <core-svg
        svg="outline_email">
      </core-svg>
    </div>
    <div class="col-12 col-sm-8 px-2 d-flex flex-column justify-content-center">
      <p>{{ 'USER.INVITE.FORM.INSTRUCTION' | translate }}</p>
      <form [formGroup]="formGroup">
        <div class="row mn-3">   
          <div class="col-12 col-sm-6 px-3 d-flex flex-column">
            <core-form-field class="w-100">
              <span coreFormFieldLabel>{{ 'USER.CONTACT.FIRST_NAME' | translate }}</span>
              <input coreInput formControlName="firstName" type="text" [placeholder]="'USER.CONTACT.FIRST_NAME' | translate" />
              <span coreFormFieldError *ngIf="formGroup.get('firstName').errors">
                {{ 'VALIDATION.REQUIRED' | translate }}
              </span>
            </core-form-field>
          </div>
    
          <div class="col-12 col-sm-6 px-3 d-flex flex-column">
            <core-form-field class="w-100">
              <span coreFormFieldLabel>{{ 'USER.CONTACT.LAST_NAME' | translate }}</span>
              <input coreInput formControlName="lastName" type="text" [placeholder]="'USER.CONTACT.LAST_NAME' | translate" />
              <span coreFormFieldError *ngIf="formGroup.get('lastName').errors">
                {{ 'VALIDATION.REQUIRED' | translate }}
              </span>
            </core-form-field>
          </div>

          <div class="col-12 px-3 d-flex flex-column">
            <core-form-field class="w-100">
              <span coreFormFieldLabel>{{ 'USER.CONTACT.EMAIL' | translate }}</span>
              <input coreInput formControlName="email" type="text" [placeholder]="'USER.CONTACT.EMAIL' | translate" />
              <span coreFormFieldError *ngIf="formGroup.get('email').errors">
                @if (formGroup.get('email').errors?.required) {
                  {{ 'VALIDATION.REQUIRED' | translate }}
                } @else {
                  {{ 'VALIDATION.EMAIL' | translate }}
                }
              </span>
            </core-form-field>
          </div>

          <div class="col-12 col-sm-6 px-3 d-flex flex-column">
            <core-form-field class="w-100">
              <span coreFormFieldLabel>{{ 'SETTINGS.PREFERENCES.LANGUAGE' | translate }}</span>
              <core-select formControlName="language" [placeholder]="'SETTINGS.PREFERENCES.LANGUAGE' | translate">
                <core-option *ngFor="let value of languageOptions" [value]="value.value">
                  <span>{{ value.label }}</span>
                  <span coreOptionIcon>{{ value.symbol }}</span>
                </core-option>
              </core-select>
              <span coreFormFieldError *ngIf="formGroup.get('language').errors">
                {{ 'VALIDATION.REQUIRED' | translate }}
              </span>
            </core-form-field>
          </div>

          <div class="col-12 col-sm-6 px-3 d-flex flex-column">
            <core-form-field class="w-100">
              <span coreFormFieldLabel>{{ 'SETTINGS.PREFERENCES.CURRENCY' | translate }}</span>
              <core-select formControlName="currency" [placeholder]="'SETTINGS.PREFERENCES.CURRENCY' | translate">
                <core-option *ngFor="let value of currencyOptions" [value]="value.value">
                  <span>{{ value.label }}</span>
                  <span coreOptionIcon>{{ value.symbol }}</span>
                </core-option>
              </core-select>
              <span coreFormFieldError *ngIf="formGroup.get('currency').errors">
                {{ 'VALIDATION.REQUIRED' | translate }}
              </span>
            </core-form-field>
          </div>

          <div class="col-12 px-3 d-flex flex-column">
            <core-form-field class="w-100">
              <span coreFormFieldLabel>{{ 'USER.COMPANIES' | translate }}</span>
              <core-select formControlName="companyIds" [placeholder]="'USER.COMPANIES' | translate" [multiple]="true">
                <core-option *ngFor="let value of companyOptions" [value]="value.value">
                  <span>{{ value.label }}</span>
                </core-option>
              </core-select>
              <span coreFormFieldError *ngIf="formGroup.get('companyIds').errors">
                {{ 'VALIDATION.REQUIRED' | translate }}
              </span>
            </core-form-field>
          </div>

          <div class="col-12 px-3 d-flex flex-column" *ngIf="sharedStores.length">
            <p>{{ 'USER.INVITE.STORE_ACCESS.TEXT' | translate }}</p>
            <core-divider></core-divider>
            <div class="scroll" *ngFor="let store of sharedStores; let last = last">
              <div class="py-2 d-flex align-items-center">
                <core-avatar variant="store" size="xs" [src]="store?.images[0]?.file"></core-avatar>
                <div class="store-name ml-2">
                  {{ store?.name }}
                </div>
              </div>
              <core-divider *ngIf="!last"></core-divider>
            </div>
            <core-divider></core-divider>
          </div>
        </div>
      </form>
    </div>
  </div>
</core-dialog>
