<div class="actions-container p-1 mx-md-auto">
  <button coreIconButton variant="light" icon="close" (click)="removeOutlet()"></button>
</div>
<div class="preview-container my-auto mx-md-auto">
  <div class="d-flex align-items-stretch align-items-sm-center justify-content-stretch">
    <core-post-device-preview
      [mediaVariant]="data?.post?.mediaVariant"
      [slides]="data?.post?.slides"
      [counts]="data?.post?.counts"
      [owner]="data?.post?.owner"
      [date]="data?.post?.date"
      [caption]="data?.post?.caption">
    </core-post-device-preview>
    <div class="flex-grow-1 d-flex flex-column flex-wrap flex-sm-row flex-md-column align-items-stretch align-items-sm-center justify-content-center">
      <core-kpi-card-large
        [label]="'POST_VIEWER.INSIGHTS.ENGAGEMENT.TITLE' | translate"
        [tooltip]="'POST_VIEWER.INSIGHTS.ENGAGEMENT.TOOLTIP' | translate"
        icon="engagement"
        fontSize="lg"
        variant="emphasis"
        [isEmpty]="!((data?.post?.counts?.likes || 0) + (data?.post?.counts?.comments || 0))">
        <core-kpi-value
          [value]="(data?.post?.counts?.likes || 0) + (data?.post?.counts?.comments || 0)"
          [abbreviateValue]="true">
        </core-kpi-value>
      </core-kpi-card-large>

      <core-kpi-card-large
        [label]="'POST_VIEWER.INSIGHTS.REACH.TITLE' | translate"
        [tooltip]="'POST_VIEWER.INSIGHTS.REACH.TOOLTIP' | translate"
        icon="profile"
        fontSize="lg"
        [src]="data?.post?.slides?.[0]?.thumbnail || data?.post?.slides?.[0]?.src"
        [isEmpty]="!data?.insights?.reach">
        <core-kpi-value
          [value]="data?.insights?.reach || 0"
          [abbreviateValue]="true">
        </core-kpi-value>
      </core-kpi-card-large>

      <core-kpi-card-large
        [label]="'POST_VIEWER.INSIGHTS.IMPRESSIONS.TITLE' | translate"
        [tooltip]="'POST_VIEWER.INSIGHTS.IMPRESSIONS.TOOLTIP' | translate"
        icon="visibility_on"
        fontSize="lg"
        [src]="data?.post?.slides?.[0]?.thumbnail || data?.post?.slides?.[0]?.src"
        [isEmpty]="!data?.insights?.impressions">
        <core-kpi-value
          [value]="data?.insights?.impressions || 0"
          [abbreviateValue]="true">
        </core-kpi-value>
      </core-kpi-card-large>

      <core-kpi-card-large
        *ngIf="showVideoViews"
        [label]="'POST_VIEWER.INSIGHTS.VIDEO_VIEWS.TITLE' | translate"
        [tooltip]="'POST_VIEWER.INSIGHTS.VIDEO_VIEWS.TOOLTIP' | translate"
        icon="video"
        fontSize="lg"
        [src]="data?.post?.slides?.[0]?.thumbnail || data?.post?.slides?.[0]?.src"
        [isEmpty]="!data?.insights?.videoViews">
        <core-kpi-value
          [value]="data?.insights?.videoViews || 0"
          [abbreviateValue]="true">
        </core-kpi-value>
      </core-kpi-card-large>
    </div>
  </div>
</div>
