export const avatarSizes = {
  xs: 28,
  sm: 32,
  md: 40,
  lg: 48,
  xl: 56,
};

export const avatarFallback = {
  user: 'assets/core/svg/avatar/avatar_person.svg',
  store: 'assets/core/svg/avatar/avatar_store.svg',
  company: 'assets/core/svg/avatar/avatar_company.svg',
};
