import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize } from '@core/ui/svg/types/icon.type';
import { SpinnerVariant } from '@core/ui/progress/types/spinner.type';
import { ButtonSize, ButtonVariant, IconAlignment } from '../../types/button.type';

@Component({
  selector: 'core-button, button[coreButton], a[coreButton]',
  exportAs: 'coreButton',
  host: {
    '[class]': `"core-button"
    + " size-"+size
    + " variant-"+variant
    + " alignment-"+iconAlignment`,
    '[attr.disabled]': 'disabled || actionInProgress || null',
    '[class.core-button--disabled]': 'disabled || actionInProgress',
    '[class.core-button--loading]': 'actionInProgress',
    '[class.core-button--shadow]': 'shadow',
  },
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  inputs: ['disabled', 'variant', 'icon', 'size'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  icon = '';

  @Input()
  iconAlignment: IconAlignment = 'text';

  @Input()
  iconSize!: IconSize;

  @Input()
  actionInProgress = false;

  @Input()
  shadow = false;

  @Input()
  size: ButtonSize = 'md';

  @Input()
  variant: ButtonVariant = 'default';

  spinnerSize = 20;

  spinnerVariant: SpinnerVariant = 'primary';

  ngOnChanges(): void {
    this.iconSize = this.getIconSize();
    this.spinnerSize = this.getSpinnerSize();
    this.spinnerVariant = this.getSpinnerVariant();
  }

  private getIconSize(): IconSize {
    if (!this.iconSize) {
      return this.size === 'lg' ? 'lg' : 'xs';
    }

    return this.iconSize;
  }

  private getSpinnerSize(): number {
    switch (this.size) {
      case 'lg':
        return 24;
      case 'sm':
        return 15;
      case 'md':
      default:
        return 20;
    }
  }

  private getSpinnerVariant(): SpinnerVariant {
    switch (this.variant) {
      case 'emphasis':
        return 'light';
      case 'primary':
        return 'surface';
      case 'text-emphasis':
        return 'emphasis';
      case 'text':
      case 'text-link':
      case 'inverted':
      case 'default':
      default:
        return 'primary';
    }
  }
}
