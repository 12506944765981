import { CrudService } from '@core/services/crud.service';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { CrudActions } from './crud.action';

export class CrudEffects<T> {
  constructor(
    public actions$: Actions,
    public crudService: CrudService<T>,
    public crudActions: CrudActions,
  ) {}

  /**
   * Effect executed to load a list
   */
  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.crudActions.loadList.type),
      switchMap((action: any) =>
        this.crudService.getList(action.config).pipe(
          map((list) => new this.crudActions.loadList.ActionSuccess(list.data, list.pagination)),
          catchError((error: HttpErrorResponse) =>
            of(new this.crudActions.loadList.ActionFail(error, action.config.page)),
          ),
        ),
      ),
    ),
  );

  /**
   * Effect executed to load an object
   */
  loadObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.crudActions.loadObject.type),
      switchMap((action: any) =>
        this.crudService.getObject(action.id).pipe(
          map((payload) => new this.crudActions.loadObject.ActionSuccess(payload)),
          catchError((error: HttpErrorResponse) => of(new this.crudActions.loadObject.ActionFail(error))),
        ),
      ),
    ),
  );

  /**
   * Effect executed to create an object
   */
  createObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.crudActions.createObject.type),
      switchMap((action: any) =>
        this.crudService.createObject(action.payload).pipe(
          map((payload) => new this.crudActions.createObject.ActionSuccess(payload)),
          catchError((error: HttpErrorResponse) => of(new this.crudActions.createObject.ActionFail(error))),
        ),
      ),
    ),
  );

  /**
   * Effect executed to update an object
   */
  updateObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.crudActions.updateObject.type),
      switchMap((action: any) =>
        this.crudService.updateObject(action.id, action.payload).pipe(
          map((user) => new this.crudActions.updateObject.ActionSuccess(user)),
          catchError((error: HttpErrorResponse) => of(new this.crudActions.updateObject.ActionFail(error))),
        ),
      ),
    ),
  );

  /**
   * Effect executed to delete an object
   */
  deleteObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.crudActions.deleteObject.type),
      switchMap((action: any) =>
        this.crudService.deleteObject(action.id).pipe(
          map(() => new this.crudActions.deleteObject.ActionSuccess(action.id)),
          catchError((error: HttpErrorResponse) => of(new this.crudActions.deleteObject.ActionFail(error))),
        ),
      ),
    ),
  );
}
