/* eslint-disable max-classes-per-file */
import {
  LoadList,
  LoadListSuccess,
  LoadListFail,
  LoadObject,
  LoadObjectSuccess,
  LoadObjectFail,
  CreateObject,
  CreateObjectSuccess,
  CreateObjectFail,
  UpdateObject,
  UpdateObjectSuccess,
  UpdateObjectFail,
  DeleteObject,
  DeleteObjectSuccess,
  DeleteObjectFail,
} from '@core/redux/crud/crud.action';
import { Store } from '@core/models/store.model';
import { Action } from '@ngrx/store';

export enum StoreActionTypes {
  LoadStoreList = '[Store] Load company store list',
  LoadStoreListSuccess = '[Store] Load company store list success',
  LoadStoreListFail = '[Store] Load company store list fail',

  LoadStore = '[Store] Load company store',
  LoadStoreSuccess = '[Store] Load company store success',
  LoadStoreFail = '[Store] Load company store fail',

  CreateStore = '[Store] Create company store',
  CreateStoreSuccess = '[Store] Create company store success',
  CreateStoreFail = '[Store] Create company store fail',

  UpdateStore = '[Store] Update company store',
  UpdateStoreSuccess = '[Store] Update company store success',
  UpdateStoreFail = '[Store] Update company store fail',

  DeleteStore = '[Store] Delete company store',
  DeleteStoreSuccess = '[Store] Delete company store success',
  DeleteStoreFail = '[Store] Delete company store fail',
}

/**********************************************/
// Load company store list
/*********************************************/
export class LoadStoreList extends LoadList implements Action {
  readonly type = StoreActionTypes.LoadStoreList;
}

export class LoadStoreListSuccess extends LoadListSuccess<Store> implements Action {
  readonly type = StoreActionTypes.LoadStoreListSuccess;
}

export class LoadStoreListFail extends LoadListFail implements Action {
  readonly type = StoreActionTypes.LoadStoreListFail;
}

/**********************************************/
// Load company store
/*********************************************/
export class LoadStore extends LoadObject implements Action {
  readonly type = StoreActionTypes.LoadStore;
}

export class LoadStoreSuccess extends LoadObjectSuccess<Store> implements Action {
  readonly type = StoreActionTypes.LoadStoreSuccess;
}

export class LoadStoreFail extends LoadObjectFail implements Action {
  readonly type = StoreActionTypes.LoadStoreFail;
}

/**********************************************/
// Create company store
/*********************************************/
export class CreateStore extends CreateObject<Store> implements Action {
  readonly type = StoreActionTypes.CreateStore;
}

export class CreateStoreSuccess extends CreateObjectSuccess<Store> implements Action {
  readonly type = StoreActionTypes.CreateStoreSuccess;
}

export class CreateStoreFail extends CreateObjectFail implements Action {
  readonly type = StoreActionTypes.CreateStoreFail;
}

/**********************************************/
// Update company store
/*********************************************/
export class UpdateStore extends UpdateObject<Store> implements Action {
  readonly type = StoreActionTypes.UpdateStore;
}

export class UpdateStoreSuccess extends UpdateObjectSuccess<Store> implements Action {
  readonly type = StoreActionTypes.UpdateStoreSuccess;
}

export class UpdateStoreFail extends UpdateObjectFail implements Action {
  readonly type = StoreActionTypes.UpdateStoreFail;
}

/**********************************************/
// Delete company store
/*********************************************/
export class DeleteStore extends DeleteObject implements Action {
  readonly type = StoreActionTypes.DeleteStore;
}

export class DeleteStoreSuccess extends DeleteObjectSuccess implements Action {
  readonly type = StoreActionTypes.DeleteStoreSuccess;
}

export class DeleteStoreFail extends DeleteObjectFail implements Action {
  readonly type = StoreActionTypes.DeleteStoreFail;
}

export type StoreAction =
  | LoadStoreList
  | LoadStoreListSuccess
  | LoadStoreListFail
  | LoadStore
  | LoadStoreSuccess
  | LoadStoreFail
  | CreateStore
  | CreateStoreSuccess
  | CreateStoreFail
  | UpdateStore
  | UpdateStoreSuccess
  | UpdateStoreFail
  | DeleteStore
  | DeleteStoreSuccess
  | DeleteStoreFail;
