import { Component, Input } from '@angular/core';

@Component({
  selector: 'core-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
  @Input() orientation: 'x' | 'y' = 'x';
}
