import { Injectable } from '@angular/core';
import { IntegrationConnectionStatus } from '@core/enums/integration.enum';
import { Adapter } from '@core/interfaces/adapter.interface';
import { IntegrationMetadata, AuthenticationMethod } from '@core/interfaces/integration.interface';

export class Integration {
  _id = '';

  authenticationMethod!: AuthenticationMethod;

  categories!: string[];

  connected!: boolean;

  connectionStatus!: IntegrationConnectionStatus;

  displayName = '';

  key = '';

  metadata!: IntegrationMetadata;

  provider = '';

  rank = 0;

  recommended = true;

  services!: string[];

  constructor(object?: Partial<Integration>) {
    Object.assign(this, object);
  }
}

@Injectable({
  providedIn: 'root',
})
export class IntegrationAdapter implements Adapter<Integration> {
  clientAdapt(object: any): Integration {
    return new Integration(object);
  }

  serverAdapt(object: any): Integration {
    return new Integration(object);
  }
}
