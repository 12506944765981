import { Injectable } from '@angular/core';
import { ClientViewDataAdapter } from '@core/interfaces/adapter.interface';
import _get from 'lodash/get';
import { Contact } from '@core/interfaces/contact.interface';
import { UserPreferences } from '@core/interfaces/user-preferences.interface';
import { AccessRights } from '@core/interfaces/access-rights.interface';
import { DocumentBase } from '@core/interfaces/document.interface';
import { Nullable } from '@core/types/nullable.type';
import { CSManagerInfo } from './cs-manager-info.model';

/**
 * Frontend model for the auth user
 */
export class AuthUser implements DocumentBase {
  readonly _id: string;

  readonly createdAt: string;

  readonly updatedAt: string;

  readonly lastOnline: Nullable<string> = null;

  readonly contact: Contact;

  readonly settings: UserPreferences;

  readonly isAdmin: boolean;

  readonly isInternalAccount: boolean;

  readonly accessRights: AccessRights;

  csManagerInfo?: CSManagerInfo;

  readonly selectedGoals: string[];

  readonly selectedProduct: string;

  companyIds: string[] = [];

  constructor(object: AuthUser) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = object._id;
    this.createdAt = object.createdAt;
    this.updatedAt = object.updatedAt;
    this.lastOnline = object.lastOnline;
    this.contact = object.contact;
    this.settings = object.settings;
    this.isAdmin = object.isAdmin;
    this.isInternalAccount = object.isInternalAccount;
    this.accessRights = object.accessRights;
    this.selectedGoals = object.selectedGoals;
    this.selectedProduct = object.selectedProduct;
    this.companyIds = object.companyIds;
  }
}

/**
 * Adapter for the auth user
 */
@Injectable({
  providedIn: 'root',
})
export class AuthUserAdapter implements ClientViewDataAdapter<AuthUser> {
  clientAdapt(object: any): AuthUser {
    const authUser = object;
    /**
     * Switches the language to English if it is set to Hebrew
     * TODO: Remove once Hebrew language is supported
     */
    if (_get(object.settings, 'language') === 'he') {
      authUser.settings.language = 'en';
    }

    return new AuthUser(authUser);
  }
}
