import { AuthUserService } from '@core/services/auth-user.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snack-bar.service';
import { PreferenceOption } from '@core/interfaces/user-preferences.interface';
import { currencyOptions, languageOptions } from '@core/variables/preferences.variable';
import { Store } from '@core/models/store.model';
import { CompanyService } from '@core/services/company.service';
import { StoreService } from '@core/services/store.service';
import { ConfirmDialogState } from '@core/ui/dialog/types/dialog.type';
import { UserInviteRoute } from '../../enums/user-invite-route.enum';
import { userInviteOutlet } from '../../variables/outlet.variable';
import { InviteUserService } from '../../services/invite-user.service';

@Component({
  selector: 'core-user-invite-send',
  templateUrl: './user-invite-send.component.html',
  styleUrls: ['./user-invite-send.component.scss'],
})
export class UserInviteSendComponent implements OnInit {
  formGroup = new FormGroup<any>({
    email: new FormControl(null, [Validators.required, Validators.email]),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    language: new FormControl(null, [Validators.required]),
    currency: new FormControl(null, [Validators.required]),
    companyIds: new FormControl([], [Validators.required]),
  });

  languageOptions = languageOptions;

  currencyOptions = currencyOptions;

  companyOptions: PreferenceOption<string>[] = [];

  storeList: Store[] = [];

  sharedStores: Store[] = [];

  isEmailRegistered = false;

  actionInProgress = false;

  overlayStateTrigger$: BehaviorSubject<{
    unsavedChangesState?: ConfirmDialogState;
    confirmSubmitState?: ConfirmDialogState;
  }> = new BehaviorSubject({});

  constructor(
    private router: Router,
    private authUserService: AuthUserService,
    private dialogService: DialogService,
    private inviteUserService: InviteUserService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private storeService: StoreService,
  ) {}

  ngOnInit(): void {
    const { queryParams } = this.router.routerState.snapshot.root;
    this.formGroup.patchValue({
      email: queryParams.email || null,
      firstName: queryParams.first_name || null,
      lastName: queryParams.last_name || null,
      language: this.authUserService.authUser.settings.language,
      currency: this.authUserService.authUser.settings.currency,
      companyIds: [],
    });
    this.companyService
      .getCompanyList()
      .pipe(
        tap((companies) => {
          this.companyOptions = companies.data?.map((company) => ({
            label: company.name,
            value: company._id,
          }));
          this.formGroup.controls.companyIds.setValue(companies.data?.map(({ _id }) => _id));
        }),
      )
      .subscribe();
    this.storeService
      .getStoreList()
      .pipe(
        tap((stores) => {
          this.storeList = stores.data;
          this.sharedStores = [...this.storeList];
        }),
      )
      .subscribe();
    this.formGroup.controls.companyIds.valueChanges.subscribe((value) => {
      this.sharedStores = this.storeList.filter((store) => value.includes(store.companyId));
    });
  }

  removeOutlet(): void {
    this.router.navigate([{ outlets: { [userInviteOutlet]: null } }]).then(() => {
      this.dialogService.closeDialog();
    });
  }

  assignCompanies(): void {
    this.actionInProgress = true;
    const { firstName, lastName, email, companyIds } = this.formGroup.value;

    this.inviteUserService
      .assignCompaniesToUser(email, { companyIds })
      .pipe(
        first(),
        catchError(() => {
          this.actionInProgress = false;
          this.snackBarService.openSnackBar({
            variant: 'error',
            text: this.translateService.instant('ERROR.REQUEST'),
          });

          return EMPTY;
        }),
        tap(() => {
          this.actionInProgress = false;
          this.isEmailRegistered = false;
          this.router.navigate([{ outlets: { [userInviteOutlet]: UserInviteRoute.Complete } }], {
            queryParams: { email, first_name: firstName, last_name: lastName, reloadUsers: true },
          });
        }),
      )
      .subscribe();
  }

  submit(): void {
    if (this.isEmailRegistered) {
      return this.assignCompanies();
    }

    this.sendInvitation();
  }

  sendInvitation(): void {
    if (this.formGroup.invalid) {
      return this.formGroup.markAllAsTouched();
    }

    this.actionInProgress = true;
    const { firstName, lastName, email, language, currency, companyIds } = this.formGroup.value;

    this.inviteUserService
      .invite({
        contact: {
          firstName,
          lastName,
          email,
        },
        settings: {
          language,
          currency,
        },
        companyIds,
      })
      .pipe(
        first(),
        catchError((error) => {
          this.actionInProgress = false;
          if (error?.code === 409) {
            this.isEmailRegistered = true;
            this.overlayStateTrigger$.next({ confirmSubmitState: 'visible' });
          } else {
            this.snackBarService.openSnackBar({
              variant: 'error',
              text: this.translateService.instant('ERROR.REQUEST'),
            });
          }

          return EMPTY;
        }),
        tap(() => {
          this.actionInProgress = false;
          this.isEmailRegistered = false;
          this.router.navigate([{ outlets: { [userInviteOutlet]: UserInviteRoute.Complete } }], {
            queryParams: { email, first_name: firstName, last_name: lastName, reloadUsers: true },
          });
        }),
      )
      .subscribe();
  }
}
