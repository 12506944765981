import { RouterModule, Routes } from '@angular/router';

import { AuthType } from '@core/enums/provider.enum';
import { integrationOutlet } from './variables/outlet.variable';

// Auth Dialogs
import { AuthIntegrationIntroDialogComponent } from './screens/auth-integration/auth-integration-intro-dialog/auth-integration-intro-dialog.component';
import { AuthIntegrationConnectDialogComponent } from './screens/auth-integration/auth-integration-connect-dialog/auth-integration-connect-dialog.component';
import { IntegrationEmptyDialogComponent } from './screens/common/integration-empty-dialog/integration-empty-dialog.component';
import { AuthIntegrationUnauthorizedDialogComponent } from './screens/auth-integration/auth-integration-unauthorized-dialog/auth-integration-unauthorized-dialog.component';
import { NoneIntgConnectDialogComponent } from './screens/none-authtype-integration/none-intg-connect-dialog/none-intg-connect-dialog.component';

const outlet = integrationOutlet;

export const makeIntegrationWorkflowRoutes = () => [
  {
    path: `${AuthType.OAUTH}/:providerName`,
    outlet,
    component: AuthIntegrationIntroDialogComponent,
  },
  {
    path: `${AuthType.OAUTH}/:providerName/empty`,
    outlet,
    component: IntegrationEmptyDialogComponent,
  },
  {
    path: `${AuthType.OAUTH}/:providerName/connect`,
    outlet,
    component: AuthIntegrationConnectDialogComponent,
  },
  {
    path: `${AuthType.OAUTH}/:providerName/unauthorized`,
    outlet,
    component: AuthIntegrationUnauthorizedDialogComponent,
  },
  {
    path: `${AuthType.NONE}/:providerName`,
    outlet,
    component: NoneIntgConnectDialogComponent,
  },
  {
    path: `${AuthType.NONE}/:providerName/empty`,
    outlet,
    component: IntegrationEmptyDialogComponent,
  },
];

const routes: Routes = [...makeIntegrationWorkflowRoutes()];

export const IntegrationWorkflowRoutingModule = RouterModule.forChild(routes);
