import { Params } from '@angular/router';
import { BrandChartConfig } from '@core/interfaces/brand-chart-config.interface';
import { CartesianScaleOptions, GridLineOptions, LegendOptions } from 'chart.js';

const orderbirdPalette: Params = {
  whiteBase: '#ffffff',
  whiteDark: '#f0f7fc',
  whiteExtraDark: '#e5e5e5',

  grayLight: '#bdbdbd',
  grayBase: '#9ba0a5',
  grayDark: '#767676',

  blackBase: '#363636',
  blackDark: '#000000',

  blueLight: '#a4d1f2',
  blueBase: '#00a3e0',
  blueDark: '#2480c2',

  mutedBlueBase: '#4f88ad',
  mutedBlueDark: '#01426a',

  redExtraLight: '#f38684',
  redLight: '#ec5747',
  redBase: '#c05145',
  redDark: '#a94442',

  yellowLight: '#fed940',
  yellowBase: '#fecc00',
  yellowDark: '#ffbb33',

  greenLight: '#68d97e',
  greenBase: ' #36cc53',
  greenDark: '#1cb52a',

  chart1: '#67C0B8',
  chart2: '#8CC04D',
  chart3: '#F8D52F',
  chart4: '#F07723',
  chart5: '#972D45',
  chart6: '#FF9E80',
};

/**
 * Grid line options
 */
const gridLineOptionsX: Partial<GridLineOptions> = {
  display: true,
  drawOnChartArea: false,
  drawBorder: true,
  tickLength: 12,
  borderDash: [1, 0],
  color: 'transparent',
  borderColor: orderbirdPalette.grayBase,
};

const gridLineOptionsY: Partial<GridLineOptions> = {
  tickLength: 0,
  display: true,
  drawTicks: false,
  drawBorder: false,
  lineWidth: 1,
  borderDash: [1, 0],
  color: (ctx) => {
    if (ctx.index === 0 || !ctx.tick.label) {
      return 'transparent';
    }

    return orderbirdPalette.grayBase;
  },
};

/**
 * Tick options
 */
const tickOptionsX: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  padding: 4,
  maxRotation: 0,
  minRotation: 0,
  color: (ctx: any) => {
    if (!ctx.tick.label) {
      return 'transparent';
    }

    return orderbirdPalette.grayDark;
  },
  display: true,
};

const tickOptionsY: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  includeBounds: true,
  padding: 4,
  labelOffset: -8,
  mirror: true,
  color: orderbirdPalette.grayDark,
  display: true,
  maxTicksLimit: 5,
};

const config: BrandChartConfig = {
  general: {
    colors: {
      audience: {
        male: orderbirdPalette.chart1,
        female: orderbirdPalette.chart4,
        unknown: orderbirdPalette.chart2,
      },
      datasets: [
        orderbirdPalette.chart1,
        orderbirdPalette.chart2,
        orderbirdPalette.chart4,
        orderbirdPalette.chart5,
        orderbirdPalette.chart3,
        orderbirdPalette.chart6,
      ],
      axisTitle: orderbirdPalette.grayDark,
    },
    pluginOptions: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          font: { size: 12 },
          color: orderbirdPalette.grayDark,
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 16,
        } as LegendOptions<any>['labels'],
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  doughnut: {
    chartOptions: {
      cutout: '90%',
      radius: '90%',
    },
    pluginOptions: {
      chartBorder: {
        borderColor: 'transparent',
        borderWidth: 0,
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
  },
  bar: {
    chartOptions: {},
    datasetOptions: {},
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  line: {
    chartOptions: {},
    datasetOptions: {
      fill: false,
      fillGradientOpacity: [],
    },
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  horizontalBar: {
    barColors: {
      filled: orderbirdPalette.blueDark,
      unfilled: orderbirdPalette.whiteExtraDark,
    },
    labelColors: {
      filled: orderbirdPalette.whiteBase,
      unfilled: orderbirdPalette.blackBase,
    },
    barThickness: 30,
    borderRadius: 0,
  },
  gauge: {
    colors: {
      low: orderbirdPalette.redBase,
      medium: orderbirdPalette.yellowDark,
      high: orderbirdPalette.greenBase,
      needle: orderbirdPalette.grayDark,
      unfilled: orderbirdPalette.whiteExtraDark,
    },
  },
};

export default config;
