import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { IntegrationDialogService } from '@core/dialogs/integration-workflow/services/integration-dialog.service';
import { IntegrationDataService } from '@core/dialogs/integration-workflow/services/integration-data.service';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { IntegrationDialogConfig } from '@core/dialogs/integration-workflow/interfaces/dialog-config.interface';
import { MessageEventService } from '@core/services/message-event.service';
import { INTEGRATION_EVENT } from '@core/variables/webview-events.variable';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'core-auth-integration-intro-dialog',
  templateUrl: './auth-integration-intro-dialog.component.html',
  styleUrls: ['./auth-integration-intro-dialog.component.scss'],
})
export class AuthIntegrationIntroDialogComponent {
  selectedStore = this.globalFilterService.store;

  actionInProgress = false;

  storeName = this.selectedStore.name;

  storeAddress = this.selectedStore.addressLine;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IntegrationDialogConfig,
    private integrationDataService: IntegrationDataService,
    private integrationDialogService: IntegrationDialogService,
    private messageEventService: MessageEventService,
    private globalFilterService: GlobalFilterService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  onCancel(): void {
    this.integrationDialogService.closeDialog();
  }

  onSubmit(): void {
    if (this.messageEventService.isWebView) {
      this.snackBarService.openSnackBar({
        text: this.translateService.instant('INTEGRATION_DIALOG.SNACKBAR.REACT_NATIVE.BROWSER_ONLY.TEXT'),
      });

      return;
    }
    this.actionInProgress = true;
    this.redirectUrl();
  }

  redirectUrl(): void {
    this.integrationDataService
      .getIntegration(this.data.integrationId!, this.selectedStore._id!)
      .pipe(first())
      .subscribe((res) => {
        const url = `${res.authenticationMethod.link}${this.data.queryParamsString?.replace('?', '&')}`;
        this.actionInProgress = false;
        if (window.ReactNativeWebView) {
          this.messageEventService.postMessageToWebView(INTEGRATION_EVENT.startedAuthWorkflow, {
            redirectUrl: url,
            integration: res,
          });

          return;
        }
        window.open(url, '_self');
      });
  }
}
