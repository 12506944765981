import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppTypeConfigService } from '@core/services/app-type-config.service';
import { integrationOutlet } from './variables/outlet.variable';

@Component({
  selector: 'core-integration-workflow',
  templateUrl: './integration-workflow.component.html',
  styleUrls: ['./integration-workflow.component.scss'],
})
export class IntegrationWorkflowComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  outlet = integrationOutlet;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private appTypeConfigService: AppTypeConfigService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent instanceof NavigationStart),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        if (!this.appTypeConfigService.includesOutlet(this.outlet)) {
          this.dialogService.closeDialog();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
