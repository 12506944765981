import { Component, Input } from '@angular/core';
import { MessageEventService } from '@core/services/message-event.service';
import { Nullable } from '@core/types/nullable.type';
import { NAVIGATION_EVENT } from '@core/variables/webview-events.variable';

@Component({
  selector: 'core-post-owner',
  templateUrl: './post-owner.component.html',
  styleUrls: ['./post-owner.component.scss'],
})
export class PostOwnerComponent {
  @Input()
  username?: string;

  @Input()
  followers?: Nullable<number>;

  @Input()
  avatar?: Nullable<string>;

  @Input()
  link?: Nullable<string>;

  constructor(private messageEventService: MessageEventService) {}

  openLink(): void {
    if (!this.link) {
      return;
    }
    if (this.messageEventService.isWebView) {
      this.messageEventService.postMessageToWebView(NAVIGATION_EVENT.providerUserClicked, {
        url: this.link,
      });
    } else {
      window.open(this.link, '_blank');
    }
  }
}
