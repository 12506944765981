import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { Nullable } from '@core/types/nullable.type';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';

export class ProviderPostComment {
  readonly _id!: string;

  createdAt!: string;

  updatedAt!: string;

  provider!: ManagedPostProvider;

  providerCommentId!: string;

  providerPostId!: string;

  parentProviderCommentId: Nullable<string> = null;

  commentCreatedAt!: string;

  commentUpdatedAt: Nullable<string> = null;

  likes = 0;

  comments: Nullable<number> = null;

  text = '';

  user!: {
    username: string;
    userId: string;
  };

  constructor(object: ProviderPostComment) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for the ProviderPostComment
 */
@Injectable({
  providedIn: 'root',
})
export class ProviderPostCommentAdapter implements Adapter<ProviderPostComment> {
  clientAdapt(object: any): ProviderPostComment {
    return new ProviderPostComment(object);
  }

  serverAdapt(object: ProviderPostComment): any {
    return object;
  }
}
