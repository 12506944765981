import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { CardModule } from '@core/ui/card/card.module';
import { ProgressModule } from '@core/ui/progress/progress.module';
import { ThemeModule } from '@core/modules/theme/theme.module';
import { AlertComponent } from './components/alert/alert.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { ButtonModule } from '../button/button.module';
import { TagModule } from '../tag/tag.module';

@NgModule({
  declarations: [AlertComponent, SnackBarComponent],
  imports: [
    CommonModule,
    SvgModule,
    CardModule,
    ButtonModule,
    ProgressModule,
    TranslateModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ThemeModule,
    TagModule,
  ],
  exports: [AlertComponent, SnackBarComponent],
})
export class AlertModule {}
