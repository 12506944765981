import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { ImageFit, ImageUrlOptions } from '../../types/image.type';
import { CrossOrigin, MediaSrcVariant } from '../../types/media.type';

@Component({
  selector: 'core-video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
  styleUrls: ['./video-thumbnail.component.scss'],
})
export class VideoThumbnailComponent {
  @Input()
  variant: MediaSrcVariant = 'url';

  @Input()
  imageUrlOptions: ImageUrlOptions = {};

  @Input()
  src = '';

  @Input()
  fit: ImageFit = 'contain';

  @Input()
  crossOrigin: Nullable<CrossOrigin> = null;

  @Output()
  error = new EventEmitter();

  @Output()
  loaded = new EventEmitter();

  hasError = false;
}
