import { Component, Inject, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { GoogleAdDataService } from '@google-ad/common/services/google-ad-data.service';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoiSetupDialogResponse } from '@google-ad/common/types/roi-setup-dialog-response.type';
import { RoiSetupDialogSettings } from '@google-ad/common/types/roi-setup-dialog-settings.type';

@Component({
  selector: 'google-ad-roi-setup-dialog',
  templateUrl: './roi-setup-dialog.component.html',
  styleUrls: ['./roi-setup-dialog.component.scss'],
})
export class RoiSetupDialogComponent implements AfterViewInit, OnDestroy {
  formGroup: FormGroup = new FormGroup(
    {
      revenuePerVisit: new FormControl(this.dialogSettings?.revenuePerVisit || '', [
        Validators.required,
        Validators.min(5),
      ]),
    },
    {
      updateOn: 'change',
    },
  );

  isLoading = false;

  destroyed$ = new Subject<void>();

  storeId = this.globalFilterService.store?._id;

  @ViewChild('revenuePerVisitInput') revenuePerVisitInput!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogSettings: RoiSetupDialogSettings,
    private googleAdsDataService: GoogleAdDataService,
    private globalFilterService: GlobalFilterService,
    private snackbarService: SnackBarService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<RoiSetupDialogComponent>,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    this.revenuePerVisitInput.nativeElement.focus();
  }

  onFormSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    if (this.dialogSettings?.revenuePerVisit === this.formGroup.value.revenuePerVisit) {
      this.closeDialog({ isRoiSetupSuccessful: false });

      return;
    }

    this.isLoading = true;

    this.googleAdsDataService
      .setExpectedRevenuePerVisit(this.storeId, this.formGroup.value.revenuePerVisit)
      .pipe(
        catchError(() => {
          this.snackbarService.openSnackBar({
            text: this.translateService.instant('ERROR.GENERIC'),
            variant: 'error',
            icon: 'danger',
          });

          this.isLoading = false;
          this.closeDialog({ isRoiSetupSuccessful: false });

          return EMPTY;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.closeDialog({ isRoiSetupSuccessful: true });
      });
  }

  closeDialog(data?: RoiSetupDialogResponse): void {
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
