import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@core/modules/material/material.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { LinearProgressBarComponent } from '@core/ui/progress/components/linear-progress-bar/linear-progress-bar.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StepperProgressComponent } from './components/stepper-progress/stepper-progress.component';
import { RouteStepperProgressComponent } from './components/route-stepper-progress/route-stepper-progress.component';
import { GaugeProgressComponent } from './components/gauge-progress/gauge-progress.component';
import { KpiModule } from '../kpi/kpi.module';
import { SegmentedProgressBarComponent } from './components/segmented-progress-bar/segmented-progress-bar.component';
import { SpinnerModule } from './components/spinner/spinner.module';

const components = [
  CircleProgressComponent,
  ProgressBarComponent,
  StepperProgressComponent,
  RouteStepperProgressComponent,
  LinearProgressBarComponent,
  SegmentedProgressBarComponent,
  GaugeProgressComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    TranslateModule,
    NgCircleProgressModule.forRoot(),
    MaterialModule,
    SvgModule,
    RouterModule,
    KpiModule,
    SpinnerModule,
  ],
  exports: [...components, SpinnerModule],
})
export class ProgressModule {}
