/* eslint-disable import/order */
import { NgModule, PLATFORM_ID } from '@angular/core';

// Modules
import { Breakpoints } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ProgressModule } from './ui/progress/progress.module';
import { SvgModule } from './ui/svg/svg.module';

// Directives
import { ClickDoubleDirective } from './directives/click-double.directive';

// Pipes
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CleanStringPipe } from './pipes/clean-string.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { DateSortPipe } from './pipes/date-sort.pipe';
import { DropdownFilterPipe } from './pipes/dropdown-filter.pipe';
import { FormattedCaptionPipe } from './pipes/formatted-caption.pipe';
import { MinutesSecondsPipe } from './pipes/minutes-seconds.pipe';
import { NumberSuffixesPipe } from './pipes/number-suffixes.pipe';
import { PluralFormatPipe } from './pipes/plural-format.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { HumanizeDatePipe } from './pipes/humanize-date.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { ByteConverterPipe } from './pipes/byte-converter.pipe';

// Services
import { WindowWrapperService, getWindow } from './services/window-wrapper.service';

// Variables
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomBreakpoints } from './variables/custom-breakpoints.variable';
import { ScrollModule } from './ui/scroll/scroll.module';

const directives = [ClickDoubleDirective];

const pipes = [
  DateFormatPipe,
  CleanStringPipe,
  DateSortPipe,
  DropdownFilterPipe,
  FormattedCaptionPipe,
  MinutesSecondsPipe,
  NumberSuffixesPipe,
  PluralFormatPipe,
  TimeFormatPipe,
  TruncatePipe,
  HumanizeDatePipe,
  OrdinalPipe,
  OrderByPipe,
  ByteConverterPipe,
  TrimPipe,
];

// Ideally, we won't export modules from here in the future
const exportedModules = [ProgressModule, SvgModule, ScrollModule];

@NgModule({
  // Do not declare components here. They should be declared in their own module
  declarations: [...directives, ...pipes],
  imports: [
    ...exportedModules,
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: WindowWrapperService,
      useFactory: getWindow,
      deps: [PLATFORM_ID],
    },
    { provide: Breakpoints, useValue: CustomBreakpoints, multi: true },
    DecimalPipe,
    NumberSuffixesPipe,
    PluralFormatPipe,
  ],
  exports: [...directives, ...pipes, ...exportedModules],
})
export class CoreModule {}
