/**
 * Name of a product offered to the stores
 */
export enum Product {
  HOME = 'HOME',
  INFLUENCER = 'INFLUENCER',
  SOCIAL = 'SOCIAL',
  REVIEW = 'REVIEW',
  PHOTO = 'PHOTO',
  META_AD = 'META_AD',
  GOOGLE_AD = 'GOOGLE_AD',
  INTEGRATION = 'INTEGRATION',
  BILLING = 'BILLING',
}
