import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { AccountDetails, AuthenticationPayload, ConnectionPayload } from '@core/interfaces/integration.interface';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Integration, IntegrationAdapter } from '@core/models/integration.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Data service for Integrations
 */
@Injectable()
export class IntegrationDataService {
  constructor(
    private apiService: ApiService,
    private integrationAdapter: IntegrationAdapter,
  ) {}

  getIntegrations(storeId: string): Observable<Integration[]> {
    return this.apiService
      .get(`stores/${storeId}/integrations`)
      .pipe(
        map((res: any) =>
          res.result.map((integration: Integration) => this.integrationAdapter.clientAdapt(integration)),
        ),
      );
  }

  getIntegration(integrationIdOrProvider: string, storeId: string): Observable<Integration> {
    return this.apiService
      .get(`stores/${storeId}/integrations/${integrationIdOrProvider}`)
      .pipe(map((res: any) => this.integrationAdapter.clientAdapt(res.result)));
  }

  getUserAccounts(integrationId: string, storeId: string): Observable<AccountDetails[]> {
    return this.apiService
      .get(`stores/${storeId}/integrations/${integrationId}/authentication/accounts`)
      .pipe(map((res: ApiSuccessResponse<AccountDetails[]>) => res.result));
  }

  createAuthenticationforUser(integrationId: string, storeId: string, payload: AuthenticationPayload): Observable<any> {
    return this.apiService
      .post(`stores/${storeId}/integrations/${integrationId}/authentication`, payload)
      .pipe(map((res: any) => this.integrationAdapter.clientAdapt(res.result)));
  }

  connectAccountForIntegration(integrationId: string, storeId: string, payload: ConnectionPayload): Observable<any> {
    return this.apiService
      .post(`stores/${storeId}/integrations/${integrationId}/connection`, payload)
      .pipe(map((res: any) => this.integrationAdapter.clientAdapt(res.result)));
  }

  getConnectionDetails(storeId: string, integrationId: string): Observable<AccountDetails> {
    return this.apiService
      .get(`stores/${storeId}/integrations/${integrationId}/connection/account`)
      .pipe(map((res: any) => res.result));
  }
}
