import { Component, Input } from '@angular/core';
import { TagVariant } from '../../types/tag-small.type';

@Component({
  selector: 'core-tag-small',
  templateUrl: './tag-small.component.html',
  styleUrls: ['./tag-small.component.scss'],
  host: {
    '[class]': `"tag-small"
      + " variant-"+variant`,
    '[class.tag-small--shadow]': 'shadow',
  },
})
export class TagSmallComponent {
  @Input()
  variant: TagVariant = 'default';

  @Input()
  shadow = false;
}
