import { ReviewPipesModule } from '@review/common/pipes/review-pipes.module';
import { ReviewDirectivesModule } from '@review/common/directives/review-directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageCardsRoutingModule } from '@review/modules/manage-cards/manage-cards-routing.module';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { ChipModule } from '@core/ui/chip/chip.module';
import { ProgressModule } from '@core/ui/progress/progress.module';
import { ToggleModule } from '@core/ui/toggle/toggle.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '@core/core.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DividerModule } from '@core/ui/divider/divider.module';
import { MatSelectModule } from '@angular/material/select';
import { FormFieldModule } from '@core/ui/form-field/form-field.module';
import { SelectModule } from '@core/ui/select/select.module';
import { ManageCardsComponent } from './manage-cards.component';
import { ManageCardsAssignComponent } from './components/manage-cards-assign/manage-cards-assign.component';
import { ManageCardsListComponent } from './components/manage-cards-list/manage-cards-list.component';

@NgModule({
  declarations: [ManageCardsComponent, ManageCardsAssignComponent, ManageCardsListComponent],
  imports: [
    CommonModule,
    ManageCardsRoutingModule,
    CommonModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    ChipModule,
    ProgressModule,
    SvgModule,
    HttpClientModule,
    ToggleModule,
    CoreModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    ReviewDirectivesModule,
    ReviewPipesModule,
    DividerModule,
    FormFieldModule,
    SelectModule,
  ],
  exports: [ManageCardsComponent, ManageCardsAssignComponent, ManageCardsListComponent],
})
export class ManageCardsModule {}
