import { Injectable, Renderer2 } from '@angular/core';
import { Router, Params } from '@angular/router';
import { GoalSettingWorkflowComponent } from '@core/dialogs/goal-setting-workflow/goal-setting-workflow.component';
import { goalSettingOutlet } from '@core/dialogs/goal-setting-workflow/variables/outlet.variable';
import { DialogService } from '@core/services/dialog.service';

/**
 * Dialog service for User Goal Setting
 */
@Injectable()
export class GoalSettingDialogService {
  constructor(
    private router: Router,
    private dialogService: DialogService,
  ) {}

  closeDialog(): void {
    this.router.navigate([{ outlets: { [goalSettingOutlet]: null } }]);
  }

  navigateToDialogRoute(screenName: string, queryParams?: Params): void {
    this.router.navigate(
      [
        {
          outlets: {
            [goalSettingOutlet]: `${screenName}`,
          },
        },
      ],
      { state: queryParams, queryParamsHandling: 'merge' },
    );
  }

  openDialog(renderer: Renderer2, screenName: string, data: any): void {
    this.navigateToDialogRoute(screenName);
    this.dialogService.openCoreDialog(GoalSettingWorkflowComponent, renderer, {
      disableClose: true,
      data,
    });
  }
}
