<core-dialog
  [canGoBack]="false"
  [headerConfig]="{
    title: 'AUTH.MODULE.REGISTER.ONBOARDING_INTRO.TITLE' | translate,
    showSupport: (hasOneStoreAssigned$ | async),
    showClose: (hasOneStoreAssigned$ | async)
  }"
  [footerConfig]="{
    submitLabel: 'AUTH.MODULE.REGISTER.COMPLETE.SUBMIT' | translate
  }"
  (submit)="submit()">
  <core-store-setup-header-options *ngIf="(hasOneStoreAssigned$ | async) === false" coreDialogHeader></core-store-setup-header-options>
  <div class="row my-auto mx-n2">
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-0">
      <core-svg
        svg="img_celebrate">
      </core-svg>
    </div>
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <h5>{{ 'AUTH.MODULE.REGISTER.STORE.COMPLETE.TITLE' | translate }}</h5>
      <ul class="mt-2">
        <li class="step-item">{{ 'AUTH.MODULE.REGISTER.STORE.TITLE' | translate }}</li>
        <li class="step-item">{{ 'GOAL_SETTING.GOAL_SELECTION_DIALOG.HEADER' | translate }}</li>
        <li class="step-item">{{ 'GOAL_SETTING.PRODUCT_SELECTION_DIALOG.HEADER_TITLE' | translate }}</li>
      </ul>
    </div>
  </div>
</core-dialog>
