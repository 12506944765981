import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSort',
})
export class DateSortPipe implements PipeTransform {
  transform(array: Array<any>, property: string, order: string): Array<any> {
    if (array !== undefined && array !== null) {
      if (typeof property[0] === 'undefined') {
        return array;
      }
      if (order === '') {
        // eslint-disable-next-line no-param-reassign
        order = 'asc';
      }
      const direction = property[0][0];
      const column = property.replace('-', '');

      array.sort((a: any, b: any) => {
        const left = Number(new Date(a[column]));
        const right = Number(new Date(b[column]));
        if (order === 'asc') {
          return direction === '-' ? left - right : right - left;
        }
        if (order === 'desc') {
          return direction === '-' ? right - left : left - right;
        }

        return left - right;
      });

      return array;
    }

    return array;
  }
}
