import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DynamicDialog } from '@core/modules/dynamic-overlay/dynamic-dialog';
import { DynamicOverlay } from '@core/modules/dynamic-overlay/dynamic-overlay';
import { DynamicOverlayContainer } from '@core/modules/dynamic-overlay/dynamic-overlay-container';

// Inject these providers at the component level
// to get a fresh instance of DynamicMatDialog service with it's own
// instances of DynamicOverlay/DynamicOverlayContainer.
//
// This enables to set the overlay container element dynamically
// and separates it from any other MatDialog/Overlay/OverlayContainer instances.
export const DYNAMIC_MAT_DIALOG_PROVIDERS = [DynamicOverlayContainer, DynamicOverlay, DynamicDialog];

@NgModule({
  imports: [OverlayModule, MatDialogModule],
  providers: DYNAMIC_MAT_DIALOG_PROVIDERS,
})
export class DynamicOverlayModule {}
