import { Action } from '@ngrx/store';

export enum AppActionTypes {
  AppLogout = '[App] Logout',
}

export class AppLogout implements Action {
  readonly type = AppActionTypes.AppLogout;
}

export type AppAction = AppLogout;
