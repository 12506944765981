<div class="core-video-thumbnail" [class.core-video-thumbnail--error]="hasError">
  <core-image
    [src]="src"
    [variant]="variant"
    [crossOrigin]="crossOrigin"
    [fit]="fit"
    [imageUrlOptions]="imageUrlOptions"
    (error)="error.emit($event); hasError = true"
    (loaded)="loaded.emit($event); hasError = false">
  </core-image>
  <div class="core-video-thumbnail__play">
    <core-svg svg="play"></core-svg>
  </div>
</div>
