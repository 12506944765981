import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as platformIcons from '@core/ui/platform/variables/icons-active.variable';
import { SocialPost } from '@social/common/types/social-post.type';
import { catchError, first, tap } from 'rxjs/operators';
import { SocialPostDialogConfig } from '@social/common/types/social-post-dialog-config.type';
import { CardHeaderIconType } from '@core/ui/card/types/section-card.type';
import { PostInteractionType } from '@core/modules/post/types/post-interaction.type';
import { EMPTY } from 'rxjs';
import { socialOutlet } from '../../variables/outlet.variable';

@Component({
  selector: 'social-post-dialog',
  templateUrl: './social-post-dialog.component.html',
  styleUrls: ['./social-post-dialog.component.scss'],
})
export class SocialPostDialogComponent implements OnInit {
  commentsLoading = false;

  editLoading = false;

  insightsLoading = false;

  icon = 'social';

  iconType: CardHeaderIconType = 'default';

  constructor(
    private dialogRef: MatDialogRef<SocialPostDialogConfig>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: SocialPostDialogConfig,
  ) {}

  ngOnInit(): void {
    if (this.data.provider) {
      this.icon = platformIcons[this.data.provider];
      this.iconType = 'platform';
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowRight':
        return this.data.onIndexChange(this.data.index + 1);
      case 'ArrowLeft':
        return this.data.onIndexChange(this.data.index - 1);
      default:
    }
  }

  onDeleteClick(post: SocialPost): void {
    this.data.onDeleteClick(post).pipe(first()).subscribe();
  }

  onInsightsClick(post: SocialPost): void {
    this.insightsLoading = true;
    this.data
      .onInsightsClick(post)
      .pipe(
        tap(() => {
          this.insightsLoading = false;
        }),
        catchError(() => {
          this.insightsLoading = false;

          return EMPTY;
        }),
        first(),
      )
      .subscribe();
  }

  onInteractionClick(interaction: PostInteractionType, post: SocialPost): void {
    this.commentsLoading = interaction === 'comments';
    this.data
      .onInteractionClick(interaction, post)
      .pipe(
        tap(() => {
          this.commentsLoading = false;
        }),
        catchError(() => {
          this.commentsLoading = false;

          return EMPTY;
        }),
        first(),
      )
      .subscribe();
  }

  onCtaClick({ action, post }: { action: string; post: SocialPost }): void {
    if (action === 'edit') {
      this.editLoading = true;
    }
    this.data
      .onCtaClick({ action, post })
      .pipe(
        tap(() => {
          if (action === 'edit') {
            this.editLoading = false;
          }
        }),
        first(),
      )
      .subscribe();
  }

  removeOutlet(): void {
    this.router.navigate([{ outlets: { [socialOutlet]: null } }], { queryParamsHandling: 'merge' }).then(() => {
      this.dialogRef.close();
    });
  }
}
