// Autogenerated from app-config.json
// Do not edit
export const productTranslations: any = [
// MICRO_FRONTEND_DATA_ENTRY_POINT
{
  path: '/assets/i18n/',
  ext: '.json'
},
{
  path: '/assets/core/i18n/',
  ext: '.json'
},
{
  path: '/assets/home/i18n/',
  ext: '.json'
},
{
  path: '/assets/social/i18n/',
  ext: '.json'
},
{
  path: '/assets/review/i18n/',
  ext: '.json'
},
{
  path: '/assets/google-ad/i18n/',
  ext: '.json'
},
{
  path: '/assets/meta-ad/i18n/',
  ext: '.json'
},
{
  path: '/assets/influencer/i18n/',
  ext: '.json'
},
{
  path: '/assets/photo/i18n/',
  ext: '.json'
},
{
  path: '/assets/integration/i18n/',
  ext: '.json'
},
{
  path: '/assets/billing/i18n/',
  ext: '.json'
},
// MICRO_FRONTEND_DATA_EXIT_POINT
];

export const lokaliseApiUrl = 'https://storage.googleapis.com/lokalise-translations/business-center/';
