import { ManagedPostConfigService } from '@core/dialogs/post-manager/services/managed-post-config.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import dayjs from 'dayjs';
import { SocialPost } from '@social/common/types/social-post.type';
import { TranslateService } from '@ngx-translate/core';
import { PostMediaAspectRatio } from '@core/modules/post/types/post-media.type';
import { TagMediumConfig } from '@core/ui/tag/types/tag-medium.type';
import { CardRoundness } from '@core/ui/card/types/common.type';

@Component({
  selector: 'social-post-thumbnail',
  templateUrl: './social-post-thumbnail.component.html',
  styleUrls: ['./social-post-thumbnail.component.scss'],
  host: {
    '[id]': '"thumbnail-" + post?.id',
  },
})
export class SocialPostThumbnailComponent implements OnChanges {
  @Input()
  post!: SocialPost;

  @Input()
  size?: number;

  @Input()
  aspectRatio?: PostMediaAspectRatio;

  @Input()
  roundness: CardRoundness = 'md';

  @Input()
  isOverlayVisible = false;

  slideIndex = 0;

  statusTagConfig: Nullable<TagMediumConfig> = null;

  isPostedToday = false;

  locale: string;

  constructor(
    private translateService: TranslateService,
    private managedPostConfigService: ManagedPostConfigService,
  ) {
    this.locale = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post && changes.post.currentValue !== changes.post.previousValue) {
      this.isPostedToday = dayjs(this.post.date).isSame(dayjs(), 'day');
      this.statusTagConfig = this.managedPostConfigService.getStatusTagConfig(this.post?.status!);
    }
  }
}
