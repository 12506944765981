import { Injectable } from '@angular/core';
import { ClientViewDataAdapter } from '@core/interfaces/adapter.interface';
import { Pagination } from '@core/interfaces/pagination.interface';

/**
 * Frontend model for API success responses
 */
export class ApiSuccessResponse<T> {
  readonly status?: string;

  readonly pagination?: Pagination;

  readonly result!: T;

  constructor(object: ApiSuccessResponse<T>) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for API success responses
 */
@Injectable({
  providedIn: 'root',
})
export class ApiSuccessResponseAdapter implements ClientViewDataAdapter<ApiSuccessResponse<any>> {
  clientAdapt(object: any): ApiSuccessResponse<any> {
    return new ApiSuccessResponse(object);
  }
}
