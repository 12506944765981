import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'core-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  host: {
    '[class]': '"empty-state"',
  },
})
export class EmptyStateComponent {
  @Input()
  svg?: string;

  @Input()
  svgMaxWidth = 280;

  @Input()
  svgMaxWidthUnit: 'px' | '%' = 'px';

  @Input()
  title?: string;

  @Input()
  ctaLabel?: string;

  @Output()
  ctaClick = new EventEmitter();
}
