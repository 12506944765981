import { Injectable } from '@angular/core';
import { tap, filter, map, first } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { RoutingService } from '@core/services/routing.service';
import { PaginatedResult } from '@core/interfaces/pagination.interface';
import { GetListConfig } from '@core/interfaces/crud-service.interface';
import { AppState } from '@core/interfaces/app-state.interface';
import { Company, CompanyAdapter } from '@core/models/company.model';
import { LoadCompany, LoadCompanyList } from '@core/redux/company/company.action';
import { getCompanyById, getCompaniesByPage } from '@core/redux/company/company.selector';
import { CrudService } from './crud.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends CrudService<Company> {
  constructor(
    protected override apiService: ApiService,
    private companyAdapter: CompanyAdapter,
    private ngrxStore$: Store<AppState>,
    private routingService: RoutingService,
  ) {
    super(apiService, companyAdapter, 'companies');
  }

  /**
   * Requests the list of company or fetches them from the redux store
   * @param config the request configuration
   * @param forceRefresh requests the list of all stores again
   */
  getCompanyList(config: GetListConfig = {}, forceRefresh = false): Observable<PaginatedResult<Company>> {
    return this.ngrxStore$.pipe(
      select(getCompaniesByPage(config.page || 0)),
      tap(({ pagination, loading, loaded, errorLimit }) => {
        const limitChanged = config.limit && config.limit !== pagination.limit && !loading;
        if (errorLimit) {
          return this.routingService.navigateToRedirect('error');
        }
        if (!(loading || loaded || errorLimit) || limitChanged || forceRefresh) {
          this.ngrxStore$.dispatch(new LoadCompanyList({}));
        }

        return EMPTY;
      }),
      filter(({ loaded, pagination }) => loaded || pagination.limit === config.limit || false),
      map(({ companies, pagination }) => ({ data: companies, pagination })),
      first(),
    );
  }

  /**
   * Requests the specified company or fetches them from the redux store
   * @param id the id of the company
   */
  getCompany(id: string): Observable<Company | undefined> {
    return this.ngrxStore$.pipe(
      select(getCompanyById(id)),
      tap(({ company, loading }) => {
        if (!loading && !company) {
          this.ngrxStore$.dispatch(new LoadCompany(id));
        }
      }),
      filter(({ company }) => Boolean(company)),
      map(({ company }) => company),
      first(),
    );
  }
}
