import { RouterModule, Routes } from '@angular/router';

import { influencerOutlet } from './variables/outlet.variable';
import { InfluencerRoute } from './enums/influencer-route.enum';
import { InfluencerPostDialogComponent } from './screens/influencer-post-dialog/influencer-post-dialog.component';

const outlet = influencerOutlet;

export const makeInfluencerWorkflowRoutes = () => [
  {
    path: `${InfluencerRoute.Post}/:postId`,
    outlet,
    component: InfluencerPostDialogComponent,
  },
];

const routes: Routes = [...makeInfluencerWorkflowRoutes()];

export const InfluencerWorkflowRoutingModule = RouterModule.forChild(routes);
