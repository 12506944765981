import { BrandChartConfigService } from '@core/ui/chart/services/brand-chart-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ParamMap, ResolveFn, Router } from '@angular/router';
import { BrandConfig } from '@core/interfaces/brand-config.interface';
import { Theme } from '@core/interfaces/user-preferences.interface';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { IconRegistryService } from '@core/services/icon-registry.service';
import { ImageAssetRegistryService } from '@core/services/image-asset-registry.service';
import { Nullable } from '@core/types/nullable.type';
import { Observable, catchError, finalize, map, of, tap } from 'rxjs';
import brandConfigFilesMap from '@core/assets/brands';
import { BrandConfigFiles } from '@core/interfaces/brand-config-files.interface';

@Injectable({
  providedIn: 'root',
})
export class BrandResolverService {
  brandConfig: Nullable<BrandConfig> = null;

  brandConfigFilesMap: Record<string, BrandConfigFiles> = brandConfigFilesMap;

  svgConfig: Nullable<{
    brand: string;
    svgMap: Nullable<Record<string, string>>;
  }> = null;

  constructor(
    private httpClient: HttpClient,
    private themeService: ThemeService,
    private router: Router,
    private iconRegistryService: IconRegistryService,
    private imageAssetRegistryService: ImageAssetRegistryService,
    private brandChartConfigService: BrandChartConfigService,
  ) {}

  getSvgMap(brand: string): Observable<Record<string, string>> {
    if (this.svgConfig && brand === this.svgConfig?.brand) {
      return of(this.svgConfig?.svgMap ?? {});
    }

    return this.httpClient.get<Record<string, string>>(`/assets/core/brands/${brand}/svg-map.json`).pipe(
      catchError(() => of({})),
      tap((svgMap) => {
        this.svgConfig = { brand, svgMap };
      }),
    );
  }

  getConfig(queryParams: ParamMap): Observable<Nullable<BrandConfig>> {
    let brand: string = queryParams?.get('app_brand') ?? '';
    let theme: Theme = (queryParams?.get('app_theme') as Theme) ?? ('' as Theme);

    if (!brand && !theme) {
      return of(null);
    }

    if (this.brandConfig && this.themeService.brand === brand && this.themeService.current === theme) {
      return of(this.brandConfig);
    }

    const desiredBrandExists = !!this.brandConfigFilesMap[brand];
    const desiredThemeExists = !!this.brandConfigFilesMap[brand]?.config?.[theme];

    if (!desiredBrandExists) {
      brand = 'alphin';
    } else if (!desiredThemeExists) {
      [theme] = this.themeService.allThemes.filter((mode) => mode !== theme);
    }

    return this.getSvgMap(brand).pipe(
      tap((svgMap) => {
        this.iconRegistryService.setBrandIcons(brand, svgMap);
      }),
      map(() => {
        const config = this.brandConfigFilesMap[brand]?.config?.[theme] ?? null;
        if (config) {
          this.imageAssetRegistryService.setBrandImages(config);
        }
        this.brandChartConfigService.setChartConfig(brand, theme);

        return config;
      }),
      finalize(() => {
        this.themeService.brand = brand;
        this.themeService.current = theme;

        // If the brand or theme specified in the query params doesn't exist, update the query params to reflect the change
        if (
          (queryParams?.get('app_brand') && !desiredBrandExists) ||
          (queryParams?.get('app_theme') && !desiredThemeExists)
        ) {
          this.router.navigate([], {
            queryParams: { app_theme: theme, app_brand: brand },
            queryParamsHandling: 'merge',
          });
        }
      }),
    );
  }
}

export const BrandResolver: ResolveFn<Nullable<BrandConfig>> = (route: ActivatedRouteSnapshot) =>
  inject(BrandResolverService).getConfig(route.queryParamMap);
