<core-dialog
  [canGoBack]="false"
  [actionInProgress]="isLoading"
  [headerConfig]="{
    title: 'GADS.CAMPAIGN.ROI.SETUP.DESCRIPTION' | translate,
    showClose: dialogSettings?.showCloseIcon,
    icon: 'settings'
  }"
  [footerConfig]="{
    submitLabel: 'GADS.CAMPAIGN.ROI_SETTINGS.SUBMIT_LABEL' | translate
  }"
  (dismiss)="closeDialog()"
  (submit)="onFormSubmit()">
  <div class="dialog-container">
    <div [innerHtml]="'GADS.CAMPAIGN.ROI_SETTINGS.DESCRIPTION' | translate"></div>

    <div class="row">
      <div class="col col-12 col-sm-6 col-md-7">
        <div class="input-label">
          {{ 'GADS.CAMPAIGN.ROI_SETTINGS.AVG_DESCRIPTION' | translate }}
        </div>
        <div>{{ 'GADS.CAMPAIGN.ROI_SETTINGS.AVG_DESCRIPTION_ADDITIONALL' | translate }}</div>
        <div>{{ 'GADS.CAMPAIGN.ROI_SETTINGS.MIN_VALUE' | translate }}</div>
      </div>
      <div class="col col-12 col-sm-6 col-md-5">
        <div class="d-flex align-items-center">
          <form class="login-form" [formGroup]="formGroup" (submit)="onFormSubmit()">
            <core-form-field>
              <div class="input-prefix" coreFormFieldPrefix>
                &euro;
              </div>
              <input
                coreInput
                #revenuePerVisitInput
                formControlName="revenuePerVisit"
                type="number" />
              <span coreFormFieldError *ngIf="formGroup.controls.revenuePerVisit.errors">
                @if (formGroup.controls.revenuePerVisit.errors.required) {
                  {{ 'VALIDATION.REQUIRED' | translate }}
                }
                @if (formGroup.controls.revenuePerVisit.errors?.min) {
                  {{ 'GADS.CAMPAIGN.ROI_SETTINGS.MIN_VALUE' | translate }}
                }
              </span>
            </core-form-field>
          </form>
          <core-icon
            class="tooltip-icon"
            icon="support"
            #tooltip="matTooltip"
            (touchstart)="tooltip?.toggle()"
            [matTooltip]="'GADS.CAMPAIGN.ROI_SETTINGS.TOOLTIP' | translate">
          </core-icon>
        </div>
      </div>
    </div>
  </div>
</core-dialog>
