import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { CardSerialNumberPipe } from './card-serial-number.pipe';
import { InitialsPipe } from './initials.pipe';

@NgModule({
  declarations: [CardSerialNumberPipe, InitialsPipe],
  imports: [CommonModule, CoreModule],
  exports: [CardSerialNumberPipe, InitialsPipe],
})
export class ReviewPipesModule {}
