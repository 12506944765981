import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateTimeValidator {
  /**
   * Check if current date greater than passed value
   * @param minutes Number of minutes to compare with current date
   * @returns
   */
  static isCurrentDateMoreThanSpecifiedMinutesValidator(minutes: number): ValidatorFn {
    return (control: AbstractControl) => {
      const controlDate = new Date(control.value);
      const targetDate = new Date(new Date().getTime() + minutes * 60000);

      if (controlDate <= targetDate) {
        return { invalidDateTimeComparison: true };
      }

      return null;
    };
  }
}
