import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SvgModule } from '@core/ui/svg/svg.module';
import { PlatformModule } from '@core/ui/platform/platform.module';
import { DialogService } from '@core/services/dialog.service';
import { FormFieldModule } from '@core/ui/form-field/form-field.module';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoiSetupDialogComponent } from './screens/roi-setup-dialog/roi-setup-dialog.component';
import { GoogleAdWorkflowRoutingModule } from './google-ad-workflow-routing.module';
import { GoogleAdWorkflowComponent } from './google-ad-workflow.component';

@NgModule({
  declarations: [GoogleAdWorkflowComponent, RoiSetupDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule,
    RouterModule,
    SvgModule,
    TranslateModule,
    PlatformModule,
    GoogleAdWorkflowRoutingModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FormFieldModule,
  ],
  providers: [DialogService],
  exports: [GoogleAdWorkflowComponent],
})
export class GoogleAdWorkflowModule {}
