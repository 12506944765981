import { BrandConfig } from '@core/interfaces/brand-config.interface';

const config: BrandConfig = {
  brand: 'orderbird',
  theme: 'light',
  images: {
    avatars: {},
    backgrounds: {},
    backgroundTextures: {},
  },
  elementStyles: {
    // Editing these does nothing yet
    card: 'raised',
    button: 'outlined',
    dialog: 'raised',
  },
};

export default config;
