<div class="device" [class.device--dark]="(theme$ | async) === 'dark'">
  <div class="device__preview">
    <div class="device__content">
      <core-post-owner
        *ngIf="owner"
        class="p-2"
        [avatar]="owner?.avatar"
        [username]="owner?.username"
        [followers]="owner?.followers">
      </core-post-owner>
      <core-divider></core-divider>
      <core-post-media
        [slides]="slides"
        [variant]="mediaVariant"
        (loaded)="loaded.emit($event)"
        (fallbackLoaded)="fallbackLoaded.emit($event)"
        (error)="error.emit($event)"
        (slideChange)="slideChange.emit($event)">
      </core-post-media>
      <core-divider></core-divider>

      <div class="p-2">
        <core-post-interactions *ngIf="counts" [counts]="counts" class="mb-1"></core-post-interactions>
        <core-post-caption *ngIf="caption" [text]="caption" class="mb-1"></core-post-caption>
        <core-post-date *ngIf="date" [date]="date"></core-post-date>
      </div>
      
      <div class="device__bottom-spacer"></div>
    </div>
  </div>
</div>
