import { AppSettingsService } from '@core/services/app-settings.service';
import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import 'emoji-picker-element';
import en from 'emoji-picker-element/i18n/en';
import de from 'emoji-picker-element/i18n/de';
import { I18n } from 'emoji-picker-element/shared';
import { SupportedLanguage } from '@core/enums/user-preference.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const translations: Record<SupportedLanguage, I18n> = { en, de };

type EmojiEvent = any;

@Component({
  selector: 'core-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent implements OnInit {
  panelOpen = false;

  @Output()
  emojiSelect = new EventEmitter<EmojiEvent>();

  i18n = translations[this.appSettingsService.currentLanguage];

  destroyRef = inject(DestroyRef);

  constructor(private appSettingsService: AppSettingsService) {}

  ngOnInit(): void {
    this.appSettingsService.language$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.i18n = translations[this.appSettingsService.currentLanguage];
    });
  }

  overlayOutsideClick(event: PointerEvent): void {
    if ((event.target as any)?.tagName === 'EMOJI-PICKER') {
      return;
    }
    this.panelOpen = false;
  }
}
