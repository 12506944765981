import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagSmallComponent } from './components/tag-small/tag-small.component';
import { TagMediumComponent } from './components/tag-medium/tag-medium.component';
import { TagLargeComponent } from './components/tag-large/tag-large.component';
import { SvgModule } from '../svg/svg.module';

@NgModule({
  declarations: [TagSmallComponent, TagMediumComponent, TagLargeComponent],
  imports: [CommonModule, SvgModule],
  exports: [TagSmallComponent, TagMediumComponent, TagLargeComponent],
})
export class TagModule {}
