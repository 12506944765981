import { AppSettingsService } from '@core/services/app-settings.service';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

/**
 * Service for handing the title of the current page
 */
@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private titleSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public appTitle$: Observable<string> = of('');

  /**
   * The page title is updated when the route, the language or the title
   * of the application are changed
   */
  public pageTitle$ = combineLatest(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ),
    this.appSettingsService.language$,
    this.titleSubject$,
  ).pipe(map(() => this.makeTitleFromRoute(this.activatedRoute.root.firstChild!, [this.getTitle()])));

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
    private title: Title,
  ) {
    this.appTitle$ = this.titleSubject$.asObservable();
  }

  public setTitle(title: string) {
    this.titleSubject$.next(title);
  }

  public getTitle(): string {
    return this.titleSubject$.value;
  }

  /**
   * Creates the page title from the routing
   * @param route the route to be checked
   * @param parentSections the parent sections of the route being checked
   */
  private makeTitleFromRoute(route: ActivatedRoute, parentSections: Array<string> = []): string {
    const section: string = route.routeConfig?.data?.title
      ? this.translateService.instant(route.routeConfig.data.title)
      : route.routeConfig?.path;
    const currentRouteSections =
      section && section !== '**' && !section.startsWith(':') ? [...parentSections, section] : parentSections;
    if (route.firstChild) {
      return this.makeTitleFromRoute(route.firstChild, currentRouteSections);
    }
    const pageTitle = currentRouteSections.join(' > ');
    this.title.setTitle(pageTitle);

    return pageTitle;
  }
}
