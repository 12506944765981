// Autogenerated from app-config.json
// Do not edit
// MICRO_FRONTEND_DATA_ENTRY_POINT
import homeImports from '@home/configs/app-root-imports.config';
import socialImports from '@social/configs/app-root-imports.config';
import reviewImports from '@review/configs/app-root-imports.config';
import googleadImports from '@google-ad/configs/app-root-imports.config';
import influencerImports from '@influencer/configs/app-root-imports.config';
import photoImports from '@photo/configs/app-root-imports.config';
import integrationImports from '@integration/configs/app-root-imports.config';
import billingImports from '@billing/configs/app-root-imports.config';

export default [...homeImports,...socialImports,...reviewImports,...googleadImports,...influencerImports,...photoImports,...integrationImports,...billingImports];
// MICRO_FRONTEND_DATA_EXIT_POINT
