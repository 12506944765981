<button
  *ngIf="cancelLabel"
  coreButton
  type="button"
  [variant]="secondaryVariant"
  [icon]="secondaryIcon"
  [size]="size"
  [disabled]="cancelDisabled || actionInProgress"
  (click)="cancelClick.emit()">
  {{ cancelLabel }}
</button>
<div class="spacer" *ngIf="submitLabel && cancelLabel"></div>
<button
  *ngIf="submitLabel"
  coreButton
  type="submit"
  [variant]="variant"
  [icon]="primaryIcon"
  [size]="size"
  [actionInProgress]="actionInProgress"
  [disabled]="submitDisabled"
  (click)="submitClick.emit()">
  {{ submitLabel }}
</button>
