import { Injectable } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

/** Provider that defines how form controls behave with regards to displaying error messages. */
@Injectable({ providedIn: 'root' })
export class ErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.touched || (form && form.submitted)));
  }
}

/**
 * Class that tracks the error state of a component.
 */
export class ErrorStateTracker {
  /** Whether the tracker is currently in an error state. */
  errorState = false;

  /** User-defined matcher for the error state. */
  matcher!: ErrorStateMatcher;

  constructor(
    private _defaultMatcher: ErrorStateMatcher | null,
    public ngControl: NgControl | null,
    private parentFormGroup: FormGroupDirective | null,
    private parentForm: NgForm | null,
    private stateChanges$: Subject<void>,
  ) {}

  /** Updates the error state based on the provided error state matcher. */
  updateErrorState() {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.matcher || this._defaultMatcher;
    const control = this.ngControl ? (this.ngControl.control as AbstractControl) : null;
    // Note: the null check here shouldn't be necessary, but there's an internal
    // test that appears to pass an object whose `isErrorState` isn't a function.
    const newState = typeof matcher?.isErrorState === 'function' ? matcher.isErrorState(control, parent) : false;

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges$.next();
    }
  }
}
