import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

// App Init
import { AppRoutingModule } from '@app/app-routing.module';
import { AppSettingsService } from '@core/services/app-settings.service';
import { AppComponent } from '@app/app.component';
import { ApiService, APISERVICE_BASEURL } from '@core/services/api.service';
import { AppPerformanceService } from '@core/services/app-performance.service';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { RemoteConfigModule } from '@angular/fire/remote-config';

// Redux
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { effects } from '@core/redux/effects.redux';
import { reducers } from '@core/redux/reducers.redux';
import { clearState } from '@core/redux/app/app.reducer';

// Internationalization
import { CustomTranslateLoader } from '@core/services/custom-translate-loader.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { productTranslations, lokaliseApiUrl } from '@app/configs/product-translations.config'; // Do not remove - auto generated paths from config

// Interceptors
import { AccessTokenInterceptor } from '@core/interceptors/access-token.interceptor';
import { AppInformationInterceptor } from '@core/interceptors/app-information.interceptor';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { REQUESTHEADER_APPNAME, APP_VERSION } from '@core/variables/injection-tokens.variable';
import { DialogService } from '@core/services/dialog.service';
import { AlertModule } from '@core/ui/alert/alert.module';
import { DynamicOverlayModule } from '@core/modules/dynamic-overlay/dynamic-overlay.module';
import { MaterialModule } from '@core/modules/material/material.module';
import appRootImportsConfig from '@app/configs/app-root-imports.config'; // Do not remove - auto generated from config
import { ApmErrorHandler, ApmModule } from '@elastic/apm-rum-angular';
import { IntegrationWorkflowModule } from '@core/dialogs/integration-workflow/integration-workflow.module';
import { StoreSetupWorkflowModule } from '@core/dialogs/store-setup-workflow/store-setup-workflow.module';
import { GoalSettingWorkflowModule } from '@core/dialogs/goal-setting-workflow/goal-setting-workflow.module';
import { UserInviteWorkflowModule } from '@core/dialogs/user-invite-workflow/user-invite-workflow.module';
import { PostManagerModule } from '@core/dialogs/post-manager/post-manager.module';
import { PostViewerModule } from '@core/dialogs/post-viewer/post-viewer.module';
import { SnackbarUpdateModule } from './components/snackbar-update/snackbar-update.module';
import { IS_ALPHIN_FE_URL } from './variables/regex.variable';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new CustomTranslateLoader(
    httpClient,
    productTranslations, // Do not remove - auto generated paths from config
    lokaliseApiUrl,
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    MaterialModule,
    AlertModule,
    SnackbarUpdateModule,
    DynamicOverlayModule,
    ApmModule,
    IntegrationWorkflowModule,
    StoreSetupWorkflowModule,
    GoalSettingWorkflowModule,
    UserInviteWorkflowModule,
    PostManagerModule,
    PostViewerModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    RemoteConfigModule,
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot(effects),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ApiService],
      },
      isolate: true,
    }),
    ...appRootImportsConfig, // Do not remove - auto generated from config
    ServiceWorkerModule.register('/main-sw.js', { enabled: new RegExp(IS_ALPHIN_FE_URL).test(window.location.href) }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [AppSettingsService],
      useFactory: (appSettingService: any) => appSettingService.currentLanguage,
    },
    { provide: REQUESTHEADER_APPNAME, useValue: 'ALPHIN' },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppInformationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APISERVICE_BASEURL, useValue: environment.baseUrlV1 },
    { provide: APP_VERSION, useValue: environment.appVersion },
    { provide: ErrorHandler, useClass: ApmErrorHandler },
    AppPerformanceService,
    DialogService,
  ],
})
export class AppModule {
  constructor(private appPerformanceService: AppPerformanceService) {
    this.appPerformanceService.init(environment?.apm);
  }
}
