/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { ActionBase } from '@core/interfaces/action-base.interface';
import { Pagination } from '@core/interfaces/pagination.interface';
import { GetListConfig } from '@core/interfaces/crud-service.interface';

/** ******************************************* */
// Load list
/** ****************************************** */

export class LoadList {
  constructor(public config: GetListConfig) {}
}

export class LoadListSuccess<T> {
  constructor(
    public payload: T[],
    public pagination: Pagination,
  ) {}
}

export class LoadListFail {
  constructor(
    public error: HttpErrorResponse,
    public page: number,
  ) {}
}

/** ******************************************* */
// Load object
/** ****************************************** */

export class LoadObject {
  constructor(public id: string) {}
}

export class LoadObjectSuccess<T> {
  constructor(public payload: T) {}
}

export class LoadObjectFail {
  constructor(public error: HttpErrorResponse) {}
}

/** ******************************************* */
// Create object
/** ****************************************** */

export class CreateObject<T> {
  constructor(public payload: T) {}
}

export class CreateObjectSuccess<T> {
  constructor(public payload: T) {}
}

export class CreateObjectFail {
  constructor(public error: HttpErrorResponse) {}
}

/** ******************************************* */
// Update object
/** ****************************************** */

export class UpdateObject<T> {
  constructor(
    public id: string,
    public payload: T,
  ) {}
}

export class UpdateObjectSuccess<T> {
  constructor(public payload: T) {}
}

export class UpdateObjectFail {
  constructor(public error: HttpErrorResponse) {}
}

/** ******************************************* */
// Delete object
/** ****************************************** */

export class DeleteObject {
  constructor(public id: string) {}
}

export class DeleteObjectSuccess {
  constructor(public id: string) {}
}

export class DeleteObjectFail {
  constructor(public error: HttpErrorResponse) {}
}

export interface CrudActions {
  loadList: ActionBase;
  loadObject: ActionBase;
  createObject: ActionBase;
  updateObject: ActionBase;
  deleteObject: ActionBase;
}
