import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { User } from '@core/models/user.model';
import { UserService } from '@core/services/user.service';
import { CrudActions } from '@core/redux/crud/crud.action';
import { CrudEffects } from '@core/redux/crud/crud.effect';
import {
  LoadUserList,
  LoadUserListSuccess,
  LoadUserListFail,
  LoadUser,
  LoadUserSuccess,
  LoadUserFail,
  CreateUser,
  CreateUserSuccess,
  CreateUserFail,
  UpdateUser,
  UpdateUserSuccess,
  UpdateUserFail,
  DeleteUser,
  DeleteUserSuccess,
  DeleteUserFail,
  UserActionTypes,
} from './user.action';

const crudActions: CrudActions = {
  loadList: {
    type: UserActionTypes.LoadUserList,
    action: LoadUserList,
    ActionSuccess: LoadUserListSuccess,
    ActionFail: LoadUserListFail,
  },

  loadObject: {
    type: UserActionTypes.LoadUser,
    action: LoadUser,
    ActionSuccess: LoadUserSuccess,
    ActionFail: LoadUserFail,
  },

  createObject: {
    type: UserActionTypes.CreateUser,
    action: CreateUser,
    ActionSuccess: CreateUserSuccess,
    ActionFail: CreateUserFail,
  },

  updateObject: {
    type: UserActionTypes.UpdateUser,
    action: UpdateUser,
    ActionSuccess: UpdateUserSuccess,
    ActionFail: UpdateUserFail,
  },

  deleteObject: {
    type: UserActionTypes.DeleteUser,
    action: DeleteUser,
    ActionSuccess: DeleteUserSuccess,
    ActionFail: DeleteUserFail,
  },
};

@Injectable()
export class UserEffects extends CrudEffects<User> {
  constructor(
    public override actions$: Actions,
    public override crudService: UserService,
  ) {
    super(actions$, crudService, crudActions);
  }

  override loadList$: any;

  override loadObject$: any;

  override createObject$: any;

  override updateObject$: any;

  override deleteObject$: any;
}
