import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateModule } from '@core/ui/empty-state/empty-state.module';
import { PostModule } from '@core/modules/post/post.module';
import { ButtonModule } from '@core/ui/button/button.module';

import { InfluencerPostComponent } from './influencer-post.component';

@NgModule({
  declarations: [InfluencerPostComponent],
  imports: [CommonModule, TranslateModule, PostModule, EmptyStateModule, ButtonModule],
  exports: [InfluencerPostComponent],
  providers: [],
})
export class InfluencerPostModule {}
