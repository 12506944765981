import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { RoutingService } from '@core/services/routing.service';
import { AuthService } from '@core/services/auth.service';

/**
 * If a user is already authenticated, redirects to the home page
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuardService {
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.validAccessToken()) {
      this.routingService.navigateToHome(route.queryParams);

      return false;
    }

    return true;
  }
}

export const LoginGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => inject(LoginGuardService).canActivate(route);
