<core-dialog
  [actionInProgress]="actionInProgress"
  [canGoBack]="true"
  [headerConfig]="{
    title: 'GOAL_SETTING.GOAL_SELECTION_DIALOG.HEADER' | translate
  }"
  [footerConfig]="{
    submitLabel: 'ACTION.CONTINUE' | translate,
    backLabel: 'ACTION.BACK' | translate
  }"
  class="dialog"
  (back)="goBack()"
  (submit)="onSubmit()">
  <div>
    <core-linear-progress-bar variant="emphasis" [value]="0.7" [total]="1"></core-linear-progress-bar>
    <div *ngIf="!loading; else spinnerTemplate">
      <h5 class="mt-4 mb-3 text-center">{{ 'GOAL_SETTING.GOAL_SELECTION_DIALOG.DESCRIPTION' | translate }}</h5>
      <p class="error-message mb-2" *ngIf="showNoGoalsSelectedError">{{ 'GOAL_SETTING.GOAL_SELECTION_DIALOG.NO_GOAL_ERROR' | translate }}</p>
      <p class="error-message mb-2" *ngIf="selectedGoals.length > maxNumberOfGoals">{{ 'GOAL_SETTING.GOAL_SELECTION_DIALOG.MAX_GOAL_ERROR' | translate }}</p>
      <div class="row">
        <div class="col col-12 col-sm-6 mt-3" *ngFor="let goal of goals; let i = index">
          <core-card
            roundness="md"
            (click)="goalChangeHandler(goal.key)"
            [selected]="isSelected(goal.key)"
            [selectable]="true">
            <div class="goal-item d-flex align-items-start justify-content-between">
              <core-checkbox [value]="isSelected(goal.key)"></core-checkbox>
              <span class="mx-3">
                {{ goal.label | translate }}
              </span>
              <core-icon
                size="lg"
                [icon]="(goal.productKeys?.[0] || 'alphin') | lowercase">
              </core-icon>
            </div>
          </core-card>
        </div>
      </div>
    </div>
    <ng-template #spinnerTemplate>
      <core-spinner class="spinner"></core-spinner>
    </ng-template>
  </div>
</core-dialog>
