<core-dialog
  [style.height]="'unset'"
  [style.width.px]="575"
  [headerConfig]="{
    title: 'IAM.DELETE_POST.DIALOG.HEADER_TITLE' | translate
  }"
  [footerConfig]="{
    submitLabel: 'ACTION.DELETE' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate,
    submitDisabled: formGroup.invalid,
    variant: 'emphasis'
  }"
  (dismiss)="removeOutlet(false)"
  (submit)="removeOutlet(true)">

  <form [formGroup]="formGroup" class="d-flex flex-column flex-md-row align-items-stretch">
    <div *ngIf="data?.post" class="media-preview px-3">
      <social-post-thumbnail
        [post]="data.post"
        [size]="204"
        [aspectRatio]="{ w: 164, h: 204 }">
      </social-post-thumbnail>
    </div>

    <div class="d-flex flex-column justify-content-between px-3 pb-3">
      <section>
        <h6>{{ 'IAM.DELETE_POST.DIALOG.TITLE' | translate: { provider: data?.post?.raw.provider | titlecase } }}</h6>
        <p>{{ 'IAM.DELETE_POST.DIALOG.TEXT' | translate: { provider: data?.post?.raw.provider | titlecase } }}</p>
      </section>

      <core-checkbox formControlName="warningAccepted" labelPosition="after" [multiLineLabel]="true">
        {{ 'IAM.DELETE_POST.DIALOG.CHECKBOX' | translate }}
      </core-checkbox>
    </div>
  </form>

</core-dialog>
