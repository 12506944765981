import { Component, Input } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { KpiVariant, KpiFontSize, KpiCustomColorConfig } from '../../types/kpi-card-large.type';

@Component({
  selector: 'core-kpi-card-large',
  templateUrl: './kpi-card-large.component.html',
  styleUrls: ['./kpi-card-large.component.scss'],
  host: {
    '[class]': `"kpi-card-large"
      + " value-"+fontSize
      + " variant-"+variant`,
    '[class.background-image]': 'src',
    '[class.font-variant--dark]': 'customColorConfig?.font === "dark-text"',
    '[class.font-variant--light]': 'customColorConfig?.font === "light-text"',
  },
})
export class KpiCardLargeComponent {
  @Input()
  src = '';

  @Input()
  icon = '';

  @Input()
  label = '';

  @Input()
  sublabel = '';

  @Input()
  tooltip = '';

  @Input()
  fontSize: KpiFontSize = 'sm';

  @Input()
  variant: KpiVariant = 'default';

  @Input()
  emptyStateText = '';

  @Input()
  isEmpty = false;

  @Input()
  selectable = false;

  @Input()
  customColorConfig: Nullable<KpiCustomColorConfig> = null;
}
