import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { filter, first } from 'rxjs/operators';
import { AuthUser } from '@core/models/auth-user.model';
import { AgentConfigOptions } from '@elastic/apm-rum';
import { AuthUserService } from './auth-user.service';

@Injectable()
export class AppPerformanceService {
  constructor(
    private apmService: ApmService,
    private authUserService: AuthUserService,
  ) {}

  /**
   * Agent API is exposed through this apm instance
   */
  init({ serviceName, serviceVersion, serverUrl, environment }: AgentConfigOptions = {}): void {
    if (!serviceName) {
      return;
    }
    const apm = this.apmService.init({
      serviceName,
      serviceVersion,
      serverUrl,
      environment,
      active: true,
      instrument: true,
      logLevel: 'warn',
      breakdownMetrics: true,
    });
    this.authUserService.authUser$
      .pipe(
        filter((value) => !!value),
        first(),
      )
      .subscribe((user: AuthUser) => {
        apm.setUserContext({
          email: user.contact.email,
          id: user._id,
        });
      });
  }
}
