import color from 'color';

/**
 * Adds alpha to hexidecimal color value
 */
export const opacity = (colorValue: string, alphaValue: number) => color(colorValue).alpha(alphaValue).toString();

/**
 * Lightens a hexidecimal color value
 */
export const lighten = (colorValue: string, alphaValue: number) => color(colorValue).lighten(alphaValue).toString();

/**
 * Darkens a hexidecimal color value
 */
export const darken = (colorValue: string, alphaValue: number) => color(colorValue).darken(alphaValue).toString();
