import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SvgModule } from '@core/ui/svg/svg.module';
import { DialogService } from '@core/services/dialog.service';
import { PostManagerModule } from '@core/dialogs/post-manager/post-manager.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { InfluencerPostModule } from '@influencer/common/components/influencer-post/influencer-post.module';
import { InfluencerWorkflowRoutingModule } from './influencer-workflow-routing.module';
import { InfluencerWorkflowComponent } from './influencer-workflow.component';
import { InfluencerPostDialogComponent } from './screens/influencer-post-dialog/influencer-post-dialog.component';

@NgModule({
  declarations: [InfluencerWorkflowComponent, InfluencerPostDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule,
    RouterModule,
    SvgModule,
    TranslateModule,
    InfluencerPostModule,
    PostManagerModule,
    ButtonModule,
    InfluencerWorkflowRoutingModule,
  ],
  providers: [DialogService],
  exports: [InfluencerWorkflowComponent],
})
export class InfluencerWorkflowModule {}
