import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { CoreModule } from '@core/core.module';
import { DialogService } from '@core/services/dialog.service';
import { PostModule } from '@core/modules/post/post.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { KpiModule } from '@core/ui/kpi/kpi.module';
import { TranslateModule } from '@ngx-translate/core';
import { PostViewerRoutingModule } from './post-viewer-routing.module';
import { PostViewerComponent } from './post-viewer.component';
import { PostPreviewComponent } from './screens/post-preview/post-preview.component';
import { PostInsightsComponent } from './screens/post-insights/post-insights.component';

@NgModule({
  declarations: [PostViewerComponent, PostPreviewComponent, PostInsightsComponent],
  imports: [
    CommonModule,
    DialogModule,
    CoreModule,
    PostModule,
    ButtonModule,
    KpiModule,
    TranslateModule,
    PostViewerRoutingModule,
  ],
  providers: [DialogService],
  exports: [PostViewerComponent],
})
export class PostViewerModule {}
