import { Inject, Injectable, NgZone, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { BlockScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';

import { DynamicDialog } from '@core/modules/dynamic-overlay/dynamic-dialog';
import { Nullable } from '@core/types/nullable.type';
import { DOCUMENT } from '@angular/common';
import { IntercomService } from './intercom.service';

@Injectable()
export class DialogService {
  public dialogRef?: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private customDialog: DynamicDialog,
    private intercomService: IntercomService,
    private viewportRuler: ViewportRuler,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
  ) {}

  openCoreDialog(
    component: any,
    renderer: Renderer2,
    matDialogConfig: MatDialogConfig,
    customContainer?: HTMLElement,
  ): Nullable<MatDialogRef<any, any>> {
    const container = customContainer || this.customDialog.getDialogContainerElement();
    if (!container || !renderer) {
      return null;
    }
    this.customDialog.setContainerElement(container, renderer);
    this.dialogRef = this.customDialog.open(component, {
      ...matDialogConfig,
      panelClass: 'core-dialog',
      scrollStrategy: new BlockScrollStrategy(this.viewportRuler, this.document),
    });
    this.intercomService.updateFab(false);

    return this.dialogRef;
  }

  /**
   * Close dialog component and custom overlay
   */
  public closeDialog(): void {
    this.intercomService.updateFab(true);
    this.ngZone.run(() => {
      this.dialogRef?.close();
      this.customDialog.closeAll();
      this.dialog.closeAll();
    });
  }
}
