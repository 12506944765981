<button
  coreIconButton
  [matMenuTriggerFor]="dialogMenu"
  icon="options_vertical">
</button>
<mat-menu
  xPosition="before"
  yPosition="below"
  #dialogMenu="matMenu"
  class="core-menu-panel">
  <ng-container *ngIf="intercomService.available">
    <div mat-menu-item (click)="openSupport()">
      <div class="d-flex align-items-center">
        <core-icon icon="user_help"></core-icon>
        <span>{{ 'SIDEBAR.USER_MENU.HELP' | translate }}</span>
      </div>
    </div>
  </ng-container>
  <div mat-menu-item (click)="logout()">
    <div class="d-flex align-items-center">
      <core-icon icon="logout"></core-icon>
      <span>{{ 'SIDEBAR.USER_MENU.LOGOUT' | translate }}</span>
    </div>
  </div>
</mat-menu>
