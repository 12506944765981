import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatRippleModule } from '@angular/material/core';
import { SvgModule } from '../svg/svg.module';
import { SelectComponent } from './components/select/select.component';
import { OptionComponent } from './components/option/option.component';
import { DividerModule } from '../divider/divider.module';
import { SelectTriggerDirective } from './directives/select-trigger.directive';

const components = [SelectComponent, OptionComponent];
const directives = [SelectTriggerDirective];

@NgModule({
  declarations: [...components, ...directives],
  imports: [
    CommonModule,
    SvgModule,
    ReactiveFormsModule,
    FormsModule,
    DividerModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    NgClass,
    TranslateModule,
    MatRippleModule,
  ],
  exports: [...components, ...directives],
})
export class SelectModule {}
