import { ThemeModule } from '@core/modules/theme/theme.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogService } from '@core/services/dialog.service';
import { ButtonModule } from '@core/ui/button/button.module';
import { CardModule } from '@core/ui/card/card.module';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { AvatarModule } from '@core/ui/avatar/avatar.module';
import { ToggleModule } from '@core/ui/toggle/toggle.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@core/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CoreModule } from '@core/core.module';
import { AlertModule } from '@core/ui/alert/alert.module';
import { TagModule } from '@core/ui/tag/tag.module';
import { IntegrationWorkflowComponent } from './integration-workflow.component';
import { IntegrationWorkflowRoutingModule } from './integration-workflow-routing.module';
import { AuthIntegrationIntroDialogComponent } from './screens/auth-integration/auth-integration-intro-dialog/auth-integration-intro-dialog.component';
import { IntegrationEmptyDialogComponent } from './screens/common/integration-empty-dialog/integration-empty-dialog.component';
import { AuthIntegrationConnectDialogComponent } from './screens/auth-integration/auth-integration-connect-dialog/auth-integration-connect-dialog.component';
import { AuthIntegrationUnauthorizedDialogComponent } from './screens/auth-integration/auth-integration-unauthorized-dialog/auth-integration-unauthorized-dialog.component';
import { IntegrationDataService } from './services/integration-data.service';
import { IntegrationDialogService } from './services/integration-dialog.service';
import { IntegrationDataHelperService } from './services/integration-data-helper.service';
import { NoneIntgConnectDialogComponent } from './screens/none-authtype-integration/none-intg-connect-dialog/none-intg-connect-dialog.component';
import { ProviderAccountListModule } from './components/provider-account-list/provider-account-list.module';

@NgModule({
  declarations: [
    IntegrationWorkflowComponent,
    AuthIntegrationIntroDialogComponent,
    AuthIntegrationConnectDialogComponent,
    AuthIntegrationUnauthorizedDialogComponent,
    NoneIntgConnectDialogComponent,
    IntegrationEmptyDialogComponent,
  ],
  imports: [
    CommonModule,
    IntegrationWorkflowRoutingModule,
    TranslateModule,
    ButtonModule,
    DialogModule,
    RouterModule,
    SvgModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    ToggleModule,
    MaterialModule,
    ThemeModule,
    TagModule,
    CoreModule,
    AvatarModule,
    AlertModule,
    ProviderAccountListModule,
  ],
  providers: [
    DialogService,
    IntegrationDataService,
    IntegrationDialogService,
    CookieService,
    IntegrationDataHelperService,
  ],
  exports: [IntegrationWorkflowComponent],
})
export class IntegrationWorkflowModule {}
