import { EntityState, Dictionary } from '@ngrx/entity';
import { Pagination } from '@core/interfaces/pagination.interface';

/**
 * Base entity state for CRUD actions
 */
export interface CrudEntityState<T> extends EntityState<T> {
  pagination: Pagination;
  loadingPages: Dictionary<boolean>;
  loadedPages: Dictionary<boolean>;

  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;

  loaded: boolean;
  created: boolean;
  updated: boolean;
  deleted: boolean;

  errorCount: number;
}

/**
 * Initial state for the entity adapter
 */
export const crudInitialState: any = {
  pagination: {
    current: 0,
    first: 0,
    hasNext: false,
    hasPrev: false,
    last: 0,
    limit: 0,
    totalCount: 0,
  },
  loadingPages: {},
  loadedPages: {},

  loading: false,
  creating: false,
  updating: false,
  deleting: false,

  loaded: false,
  created: false,
  updated: false,
  deleted: false,

  errorCount: 0,
};

/** ******************************************* */
// Load list
/** ****************************************** */

/**
 * Returns the new state after a load list action is dispatched
 * @param state the current state
 * @param action the load list action
 */
export const getLoadListState = (state: any, action: any) => {
  const loadingPages = { ...state.loadingPages, [action.config.page]: true };
  const loadedPages = { ...state.loadedPages, [action.config.page]: false };

  return {
    ...state,
    loadingPages,
    loadedPages,
  };
};

/**
 * Returns the new state after a load list success action is dispatched
 * @param state the current state
 * @param action the load list success action
 */
export const getLoadListSuccessState = (state: any, action: any) => {
  const loadingPages = { ...state.loadingPages, [action.pagination.current]: false };
  const loadedPages = { ...state.loadedPages, [action.pagination.current]: true };

  return {
    ...state,
    loadingPages,
    loadedPages,
    pagination: { ...action.pagination },
    errorCount: 0,
  };
};

/**
 * Returns the new state after a load list fail action is dispatched
 * @param state the current state
 * @param action the load list fail action
 */
export const getLoadListFailState = (state: any, action: any) => {
  const loadingPages = { ...state.loadingPages, [action.page]: false };
  const loadedPages = { ...state.loadedPages, [action.page]: false };
  const errorCount = state.errorCount + 1;

  return {
    ...state,
    loadingPages,
    loadedPages,
    errorCount,
  };
};

/** ******************************************* */
// Load object
/** ****************************************** */

/**
 * Returns the new state after a load object action is dispatched
 * @param state the current state
 */
export const getLoadObjectState = (state: any) => ({
  ...state,
  loading: true,
  loaded: false,
});

/**
 * Returns the new state after a load object success action is dispatched
 * @param state the current state
 */
export const getLoadObjectSuccessState = (state: any) => ({
  ...state,
  loading: false,
  loaded: true,
  error: 0,
});

/**
 * Returns the new state after a load object fail action is dispatched
 * @param state the current state
 */
export const getLoadObjectFailState = (state: any) => {
  const errorCount = state.errorCount + 1;

  return {
    ...state,
    loading: false,
    loaded: false,
    errorCount,
  };
};

/** ******************************************* */
// Create object
/** ****************************************** */

/**
 * Returns the new state after a create object action is dispatched
 * @param state the current state
 */
export const getCreateObjectState = (state: any) => ({
  ...state,
  creating: true,
  created: false,
});

/**
 * Returns the new state after a create object success action is dispatched
 * @param state the current state
 */
export const getCreateObjectSuccessState = (state: any) => ({
  ...state,
  creating: false,
  created: true,
  errorCount: 0,
});

/**
 * Returns the new state after a create object fail action is dispatched
 * @param state the current state
 */
export const getCreateObjectFailState = (state: any) => {
  const errorCount = state.errorCount + 1;

  return {
    ...state,
    creating: false,
    created: false,
    errorCount,
  };
};

/** ******************************************* */
// Update object
/** ****************************************** */

/**
 * Returns the new state after a update object action is dispatched
 * @param state the current state
 */
export const getUpdateObjectState = (state: any) => ({
  ...state,
  updating: true,
  updated: false,
});

/**
 * Returns the new state after a update object success action is dispatched
 * @param state the current state
 */
export const getUpdateObjectSuccessState = (state: any) => ({
  ...state,
  updating: false,
  updated: true,
  errorCount: 0,
});

/**
 * Returns the new state after a update object fail action is dispatched
 * @param state the current state
 */
export const getUpdateObjectFailState = (state: any) => {
  const errorCount = state.errorCount + 1;

  return {
    ...state,
    updating: false,
    updated: false,
    errorCount,
  };
};

/** ******************************************* */
// Delete object
/** ****************************************** */

/**
 * Returns the new state after a create object action is dispatched
 * @param state the current state
 */
export const getDeleteObjectState = (state: any) => ({
  ...state,
  deleting: true,
  deleted: false,
});

/**
 * Returns the new state after a delete object success action is dispatched
 * @param state the current state
 */
export const getDeleteObjectSuccessState = (state: any) => ({
  ...state,
  deleting: false,
  deleted: true,
  errorCount: 0,
});

/**
 * Returns the new state after a delete object fail action is dispatched
 * @param state the current state
 */
export const getDeleteObjectFailState = (state: any) => {
  const errorCount = state.errorCount + 1;

  return {
    ...state,
    deleting: false,
    deleted: false,
    errorCount,
  };
};
