import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PostModule } from '@core/modules/post/post.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { PhotoMediaCardComponent } from './photo-media-card.component';

const components = [PhotoMediaCardComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, TranslateModule, PostModule, SvgModule, ButtonModule],
  exports: [...components],
  providers: [],
})
export class PhotoMediaCardModule {}
