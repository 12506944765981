import { BrandChartConfig } from '@core/interfaces/brand-chart-config.interface';
import { CartesianScaleOptions, GridLineOptions, LegendOptions } from 'chart.js';

const deliverectPalette = {
  whiteBase: '#ffffff',
  whiteDark: '#fafafa',
  whiteExtraDark: '#eeeeee',

  grayBase: '#acacac',
  grayDark: '#696969',
  grayExtraDark: '#4f4f4f',

  blackLight: '#3d4c57',
  blackBase: '#1c1c1c',
  blackDark: '#0e212e',
  blackExtraDark: '#000000',

  blueBase: '#417dfa',
  indigoBase: '#becbff',
  pinkBase: '#ffb6ca',
  purpleBase: '#A15DFE',
  redBase: ' #ff5f3b',
  yellowBase: '#ffb905',

  greenLight: '#05CC79',
  greenBase: '#038851',
  greenDark: '#02643b',
};

/**
 * Grid line options
 */
const gridLineOptionsX: Partial<GridLineOptions> = {
  display: true,
  drawOnChartArea: false,
  drawBorder: true,
  tickLength: 12,
  borderDash: [1, 0],
  color: 'transparent',
  borderColor: deliverectPalette.whiteExtraDark,
};

const gridLineOptionsY: Partial<GridLineOptions> = {
  tickLength: 0,
  display: true,
  drawTicks: false,
  drawBorder: false,
  lineWidth: 1,
  borderDash: [1, 0],
  color: (ctx) => {
    if (ctx.index === 0 || !ctx.tick.label) {
      return 'transparent';
    }

    return deliverectPalette.whiteExtraDark;
  },
};

/**
 * Tick options
 */
const tickOptionsX: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  padding: 4,
  maxRotation: 0,
  minRotation: 0,
  color: (ctx: any) => {
    if (!ctx.tick.label) {
      return 'transparent';
    }

    return deliverectPalette.grayBase;
  },
  display: true,
};

const tickOptionsY: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  includeBounds: true,
  padding: 4,
  labelOffset: -8,
  mirror: true,
  color: deliverectPalette.grayBase,
  display: true,
  maxTicksLimit: 5,
};

const config: BrandChartConfig = {
  general: {
    colors: {
      audience: {
        male: deliverectPalette.greenLight,
        female: deliverectPalette.greenBase,
        unknown: deliverectPalette.greenDark,
      },
      datasets: [
        deliverectPalette.greenLight,
        deliverectPalette.blueBase,
        deliverectPalette.purpleBase,
        deliverectPalette.pinkBase,
        deliverectPalette.yellowBase,
        deliverectPalette.greenDark,
      ],
      axisTitle: deliverectPalette.grayBase,
    },
    pluginOptions: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          font: { size: 12 },
          color: deliverectPalette.grayBase,
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 16,
        } as LegendOptions<any>['labels'],
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  doughnut: {
    chartOptions: {
      cutout: '90%',
      radius: '90%',
    },
    pluginOptions: {
      chartBorder: {
        borderColor: 'transparent',
        borderWidth: 0,
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
  },
  bar: {
    chartOptions: {},
    datasetOptions: {},
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  line: {
    chartOptions: {},
    datasetOptions: {
      fill: false,
      fillGradientOpacity: [],
    },
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  horizontalBar: {
    barColors: {
      filled: deliverectPalette.greenBase,
      unfilled: deliverectPalette.whiteExtraDark,
    },
    labelColors: {
      filled: deliverectPalette.whiteBase,
      unfilled: deliverectPalette.blackBase,
    },
    barThickness: 30,
    borderRadius: 0,
  },
  gauge: {
    colors: {
      low: deliverectPalette.redBase,
      medium: deliverectPalette.yellowBase,
      high: deliverectPalette.greenBase,
      needle: deliverectPalette.grayDark,
      unfilled: deliverectPalette.whiteExtraDark,
    },
  },
};

export default config;
