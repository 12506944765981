import { Injectable } from '@angular/core';

/**
 * Service for handling local storage used in the application
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * Clears the local storage
   */
  public clear(): void {
    localStorage.clear();
  }

  /**
   * Adds a local storage value
   * @param key the local storage key to be set
   * @param value the value to store
   */
  public setItem(key: string, value: any): void {
    if (typeof value === 'object') {
      // eslint-disable-next-line no-param-reassign
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  /**
   * Returns a value from the local storage given a key
   * @param key the local storage key to check
   */
  public getItem(key: string): any {
    let value = localStorage.getItem(key);

    if (value && value.length && ['[', '{'].indexOf(value[0]) !== -1) {
      value = JSON.parse(value);
    }

    return value;
  }

  /**
   * Removes a value from the local storage given a key
   * @param key the local storage key to delete
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clears the session storage
   */
  public clearSession(): void {
    sessionStorage.clear();
  }

  /**
   * Adds a session storage value
   * @param key the session storage key to be set
   * @param value the value to store
   */
  public setSessionItem(key: string, value: any): void {
    if (typeof value === 'object') {
      // eslint-disable-next-line no-param-reassign
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  }

  /**
   * Returns a value from the session storage given a key
   * @param key the session storage key to check
   */
  public getSessionItem(key: string): any {
    let value = sessionStorage.getItem(key);

    if (value && value.length && ['[', '{'].indexOf(value[0]) !== -1) {
      value = JSON.parse(value);
    }

    return value;
  }

  /**
   * Removes a value from the session storage given a key
   * @param key the session storage key to delete
   */
  public removeSessionItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
