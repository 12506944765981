import { Routes, RouterModule } from '@angular/router';
import { ManageCardsRoute } from '@review/modules/manage-cards/enums/manage-cards-route.enum';
import { ManageCardsListComponent } from '@review/modules/manage-cards/components/manage-cards-list/manage-cards-list.component';
import { ManageCardsAssignComponent } from '@review/modules/manage-cards/components/manage-cards-assign/manage-cards-assign.component';

export const manageCardsOutlet = 'manage-cards';
const outlet = manageCardsOutlet;

export const manageCardsRoutes = () => [
  {
    path: ManageCardsRoute.List,
    outlet,
    component: ManageCardsListComponent,
  },
  {
    path: ManageCardsRoute.Scan,
    outlet,
    loadChildren: () =>
      import('./components/manage-cards-scan/manage-cards-scan.module').then((m) => m.ManageCardsScanModule),
  },
  {
    path: ManageCardsRoute.Assign,
    outlet,
    component: ManageCardsAssignComponent,
  },
  {
    path: `${ManageCardsRoute.Assign}/:id`,
    outlet,
    component: ManageCardsAssignComponent,
  },
];
const routes: Routes = [...manageCardsRoutes()];
export const ManageCardsRoutingModule = RouterModule.forChild(routes);
