import { tap, filter, map, first } from 'rxjs/operators';
import { Store as NgrxStore, select } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { RoutingService } from '@core/services/routing.service';
import { PaginatedResult } from '@core/interfaces/pagination.interface';
import { GetListConfig } from '@core/interfaces/crud-service.interface';
import { LoadStore, LoadStoreList, UpdateStoreSuccess } from '@core/redux/company-store/company-store.action';
import { getStoreById, getStoresByPage } from '@core/redux/company-store/company-store.selector';
import { ApiService } from '@core/services/api.service';
import { CrudService } from '@core/services/crud.service';
import { Store, StoreAdapter } from '@core/models/store.model';
import { AppState } from '@core/interfaces/app-state.interface';

@Injectable({
  providedIn: 'root',
})
export class StoreService extends CrudService<Store> {
  constructor(
    protected override apiService: ApiService,
    private storeAdapter: StoreAdapter,
    private ngrxStore$: NgrxStore<AppState>,
    private routingService: RoutingService,
  ) {
    super(apiService, storeAdapter, 'stores');
  }

  /**
   * Requests the list of company store or fetches them from the redux store
   * @param config the request configuration
   * @param forceRefresh requests the list of all stores again
   */
  getStoreList(config: GetListConfig = {}, forceRefresh = false): Observable<PaginatedResult<Store>> {
    return this.ngrxStore$.pipe(
      select(getStoresByPage(config.page || 0)),
      tap(({ pagination, loading, loaded, errorLimit }) => {
        const limitChanged = config.limit && config.limit !== pagination.limit && !loading;
        if (errorLimit) {
          return this.routingService.navigateToRedirect('error');
        }
        if (!(loading || loaded || errorLimit) || limitChanged || forceRefresh) {
          this.ngrxStore$.dispatch(new LoadStoreList({}));
        }

        return EMPTY;
      }),
      filter(({ loaded, pagination }) => loaded || pagination.limit === config.limit || false),
      map(({ companyStores, pagination }) => ({ data: companyStores, pagination })),
      first(),
    );
  }

  /**
   * Requests the specified company store or fetches them from the redux store
   * @param id the id of the company store
   */
  getStore(id: string): Observable<Store | undefined> {
    return this.ngrxStore$.pipe(
      select(getStoreById(id)),
      tap(({ companyStore, loading }) => {
        if (!loading && !(companyStore && companyStore.contact)) {
          this.ngrxStore$.dispatch(new LoadStore(id));
        }
      }),
      filter(({ companyStore }) => Boolean(companyStore && companyStore.contact)),
      map(({ companyStore }) => companyStore),
      first(),
    );
  }

  /**
   * Updates the company store object
   */
  updateStore(payload: Store): void {
    return this.ngrxStore$.dispatch(new UpdateStoreSuccess(payload));
  }
}
