<div class="form-field-wrapper">
  <div 
    class="form-field-clickable" #connectionContainer
    (click)="control?.onContainerClick && control?.onContainerClick($event)">
    <label class="form-field__label"
      [id]="labelId"
      [attr.for]="control.id"
      [attr.aria-owns]="control.id"
      [class.form-field-empty]="control.empty"
      #label>
      <ng-content select="[coreFormFieldLabel]"></ng-content>

      <span
        class="form-field__required-marker"
        aria-hidden="true"
        *ngIf="control.required && !control.disabled">
        &#32;*
      </span>
    </label>

    <div class="form-field-border">

      <div class="form-field-prefix mr-2" *ngIf="prefixChildren?.length">
        <ng-content select="[coreFormFieldPrefix]"></ng-content>
      </div>

      <div class="form-field-infix" [class.select-wrapper]="isNativeSingleSelect()" #inputContainer>
        <ng-content></ng-content>
      </div>

      <div class="form-field-suffix ml-2" *ngIf="suffixChildren?.length">
        <ng-content select="[coreFormFieldSuffix]"></ng-content>
      </div>

      <div class="select-trigger" *ngIf="isNativeSingleSelect()">
        <core-icon size="sm" icon="chevron_down"></core-icon>
      </div>
    </div>
  </div>

  <div class="form-field-subscript-wrapper">
    <div [@transitionMessages]="subscriptAnimationState" class="d-flex flex-grow-1">
      <div class="form-field-error d-flex align-items-start">
        <core-icon *ngIf="errorChildren?.length" icon="error" size="xs" class="mr-2"></core-icon>
        <ng-content select="[coreFormFieldError]"></ng-content>
      </div>
    </div>
  </div>
</div>
