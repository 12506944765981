/* eslint-disable no-plusplus */
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  InjectionToken,
  QueryList,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
  Input,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
import { formFieldAnimations } from './variables/form-field-animations.variable';
import { FormFieldControlBaseDirective } from './directives/form-field-control-base.directive';
import { FORM_FIELD_ERROR, FormFieldErrorDirective } from './directives/form-field-error.directive';
import { FORM_FIELD_PREFIX, FormFieldPrefixDirective } from './directives/form-field-prefix.directive';
import { FORM_FIELD_SUFFIX, FormFieldSuffixDirective } from './directives/form-field-suffix.directive';
import { getFormFieldMissingControlError } from './variables/form-field-usage-errors.variable';
import { FormFieldGroupPosition, FormFieldVariant } from './types/form-field.type';

let nextUniqueId = 0;

/**
 * Injection token that can be used to inject an instances of `MatFormField`. It serves
 * as alternative token to the actual `MatFormField` class which would cause unnecessary
 * retention of the `MatFormField` class and its component metadata.
 */
export const FORM_FIELD = new InjectionToken<FormFieldComponent>('FormField');

/** Container for form controls that applies Material Design styling and behavior. */
@Component({
  selector: 'core-form-field',
  exportAs: 'coreFormField',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [formFieldAnimations.transitionMessages],
  host: {
    '[class]': `"form-field"
    + " form-field-variant--" + variant`,
    '[class.subscript-spacer]': 'includeSubscriptSpacer',
    '[class.form-field--group-start]': 'groupPosition === "start"',
    '[class.form-field--group-middle]': 'groupPosition === "middle"',
    '[class.form-field--group-end]': 'groupPosition === "end"',
    '[class.form-field--invalid]': 'control.errorState',
    '[class.form-field--disabled]': 'control.disabled',
    '[class.form-field--autofilled]': 'control.autofilled',
    '[class.form-field--focused]': 'control.focused',
    '[class.form-field--force-invalid]': 'forceInvalidState',
    '[class.ng-untouched]': 'shouldForward("untouched")',
    '[class.ng-touched]': 'shouldForward("touched")',
    '[class.ng-pristine]': 'shouldForward("pristine")',
    '[class.ng-dirty]': 'shouldForward("dirty")',
    '[class.ng-valid]': 'shouldForward("valid")',
    '[class.ng-invalid]': 'shouldForward("invalid")',
    '[class.ng-pending]': 'shouldForward("pending")',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FORM_FIELD, useExisting: FormFieldComponent }],
})
export class FormFieldComponent implements AfterContentInit, AfterContentChecked, AfterViewInit, OnDestroy {
  private destroyed$: Subject<void>;

  subscriptAnimationState = 'initial';

  // Unique id for the label element.
  readonly labelId = `core-form-field-label-${nextUniqueId++}`;

  /**
   * Adds a spacer the height of a single line of the error text subscript
   *
   * This prevents form fields prevents the form from growing and shrinking when
   * the error state is toggled
   *
   * For longer errors that span over multiple lines, the space will still increase
   */
  @Input()
  includeSubscriptSpacer = true;

  /**
   * Styles the form field as invalid regardless of the validity
   */
  @Input()
  forceInvalidState = false;

  @Input()
  variant: FormFieldVariant = 'transparent';

  @Input()
  groupPosition: FormFieldGroupPosition = 'none';

  @ViewChild('connectionContainer', { static: true })
  connectionContainerRef!: ElementRef;

  @ViewChild('inputContainer')
  inputContainerRef!: ElementRef;

  @ContentChild(FormFieldControlBaseDirective)
  controlNonStatic!: FormFieldControlBaseDirective<any>;

  @ContentChild(FormFieldControlBaseDirective, { static: true })
  controlStatic!: FormFieldControlBaseDirective<any>;

  get control() {
    return this.explicitFormFieldControl || this.controlNonStatic || this.controlStatic;
  }

  set control(value) {
    this.explicitFormFieldControl = value;
  }

  private explicitFormFieldControl!: FormFieldControlBaseDirective<any>;

  @ContentChildren(FORM_FIELD_ERROR, { descendants: true })
  errorChildren!: QueryList<FormFieldErrorDirective>;

  @ContentChildren(FORM_FIELD_PREFIX, { descendants: true })
  prefixChildren!: QueryList<FormFieldPrefixDirective>;

  @ContentChildren(FORM_FIELD_SUFFIX, { descendants: true })
  suffixChildren!: QueryList<FormFieldSuffixDirective>;

  constructor(
    public elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.destroyed$ = new Subject<void>();
  }

  /**
   * Gets an ElementRef for the element that a overlay attached to the form-field should be
   * positioned relative to.
   */
  getConnectedOverlayOrigin(): ElementRef {
    return this.connectionContainerRef || this.elementRef;
  }

  ngAfterContentInit() {
    this.validateControlChild();

    const { control } = this;

    if (control.controlType) {
      this.elementRef.nativeElement.classList.add(`core-form-field-type-${control.controlType}`);
    }

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges$.pipe(startWith(null)).subscribe(() => {
      this.syncDescribedByIds();
      this.changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.changeDetectorRef.markForCheck());
    }

    // Run change detection and update the outline if the suffix or prefix changes.
    merge(this.prefixChildren.changes, this.suffixChildren.changes).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });

    // Update the aria-described by when the number of errors changes.
    this.errorChildren.changes.pipe().subscribe(({ length = 0 }) => {
      this.syncDescribedByIds();
      this.subscriptAnimationState = length ? 'visible' : 'hidden';
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterContentChecked() {
    this.validateControlChild();
  }

  ngAfterViewInit() {
    // Avoid animations on load.
    this.subscriptAnimationState = 'initial';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Determines whether a class from the NgControl should be forwarded to the host element. */
  shouldForward(prop: keyof NgControl): boolean {
    const ngControl = this.control ? this.control.ngControl : null;

    return ngControl && ngControl[prop];
  }

  /**
   * Sets the list of element IDs that describe the child control. This allows the control to update
   * its `aria-describedby` attribute accordingly.
   */
  private syncDescribedByIds() {
    if (this.control) {
      const ids: string[] = [];

      if (this.control.userAriaDescribedBy && typeof this.control.userAriaDescribedBy === 'string') {
        ids.push(...this.control.userAriaDescribedBy.split(' '));
      }

      ids.push(...this.errorChildren.map((error) => error.id));

      this.control.setDescribedByIds(ids);
    }
  }

  /** Throws an error if the form field's control is missing. */
  protected validateControlChild() {
    if (!this.control) {
      throw getFormFieldMissingControlError();
    }
  }

  isNativeSingleSelect(): boolean {
    return this.control.controlType === 'native-select';
  }
}
