import { IntercomService } from '@core/services/intercom.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from '@core/services/dialog.service';
import { ButtonVariant } from '@core/ui/button/types/button.type';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogState, DialogPadding } from '../../types/dialog.type';
import { bottomSheetAnimation, zoomInOutAnimation } from '../../variables/dialog.variable';

@Component({
  selector: 'core-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  host: {
    '[class]': `"dialog"
      + " padding-"+padding
      + " header-padding-"+headerConfig?.padding
      + " footer-padding-"+footerConfig?.padding`,
  },
  animations: [bottomSheetAnimation, zoomInOutAnimation],
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('body')
  bodyElement!: ElementRef;

  @Input()
  headerConfig?: {
    icon?: string;
    avatarSrc?: string;
    pretitle?: string;
    title: string;
    subtitle?: string;
    padding?: DialogPadding;
    showClose?: boolean;
    showSupport?: boolean;
    hidden?: boolean;
  };

  @Input()
  footerConfig?: {
    submitLabel?: string;
    cancelLabel?: string;
    submitDisabled?: boolean;
    cancelDisabled?: boolean;
    variant?: ButtonVariant;
    padding?: DialogPadding;
    backLabel?: boolean;
    hidden?: boolean;
  };

  @Input()
  changesConfig?: {
    title?: string;
    text?: string;
    keepLabel?: string;
    discardLabel?: boolean;
    icon?: string;
  };

  @Input()
  confirmSubmitConfig?: {
    title?: string;
    text?: string;
    cancelLabel?: string;
    submitLabel?: boolean;
    icon?: string;
    variant?: ButtonVariant;
  };

  @Input()
  actionInProgress = false;

  @Input()
  unsavedChanges = false;

  @Input()
  confirmSubmit = false;

  @Input()
  canGoBack = false;

  @Input()
  padding: DialogPadding = 'lg';

  @Input()
  overlayStateTrigger$?: BehaviorSubject<{
    unsavedChangesState?: ConfirmDialogState;
    confirmSubmitState?: ConfirmDialogState;
  }>;

  @Output()
  confirmCancel = new EventEmitter();

  @Output()
  back = new EventEmitter();

  @Output()
  dismiss = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  canScrollUp = false;

  canScrollDown = false;

  unsavedChangesState: ConfirmDialogState = 'void';

  confirmSubmitState: ConfirmDialogState = 'void';

  destroyed$!: Subject<void>;

  constructor(
    private dialogService: DialogService,
    private intercomService: IntercomService,
  ) {}

  ngAfterViewInit(): void {
    this.checkScrollPosition(this.bodyElement.nativeElement);
    this.destroyed$ = new Subject<void>();
    // By using a behavior subject, we can trigger an overlay state from the parent
    if (this.overlayStateTrigger$) {
      this.overlayStateTrigger$
        .asObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          if (value.confirmSubmitState) {
            this.confirmSubmitState = value.confirmSubmitState;
          }
          if (value.unsavedChangesState) {
            this.unsavedChangesState = value.unsavedChangesState;
          }
        });
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.checkScrollPosition(this.bodyElement.nativeElement);
  }

  checkScrollPosition(element: HTMLElement): void {
    const isBodyScrollable = element.scrollHeight > element.clientHeight;
    if (!isBodyScrollable) {
      this.canScrollUp = false;
      this.canScrollDown = false;

      return;
    }
    this.canScrollUp = element.scrollTop > 0;
    this.canScrollDown = Math.ceil(element.scrollHeight - element.scrollTop) !== element.clientHeight;
  }

  onDismiss(): void {
    if (this.unsavedChanges) {
      this.unsavedChangesState = 'visible';

      return;
    }
    this.closeDialog();
  }

  onSubmit(confirmSubmit = false): void {
    if (confirmSubmit) {
      this.confirmSubmitState = 'visible';

      return;
    }
    this.submit.emit();
  }

  cancelConfirm(): void {
    this.confirmSubmitState = 'void';
    this.confirmCancel.emit();
  }

  continueEditing(): void {
    this.unsavedChangesState = 'void';
  }

  support(): void {
    this.intercomService.show();
  }

  closeDialog(): void {
    this.unsavedChangesState = 'void';
    this.confirmSubmitState = 'void';
    this.dialogService.closeDialog();
    this.dismiss.emit();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
