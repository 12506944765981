/* eslint-disable no-param-reassign */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Theme } from '@core/interfaces/user-preferences.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _brand = 'alphin';

  private _current: Theme = 'light';

  private _allThemes: Theme[] = ['light', 'dark'];

  currentTheme$ = new BehaviorSubject<Theme>(this._current);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  get brand(): string {
    return this._brand;
  }

  set brand(value: string) {
    this._brand = value;
  }

  get allThemes(): Theme[] {
    return this._allThemes;
  }

  get current(): Theme {
    return this._current;
  }

  set current(theme: Theme) {
    this._current = theme;
    this.document.body.classList.forEach((className: string) => {
      if (className.includes('-dark') || className.includes('-light')) {
        this.document.body.classList.remove(className);
      }
    });
    this.document.body.classList.add(`${this._brand}-${theme}`);
    this.currentTheme$.next(theme);
  }

  /**
   * Sets the user's preferred theme
   */
  setThemeToPreferredColorScheme(): void {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.current = 'dark';
    }
    if (this._current === 'light') {
      this.document.documentElement.setAttribute('data-theme', 'light');
    } else if (this._current === 'dark') {
      this.document.documentElement.setAttribute('data-theme', 'dark');
    }
  }
}
