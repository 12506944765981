import { AccountDetails } from '@core/interfaces/integration.interface';
import { Injectable } from '@angular/core';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';

/**
 * Data helper service for Integrations
 */
@Injectable()
export class IntegrationDataHelperService {
  constructor(private globalFilterService: GlobalFilterService) {}

  getSortedAccounts(accounts: AccountDetails[]) {
    const selectableAccounts = accounts.filter(this.isSelectableAccount);
    const notSelectableAccounts = accounts.filter((account) => !this.isSelectableAccount(account));

    const fitRequirementAccounts = selectableAccounts.filter(this.hasAccountGeoAndName);
    const notFitRequirementAccounts = selectableAccounts.filter((account) => !this.hasAccountGeoAndName(account));

    return [
      ...this.sortAccounts(fitRequirementAccounts),
      ...this.sortAccounts(notFitRequirementAccounts),
      ...notSelectableAccounts,
    ];
  }

  isSelectableAccount(account: AccountDetails) {
    return account?.state?.selectable;
  }

  private hasAccountGeoAndName = (account: AccountDetails) => account?.location?.geo?.coordinates && account.name;

  private sortAccounts = (accountList: AccountDetails[]) =>
    this.addSortRate(accountList).sort((itemA, itemB) => itemB.sortRate - itemA.sortRate);

  private addSortRate(accounts: AccountDetails[]) {
    const selectedStore = this.globalFilterService.store;
    const storeCoordinates = selectedStore?.location?.geo?.coordinates;
    const storeName = selectedStore?.name || '';

    return accounts.map((account) => {
      const accountCoordinates = account?.location?.geo?.coordinates;
      const accountName = account.name;

      const nameSimilarityRate = this.getJaccardSimilarityRate(accountName, storeName);
      const distanceSimilarityRate =
        accountCoordinates && storeCoordinates
          ? this.getCoordinateDistanceRate(accountCoordinates, storeCoordinates)
          : null;

      return {
        ...account,
        sortRate: this.getAverageRate(nameSimilarityRate, distanceSimilarityRate),
      };
    });
  }

  private getJaccardSimilarityRate(strA: string, strB: string): number {
    const charSetA = new Set(strA);
    const charSetB = new Set(strB);

    const intersection = new Set([...charSetA].filter((char) => charSetB.has(char)));
    const union = new Set([...charSetA, ...charSetB]);

    return Math.round((intersection.size / union.size) * 1e2) / 1e2;
  }

  private getCoordinateDistanceRate(point1: number[], point2: number[]): number {
    const maxNormalizationDistance = 500;
    const euclideanDistance = Math.sqrt((point2[0] - point1[0]) ** 2 + (point2[1] - point1[1]) ** 2);

    return 1 - euclideanDistance / maxNormalizationDistance;
  }

  private getAverageRate(scoreA: number | null, scoreB: number | null) {
    const filteredRates = [scoreA, scoreB].filter((val) => val !== null);
    const sum = filteredRates.reduce((acc, val) => acc! + val!, 0);

    return sum! / filteredRates.length || 0;
  }
}
