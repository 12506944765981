<core-dialog
  [actionInProgress]="actionInProgress"
  [unsavedChanges]="false"
  [style.width.px]="916"
  [canGoBack]="false"
  [headerConfig]="{
    title: selectedStore?.name,
    avatarSrc: selectedStore?.images?.[0]?.file
  }"
  [footerConfig]="{
    submitLabel: 'ACTION.CONFIRM' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate,
    padding: 'none'
  }"
  (dismiss)="onCancel()"
  (submit)="onSubmit()">
  <div class="mt-n2">
    <h5>
      {{ ('INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.TITLE') | translate }}
    </h5>
    <p class="mb-4">
      {{ ('INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.HEADING.' + data.provider) | translate }}
    </p>
    <div class="error-message" *ngIf="showLocationSelectError">
      {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.SELECT_STORE_ERROR' | translate }}
    </div>
    <core-toggle
      class="w-100"
      (change)="showSelectableLocation($event)"
      [value]="showSelectable"
      [labelPadding]="{ bottom: 'md'}">
        <span class="toggle-label">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.SELECTABLE_TOGGLE' | translate }}</span>
    </core-toggle>
    <div class="store-list mx-n4" *ngIf="!loading; else spinnerTemplate">
      <core-provider-account-list
        [accounts]="selectedLocations"
        [preselectedId]="selectableLocations?.[0]?.providerId"
        (accountChange)="locationChangeHandler($event)">
      </core-provider-account-list>
      <div *ngIf="selectableLocations.length === 0" class="text-center">
        <h6 class="mb-0">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.NO_LISTINGS.TITLE' | translate }}</h6>
        <p>{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.NO_LISTINGS.DESCRIPTION' | translate : { provider: data.provider | titlecase } }}</p>
      </div>
    </div>
    <ng-template #spinnerTemplate>
      <core-spinner></core-spinner>
    </ng-template>
  </div>
  <div coreDialogFooter>
    <h6 class="mb-0">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.CANT_FIND' | translate }}</h6>
    <div class="contact-support-link">
      <a (click)="contactSupport()">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.GET_SUPPORT' | translate }}</a>
    </div>
  </div>
</core-dialog>
