/**
 * Enum for audience gender
 */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN',
}

export enum AgeRange {
  Ages13To17 = '13-17',
  Ages18To24 = '18-24',
  Ages25To34 = '25-34',
  Ages35To44 = '35-44',
  Ages45To54 = '45-54',
  Ages55To64 = '55-64',
  Ages65Plus = '65+',
}
