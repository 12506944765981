import { filter, startWith, switchMap, debounceTime, takeUntil } from 'rxjs/operators';
import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  SimpleChanges,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { PostRating } from '../../types/post-rating.type';

@Component({
  selector: 'core-post-rating',
  templateUrl: './post-rating.component.html',
  styleUrls: ['./post-rating.component.scss'],
})
export class PostRatingComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('container', { static: false })
  containerElement!: ElementRef;

  @ViewChild('trigger', { static: false })
  triggerElement!: any;

  @ViewChild('ratingOverlay', { static: false })
  overlayElement!: any;

  @Input()
  feedback?: number;

  @Input()
  actionInProgress = false;

  @Output()
  feedbackChange = new EventEmitter<{ action: PostRating; value: number }>();

  icon = '';

  popupVisible = false;

  scrollStrategy: ScrollStrategy = this.overlay.scrollStrategies.close();

  positionStrategy?: FlexibleConnectedPositionStrategy;

  destroyed$ = new Subject<void>();

  constructor(private overlay: Overlay) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.feedback && changes.feedback.previousValue !== changes.feedback.currentValue) {
      this.icon = this.getFeedbackIcon(changes.feedback.currentValue);
    }
  }

  private isMovedOutside(triggerElement: HTMLButtonElement, event: Event): boolean {
    return !(
      triggerElement?.contains(event.target as Node) ||
      this.overlayElement?.nativeElement?.contains(event.target as Node)
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.triggerElement.elementRef?.nativeElement)
        .withPositions([
          new ConnectionPositionPair({ originX: 'center', originY: 'top' }, { overlayX: 'center', overlayY: 'bottom' }),
          new ConnectionPositionPair({ originX: 'center', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' }),
          new ConnectionPositionPair({ originX: 'center', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }),
        ])
        .withLockedPosition()
        .withPush(false);

      const triggerElement = this.triggerElement.elementRef?.nativeElement;
      const open$ = fromEvent<MouseEvent>(triggerElement, 'mouseenter').pipe(
        filter(() => !this.popupVisible),
        switchMap((enterEvent: Event) =>
          fromEvent(document, 'mousemove').pipe(
            startWith(enterEvent),
            debounceTime<Event>(100),
            filter((event: Event) => triggerElement?.contains(event?.target)),
          ),
        ),
      );

      open$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.popupVisible = true;
      });

      const close$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
        debounceTime(200),
        filter(() => this.popupVisible),
        filter((event) => this.isMovedOutside(triggerElement, event)),
      );

      open$
        .pipe(
          switchMap(() => close$),
          takeUntil(this.destroyed$),
        )
        .subscribe(() => {
          this.popupVisible = false;
        });
    });
  }

  sendFeedback(action: PostRating, value: number): void {
    this.feedbackChange.emit({ action, value });
  }

  getFeedbackIcon(value: number): string {
    switch (value) {
      case -1:
        return 'negative';
      case 1:
        return 'positive';
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
