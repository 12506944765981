import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { StoreContactInfo } from '@core/interfaces/contact.interface';
import { Products } from '@core/interfaces/products.interface';
import { StoreAddress } from '@core/interfaces/address.interface';
import { CaptionSettings } from '@core/dialogs/post-manager/interfaces/ai-caption-request.interface';
import { Nullable } from '@core/types/nullable.type';

/**
 * Frontend model for a store object
 */
export class Store {
  readonly _id: string;

  description: string;

  name: string;

  companyId: string;

  location: StoreAddress;

  contact: StoreContactInfo;

  products: Products;

  addressLine?: string;

  images?: [
    {
      readonly _id: string;
      file: string;
    },
  ];

  caption: Nullable<CaptionSettings> = null;

  constructor(object: Store) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = object._id;
    this.description = object.description;
    this.name = object.name;
    this.companyId = object.companyId;
    this.location = object.location;
    this.contact = object.contact;
    this.products = object.products;
    this.addressLine = object.addressLine;
    this.images = object.images;
    this.caption = object.caption;
  }
}

/**
 * Adapter for the store object
 */
@Injectable({
  providedIn: 'root',
})
export class StoreAdapter implements Adapter<Store> {
  clientAdapt(object: any): Store {
    const store = object;
    store.companyId = object.company ?? store.companyId;
    store.addressLine = `${`${object.location.street}, ` || ''}${`${object.location.zip} ` || ''}${
      object.location.city || ''
    }`;

    const { SOCIAL, REVIEW, GOOGLE_AD, META_AD, INFLUENCER, PHOTO, IAM, RMT, GADS, ADS, IM, IPS } =
      store.products ?? {};

    store.products = {
      SOCIAL: SOCIAL ?? IAM,
      REVIEW: REVIEW ?? RMT,
      GOOGLE_AD: GOOGLE_AD ?? GADS,
      META_AD: META_AD ?? ADS,
      INFLUENCER: INFLUENCER ?? IM,
      PHOTO: PHOTO ?? IPS,
    };

    return new Store(store);
  }

  serverAdapt(object: any): Store {
    return new Store(object);
  }
}
