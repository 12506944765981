import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '../svg/svg.module';
import { ChipButtonComponent } from './components/chip-button/chip-button.component';
import { AvatarModule } from '../avatar/avatar.module';
import { ChipBaseComponent } from './components/chip-base/chip-base.component';
import { ChipInputEditableDirective } from './directives/chip-input-editable.directive';
import { ChipActionDirective } from './directives/chip-action.directive';
import { ChipEnteredInputComponent } from './components/chip-input-group/chip-entered-input/chip-entered-input.component';
import { ChipInputGroupComponent } from './components/chip-input-group/chip-input-group.component';
import { ChipOptionComponent } from './components/chip-option-group/chip-option/chip-option.component';
import { ChipOptionGroupComponent } from './components/chip-option-group/chip-option-group.component';
import { ChipGroupBaseComponent } from './components/chip-group-base/chip-group-base.component';
import { ChipInputDirective } from './directives/chip-input.directive';
import { ChipRemoveDirective } from './directives/chip-remove.directive';

const exports = [
  // Components
  ChipButtonComponent,
  ChipEnteredInputComponent,
  ChipInputGroupComponent,
  ChipOptionComponent,
  ChipOptionGroupComponent,
  // Directives
  ChipActionDirective,
  ChipRemoveDirective,
  ChipInputEditableDirective,
  ChipInputDirective,
];

const declarations = [...exports, ChipBaseComponent, ChipGroupBaseComponent];

@NgModule({
  declarations,
  imports: [CommonModule, SvgModule, AvatarModule],
  exports,
})
export class ChipModule {}
