/* eslint-disable no-plusplus */
/* eslint-disable max-classes-per-file */
import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { hasModifierKey } from '@angular/cdk/keycodes';
import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  Optional,
  Inject,
  AfterViewChecked,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  booleanAttribute,
} from '@angular/core';
import { Subject } from 'rxjs';
import { OptionParentComponent, OPTION_PARENT_COMPONENT } from '../../types/option.type';

let uniqueId = 0;

/**
 * Event object emitted by OptionComponent when selected or deselected.
 */
export class OptionSelectionChangeEvent<T = any> {
  constructor(
    /**
     * Reference to the option that emitted the event.
     */
    public source: OptionComponent<T>,
    /**
     * Whether the change in the option's value was a result of a user action
     */
    public isUserInput = false,
  ) {}
}

/**
 * Determines the position to which to scroll a panel in order for an option to be into view.
 * @param optionOffset Offset of the option from the top of the panel.
 * @param optionHeight Height of the options.
 * @param currentScrollPosition Current scroll position of the panel.
 * @param panelHeight Height of the panel.
 */
export function getOptionScrollPosition(
  optionOffset: number,
  optionHeight: number,
  currentScrollPosition: number,
  panelHeight: number,
): number {
  if (optionOffset < currentScrollPosition) {
    return optionOffset;
  }

  if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
    return Math.max(0, optionOffset - panelHeight + optionHeight);
  }

  return currentScrollPosition;
}

/**
 * Single option inside of a `<core-select>` element.
 */
@Component({
  selector: 'core-option',
  exportAs: 'coreOption',
  host: {
    '[role]': '"option"',
    '[class]': '"core-option core-list-item"',
    '[class.core-list-item--selected]': 'selected',
    '[class.core-option-multiple]': 'multiple',
    '[class.core-option-active]': 'active',
    '[class.core-list-item--disabled]': 'disabled',
    '[id]': 'id',
    '[attr.aria-selected]': 'selected',
    '[attr.aria-disabled]': 'disabled.toString()',
    '(click)': 'selectViaInteraction()',
    '(keydown)': 'handleKeydown($event)',
  },
  styleUrls: ['./option.component.scss'],
  templateUrl: './option.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent<T = any> implements FocusableOption, AfterViewChecked, OnDestroy {
  private _selected = false;

  private _active = false;

  private _disabled = false;

  private _mostRecentViewValue = '';

  /**
   * If the parent SelectComponent is allowing multiple options to be selected
   */
  get multiple() {
    return this._parent && this._parent.multiple;
  }

  /**
   * Whether or not the option is currently selected
   */
  get selected(): boolean {
    return this._selected;
  }

  /**
   * The form value of the option
   */
  @Input()
  value!: T;

  /**
   * The unique ID of the option
   */
  @Input()
  id: string = `core-option-${uniqueId++}`;

  /**
   * Whether the option is disabled
   */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  /**
   * Whether to display checkmark for single-selection
   */
  get hideSingleSelectionIndicator(): boolean {
    return !!(this._parent && this._parent.hideSingleSelectionIndicator);
  }

  /**
   * Event emitted when the option is selected or deselected
   */
  @Output()
  readonly onSelectionChange = new EventEmitter<OptionSelectionChangeEvent<T>>();

  /**
   * Element containing the option's label
   */
  @ViewChild('label', { static: true })
  label?: ElementRef<HTMLElement>;

  /**
   * Element containing the option's icon
   */
  @ViewChild('icon', { static: true })
  icon?: ElementRef<HTMLElement>;

  /**
   * Emits when the state of the option changes and any parents have to be notified
   */
  readonly stateChanges$ = new Subject<void>();

  /**
   * Whether or not the option is currently active and ready to be selected.
   * An active option displays styles as if it is focused, but the
   * focus is actually retained somewhere else. This comes in handy
   * for components like autocomplete where focus must remain on the input.
   */
  get active(): boolean {
    return this._active;
  }

  /**
   * The displayed value of the option. It is necessary to show the selected option in the
   * select's trigger.
   */
  get viewValue(): string {
    return (this.label?.nativeElement.textContent || '').trim();
  }

  get iconElement(): string | undefined {
    return this.icon?.nativeElement?.innerHTML;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    public changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(OPTION_PARENT_COMPONENT) private _parent: OptionParentComponent,
  ) {}

  ngAfterViewChecked(): void {
    if (this._selected) {
      const { viewValue } = this;

      if (viewValue !== this._mostRecentViewValue) {
        if (this._mostRecentViewValue) {
          this.stateChanges$.next();
        }

        this._mostRecentViewValue = viewValue;
      }
    }
  }

  /**
   * Selects the option
   */
  select(emitEvent = true): void {
    if (!this._selected) {
      this._selected = true;
      this.changeDetectorRef.markForCheck();

      if (emitEvent) {
        this.emitSelectionChangeEvent();
      }
    }
  }

  /**
   * Deselects the option
   */
  deselect(emitEvent = true): void {
    if (this._selected) {
      this._selected = false;
      this.changeDetectorRef.markForCheck();

      if (emitEvent) {
        this.emitSelectionChangeEvent();
      }
    }
  }

  /**
   * Sets focus onto this option
   */
  focus(_origin?: FocusOrigin, options?: FocusOptions): void {
    // Note that we aren't using `_origin`, but we need to keep it because some internal consumers
    // use `OptionComponent` in a `FocusKeyManager` and we need it to match `FocusableOption`.
    const element = this.getHostElement();

    if (typeof element.focus === 'function') {
      element.focus(options);
    }
  }

  /**
   * This method sets display styles on the option to make it appear
   * active. This is used by the ActiveDescendantKeyManager so key
   * events will display the proper options as active on arrow key events.
   */
  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * This method removes display styles on the option that made it appear
   * active. This is used by the ActiveDescendantKeyManager so key
   * events will display the proper options as active on arrow key events.
   */
  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Gets the label to be used when determining whether the option should be focused
   */
  getLabel(): string {
    return this.viewValue;
  }

  /** Ensures the option is selected when activated from the keyboard. */
  handleKeydown(event: KeyboardEvent): void {
    if ((event.key === 'Enter' || event.key === ' ') && !hasModifierKey(event)) {
      this.selectViaInteraction();

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();
    }
  }

  /**
   * Selects the option while indicating the selection came from the user. Used to
   * determine if the select's view -> model callback should be invoked.
   */
  selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = this.multiple ? !this._selected : true;
      this.changeDetectorRef.markForCheck();
      this.emitSelectionChangeEvent(true);
    }
  }

  /**
   * Gets the host DOM element
   */
  getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  /**
   * Emits the selection change event
   */
  private emitSelectionChangeEvent(isUserInput = false): void {
    this.onSelectionChange.emit(new OptionSelectionChangeEvent<T>(this, isUserInput));
  }

  ngOnDestroy(): void {
    this.stateChanges$.complete();
  }
}
