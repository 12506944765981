import { Params } from '@angular/router';
import { BrandChartConfig } from '@core/interfaces/brand-chart-config.interface';
import { CartesianScaleOptions, GridLineOptions, LegendOptions } from 'chart.js';

const sumupPalette: Params = {
  whiteBase: '#ffffff',
  whiteDark: '#f5f5f5',
  whiteExtraDark: '#e6e6e6',

  grayBase: '#cccccc',
  grayDark: '#999999',
  grayExtraDark: '#666666',

  blackBase: '#333333',
  blackDark: '#1a1a1a',
  blackExtraDark: '#000000',

  indigoBase: '#98b4f3',
  indigoDark: '#7498ef',
  indigoExtraDark: '#5c85eb',

  blueBase: '#3064e3',
  blueDark: '#1c51d3',
  blueExtraDark: '#10399e',

  redBase: '#de331d',
  redDark: '#bd2c19',
  redExtraDark: '#9e2415',

  orangeBase: '#e27900',
  orangeDark: '#cc6d00',
  orangeExtraDark: '#b25c00',

  greenBase: '#018850',
  greenDark: '#007a4e',
  greenExtraDark: '#016c26',

  purpleBase: '#9e33e0',
  purpleDark: '#8a1ecc',
  purpleExtraDark: '#7219a9',
};

/**
 * Grid line options
 */
const gridLineOptionsX: Partial<GridLineOptions> = {
  display: true,
  drawOnChartArea: false,
  drawBorder: true,
  tickLength: 12,
  borderDash: [1, 0],
  color: 'transparent',
  borderColor: sumupPalette.grayBase,
};

const gridLineOptionsY: Partial<GridLineOptions> = {
  tickLength: 0,
  display: true,
  drawTicks: false,
  drawBorder: false,
  lineWidth: 1,
  borderDash: [1, 0],
  color: (ctx) => {
    if (ctx.index === 0 || !ctx.tick.label) {
      return 'transparent';
    }

    return sumupPalette.grayBase;
  },
};

/**
 * Tick options
 */
const tickOptionsX: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  padding: 4,
  maxRotation: 0,
  minRotation: 0,
  color: (ctx: any) => {
    if (!ctx.tick.label) {
      return 'transparent';
    }

    return sumupPalette.grayDark;
  },
  display: true,
};

const tickOptionsY: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  includeBounds: true,
  padding: 4,
  labelOffset: -8,
  mirror: true,
  color: sumupPalette.grayDark,
  display: true,
  maxTicksLimit: 5,
};

const config: BrandChartConfig = {
  general: {
    colors: {
      audience: {
        male: sumupPalette.blueDark,
        female: sumupPalette.blueExtraDark,
        unknown: sumupPalette.indigoBase,
      },
      datasets: [
        sumupPalette.blueBase,
        sumupPalette.blueExtraDark,
        sumupPalette.indigoBase,
        sumupPalette.indigoExtraDark,
        sumupPalette.blueDark,
        sumupPalette.indigoDark,
      ],
      axisTitle: sumupPalette.grayExtraDark,
    },
    pluginOptions: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          font: { size: 12 },
          color: sumupPalette.grayDark,
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 16,
        } as LegendOptions<any>['labels'],
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  doughnut: {
    chartOptions: {
      cutout: '90%',
      radius: '90%',
    },
    pluginOptions: {
      chartBorder: {
        borderColor: 'transparent',
        borderWidth: 0,
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
  },
  bar: {
    chartOptions: {},
    datasetOptions: {},
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  line: {
    chartOptions: {},
    datasetOptions: {
      fill: false,
      fillGradientOpacity: [],
    },
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  horizontalBar: {
    barColors: {
      filled: sumupPalette.blueDark,
      unfilled: sumupPalette.whiteExtraDark,
    },
    labelColors: {
      filled: sumupPalette.whiteBase,
      unfilled: sumupPalette.blackDark,
    },
    barThickness: 30,
    borderRadius: 0,
  },
  gauge: {
    colors: {
      low: sumupPalette.redBase,
      medium: sumupPalette.orangeBase,
      high: sumupPalette.greenBase,
      needle: sumupPalette.blackDark,
      unfilled: sumupPalette.whiteExtraDark,
    },
  },
};

export default config;
