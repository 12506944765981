export enum PageType {
  Review = 'REVIEW',
  Funnel = 'FUNNEL',
}

/**
 * Page link type
 */
export enum PageLinkType {
  Card = 'CARD',
}
