<core-post-media-thumbnail
  [slides]="post?.slides"
  [variant]="post?.mediaVariant"
  [roundness]="roundness"
  [size]="size"
  [aspectRatio]="aspectRatio"
  [isOverlayVisible]="isOverlayVisible">
  <div thumbnailOverlayContent>
    <ng-content select="[thumbnailOverlayContent]"></ng-content>
  </div>

  <core-tag-large
    class="m-2 flex-grow-1"
    thumbnailHeaderContent
    [icon]="statusTagConfig?.icon"
    [variant]="statusTagConfig?.variant">
    {{ statusTagConfig?.label | translate }}
    <ng-container coreTagLabelSubtext>
      {{ isPostedToday ? ('CALENDAR.TODAY' | translate) : (post.date | date : 'shortDate' : undefined : locale) }},
      {{ post.date | date : 'shortTime' : undefined : locale }}
    </ng-container>
  </core-tag-large>

  <ng-container thumbnailFooterContent>
    <div class="m-2 thumbnail-footer-content" *ngIf="post?.counts?.likes">
      <core-icon icon="like" size="sm" class="mr-2"></core-icon>
      <span>{{ post?.counts?.likes }}</span>
    </div>
  </ng-container>
</core-post-media-thumbnail>
