<core-dialog
  [headerConfig]="{
    showClose: true,
    showSupport: true,
    title: 'REVIEW.GENERATION.REQUEST_CARDS.TITLE' | translate
  }"
  [footerConfig]="{
    submitLabel: 'ACTION.CLOSE' | translate
  }"
  (dismiss)="onClose()"
  (submit)="onClose()">
  <div class="row mx-n2 my-auto">
    <div class="d-flex flex-column align-items-center col-12 col-sm-6 col-md-5 px-2">
      <core-svg [svg]="'outline_email'"></core-svg>
    </div>
    <div class="d-flex flex-column col-12 col-sm-6 col-md-7 mb-3 px-2">
      <div class="my-auto d-flex flex-column align-items-center align-items-sm-start">
        <h5>
          {{ 'REVIEW.GENERATION.REQUEST_CARDS.CONFIRM.TITLE' | translate }}
        </h5>
        <p>
          {{ 'REVIEW.GENERATION.REQUEST_CARDS.CONFIRM.MESSAGE' | translate }}
        </p>
      </div>
    </div>
  </div>
</core-dialog>