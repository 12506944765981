import _chunk from 'lodash/chunk';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crudSelectors } from '../crud/crud.selector';
import { CompanyState, getCompanyState, selectAllCompanies, selectCompanyEntities } from './company.reducer';

export const selectCompanyState = createFeatureSelector<CompanyState>('companies');
export const companySelectors = crudSelectors(getCompanyState);

/**********************************************/
// Selectors for list of companies
/*********************************************/

export const getCompaniesByPage = (pageNumber: number) =>
  createSelector(
    selectAllCompanies,
    companySelectors.getListPagination,
    companySelectors.getListPageLoading(pageNumber),
    companySelectors.getListPageLoaded(pageNumber),
    companySelectors.getErrorLimitReached,
    (allCompanies, pagination, loading, loaded, errorLimit) => {
      const companies = pagination.limit ? _chunk(allCompanies, pagination.limit)[pageNumber] : allCompanies;

      return { companies, pagination, loading, loaded, errorLimit };
    },
  );

/**********************************************/
// Selectors for one company
/*********************************************/

export const getCompanyById = (id: string) =>
  createSelector(selectCompanyEntities, companySelectors.getObjectLoading, (companies, loading) => ({
    company: companies[id],
    loading,
  }));
