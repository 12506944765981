import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { Contact } from '@core/interfaces/contact.interface';
import { userInviteOutlet } from '../../variables/outlet.variable';
import { UserInviteRoute } from '../../enums/user-invite-route.enum';

@Component({
  selector: 'core-user-invite-complete',
  templateUrl: './user-invite-complete.component.html',
  styleUrls: ['./user-invite-complete.component.scss'],
})
export class UserInviteCompleteComponent implements OnInit {
  user?: Pick<Contact, 'email' | 'firstName' | 'lastName'>;

  firstName = '';

  lastName = '';

  constructor(
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const { queryParams } = this.router.routerState.snapshot.root;
    this.user = {
      email: queryParams.email,
      firstName: queryParams.first_name,
      lastName: queryParams.last_name,
    };
    // Close dialog if missing user properties as the translation text uses these
    if (!this.user.email || !this.user.firstName) {
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.router.navigate([{ outlets: { [userInviteOutlet]: null } }]).then(() => {
      this.dialogService.closeDialog();
    });
  }

  sendNewInvitation(): void {
    this.router.navigate([{ outlets: { [userInviteOutlet]: UserInviteRoute.Send } }]);
  }
}
