import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { postManagerOutlet } from '@core/dialogs/post-manager/variables/outlet.variable';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { Store } from '@core/models/store.model';
import { catchError, first, tap } from 'rxjs/operators';
import { TRACK_MANAGED_POST } from '@core/variables/tracking-events.variable';
import { SegmentService } from '@core/services/segment.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snack-bar.service';
import { AuthUserService } from '@core/services/auth-user.service';
import { EMPTY } from 'rxjs';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { PostManagerConfig } from '../../types/post-manager.type';
import { PostManagerComponent } from '../../post-manager.component';
import { ManagedPostDataService } from '../../services/managed-post-data.service';
import { ManagedPost } from '../../models/managed-post.model';
import { CreatorType } from '../../enums/creator-type.enum';
import { PostManagerRoute } from '../../enums/post-manager-route.enum';
import { Action } from '../../enums/action.enum';

@Component({
  selector: 'core-reject-post',
  templateUrl: './reject-post.component.html',
  styleUrls: ['./reject-post.component.scss'],
})
export class RejectPostComponent implements OnInit, AfterViewInit {
  formGroup: FormGroup = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  actionInProgress = false;

  selectedStore?: Store;

  viewReady = false;

  data?: PostManagerConfig;

  post?: ManagedPost;

  slides: MediaConfig[] = [];

  constructor(
    private managedPostDataService: ManagedPostDataService,
    private globalFilterService: GlobalFilterService,
    private router: Router,
    private dialogRef: MatDialogRef<PostManagerComponent>,
    private activatedRoute: ActivatedRoute,
    private segmentService: SegmentService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private authUserService: AuthUserService,
  ) {}

  ngOnInit(): void {
    this.data = this.dialogRef.componentInstance.data;
    this.selectedStore = this.globalFilterService.store;
    this.initPost();
  }

  ngAfterViewInit(): void {
    this.viewReady = true;
  }

  initPost(): void {
    const { postId } = this.activatedRoute.snapshot.params;
    if (!postId) {
      this.removeOutlet();
    }
    this.managedPostDataService
      .getPost(this.selectedStore?._id!, postId!)
      .pipe(first())
      .subscribe((res) => {
        this.post = res.result;
        if (!this.post) {
          return;
        }
        this.slides = this.post.post.resources.map((resource) => ({
          src: resource.media.url!,
          thumbnail: resource.media.thumbnail!,
          imageSrcVariant: 'asset', // TODO: Change to 'url' when imgix service issue is fixed
          variant: resource.type === 'VIDEO' ? 'VIDEO' : 'IMAGE',
        }));
      });
  }

  cancel(): void {
    if (!this.viewReady) {
      return;
    }
    this.viewReady = false;
    this.removeOutlet();
  }

  submit(): void {
    if (this.formGroup.invalid || !this.viewReady) {
      return;
    }
    this.viewReady = false;
    this.actionInProgress = true;
    this.managedPostDataService
      .rejectPost(this.selectedStore?._id!, this.post?._id!, {
        action: Action.Rejected,
        message: this.formGroup.controls.message.value,
        actionBy: CreatorType.User,
        actionByEmail: this.authUserService.authUser.contact.email,
      })
      .pipe(
        tap(() => {
          this.actionInProgress = false;
          setTimeout(() => {
            this.snackBarService.openSnackBar({
              icon: 'check_circle',
              text: this.translateService.instant('POST_MANAGER.SNACKBAR.SUCCESS.REJECT'),
              variant: 'default',
            });
            this.segmentService.track(TRACK_MANAGED_POST.deleted, {
              postId: this.post?._id,
              storeId: this.selectedStore?._id,
            });
            this.removeOutlet({ postId: this.post?._id! });
          }, 500);
        }),
        catchError(() => {
          this.actionInProgress = false;
          this.viewReady = true;
          this.snackBarService.openSnackBar({
            icon: 'warning',
            text: this.translateService.instant('POST_MANAGER.SNACKBAR.ERROR.REJECT'),
            variant: 'error',
          });

          return EMPTY;
        }),
      )
      .subscribe();
  }

  updatePost(): void {
    this.router.navigate([{ outlets: { [postManagerOutlet]: `${PostManagerRoute.Update}/${this.post?._id}` } }], {
      queryParams: this.activatedRoute.snapshot.queryParams,
    });
  }

  removeOutlet(data?: { postId: string }): void {
    this.router
      .navigate([{ outlets: { [postManagerOutlet]: null } }], {
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
        },
      })
      .then(() => {
        this.dialogRef.close(data);
      });
  }
}
