import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ButtonModule } from '@core/ui/button/button.module';
import { RouterModule } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { SvgModule } from '@core/ui/svg/svg.module';
import { DividerModule } from '@core/ui/divider/divider.module';
import { AvatarModule } from '@core/ui/avatar/avatar.module';
import { SelectModule } from '@core/ui/select/select.module';
import { FormFieldModule } from '@core/ui/form-field/form-field.module';
import { UserInviteWorkflowComponent } from './user-invite-workflow.component';
import { UserInviteWorkflowRoutingModule } from './user-invite-workflow-routing.module';
import { UserInviteSendComponent } from './screens/user-invite-send/user-invite-send.component';
import { UserInviteCompleteComponent } from './screens/user-invite-complete/user-invite-complete.component';
import { SelectAllCheckboxModule } from './components/select-all-checkbox/select-all-checkbox.module';

@NgModule({
  declarations: [UserInviteWorkflowComponent, UserInviteSendComponent, UserInviteCompleteComponent],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SelectAllCheckboxModule,
    ButtonModule,
    RouterModule,
    AvatarModule,
    SvgModule,
    DividerModule,
    SelectModule,
    FormFieldModule,
    TranslateModule,
    UserInviteWorkflowRoutingModule,
  ],
  providers: [DialogService],
  exports: [UserInviteWorkflowComponent],
})
export class UserInviteWorkflowModule {}
