<core-dialog
  [headerConfig]="{
    title: 'REVIEW.MANAGE_CARDS.MANAGE.TITLE' | translate,
    showSupport: false,
    showClose: true
  }"
  [footerConfig]="{
    cancelLabel: 'ACTION.CANCEL' | translate,
    submitLabel: 'REVIEW.MANAGE_CARDS.BUTTON.SCAN' | translate
  }"
  (dismiss)="dismiss()"
  (submit)="startScanning()">
  <div class="row my-auto mx-n2">
    <div
      class="col-12 px-2 d-flex flex-column align-items-center align-self-center"
      [class.col-sm-4]="cards?.length"
      [class.col-sm-6]="!cards?.length">
      <core-svg
        svg="img_nfc_cards">
      </core-svg>
    </div>
    <div
      class="col-12 px-2 d-flex flex-column align-self-center"
      [class.col-sm-8]="cards?.length"
      [class.col-sm-6]="!cards?.length">
      <div class="main">
        <p class="mb-3 mb-sm-2 mr-sm-3">
          {{ 'REVIEW.MANAGE_CARDS.LISTING.MAIN' | translate }}
        </p>
        <div
          *ngIf="assignedCardsAmount > 0 || unassignedCardsAmount > 0"
          class="d-flex align-items-center justify-content-between mr-sm-3">
          <div class="main__card-success" *ngIf="assignedCardsAmount > 0">
            <core-icon icon="check_circle" size="xs"></core-icon>
            <span
              >{{ 'REVIEW.MANAGE_CARDS.LISTING.SUCCESS_CARDS' | pluralFormat: assignedCardsAmount | translate: { count: assignedCardsAmount } }}</span>
          </div>
          <div class="main__card-unassigned" *ngIf="unassignedCardsAmount > 0">
            <core-icon icon="error" size="xs"></core-icon>
            <span
            >{{ 'REVIEW.MANAGE_CARDS.LISTING.UNASSIGNED_CARDS' | pluralFormat: unassignedCardsAmount | translate: { count: unassignedCardsAmount } }}</span>
          </div>
        </div>
      </div>

      <div class="card-list-container">
        <div class="card-list pr-sm-3">
          <core-divider *ngIf="cards?.length"></core-divider>
          <ng-container
            *ngFor="let card of cards">
            <div
              class="card-item"
              [class.card-item--unassigned]="!card?.name"
              (click)="editCard(card)">
              <div class="mx-1">
                <core-icon class="d-none d-sm-block" size="xl" [icon]="getIcon(card)"></core-icon>
                <core-icon
                  class="d-sm-none status-icon"
                  size="sm"
                  [icon]="card.name ? 'check_circle' : 'error'">
                </core-icon>
              </div>
              <h6 class="mb-0 mx-2 flex-grow-1">
                <span>{{ card?.name || 'REVIEW.MANAGE_CARDS.LISTING.UNASSIGNED_CARD' | translate }}</span>
                <strong>{{ card?.id | cardSerialNumber }}</strong>
              </h6>
              <div class="card-item__action mx-3" *ngIf="!card?.name">
                {{'REVIEW.MANAGE_CARDS.LISTING.ACTION' | translate }}
              </div>
              <div>
                <core-icon icon="chevron_right" size="xs"></core-icon>
              </div>
            </div>
            <core-divider></core-divider>
          </ng-container>
          <div *ngIf="loading" class="my-3">
            <core-spinner diameter="50"></core-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</core-dialog>
