import { Component, Input } from '@angular/core';
import { CardPadding, CardRoundness } from '@core/ui/card/types/common.type';
import { SkeletonCardLevel, SkeletonAnimation, SkeletonColorVariant } from './types/skeleton.type';

@Component({
  selector: 'core-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  host: {
    '[class]': `"skeleton"
      + " roundness-"+roundness
      + " padding-"+padding
      + " level-"+level
      + " variant-"+variant
      + " animation-"+animation`,
    '[class.skeleton--shadow]': 'shadow',
    '[class.skeleton--border]': 'border',
  },
})
export class SkeletonComponent {
  @Input()
  animation: SkeletonAnimation = 'none';

  @Input()
  roundness: CardRoundness = 'md';

  @Input()
  padding: CardPadding = 'none';

  @Input()
  variant: SkeletonColorVariant = 'surface';

  @Input()
  level: SkeletonCardLevel = 1;

  @Input()
  shadow = false;

  @Input()
  border = false;
}
