<div class="core-media-carousel">
  <ng-container *ngIf="loadSwiper">
    <swiper
      #swiper
      [lazy]="lazyLoading"
      [spaceBetween]="spaceBetween"
      [effect]="effect"
      [grabCursor]="grabCursor"
      [pagination]="{
        dynamicBullets: pagination?.dynamicBullets || false,
        clickable: pagination?.clickable || false
      }"
      [loop]="loop"
      [navigation]="navigation"
      [autoplay]="autoplay || {}"
      [initialSlide]="slideIndex"
      (indexChange)="slideChange.emit({ index: $event }); slideIndex = $event; stopVideo()"
      (swiper)="updateLayout(swiper)"
      class="carousel">
      <ng-template swiperSlide *ngFor="let media of slides; let index = index">
        <core-media-item
          [src]="media.src"
          class="swiper-lazy"
          [thumbnail]="media.thumbnail"
          [variant]="media.variant"
          [errorVariant]="errorVariant"
          [imageFit]="imageFit"
          [autoplayVideo]="autoplayVideo && index === slideIndex"
          [imageSrcVariant]="media.imageSrcVariant"
          [imageUrlOptions]="media.imageUrlOptions"
          [videoSrcVariant]="media.videoSrcVariant"
          [crossOrigin]="media?.crossOrigin"
          (actionChange)="actionChange.emit({ index: slideIndex, event: $event })"
          (error)="error.emit({ index: slideIndex, event: $event })"
          (fallbackLoaded)="fallbackLoaded.emit({ index: slideIndex, event: $event }); updateLayout();"
          (loaded)="emitLoadedEvent(swiper, $event); updateLayout(); onMediaLoaded($event)">
        </core-media-item>
        <div class="swiper-lazy-preloader"></div>
      </ng-template>
    </swiper>
  </ng-container>
</div>
