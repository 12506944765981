import { SelectModule } from '@core/ui/select/select.module';
import { FormFieldModule } from '@core/ui/form-field/form-field.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/modules/material/material.module';
import { CoreModule } from '@core/core.module';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

import { RequestCardsComponent } from '@review/modules/request-cards/request-cards.component';
import { DividerModule } from '@core/ui/divider/divider.module';
import { RequestCardsRoutingModule } from './request-cards-routing.module';
import { OrderCardsComponent } from './components/order-cards/order-cards.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';

@NgModule({
  declarations: [RequestCardsComponent, OrderCardsComponent, OrderConfirmationComponent],
  imports: [
    CommonModule,
    RouterModule,
    RequestCardsRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    TranslateModule,
    DialogModule,
    DividerModule,
    FormFieldModule,
    SelectModule,
  ],
})
export class RequestCardsModule {}
