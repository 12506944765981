import { Injectable } from '@angular/core';
import { Product } from '@core/enums/product.enum';
import { Store } from '@core/models/store.model';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { StoreProductStatus } from '@core/enums/store-product-status.enum';

@Injectable({
  providedIn: 'root',
})
export class StoreProductStatusService {
  constructor(private globalFilterService: GlobalFilterService) {}

  getProductStatusForSelectedStore(product: Product): StoreProductStatus {
    return this.getProductStatusForStore(this.globalFilterService.store, product);
  }

  getProductStatusForStore(store: Store, product: Product): StoreProductStatus {
    const { booked, status } = store?.products[product] || { booked: false, status: null };

    if (!booked) {
      return StoreProductStatus.Unbooked;
    }
    if (status === 'LOCATION_SET' || status === 'PENDING') {
      return StoreProductStatus.Pending;
    }
    if (status === 'LOCATION_ERROR') {
      return StoreProductStatus.LocationError;
    }

    return StoreProductStatus.Default;
  }

  getRouteForProduct(product: Product): string {
    if (!product) {
      return '';
    }
    switch (product) {
      case Product.SOCIAL:
        return 'socials';
      case Product.REVIEW:
        return 'reviews';
      case Product.GOOGLE_AD:
        return 'google-ads';
      case Product.META_AD:
        return 'meta-ads';
      case Product.INFLUENCER:
        return 'influencers';
      case Product.PHOTO:
        return 'photos';
      case Product.INTEGRATION:
        return 'integrations';
      default:
        return product.toLocaleLowerCase();
    }
  }

  hasPresaleConfig(product: Product): boolean {
    switch (product) {
      case Product.SOCIAL:
      case Product.REVIEW:
      case Product.GOOGLE_AD:
      case Product.META_AD:
      case Product.INFLUENCER:
        return true;
      default:
        return false;
    }
  }
}
