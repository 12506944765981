import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateTimeValidator } from '@core/validators/date-time.validator';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';

@Component({
  selector: 'core-publish-date-input',
  templateUrl: './publish-date-input.component.html',
  styleUrls: ['./publish-date-input.component.scss'],
})
export class PublishDateInputComponent implements OnInit, OnDestroy {
  @ViewChild('publishDateInput', { static: true })
  publishDateInput?: ElementRef;

  @Input()
  minsInFutureRequired = 5;

  @Input()
  publishAtValidators: ValidatorFn[] = [
    Validators.required,
    DateTimeValidator.isCurrentDateMoreThanSpecifiedMinutesValidator(this.minsInFutureRequired),
  ];

  @Input()
  formGroup: FormGroup = new FormGroup({
    publishAt: new FormControl('', this.publishAtValidators),
    schedule: new FormControl(true),
  });

  destroyed$ = new Subject<void>();

  isScreenSmall = false;

  private hasUserSeenDatePicker = false;

  constructor(private breakpointObserverService: BreakpointObserverService) {}

  ngOnInit(): void {
    this.breakpointObserverService.observeBreakpoints(['xs', 'sm'], (state) => {
      this.isScreenSmall = state.matches;
    });

    this.formGroup.controls.schedule.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.setPublishAtValidators(value);
    });

    this.formGroup.controls.publishAt.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      // Workaround to trigger form validation when publish date changes after the user has seen the date picker
      // Since we programmatically trigger the date picker when the 'Schedule' toggle changes, the input is never marked as touched otherwise
      // If we trigger it when the toggle is changed, the user will see an error while they are still selecting a date
      if (this.hasUserSeenDatePicker) {
        this.formGroup.controls.publishAt?.markAllAsTouched();
      }
    });
  }

  private setPublishAtValidators(schedule: boolean): void {
    if (!schedule) {
      this.formGroup.controls.publishAt.clearValidators();
    } else {
      this.formGroup.controls.publishAt.addValidators(this.publishAtValidators);
    }
    this.formGroup.controls.publishAt.updateValueAndValidity();
  }

  openDatePicker(): void {
    this.publishDateInput?.nativeElement.showPicker?.();
    this.hasUserSeenDatePicker = true;
  }

  onToggleChange(value: boolean): void {
    this.formGroup.controls.publishAt.markAsUntouched();
    if (!value) {
      return;
    }
    setTimeout(() => {
      this.openDatePicker();
    }, 300);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
