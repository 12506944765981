import { AppActionTypes } from './app.action';

export function clearState(reducer: any): any {
  return (state: undefined, action: { type: AppActionTypes }) => {
    if (action.type === AppActionTypes.AppLogout) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }

    return reducer(state, action);
  };
}
