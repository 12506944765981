<form [formGroup]="commentForm" class="comment-field">
  <div
    class="comment-field__content"
    [class.comment-field__content--focused]="focused"
    [class.comment-field__content--disabled]="disabled"
    [class.comment-field__content--error]="
      commentForm?.controls?.comment.dirty && commentForm?.controls?.comment?.errors
    ">
    <div class="comment-field__content__main">
      <div class="comment-field__content__header-title">
        <span class="comment-field__content__title">{{ title }}</span>
        <div class="d-flex">
          <ng-content select="[coreCommentActions]"></ng-content>
        </div>
      </div>
      <div class="grow-wrap">
        <textarea
          #textarea
          onInput="this.parentNode.dataset.replicatedValue = this.value"
          [rows]="textfieldRows || 1"
          formControlName="comment"
          [tabIndex]="tabIndex"
          (focus)="onFocus()"
          (blur)="onBlur()"
          [placeholder]="placeholder"
          class="comment-field__content__input">
        </textarea>
      </div>
      <div
        class="comment-field__validation-error"
        [class.comment-field__validation-error--hidden]="
          !(
            commentForm?.controls?.comment.dirty && commentForm?.controls?.comment?.errors?.required
          )
        ">
        <core-icon icon="error" size="xs" class="mr-2"></core-icon>
        {{ 'VALIDATION.REQUIRED' | translate }}
      </div>
    </div>
    <div class="comment-field">
      <core-form-actions
        class="justify-content-end"
        (cancelClick)="close.emit()"
        (submitClick)="submit(textarea.value)"
        [cancelLabel]="'ACTION.CANCEL' | translate"
        [submitLabel]="ctaLabel || 'ACTION.POST' | translate"
        [actionInProgress]="actionInProgress"
        [primaryIcon]="ctaConfig?.icon"
        [variant]="ctaConfig?.variant || 'primary'">
      </core-form-actions>
    </div>
  </div>
</form>
