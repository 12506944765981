import { Component, Input } from '@angular/core';
import { TagOutline, TagVariant } from '../../types/tag-medium.type';

@Component({
  selector: 'core-tag-medium',
  templateUrl: './tag-medium.component.html',
  styleUrls: ['./tag-medium.component.scss'],
  host: {
    '[class]': `"tag-medium"
      + " outline-"+outline
      + " variant-"+variant`,
    '[class.tag-medium--interactive]': 'interactive',
  },
})
export class TagMediumComponent {
  @Input()
  icon?: string;

  @Input()
  triggerIcon = 'chevron_down';

  @Input()
  interactive = false;

  @Input()
  variant: TagVariant = 'default';

  @Input()
  outline: TagOutline = 'solid';
}
