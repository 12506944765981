import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { WindowWrapperService } from './window-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class MessageEventService {
  isWebView = this.window.ReactNativeWebView;

  constructor(@Inject(WindowWrapperService) private window: WindowWrapperService) {}

  private formatMessage(event: string, params: Params = {}): string {
    return JSON.stringify({
      event,
      params,
    });
  }

  postMessage(event: string, params: Params = {}): void {
    window.postMessage(this.formatMessage(event, params), '/');
  }

  postMessageToWebView(event: string, params: Params = {}): void {
    if (this.window.ReactNativeWebView) {
      this.window.ReactNativeWebView.postMessage(this.formatMessage(event, params));
    }
  }
}
