import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectAllCheckboxComponent } from './select-all-checkbox.component';

@NgModule({
  declarations: [SelectAllCheckboxComponent],
  imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, TranslateModule],
  exports: [SelectAllCheckboxComponent],
})
export class SelectAllCheckboxModule {}
