import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@core/ui/button/button.module';
import { AvatarModule } from '@core/ui/avatar/avatar.module';

import { TagModule } from '@core/ui/tag/tag.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { MediaModule } from '@core/ui/media/media.module';
import { CardModule } from '@core/ui/card/card.module';
import { SkeletonModule } from '@core/ui/skeleton/skeleton.module';

import { FormattedCaptionPipe } from '@core/pipes/formatted-caption.pipe';

import { NumberSuffixesPipe } from '@core/pipes/number-suffixes.pipe';
import { CoreModule } from '@core/core.module';
import { OverlayModule } from '@angular/cdk/overlay';

import { DividerModule } from '@core/ui/divider/divider.module';
import { ProgressModule } from '@core/ui/progress/progress.module';
import { EmptyStateModule } from '@core/ui/empty-state/empty-state.module';
import { PostCardComponent } from './components/post-card/post-card.component';
import { PostMediaComponent } from './components/post-media/post-media.component';
import { PostMediaSaveComponent } from './components/post-media-save-button/post-media-save.component';
import { PostCaptionComponent } from './components/post-caption/post-caption.component';
import { PostOwnerComponent } from './components/post-owner/post-owner.component';
import { PostInteractionsComponent } from './components/post-interactions/post-interactions.component';
import { PostDateComponent } from './components/post-date/post-date.component';
import { PostRatingComponent } from './components/post-rating/post-rating.component';
import { PostDevicePreviewComponent } from './components/post-device-preview/post-device-preview.component';
import { PostMediaThumbnailComponent } from './components/post-media-thumbnail/post-media-thumbnail.component';
import { PostCardSkeletonComponent } from './components/post-card-skeleton/post-card-skeleton.component';

const components = [
  PostCardComponent,
  PostMediaComponent,
  PostMediaSaveComponent,
  PostCaptionComponent,
  PostOwnerComponent,
  PostInteractionsComponent,
  PostDateComponent,
  PostRatingComponent,
  PostDevicePreviewComponent,
  PostMediaThumbnailComponent,
  PostCardSkeletonComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    ButtonModule,
    AvatarModule,
    TagModule,
    SvgModule,
    MediaModule,
    DividerModule,
    EmptyStateModule,
    CardModule,
    OverlayModule,
    ProgressModule,
    SkeletonModule,
  ],
  exports: components,
  providers: [FormattedCaptionPipe, NumberSuffixesPipe],
})
export class PostModule {}
