import { CommentModule } from '@core/ui/comment/comment.module';
import { CoreModule } from '@core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SvgModule } from '@core/ui/svg/svg.module';
import { PlatformModule } from '@core/ui/platform/platform.module';
import { SocialPostModule } from '@social/common/components/social-post/social-post.module';
import { DialogService } from '@core/services/dialog.service';
import { PostManagerModule } from '@core/dialogs/post-manager/post-manager.module';
import { PostViewerModule } from '@core/dialogs/post-viewer/post-viewer.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { MediaModule } from '@core/ui/media/media.module';
import { CheckboxModule } from '@core/ui/checkbox/checkbox.module';
import { AlertModule } from '@core/ui/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialPostThumbnailModule } from '@social/common/components/social-post-thumbnail/social-post-thumbnail.module';
import { DividerModule } from '@core/ui/divider/divider.module';
import { EmptyStateModule } from '@core/ui/empty-state/empty-state.module';
import { SocialWorkflowRoutingModule } from './social-workflow-routing.module';
import { SocialWorkflowComponent } from './social-workflow.component';
import { RecommendConnectionDialogComponent } from './screens/recommend-connection-dialog/recommend-connection-dialog.component';
import { SocialPostDialogComponent } from './screens/social-post-dialog/social-post-dialog.component';
import { DeletePostConfirmationDialogComponent } from './screens/delete-post-confirmation-dialog/delete-post-confirmation-dialog.component';
import { SocialPostCommentsDialogComponent } from './screens/social-post-comments-dialog/social-post-comments-dialog.component';
import { SocialStoryPostGroupDialogComponent } from './screens/social-story-post-group-dialog/social-story-post-group-dialog.component';

@NgModule({
  declarations: [
    SocialWorkflowComponent,
    RecommendConnectionDialogComponent,
    SocialPostDialogComponent,
    DeletePostConfirmationDialogComponent,
    SocialPostCommentsDialogComponent,
    SocialStoryPostGroupDialogComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    DialogModule,
    TranslateModule,
    RouterModule,
    SvgModule,
    TranslateModule,
    SocialPostModule,
    SocialPostThumbnailModule,
    PlatformModule,
    PostViewerModule,
    PostManagerModule,
    ButtonModule,
    MediaModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CommentModule,
    AlertModule,
    DividerModule,
    EmptyStateModule,
    SocialWorkflowRoutingModule,
  ],
  providers: [DialogService],
  exports: [SocialWorkflowComponent],
})
export class SocialWorkflowModule {}
