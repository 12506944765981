/* eslint-disable max-classes-per-file */
/* eslint-disable no-plusplus */
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Optional,
  Inject,
  Directive,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { RadioButtonBaseDirective, RadioGroupBaseDirective } from './radio-base.directive';
import { RADIO_GROUP } from '../../variables/radio.variable';

@Component({
  selector: 'core-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
  host: {
    '[class]': `"core-radio-button"
      + " label-position-"+labelPosition
      + " variant-"+variant`,
    '[attr.tabindex]': '-1',
    '[attr.id]': 'id',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[class.core-checkbox--checked]': 'checked',
    '[class.core-radio--disabled]': 'disabled',
  },
  inputs: ['disabled', 'tabIndex', 'checked'],
  exportAs: 'matRadioButton',
})
export class RadioButtonComponent extends RadioButtonBaseDirective {
  constructor(
    @Optional() @Inject(RADIO_GROUP) radioGroup: any, // RadioGroupDirective
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef,
    focusMonitor: FocusMonitor,
    radioDispatcher: UniqueSelectionDispatcher,
  ) {
    super(radioGroup, elementRef, changeDetector, focusMonitor, radioDispatcher);
  }
}

/**
 * A group of radio buttons. May contain one or more `<core-radio-button>` elements.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'core-radio-group',
  exportAs: 'coreRadioGroup',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true,
    },
    {
      provide: RADIO_GROUP,
      useExisting: RadioGroupDirective,
    },
  ],
  host: {
    role: 'radiogroup',
    class: 'core-radio-group',
  },
})
export class RadioGroupDirective extends RadioGroupBaseDirective<RadioButtonComponent> {
  @ContentChildren(forwardRef(() => RadioButtonComponent), { descendants: true })
  _radios!: QueryList<RadioButtonComponent>;
}
