import { SimpleChanges, Component, Input, OnChanges } from '@angular/core';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { MediaVariant, SingleMediaVariant } from '@core/ui/media/types/media.type';
import { ImageUrlOptions } from '@core/ui/media/types/image.type';
import { MediaBadgePosition, PostMediaAspectRatio, ThumbnailRoundness } from '../../types/post-media.type';

@Component({
  selector: 'core-post-media-thumbnail',
  templateUrl: './post-media-thumbnail.component.html',
  styleUrls: ['./post-media-thumbnail.component.scss'],
  host: {
    '[class]': '"core-post-media-thumbnail"',
  },
})
export class PostMediaThumbnailComponent implements OnChanges {
  /**
   * The aspect ratio of the thumbnail
   */
  @Input()
  aspectRatio: PostMediaAspectRatio = { w: 1, h: 1 };

  /**
   * The max size of the longest side of the thumbnail in pixels (optional)
   */
  @Input()
  size?: number;

  @Input()
  roundness: ThumbnailRoundness = 'md';

  @Input()
  shadow = true;

  @Input()
  isOverlayVisible = false;

  @Input()
  set variant(value: MediaVariant) {
    this._variant = this.getSingleMediaVariant(value);
  }

  get variant() {
    return this._variant;
  }

  private _variant: MediaVariant = 'IMAGE';

  @Input()
  set slides(value: MediaConfig[]) {
    this.imageUrlOptions = this.getImageOptions();
    this._slides = value.map((slide) => ({
      src: slide.thumbnail || slide.src,
      variant: this.getSingleMediaVariant(slide.variant),
      imageUrlOptions: this.imageUrlOptions,
      imageSrcVariant: slide.imageSrcVariant || 'url',
    }));
  }

  get slides() {
    return this._slides;
  }

  private _slides: MediaConfig[] = [];

  @Input()
  badgePosition: MediaBadgePosition = 'bottom-left';

  imageUrlOptions?: ImageUrlOptions;

  devicePixelRatio = window.devicePixelRatio || 1;

  hasError = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.aspectRatio || changes.size) {
      this.imageUrlOptions = this.getImageOptions();
      this._slides = this._slides.map((slide) => ({
        src: slide.thumbnail || slide.src,
        variant: this.getSingleMediaVariant(slide.variant),
        imageUrlOptions: this.imageUrlOptions,
        imageSrcVariant: slide.imageSrcVariant || 'url',
      }));
    }
  }

  getImageOptions(): ImageUrlOptions {
    const options: ImageUrlOptions = {
      ar: this.aspectRatio,
    };

    if (this.size) {
      const size = this.size * this.devicePixelRatio;
      if (this.aspectRatio.h > this.aspectRatio.w) {
        options.h = size;
        options.w = (size / this.aspectRatio.h) * this.aspectRatio.w;
      } else if (this.aspectRatio.w > this.aspectRatio.h) {
        options.h = (size / this.aspectRatio.w) * this.aspectRatio.h;
        options.w = size;
      } else {
        options.h = size;
        options.w = size;
      }
    }

    return options;
  }

  private getSingleMediaVariant(variant: MediaVariant): SingleMediaVariant {
    if (!variant) {
      return 'IMAGE';
    }
    if (variant === 'VIDEO') {
      return 'VIDEO_THUMBNAIL';
    }

    return variant as SingleMediaVariant;
  }
}
