import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Theme } from '@core/interfaces/user-preferences.interface';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { Nullable } from '@core/types/nullable.type';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { MediaVariant } from '@core/ui/media/types/media.type';
import { Observable } from 'rxjs';
import { PostOwner } from '../../types/post-owner.type';

@Component({
  selector: 'core-post-device-preview',
  templateUrl: './post-device-preview.component.html',
  styleUrls: ['./post-device-preview.component.scss'],
  host: {
    '[id]': 'id',
  },
})
export class PostDevicePreviewComponent {
  @Input()
  id?: string;

  @Input()
  slides?: MediaConfig[] = [];

  @Input()
  owner?: PostOwner;

  @Input()
  counts?: {
    likes: Nullable<number>;
    comments: Nullable<number>;
    views: Nullable<number>;
  };

  @Input()
  date?: string;

  @Input()
  caption?: string;

  @Input()
  mediaVariant?: MediaVariant;

  @Output()
  error = new EventEmitter();

  @Output()
  loaded = new EventEmitter();

  @Output()
  fallbackLoaded = new EventEmitter();

  @Output()
  slideChange = new EventEmitter();

  theme$: Observable<Theme> = this.themeService.currentTheme$;

  constructor(private themeService: ThemeService) {}
}
