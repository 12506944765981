<core-dialog
  [canGoBack]="false"
  [headerConfig]="{
    avatarSrc: data?.store?.images?.[0]?.file,
    title: data?.store?.name,
    showClose: false
  }"
  [footerConfig]="{
    submitLabel: 'IAM.ALERT.CONNECT_INSTAGRAM.CTA' | translate,
    cancelLabel: 'IAM.ALERT.CONNECT_INSTAGRAM.SKIP' | translate
  }"
  (dismiss)="skip()"
  (submit)="connect()">
  <div class="row my-auto mx-n2">
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-0">
      <div class="illustration">
        <core-svg
          svg="setup_instagram">
        </core-svg>
      </div>
    </div>
    
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <h5>{{ 'IAM.ALERT.CONNECT_INSTAGRAM.TITLE' | translate }}</h5>
      <p [innerHTML]="'IAM.ALERT.CONNECT_INSTAGRAM.TEXT_HTML' | translate"></p>
    </div>
  </div>
</core-dialog>
