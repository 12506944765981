import { ReviewWorkflowService } from '@review/common/services/review-workflow.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ManageCardsRoute } from '@review/modules/manage-cards/enums/manage-cards-route.enum';
import { Subject } from 'rxjs';
import { PageLink } from '@core/models/page-link.model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MessageEventService } from '@core/services/message-event.service';
import { REVIEW_CARD_EVENT } from '@review/common/variables/webview-events.variable';
import { ReviewPageDataService } from '@review/common/services/review-page-data.service';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { Params } from '@angular/router';

@Component({
  selector: 'review-manage-cards-list',
  templateUrl: './manage-cards-list.component.html',
  styleUrls: ['./manage-cards-list.component.scss'],
})
export class ManageCardsListComponent implements OnInit {
  destroyed$ = new Subject<void>();

  cards: PageLink[] = [];

  unassignedCardsAmount = 0;

  assignedCardsAmount = 0;

  loading = false;

  constructor(
    private reviewWorkflowService: ReviewWorkflowService,
    private reviewPageDataService: ReviewPageDataService,
    private messageEventService: MessageEventService,
    private globalFilterService: GlobalFilterService,
  ) {}

  ngOnInit(): void {
    this.getCards();
  }

  @HostListener('window:message', ['$event'])
  onWindowMessage(message: MessageEvent) {
    this.onMessageReceived(message);
  }

  @HostListener('document:message', ['$event'])
  onDocumentMessage(message: MessageEvent) {
    this.onMessageReceived(message);
  }

  onMessageReceived(message: MessageEvent) {
    try {
      const { event, params } = JSON.parse(message.data);
      switch (event) {
        case REVIEW_CARD_EVENT.cardScanned:
        default:
          this.addScannedCard(params?.id, { method: params?.method });
          break;
      }
    } catch (e) {
      // do nothing
    }
  }

  addScannedCard(id: any, queryParams?: Params): void {
    this.reviewWorkflowService.setCurrentStage(ManageCardsRoute.Assign, id, queryParams);
  }

  getIcon(card: PageLink): string {
    return card.name ? 'icon_nfc_card_assigned' : 'icon_nfc_card_unassigned';
  }

  editCard(item: PageLink): void {
    this.reviewWorkflowService.setCurrentStage(ManageCardsRoute.Assign, item.id);
  }

  getCards(): void {
    this.loading = true;
    this.reviewPageDataService
      .getReviewPageConfig(this.globalFilterService.store?._id)
      .pipe(
        switchMap((page) =>
          this.reviewPageDataService.getPageLinkList(this.globalFilterService.store?._id, page?._id!),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((result) => {
        this.cards = result.sort((a: PageLink, b: PageLink) =>
          // eslint-disable-next-line no-nested-ternary
          a.name === b.name ? 0 : a.name ? -1 : 1,
        );
        this.assignedCardsAmount = this.cards.filter((e: PageLink) => e.name).length;
        this.unassignedCardsAmount = this.cards.filter((e: PageLink) => !e.name).length;
        this.loading = false;
      });
  }

  startScanning(): void {
    if (window.ReactNativeWebView) {
      this.messageEventService.postMessageToWebView(REVIEW_CARD_EVENT.enteredAddWorkflow);
    } else {
      this.reviewWorkflowService.setCurrentStage(ManageCardsRoute.Scan);
    }
  }

  dismiss(): void {
    this.reviewWorkflowService.clearStage();
  }
}
