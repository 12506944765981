import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AppSettingsService } from '@core/services/app-settings.service';

@Pipe({
  name: 'numberSuffixes',
})
export class NumberSuffixesPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private appSettingsService: AppSettingsService,
  ) {}

  transform(input: string | number, decimals = 2, suffix = ''): string | undefined {
    const localeId = this.appSettingsService.getLocaleIdByLanguage();
    let exp = 0;
    let parseNumber: string | null = '';

    // When input null returns null
    if (typeof input === 'string') {
      // eslint-disable-next-line no-param-reassign
      input = parseFloat(input);
    }

    // When input null returns null
    if (Number.isNaN(input) || input === null || input === undefined) {
      return;
    }

    // If number < 1000 returns the formatted number with prefix or suffix and
    // no thousand [K, M] suffixes
    if (input < 1000) {
      return this.decimalPipe.transform(input, `1.0-${decimals}`, localeId) + (suffix || '');
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));

    // eslint-disable-next-line no-restricted-properties
    parseNumber = (input / 1000 ** exp).toString();
    parseNumber = this.decimalPipe.transform(parseNumber, `1.0-${decimals}`, localeId);

    // Get calculated suffix by language
    const suffixNumber = this.appSettingsService.getSuffixesByLanguage(exp - 1);

    return parseNumber + suffixNumber + (suffix ? ` ${suffix}` : '');
  }
}
