/* eslint-disable no-underscore-dangle */
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// eslint-disable-next-line
import { ISegmentService, SegmentTraits } from '@core/interfaces/segment-service.interface';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { AuthUser } from '@core/models/auth-user.model';
import { DOCUMENT } from '@angular/common';
import { WindowWrapperService } from './window-wrapper.service';
import { AuthUserService } from './auth-user.service';

/**
 * Service for handling Segment Tracking and Events in the application
 */
@Injectable({
  providedIn: 'root',
})
export class SegmentService implements ISegmentService, OnDestroy {
  private segment?: any;

  private script?: any;

  private userId?: string;

  private destroyed$ = new Subject<void>();

  private initializedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public initialized$: Observable<boolean>;

  public isInternalLogin = false;

  constructor(
    @Inject(WindowWrapperService) private window: WindowWrapperService,
    @Inject(DOCUMENT) private _doc: Document,
    private router: Router,
    private authUserService: AuthUserService,
    private authService: AuthService,
  ) {
    this.initialized$ = this.initializedSubject$.asObservable();
    this.init();
  }

  /**
   * Initializes tracking
   */
  init(): void {
    this.window.analytics = [];
    this.window.analytics.invoked = true;

    this.window.analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    this.window.analytics.factory =
      (method: string) =>
      (...args: any[]) => {
        args.unshift(method);
        this.window.analytics.push(args);

        return this.window.analytics;
      };

    this.window.analytics.methods.forEach((method: string) => {
      this.window.analytics[method] = this.window.analytics.factory(method);
    });

    this.window.analytics.load = async (key: string, options: { integrations: { [key: string]: boolean } }) => {
      this.script = this._doc.createElement('script');
      this.script.type = 'text/javascript';
      this.script.async = true;
      this.script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

      const first = this._doc.getElementsByTagName('script')[0];
      first.parentNode?.insertBefore(this.script, first);
      this.window.analytics._loadOptions = options;
      this.window.analytics.SNIPPET_VERSION = '4.1.0';

      return true;
    };

    this.window.analytics.ready(() => {
      this.initialize();
      this.segment = this.window.analytics;
      // initial page tracking
      this.screen();

      // subscribe to user data to set the tracking
      this.authUserService.authUser$.pipe(takeUntil(this.destroyed$)).subscribe((res: AuthUser) => {
        if (res) {
          const isInternalLogin = this.authService.isSessionLogin();
          if (this.userId !== res._id.toString() || isInternalLogin !== this.isInternalLogin) {
            this.userId = res._id.toString();
            const traits: SegmentTraits = {
              id: this.userId,
              email: res.contact.email,
              firstName: res.contact.firstName,
              lastName: res.contact.lastName,
            };
            this.isInternalLogin = isInternalLogin;
            if (!this.isInternalLogin) {
              this.identify(this.userId, traits);
            }
          }
        }
      });

      // subscribe to router events and send page views to Segment
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.screen();
        }
      });
    });
  }

  public initialize() {
    this.initializedSubject$.next(true);
  }

  /**
   * Sets the Segment API Key and Config
   */
  public configure(key: string, configOptions?: any) {
    if (!this.segment) {
      this.window.analytics.load(key, configOptions);
    }
  }

  /**
   * Identify
   */
  public identify(userId: string, traits?: SegmentTraits, customTraits?: any, options?: any): void {
    if (this.segment && !this.isInternalLogin && this.userId) {
      let mergedTraits = {};
      if (traits) {
        mergedTraits = Object.assign(mergedTraits, traits);
      }
      if (customTraits) {
        mergedTraits = Object.assign(mergedTraits, customTraits);
      }
      this.segment.identify(userId, mergedTraits, options);
    }
  }

  public track(event: string, properties?: any, options?: any): void {
    if (this.segment && !this.isInternalLogin) {
      this.segment.track(event, properties, options);
    }
  }

  public screen(name?: string, properties?: any, options?: any): void {
    if (this.segment && !this.isInternalLogin) {
      this.segment.page(name, properties, options);
    }
  }

  public trackByPage(event: string, properties?: any): void {
    this.track(event, { page: this.router.url, ...properties });
  }

  public trackFakedoorClick(productName: string, action: string, properties?: any): void {
    this.track(`FD - ${productName} - ${action}`, { page: this.router.url, ...properties });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
