<core-overlay
  [class.thumbnail-shadow]="shadow"
  [visible]="isOverlayVisible"
  [overflowVisible]="true"
  [roundness]="roundness"
  [selectable]="true"
  variant="primary"
  padding="none">
  <div 
    coreOverlayProtectedContent
    class="media-wrapper"
    [style.width]="imageUrlOptions ? (imageUrlOptions?.w / devicePixelRatio) + 'px' : '100%'"
    [style.max-width.%]="100"
    [style.padding-top.%]="(aspectRatio?.h / aspectRatio?.w) * 100"
    [ngClass]="'media-wrapper--roundness-' + roundness">
    <core-post-media
      [style.width]="imageUrlOptions ? (imageUrlOptions?.w / devicePixelRatio) + 'px' : '100%'"
      [style.max-width.%]="100"
      [slides]="slides"
      imageFit="cover"
      [roundness]="roundness"
      [carouselOptions]="{
        aspectRatio: aspectRatio
      }"
      [variant]="variant"
      errorVariant="image-only"
      (error)="hasError = true">
    </core-post-media>
    <div class="gradients">
      <div class="top-gradient">
        <ng-content select="[thumbnailHeaderContent]"></ng-content>
      </div>
      <div class="bottom-gradient">
        <ng-content select="[thumbnailFooterContent]"></ng-content>
      </div>
    </div>
  </div>
  <div class="thumbnail-overlay-content-wrapper">
    <ng-content select="[thumbnailOverlayContent]"></ng-content>
  </div>
</core-overlay>

<div class="badge" [ngClass]="badgePosition">
  <ng-content select="[thumbnailBadge]"></ng-content>
</div>