<label #label
  [attr.for]="inputId"
  [class.checked]="checked"
  [class.disabled]="disabled"
  [class.centered]="contentAlignment === 'center'"
  [class.truncate-label-content]="!multiLineLabel"
  [ngClass]="labelPaddingClassList">
  <span #labelContent class="label-content" (cdkObserveContent)="onLabelChange()">
    <ng-content></ng-content>
  </span>
  <input
    #input
    type="checkbox"
    role="switch"
    [id]="inputId"
    [required]="required"
    [tabIndex]="tabIndex"
    [checked]="checked"
    [disabled]="disabled"
    [attr.name]="name"
    [attr.aria-checked]="checked?.toString()"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="toggleChecked($event)"
    (click)="onInputClick($event)" />
</label>
