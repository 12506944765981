<div class="actions-container p-1 pb-2 mx-auto mt-auto">
  <div class="actions-container__navigate">
    <button
      class="mr-2" [disabled]="first" coreIconButton variant="light" icon="chevron_left"
      (keyup)="onKeyUp($event)"
      (click)="onIndexChange(index - 1)">
    </button>
    <button
      class="mr-2" [disabled]="last" coreIconButton variant="light" icon="chevron_right"
      (keyup)="onKeyUp($event)"
      (click)="onIndexChange(index + 1)">
    </button>
  </div>
  <button coreIconButton variant="light" icon="close" (click)="removeOutlet()"></button>
</div>
<div class="preview-container mx-auto mb-auto">
  <social-post
    [index]="index"
    [groupLength]="data.posts.length"
    [autoplayVideo]="true"
    [duration]="durations[index]"
    [icon]="icon"
    [iconType]="iconType"
    [provider]="data?.provider"
    [post]="data.posts[index]"
    [owner]="data.postOwner"
    [editLoadingForPost]="editLoading ? data.posts[index].id : null"
    (durationEnded)="onIndexChange(index + 1)"
    (deleteClick)="onDeleteClick(data.posts[index])"
    (ctaClick)="onCtaClick($event)">
  </social-post>
</div>
