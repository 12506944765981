<div class="bar w-100 variant-{{ variant }}" [style.backgroundColor]="unfilledBarColor">
  <div
    class="bar__filled bar__filled--{{ mode }}"
    [class.bar__filled--flash]="flash"
    [style.background-color]="variant === 'custom' ? customColor : null"
    [style.width.%]="mode === 'determinate' ? (value / total) * 100 : 100"
    [ngClass]="
      50 > (value / total) * 100
        ? 25 > (value / total) * 100
          ? 'bar__filled--low'
          : 'bar__filled--medium'
        : 'bar__filled--high'
    "></div>
</div>
