<core-dialog
  [style.height]="'unset'"
  [headerConfig]="{
    showClose: true,
    showSupport: true,
    title: 'REVIEW.GENERATION.REQUEST_CARDS.TITLE' | translate
  }"
  [footerConfig]="{
    submitLabel: 'REVIEW.GENERATION.REQUEST_CARDS.REQUEST_BUTTON' | translate
  }"
  (dismiss)="onClose()"
  (submit)="onConfirm()">
  <div class="row mx-n2 my-auto">
    <!-- Image section -->
    <div class="col-12 col-sm-4 px-2 flex-column d-flex align-items-center">
      <core-svg
        svg="img_nfc_cards">
      </core-svg>
    </div>
    <!-- Form section -->
    <div class="col-12 px-2 d-flex flex-column col-sm-8">
      <form [formGroup]="orderForm!">
        <core-form-field class="flex-grow-1 d-flex flex-column">
          <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.SELECT_CARDS' | translate }}</span>
          <core-select formControlName="amount" required>
            <core-option *ngFor="let amount of [1, 3, 5, 10]" [value]="amount">
              {{ amount }}
            </core-option>
          </core-select>
        </core-form-field>
        
        <h4 class="mb-3">{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.DELIVERY_DETAILS' | translate }}</h4>
        <div formGroupName="address">
          <div class="row mx-n2">
            <div class="col-12 col-sm-6 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.FIRST_NAME' | translate }}</span>
                <input
                  coreInput formControlName="firstName" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.FIRST_NAME' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('firstName')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 col-sm-6 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.LAST_NAME' | translate }}</span>
                <input
                  coreInput formControlName="lastName" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.LAST_NAME' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('lastName')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.COMPANY_NAME' | translate }}</span>
                <input
                  coreInput formControlName="companyName" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.COMPANY_NAME' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('companyName')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 col-sm-6 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.STREET_NAME' | translate }}</span>
                <input
                  coreInput formControlName="streetName" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.STREET_NAME' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('streetName')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 col-sm-6 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.HOUSE_NUMBER' | translate }}</span>
                <input
                  coreInput formControlName="houseNumber" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.HOUSE_NUMBER' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('houseNumber')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 col-sm-6 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.CITY' | translate }}</span>
                <input
                  coreInput formControlName="city" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.CITY' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('city')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 col-sm-6 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.POST_CODE' | translate }}</span>
                <input
                  coreInput formControlName="zip" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.POST_CODE' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('zip')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 px-2">
              <core-form-field class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.COUNTRY' | translate }}</span>
                <input
                  coreInput formControlName="country" required type="text"
                  [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.COUNTRY' | translate"
                />
                <span coreFormFieldError *ngIf="orderForm?.get('country')?.errors">
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 d-flex flex-row px-2 px-2">
              <div class="col-4 p-0 d-flex flex-column">
                <core-form-field
                  class="flex-grow-1 d-flex flex-column"
                  variant="secondary"
                  groupPosition="start">
                  <span coreFormFieldLabel>{{ 'AUTH.FIELD.DIALCODE' | translate }}</span>
                  <core-select formControlName="dialCode" required autocomplete="tel-country-code" [hideSingleSelectionIndicator]="true" [panelWidth]="null">
                    <span coreSelectTrigger>
                      <div class="d-flex align-items-center">
                        <div class="core-option-icon">{{ orderForm?.get('address.dialCode')?.value?.flag }}</div>
                        <span>{{ orderForm?.get('address.dialCode')?.value?.dialCode }}</span>
                      </div>
                    </span>
                    <core-option *ngFor="let option of dialCodeQuickOptions" [value]="option">
                      <span coreOptionIcon>{{ option.flag }}</span>
                      <div class="dialcode d-flex flex-row align-items-center justify-content-between">
                        <span class="dialcode__country-name">{{ option.name[language || 'en'] }}</span>
                        <span class="dialcode__value">{{ option.dialCode }}</span>
                      </div>
                    </core-option>
                    <core-divider></core-divider>
                    <core-option *ngFor="let option of dialCodeOptions" [value]="option">
                      <span coreOptionIcon>{{ option.flag }}</span>
                      <div class="dialcode d-flex flex-row align-items-center justify-content-between">
                        <span class="dialcode__country-name">{{ option.name[language || 'en'] }}</span>
                        <span class="dialcode__value">{{ option.dialCode }}</span>
                      </div>
                    </core-option>
                  </core-select>
                </core-form-field>
              </div>
              <div class="col-8 p-0 d-flex w-100 flex-column">
                <core-form-field
                  class="flex-grow-1 d-flex flex-column"
                  groupPosition="end">
                  <span coreFormFieldLabel>{{ 'REVIEW.GENERATION.REQUEST_CARDS.FORM.PHONE_NUMBER' | translate }}</span>
                  <input
                    coreInput formControlName="phoneNumber" required type="text"
                    [placeholder]="'REVIEW.GENERATION.REQUEST_CARDS.FORM.PHONE_NUMBER' | translate"
                  />
                  <span coreFormFieldError *ngIf="orderForm?.get('phoneNumber')?.errors">
                    {{ 'VALIDATION.REQUIRED' | translate }}
                  </span>
                </core-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</core-dialog>
