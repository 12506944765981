<div class="media" [class.media--error]="hasError" [ngSwitch]="variant">
  <core-image
    *ngSwitchCase="'IMAGE'"
    [src]="src"
    [variant]="imageSrcVariant"
    [imageUrlOptions]="imageUrlOptions"
    [fit]="imageFit"
    [crossOrigin]="crossOrigin"
    (error)="error.emit($event); hasError = true"
    (loaded)="loaded.emit($event); hasError = false">
  </core-image>

  <core-video
    *ngSwitchCase="'VIDEO'"
    [src]="src"
    [isPlaying]="autoplayVideo"
    [thumbnail]="thumbnail"
    [variant]="videoSrcVariant"
    [thumbnailVariant]="imageSrcVariant"
    [crossOrigin]="crossOrigin"
    (actionChange)="actionChange.emit($event)"
    (error)="error.emit($event); hasError = true"
    (loaded)="loaded.emit($event); hasError = false">
  </core-video>

  <core-video-thumbnail
    *ngSwitchCase="'VIDEO_THUMBNAIL'"
    [src]="src"
    [variant]="imageSrcVariant"
    [imageUrlOptions]="imageUrlOptions"
    [fit]="imageFit"
    [crossOrigin]="crossOrigin"
    (error)="error.emit($event)"
    (loaded)="loaded.emit($event); hasError = false">
  </core-video-thumbnail>

  <ng-container
    *ngSwitchDefault>
  </ng-container>
</div>

<core-overlay
  *ngIf="hasError"
  class="error-overlay"
  padding="none" [visible]="true" [roundness]="roundness" variant="primary">
  <ng-container coreOverlayProtectedContent>
    <core-image fit="cover" src="assets/core/img/sample_background_image_square.png" variant="asset" (loaded)="fallbackLoaded.emit($event)"></core-image>
  </ng-container>
  <div [ngSwitch]="errorVariant" class="media-error">
    <div  
      *ngSwitchCase="'image-text'"
      class="broken">
      <core-svg svg="outline_media_broken"></core-svg>
      <div class="broken__title">{{ 'POST.RESOURCE_NOT_LOADED' | translate }}</div>
    </div>

    <div
      *ngSwitchCase="'image-only'"
      class="broken broken--image-only">
      <core-svg 
        svg="outline_thumbnail_broken">
      </core-svg>
    </div>

    <div  
      *ngSwitchDefault
      class="broken">
      <core-svg svg="outline_media_broken"></core-svg>
      <div class="broken__title">{{ 'POST.RESOURCE_NOT_LOADED' | translate }}</div>
    </div>
  </div>
</core-overlay>
