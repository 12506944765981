import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import {
  getLoadListState,
  getLoadListSuccessState,
  getLoadListFailState,
  getLoadObjectState,
  getLoadObjectSuccessState,
  getLoadObjectFailState,
  getCreateObjectState,
  getCreateObjectSuccessState,
  getCreateObjectFailState,
  getUpdateObjectState,
  getUpdateObjectSuccessState,
  getUpdateObjectFailState,
  getDeleteObjectState,
  getDeleteObjectSuccessState,
  getDeleteObjectFailState,
  CrudEntityState,
  crudInitialState,
} from '@core/redux/crud/crud.reducer';
import { AppState } from '@core/interfaces/app-state.interface';
import { Company } from '@core/models/company.model';
import { CompanyActionTypes, CompanyAction } from './company.action';

export const companyAdapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  // eslint-disable-next-line no-underscore-dangle
  selectId: (company) => company._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export type CompanyState = CrudEntityState<Company>;

export function companyReducer(
  state: CompanyState = companyAdapter.getInitialState(crudInitialState),
  action: CompanyAction,
): CompanyState {
  switch (action.type) {
    /** ******************************************* */
    // Load company list
    /** ****************************************** */
    case CompanyActionTypes.LoadCompanyList:
      return getLoadListState(state, action);

    case CompanyActionTypes.LoadCompanyListSuccess:
      return companyAdapter.upsertMany(action.payload, getLoadListSuccessState(state, action));

    case CompanyActionTypes.LoadCompanyListFail:
      return getLoadListFailState(state, action);

    /** ******************************************* */
    // Load company
    /** ****************************************** */
    case CompanyActionTypes.LoadCompany:
      return getLoadObjectState(state);

    case CompanyActionTypes.LoadCompanySuccess:
      return companyAdapter.upsertOne(action.payload, getLoadObjectSuccessState(state));

    case CompanyActionTypes.LoadCompanyFail:
      return getLoadObjectFailState(state);

    /** ******************************************* */
    // Create company
    /** ****************************************** */
    case CompanyActionTypes.CreateCompany:
      return getCreateObjectState(state);

    case CompanyActionTypes.CreateCompanySuccess:
      return companyAdapter.addOne(action.payload, getCreateObjectSuccessState(state));

    case CompanyActionTypes.CreateCompanyFail:
      return getCreateObjectFailState(state);

    /** ******************************************* */
    // Update company
    /** ****************************************** */
    case CompanyActionTypes.UpdateCompany:
      return getUpdateObjectState(state);

    case CompanyActionTypes.UpdateCompanySuccess:
      return companyAdapter.upsertOne(action.payload, getUpdateObjectSuccessState(state));

    case CompanyActionTypes.UpdateCompanyFail:
      return getUpdateObjectFailState(state);

    /** ******************************************* */
    // Delete company
    /** ****************************************** */
    case CompanyActionTypes.DeleteCompany:
      return getDeleteObjectState(state);

    case CompanyActionTypes.DeleteCompanySuccess:
      return companyAdapter.removeOne(action.id, getDeleteObjectSuccessState(state));

    case CompanyActionTypes.DeleteCompanyFail:
      return getDeleteObjectFailState(state);

    default:
      return state;
  }
}

/**
 * Return company state from the app state.
 */
export const getCompanyState = (state: AppState) => state.companies;

export const {
  selectAll: selectAllCompanies,
  selectIds: selectCompanyIds,
  selectEntities: selectCompanyEntities,
} = companyAdapter.getSelectors(getCompanyState);
