import { AgeRange, Gender } from '@core/enums/audience.enum';
import { SupportedLanguage } from '@core/enums/user-preference.enum';
import { CaptionSettings } from '../interfaces/ai-caption-request.interface';

export const defaultCaptionSettings = (languageCode: SupportedLanguage): CaptionSettings => ({
  language: {
    languageCode,
    localization: 3,
  },
  audience: {
    gender: [Gender.Male, Gender.Female],
    age: Object.values(AgeRange),
  },
  voice: {
    directness: 3,
    formality: 3,
    complexity: 3,
  },
  content: {
    emotionality: 3,
    persuasiveness: 3,
    imagery: 3,
    interactivity: 3,
    humor: 3,
  },
  social: {
    length: 3,
    emojis: 3,
    hashtags: 3,
  },
});
