import { MessageEventService } from '@core/services/message-event.service';
import { RoutingService } from '@core/services/routing.service';
import { AuthService } from '@core/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService } from '@core/services/dialog.service';
import { REQUEST_EVENT } from '@core/variables/webview-events.variable';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private messageEventService: MessageEventService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hasUrlSVGPath = request.url.includes('.svg');
    const isLokalisePath = request.url.startsWith('https://storage.googleapis.com/lokalise-translations');

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.messageEventService.postMessageToWebView(REQUEST_EVENT.errored, {
          error,
          accessToken: this.authService.accessToken,
          request,
        });
        if (error.status === 401 && !hasUrlSVGPath && !isLokalisePath) {
          this.authService.clearTokens();
          this.dialogService.closeDialog();
          this.routingService.navigateToLogin();
        }

        return throwError(() => error);
      }),
    );
  }
}
