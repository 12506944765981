import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgComponent } from './components/svg/svg.component';
import { IconComponent } from './components/icon/icon.component';

@NgModule({
  declarations: [SvgComponent, IconComponent],
  imports: [CommonModule, MatIconModule],
  exports: [SvgComponent, IconComponent],
})
export class SvgModule {}
