import { Injectable } from '@angular/core';
import { PageLinkType } from '@core/enums/page.enum';
import { Adapter } from '@core/interfaces/adapter.interface';

export class PageLinkTypeAnalytics {
  id?: string;

  lastEvent?: string;

  name?: string;

  total?: number;

  type?: PageLinkType;

  provider?: string;

  trend?: {
    after: {
      total: number;
    };
    before: {
      total: number;
    };
    delta: {
      total: number;
      totalPercentage: number;
    };
    series: {
      unit: string;
      unitAmount: number;
      unitStart: string;
      unitEnd: string;
      data: {
        date: string;
        count: number;
        countCumulative: number;
      }[];
    };
  };

  series?: {
    unit: string;
    unitAmount: number;
    unitStart: string;
    unitEnd: string;
    data: {
      date: string;
      count: number;
      countCumulative: number;
    }[];
  };

  constructor(object: PageLinkTypeAnalytics) {
    Object.assign(this, object);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PageLinkTypeAnalyticsAdapter implements Adapter<PageLinkTypeAnalytics> {
  clientAdapt(object: any): PageLinkTypeAnalytics {
    return new PageLinkTypeAnalytics(object);
  }

  serverAdapt(object: PageLinkTypeAnalytics): any {
    return object;
  }
}
