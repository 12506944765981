/**
 * Tracking events for IAM Post
 */
export const TRACK_MANAGED_POST = {
  created: 'Created post',
  shared: 'Reposted media',
  edited: 'Edited post',
  deleted: 'Rejected post',
};

/**
 * Tracking events for language
 */
export const TRACK_LANGUAGE = {
  updated: 'Changed Language',
};

/**
 * Tracking events for registration
 */
export const TRACK_REGISTRATION = {
  method: '[Register] Chose method',
  user: {
    created: '[Register] Confirmed user details',
    invalidForm: '[Register] Submitted invalid user details',
    checkedEmail: '[Register] Checked if email address is registered',
    authSuccess: '[Register] Social auth success',
    authError: '[Register] Social auth failed',
    createError: '[Register] Could not create user',
    checkedEmailError: '[Register] Could not check if email address is registered',
  },
  store: {
    attached: '[Register] Attached store to user',
    invalidForm: '[Register] Submitted invalid store details',
    error: '[Register] Could not attach store',
  },
  finished: '[Register] Finished registration',
};

/**
 * Tracking events for registration
 */
export const TRACK_LOGIN = {
  method: '[Login] Chose method',
  user: {
    authSuccess: '[Login] Social auth success',
    authError: '[Login] Social auth failed',
  },
};

/**
 * Tracking events for upselling
 */
export const TRACK_UPSELLING = {
  viewedDialog: (productName: string) => `Viewed Upselling Dialog - ${productName}`,
  viewedBanner: (productName: string) => `Viewed Upselling Banner - ${productName}`,
  clickedDemo: (productName: string) => `Clicked Upselling Demo - ${productName}`,
  bookedDemo: (productName: string) => `Booked Upselling Demo - ${productName}`,
};

/**
 * Tracking navigation event for web view
 */
export const TRACK_NAVIGATION = {
  navigatedToWebViewPage: 'Navigated to web view page',
  navigatedToExternalUrl: 'Navigated to external url',
};

/**
 * Tracking events for presale screens
 */
export const TRACK_PRESALE = {
  clickedCta: '[Presale] Clicked on CTA button',
  pageViewed: '[Presale] Page viewed',
};

/**
 * Tracking events for review additional platforms
 */
export const TRACK_REVIEW_ADDITIONAL_PLATFORMS = {
  clickedConnect: '[REVIEW] Clicked on Connect Additional Platforms',
};

/**
 * Tracking events for Goal setting dialog
 */
export const TRACK_GOAL_SETTING = {
  clickedGoBackButton: '[Goal setting] Clicked on Back to Goals button',
};

/**
 * Tracking events for checking previously generated reply
 */
export const PAGINATED_GENERATED_REPLY = `[Review] Paginated Generated Reply`;
