import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Plyr from 'plyr';
import { Nullable } from '@core/types/nullable.type';
import { CrossOrigin, MediaSrcVariant } from '../../types/media.type';

@Component({
  selector: 'core-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  host: {
    '[style.aspectRatio]': 'aspectRatio',
  },
})
export class VideoComponent implements OnChanges {
  safeUrl?: SafeUrl | string;

  @ViewChild('video', { static: false })
  video?: ElementRef;

  @Input()
  src: Nullable<string> = null;

  @Input()
  variant: MediaSrcVariant = 'url';

  @Input()
  thumbnail: Nullable<string> = null;

  @Input()
  thumbnailVariant: MediaSrcVariant = 'url';

  @Input()
  crossOrigin: Nullable<CrossOrigin> = null;

  player!: Plyr;

  @Input()
  isPlaying = false;

  aspectRatio = 1;

  @Output()
  actionChange = new EventEmitter();

  @Output()
  error = new EventEmitter();

  @Output()
  loaded = new EventEmitter();

  autoplay = true;

  private canvas: HTMLCanvasElement = document.createElement('canvas');

  thumbnailSrc: Nullable<string> = null;

  thumbnailMissing = false;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.thumbnail) {
      this.autoplay = false;
      this.isPlaying = true;
    } else {
      this.thumbnailSrc = this.thumbnail;
    }
    this.setEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variant || changes.src) {
      if (this.src && this.variant !== 'asset' && this.variant !== 'blob') {
        this.safeUrl = this.domSanitizer.bypassSecurityTrustUrl(this.src);

        return;
      }
      this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.src!);
    }
  }

  setEvents(): void {
    this.player = new Plyr(this.video?.nativeElement, {
      fullscreen: {
        enabled: true,
      },
      controls: [],
    });
  }

  captureThumbnail(video: HTMLVideoElement): void {
    // If a thumbnail exists, we don't need to capture it
    // If a thumbnail is marked as missing, we ignore this function
    if (this.thumbnailSrc || this.thumbnailMissing) {
      return;
    }
    this.canvas.width = video.videoWidth;
    this.canvas.height = video.videoHeight;
    const context = this.canvas.getContext('2d');
    setTimeout(() => {
      context?.drawImage(video, 0, 0);
      try {
        this.thumbnailVariant = 'blob';
        this.thumbnailSrc = this.canvas.toDataURL();
      } catch (e) {
        this.thumbnailSrc = null;
        // Setting this ensures that we don't keep trying to capture it each time after it fails once
        this.thumbnailMissing = true;
      }
      this.isPlaying = false;
      this.autoplay = true;
    }, 500);
  }

  playVideo(value: boolean): void {
    this.isPlaying = value;
    setTimeout(() => {
      if (this.video && this.video.nativeElement) {
        this.setEvents();
      }
    });
  }

  /**
   * Sets the aspect ratio so the dom doesn't collapse when switching between the thumbnail and video
   * @param event loadedmetadata event
   */
  setAspectRatio(eventTarget: Nullable<EventTarget>): void {
    const element = eventTarget as HTMLImageElement | HTMLVideoElement;
    if ('videoHeight' in element) {
      const { videoHeight, videoWidth } = element;
      if (!(videoHeight && videoWidth)) {
        return;
      }
      this.aspectRatio = videoWidth / videoHeight;
    } else if (!this.aspectRatio) {
      const { clientHeight, clientWidth } = element;
      if (!(clientHeight && clientWidth)) {
        this.aspectRatio = 1;

        return;
      }
      this.aspectRatio = clientWidth / clientHeight;
    }
  }

  onThumbnailError(): void {
    if (this.thumbnailMissing) {
      return;
    }
    this.thumbnailSrc = null;
    // Setting this ensures that we don't keep trying to capture it each time after it fails once
    this.thumbnailMissing = true;
    const video = document.createElement('video');
    video.onloadedmetadata = (event) => {
      const { videoHeight, videoWidth } = video;
      if (!(videoHeight && videoWidth)) {
        this.aspectRatio = 1;
      }
      this.aspectRatio = videoWidth / videoHeight;
      this.loaded.emit(event);
    };
    video.src = this.src!;
  }

  onThumbnailLoaded(event: Event): void {
    this.setAspectRatio(event.currentTarget);
    this.loaded.emit(event);
  }
}
