import { InjectionToken } from '@angular/core';

/**
 * Injection token for the app name to be sent as a request header
 */
export const REQUESTHEADER_APPNAME = new InjectionToken('fr-app-name');

/**
 * Injection token for the version to be displayed in the settings module
 */
export const APP_VERSION = new InjectionToken('app-version');
