import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AccountDetails } from '@core/interfaces/integration.interface';
import * as activeIcons from '@core/ui/platform/variables/icons-active.variable';
import { Subject } from 'rxjs';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { ProviderAccountColumn } from '../../types/provider-account.type';

@Component({
  selector: 'core-provider-account-list',
  templateUrl: './provider-account-list.component.html',
  styleUrls: ['./provider-account-list.component.scss'],
})
export class ProviderAccountListComponent implements OnChanges, OnDestroy {
  icons = activeIcons;

  @Input()
  accounts: AccountDetails[] = [];

  @Input()
  preselectedId = '';

  @Output()
  accountChange = new EventEmitter<string>();

  providerId = '';

  visibleColumns: Record<ProviderAccountColumn, boolean> = {
    name: true,
    rating: false,
    followers: false,
    location: false,
  };

  isHandset = false;

  destroyed$ = new Subject<void>();

  constructor(private breakpointObserverService: BreakpointObserverService) {
    this.breakpointObserverService.observeBreakpoints('handset', (state) => {
      this.isHandset = state.matches;
    });

    this.providerId = this.preselectedId || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accounts && this.accounts) {
      this.visibleColumns = {
        name: true,
        rating: this.accounts.some((account) => account.ratings?.average || account.ratings?.total),
        followers: this.accounts.some((account) => account.socialMetrics?.followers),
        location: this.accounts.some(
          (account) => account.location?.street || account.location?.zip || account.location?.city,
        ),
      };
    }
  }

  radioButtonChangeHandler(value: string): void {
    this.providerId = value;
    this.accountChange.emit(this.providerId);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
