<div class="gauge">
  <svg xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="getViewBox()">
    <circle
      fill="none"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="radius - stroke / 2"
      [style.stroke]="colors.unfilled"
      [style.stroke-width]="stroke" />
    <path
      #pathHigh
      fill="none"
      [style.stroke-width]="stroke"
      [style.stroke]="colors.high"
      [attr.transform]="getPathTransform()" />
    <path
      #pathMedium
      fill="none"
      [style.stroke-width]="stroke"
      [style.stroke]="colors.medium"
      [attr.transform]="getPathTransform()" />
    <path
      #pathLow
      fill="none"
      [style.stroke-width]="stroke"
      [style.stroke]="colors.low"
      [attr.transform]="getPathTransform()" />
    <path
      d="m 188,60.5 c 1.10457,0 2,0.89543 2,2 0,1.10457 -0.89543,2 -2,2 -1.10457,0 -2,-0.89543 -2,-2 0,-1.10457 0.89543,-2 2,-2 z"
      [style.fill]="clockwise ? colors.high : colors.low" />
    <path
      d="m 125,33.75 c 1.10457,0 2,0.89543 2,2 0,1.10457 -0.89543,2 -2,2 -1.10457,0 -2,-0.89543 -2,-2 0,-1.10457 0.89543,-2 2,-2 z"
      [style.fill]="colors.medium" />
    <path
      d="m 62,60.5 c 1.10457,0 2,0.89543 2,2 0,1.10457 -0.89543,2 -2,2 -1.10457,0 -2,-0.89543 -2,-2 0,-1.10457 0.89543,-2 2,-2 z"
      [style.fill]="clockwise ? colors.low : colors.high" />
  </svg>
</div>

<svg
  class="needle"
  [class.needle--clockwise]="clockwise"
  [class.needle--anticlockwise]="!clockwise"
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  [style.transform]="rotateNeedle()"
  viewBox="0 0 100 100">
  <path
    [style.fill]="colors.needle"
    d="m 49.99996,42.999999 c -2.107184,-0.0011 -3.736483,-1.830528 -3.471693,-3.897598 L 50.000238,12 53.471733,39.106958 c 0.264794,2.067421 -1.36451,3.894447 -3.471773,3.893041 z" />
</svg>

<div class="percentage">
  <core-kpi-value class="percentage__value" [value]="value" unit="%"></core-kpi-value>
  <div *ngIf="label" class="percentage__label">{{ label }}</div>
</div>
