import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { FormFieldErrorDirective } from './directives/form-field-error.directive';
import { FormFieldPrefixDirective } from './directives/form-field-prefix.directive';
import { FormFieldSuffixDirective } from './directives/form-field-suffix.directive';
import { InputDirective } from './directives/input.directive';
import { FormFieldComponent } from './form-field.component';

const components = [
  FormFieldErrorDirective,
  FormFieldPrefixDirective,
  FormFieldSuffixDirective,
  InputDirective,
  FormFieldComponent,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, SvgModule, ObserversModule],
  exports: [...components],
})
export class FormFieldModule {}
