/* eslint-disable max-classes-per-file */
import { Component, Inject, ElementRef, Renderer2 } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SocialPostCommentsDialogConfig } from '@social/common/types/social-post-comments-dialog-config.type';
import { ProviderAccountPostDataService } from '@social/common/services/provider-account-post-data.service';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { first, catchError } from 'rxjs/operators';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { EMPTY } from 'rxjs';
import { ProviderPostComment } from '@social/common/models/provider-post-comment.model';
import { socialOutlet } from '../../variables/outlet.variable';

class ProviderPostCommentExtended extends ProviderPostComment {
  replies?: ProviderPostComment[];

  constructor(object: ProviderPostCommentExtended) {
    super(object);
    this.replies = object.replies;
  }
}

type CommentMenuAction = 'reply' | 'edit' | 'remove';

@Component({
  selector: 'social-post-comments-dialog',
  templateUrl: './social-post-comments-dialog.component.html',
  styleUrls: ['./social-post-comments-dialog.component.scss'],
})
export class SocialPostCommentsDialogComponent {
  actionInProgress = false;

  showAddCommentField = false;

  comments: ProviderPostCommentExtended[] = [];

  commentIdToFetchRepliesFor: Nullable<string> = null;

  commentIdToReplyTo: Nullable<string> = null;

  commentIdToEdit: Nullable<string> = null;

  commentIdToDelete: Nullable<string> = null;

  commentActionsForPostOwner: Partial<Record<CommentMenuAction, { label: string }>> = {};

  commentActionsForOtherUsers: Partial<Record<CommentMenuAction, { label: string }>> = {};

  replyActionsForPostOwner: Partial<Record<CommentMenuAction, { label: string }>> = {};

  replyActionsForOtherUsers: Partial<Record<CommentMenuAction, { label: string }>> = {};

  constructor(
    private dialogRef: MatDialogRef<SocialPostCommentsDialogConfig>,
    private router: Router,
    private providerAccountPostDataService: ProviderAccountPostDataService,
    private globalFilterService: GlobalFilterService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private el: ElementRef,
    public dialog: MatDialog,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: SocialPostCommentsDialogConfig,
  ) {
    this.comments = data.comments;
    this.setActionMenus(data.provider);
  }

  setActionMenus(provider: ManagedPostProvider): void {
    const reply = { label: 'ACTION.REPLY' };
    const edit = { label: 'ACTION.EDIT' };
    const remove = { label: 'ACTION.DELETE' };

    switch (provider) {
      case ManagedPostProvider.Instagram:
        this.commentActionsForPostOwner = { reply, remove };
        this.commentActionsForOtherUsers = { reply, remove };
        this.replyActionsForPostOwner = { reply, remove };
        this.replyActionsForOtherUsers = { reply, remove };
        break;
      case ManagedPostProvider.Facebook:
        this.commentActionsForPostOwner = { edit, remove };
        this.commentActionsForOtherUsers = { remove };
        this.replyActionsForPostOwner = {};
        this.replyActionsForOtherUsers = {};
        break;
      default:
        this.commentActionsForPostOwner = {};
        this.commentActionsForOtherUsers = {};
        this.replyActionsForPostOwner = {};
        this.replyActionsForOtherUsers = {};
    }
  }

  onActionClick(action: CommentMenuAction, commentId: string): void {
    switch (action) {
      case 'reply':
        return this.openReplyMode(commentId);
      case 'edit':
        return this.openEditMode(commentId);
      case 'remove':
        return this.openDeleteMode(commentId);
      default:
        break;
    }
  }

  replyPrefillText(replyingTo: string) {
    if (this.data.provider !== ManagedPostProvider.Instagram) {
      return '';
    }

    return `@${replyingTo} `;
  }

  onAddComment(): void {
    this.showAddCommentField = true;
    this.commentIdToReplyTo = null;
    this.commentIdToEdit = null;
    this.commentIdToDelete = null;
    this.scrollToTop();
  }

  openReplyMode(commentId: string): void {
    this.commentIdToDelete = null;
    this.commentIdToEdit = null;
    this.commentIdToReplyTo = commentId;
    this.showAddCommentField = false;
  }

  openEditMode(commentId: string): void {
    this.commentIdToDelete = null;
    this.commentIdToReplyTo = null;
    this.commentIdToEdit = commentId;
    this.showAddCommentField = false;
  }

  openDeleteMode(commentId: string): void {
    this.commentIdToEdit = null;
    this.commentIdToReplyTo = null;
    this.commentIdToDelete = commentId;
    this.showAddCommentField = false;
  }

  getCommentList(): void {
    this.providerAccountPostDataService
      .getPostComments(this.globalFilterService.store._id, this.data.post.raw?._id!)
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;

          return EMPTY;
        }),
      )
      .subscribe((res) => {
        this.comments = res.result;
        this.actionInProgress = false;
      });
  }

  createComment(text: string): void {
    this.actionInProgress = true;
    this.providerAccountPostDataService
      .createPostComment(this.globalFilterService.store._id, this.data.post.raw?._id!, { text })
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;
          this.snackBarService.openSnackBar({
            icon: 'check_circle',
            text: this.translateService.instant('IAM.POST_COMMENTS.COMMENT.SNACKBAR.CREATED'),
          });

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.showAddCommentField = false;
        this.commentIdToReplyTo = null;
        this.getCommentList();
      });
  }

  updateComment(content: string, commentId: string): void {
    this.providerAccountPostDataService
      .updatePostComment(this.globalFilterService.store._id, this.data.post.raw?._id!, commentId, { text: content })
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.snackBarService.openSnackBar({
          icon: 'check_circle',
          text: this.translateService.instant('IAM.POST_COMMENTS.COMMENT.SNACKBAR.UPDATED'),
        });
        this.commentIdToEdit = null;
        this.getCommentList();
      });
  }

  deleteComment(commentId: string): void {
    this.actionInProgress = true;
    this.providerAccountPostDataService
      .deletePostComment(this.globalFilterService.store._id, this.data.post.raw?._id!, commentId)
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.snackBarService.openSnackBar({
          icon: 'check_circle',
          text: this.translateService.instant('IAM.POST_COMMENTS.COMMENT.SNACKBAR.DELETED'),
        });
        this.commentIdToDelete = null;
        this.getCommentList();
      });
  }

  getReplies(commentId: string): void {
    this.commentIdToFetchRepliesFor = commentId;
    this.providerAccountPostDataService
      .getPostCommentReplies(this.globalFilterService.store._id, this.data.post.raw?._id!, commentId)
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.commentIdToFetchRepliesFor = null;

          return EMPTY;
        }),
      )
      .subscribe((res) => {
        const commentIndex = this.comments.findIndex((comment) => comment.providerCommentId === commentId);
        this.comments[commentIndex].replies = res.result;
        this.commentIdToFetchRepliesFor = null;
      });
  }

  createReply(text: string, commentId: string): void {
    this.actionInProgress = true;
    this.providerAccountPostDataService
      .createPostCommentReply(this.globalFilterService.store._id, this.data.post.raw?._id!, commentId, { text })
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.snackBarService.openSnackBar({
          icon: 'check_circle',
          text: this.translateService.instant('IAM.POST_COMMENTS.COMMENT.SNACKBAR.CREATED'),
        });
        this.showAddCommentField = false;
        this.commentIdToReplyTo = null;
        this.getCommentList();
      });
  }

  updateReply(text: string, commentId: string, replyId: string): void {
    this.providerAccountPostDataService
      .updatePostCommentReply(this.globalFilterService.store._id, this.data.post.raw?._id!, commentId, replyId, {
        text,
      })
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.snackBarService.openSnackBar({
          icon: 'check_circle',
          text: this.translateService.instant('IAM.POST_COMMENTS.COMMENT.SNACKBAR.UPDATED'),
        });
        this.commentIdToEdit = null;
        this.getCommentList();
      });
  }

  deleteReply(commentId: string, replyId: string): void {
    this.actionInProgress = true;
    this.providerAccountPostDataService
      .deletePostCommentReply(this.globalFilterService.store._id, this.data.post.raw?._id!, commentId, replyId)
      .pipe(
        first(),
        catchError((error) => {
          this.showError(error);
          this.actionInProgress = false;

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.snackBarService.openSnackBar({
          icon: 'check_circle',
          text: this.translateService.instant('IAM.POST_COMMENTS.COMMENT.SNACKBAR.DELETED'),
        });
        this.commentIdToDelete = null;
        this.getCommentList();
      });
  }

  showError(error: string) {
    this.snackBarService.openSnackBar({
      variant: 'error',
      icon: 'danger',
      text: this.translateService.instant('ERROR.REQUEST', error),
    });
  }

  scrollToTop() {
    this.renderer.setProperty(this.el.nativeElement, 'scrollTop', 0);
    // or
    this.el.nativeElement.scrollIntoView();
  }

  removeOutlet(): void {
    this.router.navigate([{ outlets: { [socialOutlet]: null } }], { queryParamsHandling: 'merge' }).then(() => {
      this.dialogRef.close();
    });
  }
}
