<button
  #trigger="cdkOverlayOrigin"
  cdk-overlay-origin
  coreIconButton
  icon="positive"
  type="button"
  (click)="panelOpen = !panelOpen">
</button>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="panelOpen"
  cdkConnectedOverlayHeight="424px"
  (overlayOutsideClick)="overlayOutsideClick($event)">
  <div class="emoji-panel">
    <emoji-picker (emoji-click)="emojiSelect.emit($event)" [i18n]="i18n"></emoji-picker>
  </div>
</ng-template>
