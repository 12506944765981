import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '@core/services/auth.service';
import { Params } from '@angular/router';
import { Store } from '@core/models/store.model';
import { AICaptionBody, AIPromptBody, CaptionSettings } from '../interfaces/ai-caption-request.interface';

@Injectable({
  providedIn: 'root',
})
export class AiCaptionService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {}

  private getFormData(body: Params): FormData {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        if (key === 'files') {
          (value as File[]).forEach((file) => {
            formData.append('files', file, file.name || new Date().toISOString());
          });
        } else {
          formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
        }
      }
    });

    return formData;
  }

  /**
   * Uses AI to generate a caption based on the body provided
   * @param body config to influence the caption
   */
  generateCaption(body: AICaptionBody): Observable<string[]> {
    return this.apiService
      .post(`stores/${body.storeId}/socials/ai/generate-caption`, body.files?.length ? this.getFormData(body) : body)
      .pipe(map((res: ApiSuccessResponse<string[]>) => res.result));
  }

  generateCaptionStream(body: AICaptionBody): Observable<{ value?: string; inProgress: boolean }> {
    const headers: Params = {
      Authorization: `Bearer ${this.authService.accessToken}`,
      'Content-Type': 'application/json',
    };

    // Don't set the content type for form data
    if (body.files?.length) {
      delete headers['Content-Type'];
    }

    return new Observable<{ value?: string; inProgress: boolean }>((observer) => {
      fetch(`${this.apiService.baseUrl}stores/${body.storeId}/socials/ai/generate-caption-stream`, {
        method: 'POST',
        body: body.files?.length ? this.getFormData(body) : JSON.stringify(body),
        headers,
      })
        .then((response) => {
          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          if (!response.ok) {
            observer.error();
          }

          const push = () =>
            reader?.read().then(({ done, value }) => {
              if (done) {
                observer.next({ value: '', inProgress: !done });
                observer.complete();

                return;
              }

              //parse text content from response
              const events = decoder
                .decode(value)
                .split('\n')
                .filter((v) => v);

              let content = '';
              events.forEach((event) => {
                if (event) {
                  try {
                    const data: { index: number; value: string } = JSON.parse(event);
                    content += data.value || '';
                  } catch {
                    // do nothing
                  }
                }
              });
              observer.next({ value: content, inProgress: !done });
              push();
            });

          push();
        })
        .catch(() => {
          observer.error();
        });
    });
  }

  generateCaptionStreamPreview(body: AICaptionBody): Observable<{ value?: string; inProgress: boolean }> {
    const headers: Params = {
      Authorization: `Bearer ${this.authService.accessToken}`,
      'Content-Type': 'application/json',
    };

    // Don't set the content type for form data
    if (body.files?.length) {
      delete headers['Content-Type'];
    }

    return new Observable<{ value?: string; inProgress: boolean }>((observer) => {
      fetch(`${this.apiService.baseUrl}stores/${body.storeId}/socials/ai/preview-caption-stream`, {
        method: 'POST',
        body: body.files?.length ? this.getFormData(body) : JSON.stringify(body),
        headers,
      })
        .then((response) => {
          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          if (!response.ok) {
            observer.error();
          }

          const push = () =>
            reader?.read().then(({ done, value }) => {
              if (done) {
                observer.next({ value: '', inProgress: !done });
                observer.complete();

                return;
              }

              //parse text content from response
              const events = decoder
                .decode(value)
                .split('\n')
                .filter((v) => v);

              let content = '';
              events.forEach((event) => {
                if (event) {
                  try {
                    const data: { index: number; value: string } = JSON.parse(event);
                    content += data.value || '';
                  } catch {
                    // do nothing
                  }
                }
              });
              observer.next({ value: content, inProgress: !done });
              push();
            });

          push();
        })
        .catch(() => {
          observer.error();
        });
    });
  }

  /**
   * Analyzes past captions and returns CaptionSettings object
   * @param storeId store id
   */
  analyzeCaptions(storeId: string): Observable<CaptionSettings> {
    return this.apiService
      .post(`stores/${storeId}/socials/ai/analyze-captions`, { storeId })
      .pipe(map((res: ApiSuccessResponse<CaptionSettings>) => res.result));
  }

  /**
   * Fetches the caption settings
   * @param storeId store id
   */
  getCaptionSettings(storeId: string): Observable<CaptionSettings> {
    const params = new HttpParams({
      fromObject: { storeId },
    });

    return (
      this.apiService
        .get(`stores/${storeId}/socials/ai/caption-settings`, params)
        // temporary dirty workaround
        .pipe(
          map((res: any) =>
            Object.prototype.hasOwnProperty.call(res?.result, 'result') ? res?.result?.result : res?.result,
          ),
        )
    );
  }

  /**
   * Update caption settings
   * @param storeId store id
   * @param body config of caption settings
   */
  updateCaptionSettings(storeId: string, body: { caption: CaptionSettings }): Observable<Store> {
    return this.apiService
      .put(`stores/${storeId}/socials/ai/caption-settings`, body)
      .pipe(map((res: ApiSuccessResponse<Store>) => res.result));
  }

  /**
   * Uses AI to generate a prompt based on the body provided
   * @param storeId store id
   * @param body prompt config
   */
  generatePrompt(storeId: string, body: AIPromptBody): Observable<string> {
    return this.apiService
      .post(`stores/${storeId}/socials/ai/generate-prompt`, body)
      .pipe(map((res: ApiSuccessResponse<string>) => res.result));
  }
}
