export enum ProviderStatus {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Error = 'ERROR',
}

export enum AuthType {
  OAUTH = 'OAUTH',
  NONE = 'NONE',
}
