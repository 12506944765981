import { Component, Input } from '@angular/core';
import { CardOverlayStrength, CardVariant } from '../../types/card.type';
import { CardPadding, CardRoundness, CardLevel } from '../../types/common.type';

@Component({
  selector: 'core-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  host: {
    '[class]': `"overlay"
      + " roundness-"+roundness`,
    '[class.overlay--visible]': 'visible',
    '[class.overlay--overflow]': 'overflowVisible',
  },
})
export class OverlayComponent {
  @Input()
  visible = false;

  @Input()
  selectable = false;

  @Input()
  overflowVisible = false;

  @Input()
  overlayStrength: CardOverlayStrength = 'high';

  @Input()
  padding: CardPadding = 'md';

  @Input()
  roundness: CardRoundness = 'sm';

  @Input()
  variant: CardVariant = 'default';

  @Input()
  level: CardLevel = 1;
}
