import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { postViewerOutlet } from '@core/dialogs/post-viewer/variables/outlet.variable';
import { MatDialogRef } from '@angular/material/dialog';
import { PostViewerComponent } from '../../post-viewer.component';
import { PostViewerConfig } from '../../types/post-viewer.type';

@Component({
  selector: 'core-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.scss'],
})
export class PostPreviewComponent implements OnInit {
  data?: PostViewerConfig;

  constructor(
    private dialogRef: MatDialogRef<PostViewerComponent>,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.data = this.dialogRef.componentInstance?.data;
  }

  removeOutlet(): void {
    this.dialogService.closeDialog();
    this.router.navigate([{ outlets: { [postViewerOutlet]: null } }]);
  }
}
