import { PeriodUnit } from '@core/enums/period.enum';
import { Store } from '@core/models/store.model';
import dayjs from 'dayjs';

export interface DateRangeFilter {
  since?: string;
  until?: string;
}

export interface RelativeDateFilter {
  period_value: number;
  period_unit: PeriodUnit;
}

export type DateFilter = DateRangeFilter | RelativeDateFilter;

export interface GlobalFilterRequestParams extends DateRangeFilter {
  store_ids?: string | string[];
}

export interface GlobalFilterUrlParams extends DateRangeFilter, Partial<RelativeDateFilter> {
  store_id?: string;
}

export class GlobalFilter {
  date: DateFilter = {
    period_value: 30,
    period_unit: PeriodUnit.Days,
  };

  store!: Store;

  constructor(object?: GlobalFilter) {
    Object.assign(this, object);
  }

  static toUrlParams(filter: GlobalFilter = new GlobalFilter()): GlobalFilterUrlParams {
    return {
      store_id: filter.store?._id,
      ...filter.date,
    };
  }

  static toRequestParams(filter: GlobalFilter = new GlobalFilter()): GlobalFilterRequestParams {
    return {
      store_ids: filter.store?._id,
      ...this.dateToDateRangeRequestParams(filter.date),
    };
  }

  static dateToDateRangeRequestParams(date: DateFilter): DateRangeFilter {
    // Convert these values as the API does not know how to handle them
    if ('period_unit' in date) {
      const currentDate = dayjs();

      return {
        since: currentDate.clone().subtract(Number(date.period_value), date.period_unit).format('YYYY-MM-DD'),
        until: currentDate.clone().format('YYYY-MM-DD'),
      };
    }

    return date;
  }
}
