import { Router, ActivatedRoute, UrlSerializer, Params } from '@angular/router';
import { Injectable } from '@angular/core';

/**
 * Service for handling reload
 */
@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private urlSerializer: UrlSerializer,
  ) {}

  /**
   * Reloads the view
   */
  reload(queryParams = this.route.snapshot.queryParams): void {
    const tree = this.router.createUrlTree([], { queryParams });
    const queryString = this.urlSerializer.serialize(tree).substring(1);
    window.location.replace(`${window.location.pathname}${queryString}`);
  }

  /**
   * Get query params from url
   *
   * Note: Using ActivatedRoute isn't possible as the query params are asynchronous.
   * this.activatedRoute.snapshot.queryParams is empty initially.
   */
  getQueryParamsFromUrl(): Params {
    const { queryParams } = this.urlSerializer.parse(`/${window.location.search}`);

    return queryParams;
  }

  updateUrl(queryParams: Params): void {
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key] || (Array.isArray(queryParams[key]) && !queryParams[key].length)) {
        // eslint-disable-next-line no-param-reassign
        delete queryParams[key];
      }
    });
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }
}
