// https://github.com/crisbeto/angular-svg-round-progressbar

/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import {
  Component,
  Input,
  Output,
  OnChanges,
  NgZone,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  ChangeDetectionStrategy,
  Optional,
  Inject,
  inject,
  DestroyRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs';
import { BrandChartConfigService } from '@core/ui/chart/services/brand-chart-config.service';
import { GaugeChartColors } from '@core/interfaces/brand-chart-config.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const DEGREE_IN_RADIANS: number = Math.PI / 180;

@Component({
  selector: 'core-gauge-progress',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './gauge-progress.component.html',
  styleUrls: ['./gauge-progress.component.scss'],
  host: {
    role: 'progressbar',
    '[attr.aria-valuemin]': '0',
    '[attr.aria-valuemax]': 'total',
    '[attr.aria-valuenow]': 'value',
    '[style.width]': 'responsive ? "" : getDiameter() + "px"',
    '[style.height]': 'getElementHeight()',
    '[style.padding-bottom]': 'getPaddingBottom()',
    '[class.responsive]': 'responsive',
  },
})
export class GaugeProgressComponent implements OnChanges {
  @ViewChild('pathLow')
  pathLow!: ElementRef<SVGPathElement>;

  @ViewChild('pathMedium')
  pathMedium!: ElementRef<SVGPathElement>;

  @ViewChild('pathHigh')
  pathHigh!: ElementRef<SVGPathElement>;

  /**
   * Current value of the progress bar.
   */
  @Input()
  value = 0;

  /**
   * Maximum value of the progress bar.
   */
  @Input()
  total = 100;

  /**
   * Label displayed underneath the percentage
   */
  @Input()
  label = '';

  /**
   * Whether the circle should take up the width of its parent.
   */
  @Input()
  responsive = false;

  /**
   * Whether the circle is filling up clockwise.
   */
  @Input()
  clockwise = true;

  /**
   * Whether the tip of the progress should be rounded off.
   */
  @Input()
  rounded = false;

  /**
   * Emits when a new value has been rendered.
   */
  @Output()
  onRender: EventEmitter<number> = new EventEmitter();

  /**
   * Name of the easing function to use when animating.
   */
  animation = 'easeInOutCubic';

  /**
   * Time in millisconds by which to delay the animation.
   */
  animationDelay = 0;

  /**
   * Duration of the animation.
   */
  duration = 1500;

  /**
   * Width of the circle's stroke.
   */
  stroke = 30;

  /**
   * Radius of the circle.
   */
  radius = 125;

  private lastAnimationId = 0;

  private currentLinecap: 'round' | '' = '';

  colors: GaugeChartColors = this.brandChartConfigService.chartConfig?.gauge?.colors ?? {};

  private hasPerformance: boolean;

  public supportsSvg: boolean;

  readonly destroyRef = inject(DestroyRef);

  constructor(
    private ngZone: NgZone,
    private brandChartConfigService: BrandChartConfigService,
    @Optional() @Inject(DOCUMENT) document: any,
  ) {
    this.supportsSvg = !!(
      document &&
      document.createElementNS &&
      document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect
    );

    this.hasPerformance =
      typeof window !== 'undefined' &&
      window.performance &&
      window.performance.now &&
      typeof window.performance.now() === 'number';

    this.brandChartConfigService.chartConfig$
      .pipe(
        tap((config) => {
          this.colors = config?.gauge?.colors ?? {};
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.animateChange(changes.value.previousValue, changes.value.currentValue);
    } else {
      this.updatePath(Math.min(this.value, this.total / 4), this.pathLow.nativeElement);
      this.updatePath(Math.min(this.value, this.total / 2), this.pathMedium.nativeElement);
      this.updatePath(this.value, this.pathHigh.nativeElement);
    }
  }

  /**
   * Generates a timestamp.
   */
  getTimestamp(): number {
    return this.hasPerformance ? window.performance.now() : Date.now();
  }

  /** Animates a change in the current value. */
  private animateChange(from: number, to: number): void {
    if (typeof from !== 'number') {
      from = 0;
    }

    to = this.clamp(to);
    from = this.clamp(from);

    const self = this;
    const changeInValue = to - from;
    const { duration } = self;

    // Avoid firing change detection for each of the animation frames.
    self.ngZone.runOutsideAngular(() => {
      const start = () => {
        const startTime = self.getTimestamp();
        const id = ++self.lastAnimationId;

        requestAnimationFrame(function animation() {
          const currentTime = Math.min(self.getTimestamp() - startTime, duration);
          const value = self.easeInOutAnimation(currentTime, from, changeInValue, duration);

          self.updatePath(Math.min(value, self.total / 4), self.pathLow.nativeElement);
          self.updatePath(Math.min(value, self.total / 2), self.pathMedium.nativeElement);
          self.updatePath(value, self.pathHigh.nativeElement);

          if (self.onRender.observers.length > 0) {
            self.onRender.emit(value);
          }

          if (id === self.lastAnimationId && currentTime < duration) {
            requestAnimationFrame(animation);
          }
        });
      };

      if (this.animationDelay > 0) {
        setTimeout(start, this.animationDelay);
      } else {
        start();
      }
    });
  }

  /** Updates the path apperance. */
  private updatePath(value: number, path: SVGPathElement): void {
    if (path) {
      const arc = this.getArc(value, this.total, this.radius - this.stroke / 2, this.radius, true);

      // Remove the rounded line cap when the value is zero,
      // because SVG won't allow it to disappear completely.
      const linecap = this.rounded && value > 0 ? 'round' : '';

      // This is called on each animation frame so avoid
      // updating the line cap unless it has changed.
      if (linecap !== this.currentLinecap) {
        this.currentLinecap = linecap;
        path.style.strokeLinecap = linecap;
      }

      path.setAttribute('d', arc);
    }
  }

  /**
   * Generates the value for an SVG arc.
   *
   * @param current Current value.
   * @param total Maximum value.
   * @param pathRadius Radius of the SVG path.
   * @param elementRadius Radius of the SVG container.
   * @param isSemicircle Whether the element should be a semicircle.
   */
  getArc(current: number, total: number, pathRadius: number, elementRadius: number, isSemicircle = false): string {
    const value = Math.max(0, Math.min(current || 0, total));
    const maxAngle = isSemicircle ? 180 : 359.9999;
    const percentage = total === 0 ? maxAngle : (value / total) * maxAngle;
    const start = this.polarToCartesian(elementRadius, pathRadius, percentage);
    const end = this.polarToCartesian(elementRadius, pathRadius, 0);
    const arcSweep = percentage <= 180 ? 0 : 1;

    return `M ${start} A ${pathRadius} ${pathRadius} 0 ${arcSweep} 0 ${end}`;
  }

  /**
   * Converts polar cooradinates to Cartesian.
   *
   * @param elementRadius Radius of the wrapper element.
   * @param pathRadius Radius of the path being described.
   * @param angleInDegrees Degree to be converted.
   */
  private polarToCartesian(elementRadius: number, pathRadius: number, angleInDegrees: number): string {
    const angleInRadians = (angleInDegrees - 90) * DEGREE_IN_RADIANS;
    const x = elementRadius + pathRadius * Math.cos(angleInRadians);
    const y = elementRadius + pathRadius * Math.sin(angleInRadians);

    return `${x} ${y}`;
  }

  easeInOutAnimation(t: number, b: number, c: number, d: number): number {
    // eslint-disable-next-line no-cond-assign
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t * t + b;
    }

    // eslint-disable-next-line no-return-assign
    return (c / 2) * ((t -= 2) * t * t + 2) + b;
  }

  /** Clamps a value between the maximum and 0. */
  private clamp(value: number): number {
    return Math.max(0, Math.min(value || 0, this.total));
  }

  /**
   * Determines the SVG transforms for the <path> node.
   */
  getPathTransform(): string {
    const diameter = this.getDiameter();

    return this.clockwise
      ? `translate(0, ${diameter}) rotate(-90)`
      : `translate(${`${diameter},${diameter}`}) rotate(90) scale(-1, 1)`;
  }

  /**
   * Diameter of the circle.
   */
  getDiameter(): number {
    return this.radius * 2;
  }

  /**
   * The CSS height of the wrapper element.
   */
  getElementHeight(): string {
    if (!this.responsive) {
      return `${this.radius}px`;
    }

    return '';
  }

  /**
   * Viewbox for the SVG element.
   */
  getViewBox(): string {
    const diameter = this.getDiameter();

    return `0 0 ${diameter} ${this.radius}`;
  }

  /**
   * Bottom padding for the wrapper element.
   */
  getPaddingBottom(): string {
    if (this.responsive) {
      return '50%';
    }

    return '';
  }

  /**
   * Calculated rotation of the needle.
   */
  rotateNeedle(): string {
    const angle = -90 + (this.value / this.total) * 180;
    const angleTransformed = this.clockwise ? angle : angle * -1;

    return `rotate(${angleTransformed}deg)`;
  }
}
