import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Nullable } from '@core/types/nullable.type';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { ApiService } from '@core/services/api.service';

import {
  GetPageLinkTypeAnalyticsListParams,
  GetReviewPageListParams,
} from '@review/common/interfaces/query-params.interface';
import { PageLinkType } from '@core/enums/page.enum';
import { PageDocument, PageDocumentAdapter } from '@core/models/page-document.model';
import { PageLink, PageLinkAdapter } from '@core/models/page-link.model';
import { PageLinksAnalytics, PageLinksAnalyticsAdapter } from '@review/common/models/page-links-analytics.model';
import {
  PageLinkTypeAnalytics,
  PageLinkTypeAnalyticsAdapter,
} from '@review/common/models/page-link-type-analytics.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewPageDataService {
  private pagesUrl = (storeId: string) => `stores/${storeId}/reviews/pages`;

  private _pageConfig$ = new BehaviorSubject<Nullable<PageDocument>>(null);

  pageConfig$ = this._pageConfig$.asObservable();

  constructor(
    private apiService: ApiService,
    private pageDocumentAdapter: PageDocumentAdapter,
    private pageLinkAdapter: PageLinkAdapter,
    private pageLinksAnalyticsAdapter: PageLinksAnalyticsAdapter,
    private pageLinkTypeAnalyticsAdapter: PageLinkTypeAnalyticsAdapter,
  ) {}

  /**
   * Gets the review page config
   * @param storeId the store id
   */
  getReviewPageConfig(storeId: string, reload = false): Observable<Nullable<PageDocument>> {
    const pageConfig = this._pageConfig$.getValue();
    if (storeId === pageConfig?._id && !reload) {
      return this.pageConfig$;
    }

    return this.getPages(storeId, { type: 'review' }).pipe(
      map(([page]: PageDocument[]) => page),
      tap((page) => this._pageConfig$.next(page)),
    );
  }

  /**
   * Gets an array of page configs
   * @param storeId the store id
   * @param queryParams optional query params, e.g. to filter by type
   */
  getPage(storeId: string, queryParams: GetReviewPageListParams): Observable<PageDocument> {
    const params = new HttpParams({
      fromObject: { ...queryParams },
    });

    return this.apiService
      .get(this.pagesUrl(storeId), params)
      .pipe(map((res: ApiSuccessResponse<PageDocument>) => this.pageDocumentAdapter.clientAdapt(res.result)));
  }

  /**
   * Gets an array of page configs
   * @param storeId the store id
   * @param queryParams optional query params, e.g. to filter by type
   */
  getPages(storeId: string, queryParams: GetReviewPageListParams): Observable<PageDocument[]> {
    const params = new HttpParams({
      fromObject: { ...queryParams },
    });

    return this.apiService
      .get(this.pagesUrl(storeId), params)
      .pipe(
        map((res: ApiSuccessResponse<PageDocument[]>) =>
          res.result.map((page) => this.pageDocumentAdapter.clientAdapt(page)),
        ),
      );
  }

  /**
   * Gets an array of links for a specific page
   * @param storeId the store id
   * @param pageId the page id
   */
  getPageLinkList(storeId: string, pageId: string): Observable<PageLink[]> {
    return this.apiService
      .get(`${this.pagesUrl(storeId)}/${pageId}/links`)
      .pipe(
        map((res: ApiSuccessResponse<PageLink[]>) => res.result.map((page) => this.pageLinkAdapter.clientAdapt(page))),
      );
  }

  /**
   * Gets an existing page link
   * @param storeId the store id
   * @param pageId the page id
   * @param linkId the link id
   */
  getPageLink(storeId: string, pageId: string, linkId: string): Observable<Nullable<PageLink>> {
    return this.apiService
      .get(`${this.pagesUrl(storeId)}/${pageId}/links/${linkId}`)
      .pipe(
        map((res: ApiSuccessResponse<Nullable<PageLink>>) =>
          res.result ? this.pageLinkAdapter.clientAdapt(res.result) : res.result,
        ),
      );
  }

  /**
   * Creates a new page link for a specific page
   * @param storeId the store id
   * @param pageId the page id
   * @param body the page link object
   */
  createPageLink(storeId: string, pageId: string, body: PageLink): Observable<PageLink> {
    return this.apiService
      .post(`${this.pagesUrl(storeId)}/${pageId}/links`, body)
      .pipe(map((res: ApiSuccessResponse<PageLink>) => this.pageLinkAdapter.clientAdapt(res.result)));
  }

  /**
   * Updates an existing page link
   * @param storeId the store id
   * @param pageId the page id
   * @param linkId the link id
   * @param body the page link object
   */
  updatePageLink(storeId: string, pageId: string, linkId: string, body: PageLink): Observable<PageLink> {
    return this.apiService
      .put(`${this.pagesUrl(storeId)}/${pageId}/links/${linkId}`, body)
      .pipe(map((res: ApiSuccessResponse<PageLink>) => this.pageLinkAdapter.clientAdapt(res.result)));
  }

  /**
   * Gets a list of links analytics by provider
   * @param storeId the store id
   * @param pageId the page id
   */
  getPageLinksAnalyticsList(storeId: string, pageId: string): Observable<PageLinksAnalytics[]> {
    return this.apiService
      .get(`${this.pagesUrl(storeId)}/${pageId}/links-analytics`)
      .pipe(
        map((res: ApiSuccessResponse<PageLinksAnalytics[]>) =>
          res.result.map((analytics) => this.pageLinksAnalyticsAdapter.clientAdapt(analytics)),
        ),
      );
  }

  /**
   * Gets analytics for page of a given link type
   * @param storeId the store id
   * @param pageId the page id
   * @param linkType the link type
   */
  getPageLinkTypeAnalyticsList(
    storeId: string,
    pageId: string,
    linkType: PageLinkType,
    queryParams: GetPageLinkTypeAnalyticsListParams = {},
  ): Observable<PageLinkTypeAnalytics[]> {
    const params = new HttpParams({
      fromObject: { ...queryParams },
    });

    return this.apiService
      .get(`${this.pagesUrl(storeId)}/${pageId}/links-analytics/${linkType}`, params)
      .pipe(
        map((res: ApiSuccessResponse<PageLinkTypeAnalytics[]>) =>
          res.result.map((analytics) => this.pageLinkTypeAnalyticsAdapter.clientAdapt(analytics)),
        ),
      );
  }
}
