import { RouterModule, Routes } from '@angular/router';

import { UserInviteSendComponent } from './screens/user-invite-send/user-invite-send.component';
import { UserInviteCompleteComponent } from './screens/user-invite-complete/user-invite-complete.component';
import { userInviteOutlet } from './variables/outlet.variable';
import { UserInviteRoute } from './enums/user-invite-route.enum';

const outlet = userInviteOutlet;

export const makeUserInviteWorkflowRoutes = () => [
  {
    path: UserInviteRoute.Send,
    outlet,
    component: UserInviteSendComponent,
  },
  {
    path: UserInviteRoute.Complete,
    outlet,
    component: UserInviteCompleteComponent,
  },
];

const routes: Routes = [...makeUserInviteWorkflowRoutes()];

export const UserInviteWorkflowRoutingModule = RouterModule.forChild(routes);
