import { RouterModule, Routes } from '@angular/router';

import { photoOutlet } from './variables/outlet.variable';
import { PhotoRoute } from './enums/photo-route.enum';
import { PhotoDialogComponent } from './screens/photo-dialog/photo-dialog.component';

const outlet = photoOutlet;

export const makePhotoWorkflowRoutes = () => [
  {
    path: `${PhotoRoute.Photo}/:photoId`,
    outlet,
    component: PhotoDialogComponent,
  },
];

const routes: Routes = [...makePhotoWorkflowRoutes()];

export const PhotoWorkflowRoutingModule = RouterModule.forChild(routes);
