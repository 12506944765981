import { BrandConfig } from '@core/interfaces/brand-config.interface';

const config: BrandConfig = {
  brand: 'sumup',
  theme: 'light',
  images: {
    avatars: {
      company: 'assets/avatar_company.svg',
      store: 'assets/avatar_store.svg',
      user: 'assets/avatar_user.svg',
    },
    backgrounds: {},
    backgroundTextures: {
      kpiCard:
        'https://images.ctfassets.net/txhaodyqr481/3kYbbssrmjTWp5SvM542mm/59933bbfe7d0b193b52296f4d3901f84/Device_Desktop__Country_UK.png',
    },
  },
  elementStyles: {
    card: 'outlined',
    button: 'outlined',
    dialog: 'raised',
  },
};

export default config;
