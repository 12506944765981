import { Component, Input } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { PostMediaAspectRatio } from '../../types/post-media.type';

@Component({
  selector: 'core-post-card-skeleton',
  templateUrl: './post-card-skeleton.component.html',
  styleUrls: ['./post-card-skeleton.component.scss'],
})
export class PostCardSkeletonComponent {
  @Input()
  aspectRatio: Nullable<PostMediaAspectRatio> = null;

  @Input()
  header = true;

  @Input()
  caption = true;

  @Input()
  cta = true;
}
