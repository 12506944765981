import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as platformIcons from '@core/ui/platform/variables/icons-active.variable';
import { SocialPost } from '@social/common/types/social-post.type';
import { first, tap } from 'rxjs/operators';
import { SocialStoryPostGroupDialogConfig } from '@social/common/types/social-story-post-group-dialog-config.type';
import { CardHeaderIconType } from '@core/ui/card/types/section-card.type';
import { socialOutlet } from '../../variables/outlet.variable';

@Component({
  selector: 'social-story-post-group-dialog',
  templateUrl: './social-story-post-group-dialog.component.html',
  styleUrls: ['./social-story-post-group-dialog.component.scss'],
})
export class SocialStoryPostGroupDialogComponent implements OnInit {
  editLoading = false;

  icon = 'social';

  iconType: CardHeaderIconType = 'default';

  index = 0;

  first = true;

  last = false;

  defaultDuration = 5000;

  durations: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<SocialStoryPostGroupDialogConfig>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: SocialStoryPostGroupDialogConfig,
  ) {}

  ngOnInit(): void {
    this.durations = this.data.posts.map((post) => post.slides[0]?.duration || 5);
    this.onIndexChange(this.data.index);
    if (this.data.provider) {
      this.icon = platformIcons[this.data.provider];
      this.iconType = 'platform';
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowRight':
        return this.onIndexChange(this.index);
      case 'ArrowLeft':
        return this.onIndexChange(this.index);
      default:
    }
  }

  onIndexChange(index = 0): void {
    if (index < 0 || index >= this.data.posts.length) {
      return;
    }
    this.index = index;
    this.first = index === 0;
    this.last = index === this.data.posts.length - 1;
  }

  onDeleteClick(post: SocialPost): void {
    this.data.onDeleteClick(post).pipe(first()).subscribe();
  }

  onCtaClick({ action, post }: { action: string; post: SocialPost }): void {
    if (action === 'edit') {
      this.editLoading = true;
    }
    this.data
      .onCtaClick({ action, post })
      .pipe(
        tap(() => {
          if (action === 'edit') {
            this.editLoading = false;
          }
        }),
        first(),
      )
      .subscribe();
  }

  removeOutlet(): void {
    this.router.navigate([{ outlets: { [socialOutlet]: null } }], { queryParamsHandling: 'merge' }).then(() => {
      this.dialogRef.close();
    });
  }
}
