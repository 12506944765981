import { RouterModule, Routes } from '@angular/router';

import { socialOutlet } from './variables/outlet.variable';
import { SocialRoute } from './enums/social-route.enum';
import { RecommendConnectionDialogComponent } from './screens/recommend-connection-dialog/recommend-connection-dialog.component';
import { SocialPostDialogComponent } from './screens/social-post-dialog/social-post-dialog.component';
import { DeletePostConfirmationDialogComponent } from './screens/delete-post-confirmation-dialog/delete-post-confirmation-dialog.component';
import { SocialPostCommentsDialogComponent } from './screens/social-post-comments-dialog/social-post-comments-dialog.component';
import { SocialStoryPostGroupDialogComponent } from './screens/social-story-post-group-dialog/social-story-post-group-dialog.component';

const outlet = socialOutlet;

export const makeSocialWorkflowRoutes = () => [
  {
    path: SocialRoute.Connect,
    outlet,
    component: RecommendConnectionDialogComponent,
  },
  {
    path: `${SocialRoute.Post}/:postId`,
    outlet,
    component: SocialPostDialogComponent,
  },
  {
    path: `${SocialRoute.DeletePost}/:postId`,
    outlet,
    component: DeletePostConfirmationDialogComponent,
  },
  {
    path: `${SocialRoute.PostComments}/:postId`,
    outlet,
    component: SocialPostCommentsDialogComponent,
  },
  {
    path: `${SocialRoute.Story}/:date`,
    outlet,
    component: SocialStoryPostGroupDialogComponent,
  },
];

const routes: Routes = [...makeSocialWorkflowRoutes()];

export const SocialWorkflowRoutingModule = RouterModule.forChild(routes);
