<div class="image-wrapper">
  <img
    *ngIf="srcType === 'url'; else iconTemplate"
    alt="Platform avatar"
    class="image"
    [src]="url"
    (load)="loading = false"
    (error)="setFallback()" />
  <ng-template #iconTemplate>
    <core-svg class="image" [svg]="iconName"> </core-svg>
  </ng-template>
</div>
<div class="badge" [ngClass]="badgePosition">
  <ng-content></ng-content>
</div>
