import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Params, Router, UrlSerializer } from '@angular/router';
import { WindowWrapperService } from './window-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class AppTypeConfigService {
  isWebView = this.window?.ReactNativeWebView;

  isStandaloneApp = environment.microfrontend;

  isStandaloneBrowserWindow = this.isStandaloneApp && !this.isWebView;

  appUri = 'alphin://';

  constructor(
    @Inject(WindowWrapperService) private window: WindowWrapperService,
    private router: Router,
    private urlSerializer: UrlSerializer,
  ) {}

  /**
   * Checks if a url contains an auxiliary route
   *
   * @param outletName the auxiliary route name
   * @param url the url to check
   */
  includesOutlet(outletName: string, url = this.router.url): boolean {
    const urlTree = this.router.createUrlTree([url]);

    return !!Object.keys(urlTree?.root?.children || {}).find((key: string) => key === outletName);
  }

  /**
   * Returns the redirect uri depending on the app type
   * @param alwaysBrowserOrigin if true, uses window.location.origin even for a web view
   */
  getRedirectOrigin(alwaysBrowserOrigin = false): string {
    if (!this.isWebView || alwaysBrowserOrigin) {
      return window.location.origin;
    }

    return this.appUri;
  }

  /**
   * Returns a prefix for the wrapper application or not if it's standalone
   * @param wrapperPrefix route prefix used in the wrapper application only
   * @param usesAngularRouter false when window.location is used
   */
  getPathPrefix(wrapperPrefix: string, usesAngularRouter = true): string {
    if (this.isStandaloneBrowserWindow) {
      return '';
    }
    if (this.isWebView && usesAngularRouter) {
      return '';
    }

    // for web views, we want to return the prefix for redirect urls
    // since this format is required for deep links
    return wrapperPrefix;
  }

  /**
   * Gets the current path without the auxiliary routes or query params
   * @param wrapperPrefix route prefix used in the wrapper application only
   */
  getPrimaryOutletPath(wrapperPrefix = ''): string {
    let [url = ''] = this.router.url.split('?') || [];
    [url] = url.split('(');
    if (url.startsWith(wrapperPrefix)) {
      url = url.replace(wrapperPrefix, '');
    }

    return url;
  }

  /**
   * Return query params as a string
   * @param queryParams the query params object
   */
  getQueryParamsString(queryParams: Params = {}): string {
    const tree = this.router.createUrlTree(['/'], { queryParams });

    return decodeURIComponent(this.urlSerializer.serialize(tree).substring(1));
  }

  /**
   * Returns a redirect url
   * @param path the path to redirect to
   * @param queryParams query params
   * @param encodeUri whether or not to encode the url
   * @param alwaysBrowserOrigin if true, uses window.location.origin even for a web view
   */
  createRedirectUrl(path: string, queryParams: Params = {}, encodeUri = false, alwaysBrowserOrigin = false): string {
    const queryParamsString = this.getQueryParamsString(queryParams);
    const url = `${this.getRedirectOrigin(alwaysBrowserOrigin)}${path}${queryParamsString}`;

    return encodeUri ? encodeURIComponent(url) : url;
  }
}
