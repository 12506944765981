import { createSelector } from '@ngrx/store';
import { AppState } from '@core/interfaces/app-state.interface';
import { CrudEntityState } from './crud.reducer';

export const selectListPagination = (state: CrudEntityState<any>) => state.pagination;
export const selectListLoadingDict = (state: CrudEntityState<any>) => state.loadingPages;
export const selectListLoadedDict = (state: CrudEntityState<any>) => state.loadedPages;

export const selectObjectLoading = (state: CrudEntityState<any>) => state.loading;
export const selectObjectCreating = (state: CrudEntityState<any>) => state.creating;
export const selectObjectUpdating = (state: CrudEntityState<any>) => state.updating;
export const selectObjectDeleting = (state: CrudEntityState<any>) => state.deleting;

export const selectObjectLoaded = (state: CrudEntityState<any>) => state.loaded;
export const selectObjectCreated = (state: CrudEntityState<any>) => state.created;
export const selectObjectUpdated = (state: CrudEntityState<any>) => state.updated;
export const selectObjectDeleted = (state: CrudEntityState<any>) => state.deleted;

export const selectErrorLimitedReached = (state: CrudEntityState<any>) => state.errorCount >= 3;

export const crudSelectors = (state: (appState: AppState) => CrudEntityState<any>) => ({
  getListPagination: createSelector(state, selectListPagination),
  getListLoadingDict: createSelector(state, selectListLoadingDict),
  getListLoadedDict: createSelector(state, selectListLoadedDict),

  getObjectLoading: createSelector(state, selectObjectLoading),
  getObjectCreating: createSelector(state, selectObjectCreating),
  getObjectUpdating: createSelector(state, selectObjectUpdating),
  getObjectDeleting: createSelector(state, selectObjectDeleting),

  getObjectLoaded: createSelector(state, selectObjectLoaded),
  getObjectCreated: createSelector(state, selectObjectCreated),
  getObjectUpdated: createSelector(state, selectObjectUpdated),
  getObjectDeleted: createSelector(state, selectObjectDeleted),

  getErrorLimitReached: createSelector(state, selectErrorLimitedReached),

  getListPageLoading: (pageNumber: number) =>
    createSelector(createSelector(state, selectListLoadingDict), (loadingPages) => loadingPages[pageNumber]),
  getListPageLoaded: (pageNumber: number) =>
    createSelector(createSelector(state, selectListLoadedDict), (loadedPages) => loadedPages[pageNumber]),
});
