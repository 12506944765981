import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { ProviderConnectionSync } from '@core/dialogs/integration-workflow/interfaces/provider-connection-sync.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SocialProviderConnectionSyncService {
  constructor(private apiService: ApiService) {}

  /**
   * Gets the socials connection status for the given store
   * @param storeId the store id
   */
  getConnections(storeId: string): Observable<ProviderConnectionSync[]> {
    return this.apiService
      .get(`stores/${storeId}/socials/connections`)
      .pipe(map((res: ApiSuccessResponse<ProviderConnectionSync[]>) => res.result));
  }
}
