// https://stackoverflow.com/questions/44669340/how-to-truncate-text-in-angular2

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, initialLimit = 150, completeWords = true, ellipsis = '…') {
    let limit = initialLimit;
    if (!value || value.length <= limit) {
      return value;
    }
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }

    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
