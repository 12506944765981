<div class="wrapper">
  <div class="indicator"></div>

  <div class="content">
    <div
      matRipple
      [matRippleDisabled]="!config?.headerClickable"
      class="header"
      [class.header--clickable]="config?.headerClickable"
      [class.header--shadow]="canScrollUp"
      (click)="onHeaderClick()">
      <div *ngIf="config?.title || config?.icon" class="header__config">
        <ng-container *ngIf="config?.icon">
          <ng-container [ngSwitch]="config?.iconType">
            <core-platform-avatar
              *ngSwitchCase="'platform'"
              variant="enabled"
              [size]="config?.headerFooterSize || 'lg'"
              [url]="config.icon">
            </core-platform-avatar>
            <core-avatar *ngSwitchCase="'avatar'" size="lg" [src]="config.icon"></core-avatar>
            <core-icon *ngSwitchDefault [size]="config?.iconSize || config?.headerFooterSize || 'lg'" [icon]="config.icon"></core-icon>
          </ng-container>
        </ng-container>
        <div class="header__title">
          <h6 *ngIf="config?.headerFooterSize !== 'lg'; else largeTitle">
            <ng-container [ngTemplateOutlet]="titleContent"></ng-container>
          </h6>
          <ng-template #largeTitle>
            <h5>
              <ng-container [ngTemplateOutlet]="titleContent"></ng-container>
            </h5>
          </ng-template>
        </div>
      </div>
      <ng-content select="[coreSectionCardHeader]"></ng-content>
    </div>
    <core-divider></core-divider>

    <div class="body" #body (scroll)="checkScrollPosition(body)">
      <ng-content></ng-content>
    </div>

    <div class="footer" [class.footer--shadow]="canScrollDown">
      <ng-content select="[coreSectionCardFooter]"></ng-content>
    </div>
    <core-divider></core-divider>
  </div>
</div>

<ng-template #titleContent>
  <strong>{{ config?.pretitle }}</strong>
  <span class="d-flex">
    <span class="header__title-text">{{ config.title }}</span>
    <core-icon
      class="ml-2 header__action-icon"
      (click)="titleIconClick.emit()"
      *ngIf="config.titleActionIcon"
      size="md"
      [icon]="config.titleActionIcon">
    </core-icon>
  </span>
  <strong>{{ config.subtitle }}</strong>
</ng-template>
