<core-post-card
  (loaded)="onMediaLoaded($event); updateLayout.emit()"
  (fallbackLoaded)="updateLayout.emit()"
  (error)="updateLayout.emit(); hasError = true"
  [slides]="slides"
  [mediaVariant]="slides?.[0]?.variant"
  [cardVariant]="cardVariant">
  <div corePostFooter *ngIf="!hasError" class="d-flex align-items-center justify-content-between">
    <button
      coreButton
      [actionInProgress]="repostLoading"
      [disabled]="repostDisabled"
      [variant]="cardVariant === 'content-overlay' ? 'light' : 'primary'"
      (click)="repostClick.emit(photo)">
      {{ 'POST.CTA.POST' | translate }}
    </button>
    <div></div>
    <div class="d-flex align-items-center">
      <button
        *ngIf="deletable"
        coreIconButton
        icon="delete"
        [shadow]="true"
        [actionInProgress]="deleting"
        variant="light"
        (click)="deleteClick.emit($event)">
      </button>
      <core-post-media-save
        class="ml-2"
        [url]="slides?.[0]?.src">
      </core-post-media-save>
    </div>
  </div>
</core-post-card>
