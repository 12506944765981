import { companyReducer } from './company/company.reducer';
import { companyStoreReducer } from './company-store/company-store.reducer';
import { userReducer } from './user/user.reducer';

/**
 * Reducer map for app state
 */
export const reducers: any = {
  companies: companyReducer,
  companyStores: companyStoreReducer,
  users: userReducer,
};
