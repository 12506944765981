import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, tap } from 'rxjs/operators';
import { Resource } from '@core/interfaces/post.interface';
import { photoOutlet } from '../../variables/outlet.variable';
import { PhotoDialogConfig } from '../../types/photo-dialog.type';

@Component({
  selector: 'photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss'],
})
export class PhotoDialogComponent {
  repostLoading = false;

  constructor(
    private dialogRef: MatDialogRef<PhotoDialogConfig>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: PhotoDialogConfig,
  ) {}

  onKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowRight':
        return this.data.onIndexChange(this.data.index + 1);
      case 'ArrowLeft':
        return this.data.onIndexChange(this.data.index - 1);
      default:
    }
  }

  onRepostClick(photo: Resource): void {
    this.repostLoading = true;
    this.data
      .onRepostClick(photo, this.data.index)
      .pipe(
        tap(() => {
          this.repostLoading = false;
        }),
        first(),
      )
      .subscribe();
  }

  removeOutlet(): void {
    this.router.navigate([{ outlets: { [photoOutlet]: null } }]).then(() => {
      this.dialogRef.close();
    });
  }
}
