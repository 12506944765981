import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '../svg/svg.module';
import { PlatformAvatarComponent } from './components/platform-avatar.component';

@NgModule({
  declarations: [PlatformAvatarComponent],
  imports: [CommonModule, SvgModule],
  exports: [PlatformAvatarComponent],
})
export class PlatformModule {}
