import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from '@core/ui/card/card.module';
import { MaterialModule } from '@core/modules/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { TipModule } from '@core/ui/tip/tip.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PostedCommentComponent } from './components/posted-comment/posted-comment.component';
import { CommentFieldComponent } from './components/comment-field/comment-field.component';
import { SvgModule } from '../svg/svg.module';

@NgModule({
  declarations: [PostedCommentComponent, CommentFieldComponent],
  imports: [
    CommonModule,
    CardModule,
    CoreModule,
    SvgModule,
    MaterialModule,
    TranslateModule,
    ButtonModule,
    TipModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [PostedCommentComponent, CommentFieldComponent],
})
export class CommentModule {}
