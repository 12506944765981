import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountUpModule } from 'ngx-countup';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgModule } from '@core/ui/svg/svg.module';
import { CardModule } from '@core/ui/card/card.module';
import { SkeletonModule } from '@core/ui/skeleton/skeleton.module';
import { KpiCardLargeComponent } from './components/kpi-card-large/kpi-card-large.component';
import { KpiCardSmallComponent } from './components/kpi-card-small/kpi-card-small.component';
import { KpiHeaderContentComponent } from './components/kpi-header-content/kpi-header-content.component';
import { KpiValueComponent } from './components/kpi-value/kpi-value.component';
import { DurationSegmentPipe } from './pipes/duration-segment.pipe';
import { KpiGrowthRateComponent } from './components/kpi-growth-rate/kpi-growth-rate.component';
import { KpiBodyContentComponent } from './components/kpi-body-content/kpi-body-content.component';
import { KpiCardLargeSkeletonComponent } from './components/kpi-card-large-skeleton/kpi-card-large-skeleton.component';

@NgModule({
  declarations: [
    KpiCardLargeComponent,
    KpiCardSmallComponent,
    KpiHeaderContentComponent,
    KpiValueComponent,
    KpiGrowthRateComponent,
    DurationSegmentPipe,
    KpiBodyContentComponent,
    KpiCardLargeSkeletonComponent,
  ],
  imports: [CommonModule, MatTooltipModule, CountUpModule, SvgModule, CardModule, TranslateModule, SkeletonModule],
  exports: [
    KpiCardLargeComponent,
    KpiCardSmallComponent,
    KpiHeaderContentComponent,
    KpiValueComponent,
    KpiGrowthRateComponent,
    KpiBodyContentComponent,
    KpiCardLargeSkeletonComponent,
    DurationSegmentPipe,
  ],
})
export class KpiModule {}
