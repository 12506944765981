import { Component, Input } from '@angular/core';
import { LinearProgressBarVariant } from '@core/ui/progress/types/linear-progress-bar.variant';

@Component({
  selector: 'core-segmented-progress-bar',
  templateUrl: './segmented-progress-bar.component.html',
  styleUrls: ['./segmented-progress-bar.component.scss'],
})
export class SegmentedProgressBarComponent {
  /**
   * The color variant
   */
  @Input()
  variant: LinearProgressBarVariant = 'emphasis';

  /**
   * The flashing bar
   */
  @Input()
  flashingBar = 1;

  /**
   * Total amount of segmented bars
   */
  @Input()
  bars = 4;
}
