import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { interval, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'bc-snackbar-update',
  templateUrl: './snackbar-update.component.html',
  styleUrls: ['./snackbar-update.component.scss'],
})
export class SnackbarUpdateComponent implements OnInit {
  public countdown = 10;

  public destroyed$ = new Subject<void>();

  constructor(
    private swUpdate: SwUpdate,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    const timer$ = interval(1000).pipe(
      take(11),
      map((x) => 10 - x),
    );
    timer$.subscribe((time) => {
      this.countdown = time;
      if (time === 0) {
        this.closeSnackBar();
        this.swUpdate.activateUpdate().then(() => window.location.reload());
      }
    });
  }

  closeSnackBar(): void {
    this.snackBar.dismiss();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
