import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { DocumentBase } from '@core/interfaces/document.interface';
import { Nullable } from '@core/types/nullable.type';
import { CampaignStatus } from '@google-ad/common/enums/campaign-status.enum';

/**
 * Frontend model for a Google Campaign
 */
export class Campaign<T = unknown> implements DocumentBase {
  _id!: string;

  createdAt!: string;

  updatedAt!: string;

  connectionId!: string;

  accountId!: string;

  providerAccountId!: string;

  provider!: string;

  providerCampaignId!: string;

  name!: string;

  startDate!: Date;

  endDate!: Nullable<Date>;

  status!: CampaignStatus;

  raw!: T;

  constructor(object?: Partial<Campaign>) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for the Google Campaign
 */
@Injectable({
  providedIn: 'root',
})
export class CampaignListAdapter implements Adapter<Campaign> {
  clientAdapt(object: any): Campaign {
    return new Campaign(object);
  }

  serverAdapt(object: any): Campaign {
    return new Campaign(object);
  }
}
