import { Pipe, PipeTransform } from '@angular/core';

/**
 * Switches between singular and plural translation string depending on the count
 */
@Pipe({
  name: 'pluralFormat',
})
export class PluralFormatPipe implements PipeTransform {
  /**
   * Returns either a single or plural translation string
   * @param translation the translation string
   * @param count the number of items
   */
  transform(translation: string, count?: number | string): string {
    const countVal = typeof count === 'string' ? parseInt(count, 10) : count;

    if (!countVal) {
      return `${translation}.zero`;
    }

    return countVal === 1 ? `${translation}.one` : `${translation}.many`;
  }
}
