import { AppTypeConfigService } from '@core/services/app-type-config.service';
import { MessageEventService } from '@core/services/message-event.service';
import { Store } from '@ngrx/store';
import { AppLogout } from '@core/redux/app/app.action';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import { ApiService, APISERVICE_BASEURL } from '@core/services/api.service';
import { StorageService } from '@core/services/storage.service';
import { ReloadService } from '@core/services/reload.service';
import { CURRENT_FILTER, ACCESS_TOKEN, NAVIGATION_DEFAULT_STATE } from '@core/variables/storage-keys.variable';
import { POST_MANAGER_DRAFTS } from '@core/dialogs/post-manager/variables/storage.variable';
import { AppState } from '@core/interfaces/app-state.interface';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { Params, Router, UrlSerializer } from '@angular/router';
import { TokenPayload } from '@core/modules/auth/interfaces/auth-token.interface';
import { AuthTokenDocument } from '@core/modules/auth/models/auth-token-document.model';
import { AuthMethod } from '@core/modules/auth/enums/auth-method.enum';
import { Nullable } from '@core/types/nullable.type';
import { AUTH_EVENT } from '@core/variables/webview-events.variable';
import _omit from 'lodash/omit';
import { AuthTokenDocumentAdapter } from '../modules/auth/models/auth-token-document.model';

/**
 * Service for handling user authentication
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _accessToken: Nullable<string> = null;

  private decodedAccessToken: Nullable<TokenPayload> = null;

  constructor(
    private apiService: ApiService,
    private authTokenDocumentAdapter: AuthTokenDocumentAdapter,
    private router: Router,
    private urlSerializer: UrlSerializer,
    private storageService: StorageService,
    private reloadService: ReloadService,
    private ngrxStore$: Store<AppState>,
    private messageEventService: MessageEventService,
    private appTypeConfigService: AppTypeConfigService,
    @Inject(APISERVICE_BASEURL) readonly baseUrl: string,
  ) {
    // Get the token from storage
    this._accessToken = storageService.getSessionItem(ACCESS_TOKEN) || storageService.getItem(ACCESS_TOKEN);
    this.decodedAccessToken = this.getDecodedJwt(this._accessToken);

    // Check if there is a token in the query params
    const queryParams = this.reloadService.getQueryParamsFromUrl();
    if (queryParams?.access_token) {
      this._accessToken = queryParams?.access_token;
      this.decodedAccessToken = this.getDecodedJwt(this._accessToken);
      if (this.isSessionLogin(this.decodedAccessToken)) {
        this.storageService.setSessionItem(ACCESS_TOKEN, queryParams?.access_token);
      } else {
        this.storageService.setItem(ACCESS_TOKEN, queryParams?.access_token);
      }
      this.reloadService.reload(_omit(queryParams, 'access_token'));
    }
  }

  get accessToken(): Nullable<string> {
    return this._accessToken;
  }

  set accessToken(accessToken: Nullable<string>) {
    this._accessToken = accessToken;
    this.decodedAccessToken = this.getDecodedJwt(this._accessToken);
    const isSessionLogin = this.isSessionLogin(this.decodedAccessToken);
    if (isSessionLogin) {
      this.storageService.setSessionItem(ACCESS_TOKEN, this._accessToken);
    } else {
      this.storageService.setItem(ACCESS_TOKEN, this._accessToken);
    }
  }

  /**
   * We use session storage instead of local storage when auth method is LOGIN_AS_USER.
   * This functions checks if we are using session storage or not.
   *
   * @param decodedAccessToken the decoded JWT
   */
  isSessionLogin(decodedAccessToken = this.decodedAccessToken): boolean {
    return decodedAccessToken?.amr?.includes(AuthMethod.LoginAsUser) || false;
  }

  /**
   * Tries to decode the jwt or clears it if it's invalid
   * @param token the encoded jwt
   */
  getDecodedJwt(token: Nullable<string>): Nullable<TokenPayload> {
    if (!token) {
      return null;
    }
    try {
      return jwtDecode(token);
    } catch (error) {
      const isSessionLogin = this.isSessionLogin(this.decodedAccessToken);
      if (isSessionLogin) {
        this.storageService.removeSessionItem(ACCESS_TOKEN);
        this.storageService.removeSessionItem(CURRENT_FILTER);
      } else {
        this.storageService.removeItem(ACCESS_TOKEN);
        this.storageService.removeItem(CURRENT_FILTER);
      }

      return null;
    }
  }

  /**
   * Sends a login request to the server
   * @param email the user's email
   * @param password the user's password
   */
  login(email: string, password: string): Observable<AuthTokenDocument> {
    return this.apiService.post('auth/login/email', { email, password }).pipe(
      map((res: ApiSuccessResponse<AuthTokenDocument>) => {
        const authResponse = this.authTokenDocumentAdapter.clientAdapt(res.result);
        this.accessToken = authResponse.tokenValue;

        return authResponse;
      }),
    );
  }

  /**
   * Creates a company user for the given config
   * @param provider provider method for the social logins
   * @param successUrl redirect URL when the request has been completed
   * @param queryParams query parameters
   */
  loginViaMethod(authMethod: AuthMethod, successPath: string, queryParams: Params = {}): string {
    const tree = this.router.createUrlTree([], { queryParams });
    const origin = this.appTypeConfigService.getRedirectOrigin();
    const successUrl = encodeURIComponent(`${origin}${successPath}${this.urlSerializer.serialize(tree)}`);

    return `${this.baseUrl}auth/login/${authMethod.toLocaleLowerCase()}?success_url=${successUrl}`;
  }

  /**
   * Sends a logout request to the server
   */
  logout(): Observable<void> {
    return this.apiService.post('auth/logout/').pipe(
      tap(() => {
        this.ngrxStore$.dispatch(new AppLogout());
        this.clearTokens();
      }),
    );
  }

  /**
   * Sends a request for instructions on how to reset password
   * @param email the user's email
   */
  forgotPassword(params: { email: string }): Observable<void> {
    return this.apiService.post('auth/password-forgot', params);
  }

  /**
   * Sends a request to get info about rest password
   * link expiration
   * @param resetToken the unique identifier
   */
  verifyPasswordToken(token: string): Observable<void> {
    return this.apiService.get(`auth/password-reset/${token}`);
  }

  /**
   * Sends a request to reset password
   * @param resetToken the unique identifier
   * @param password the new password
   */
  resetPassword(resetToken: string, password: string): Observable<void> {
    return this.apiService.post(`auth/password-reset/${resetToken}`, {
      resetToken,
      password,
    });
  }

  /**
   * Clears the tokens from the local storage
   */
  public clearTokens(): void {
    this.messageEventService.postMessageToWebView(AUTH_EVENT.signedOut);
    this.storageService.removeItem(NAVIGATION_DEFAULT_STATE);
    this.storageService.removeItem(POST_MANAGER_DRAFTS);
    this.accessToken = null;

    // Clear session storage
    this.storageService.removeSessionItem(ACCESS_TOKEN);
    this.storageService.removeSessionItem(CURRENT_FILTER);

    // Clear local storage
    this.storageService.removeItem(ACCESS_TOKEN);
    this.storageService.removeItem(CURRENT_FILTER);
  }

  /**
   * Checks if the current access token is valid.
   */
  validAccessToken(): boolean {
    return !!this.decodedAccessToken?.jti;
  }
}
