import { Injectable } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { TagMediumConfig } from '@core/ui/tag/types/tag-medium.type';
import { CreatorType } from '../enums/creator-type.enum';
import { ManagedPostStatus } from '../enums/managed-post-status.enum';

@Injectable({
  providedIn: 'root',
})
export class ManagedPostConfigService {
  /**
   * Gets the configuration for the creator tag
   * @param creatorType the creator type
   */
  getCreatorTagConfig(creatorType?: CreatorType): Nullable<TagMediumConfig> {
    switch (creatorType) {
      case CreatorType.Manager:
        return {
          variant: 'emphasis',
          outline: 'solid',
          icon: 'alphin',
          label: 'POST.POSTED_BY_ALPHIN',
        };
      case CreatorType.User:
        return {
          variant: 'secondary',
          outline: 'solid',
          icon: 'check_circle',
          label: 'POST.POSTED_BY_ME',
        };
      case CreatorType.System:
      default:
        return null;
    }
  }

  /**
   * Gets the configuration for the post status tag
   * @param status the status of the current campaign
   */
  getStatusTagConfig(status: ManagedPostStatus): Nullable<TagMediumConfig> {
    switch (status) {
      case ManagedPostStatus.Published:
      case ManagedPostStatus.Processing:
        return {
          variant: 'default',
          outline: 'solid',
          icon: 'check_circle',
          label: 'POST.PUBLISHED',
        };
      case ManagedPostStatus.Scheduled:
        return {
          variant: 'default',
          outline: 'dashed',
          icon: 'calendar',
          label: 'POST.SCHEDULED',
        };
      case ManagedPostStatus.Error:
        return {
          variant: 'emphasis',
          outline: 'solid',
          icon: 'error',
          label: 'POST.ERROR',
        };
      default:
        return null;
    }
  }
}
