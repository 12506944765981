import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestCardsRoute } from '@review/modules/request-cards/enums/request-cards-route.enum';
import { ManageCardsRoute } from '@review/modules/manage-cards/enums/manage-cards-route.enum';
import { Subject } from 'rxjs';

export type ReviewWorkflow = 'request-cards' | 'manage-cards';

export const STAGES = [...Object.values(RequestCardsRoute), ...Object.values(ManageCardsRoute)];

export type Stage = (typeof STAGES)[number];

@Injectable({
  providedIn: 'root',
})
export class ReviewWorkflowService {
  private workflow?: ReviewWorkflow;

  updateCards$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  getWorkflow(): ReviewWorkflow {
    return this.workflow!;
  }

  setWorkflow(workflow: ReviewWorkflow): void {
    this.workflow = workflow;
  }

  getCurrentStage(): Stage {
    return this.activatedRoute.snapshot.children
      .filter((route) => route.outlet === this.workflow)?.[0]
      ?.url.join('/') as any;
  }

  setCurrentStage(newStage: Stage, routeParam?: string, queryParams: any = {}): void {
    if (!this.workflow) {
      return;
    }
    this.router.navigate([{ outlets: { [this.workflow!]: routeParam ? `${newStage}/${routeParam}` : newStage } }], {
      queryParams: this.router.routerState.snapshot.root.queryParams,
      ...queryParams,
    });
  }

  clearStage(): void {
    this.setCurrentStage(null!);
  }
}
