import { Injectable } from '@angular/core';
import { PageLinkType } from '@core/enums/page.enum';
import { Adapter } from '@core/interfaces/adapter.interface';
import { Nullable } from '@core/types/nullable.type';

/**
 * Frontend model for link
 */
export class PageLink {
  id!: string;

  name!: Nullable<string>;

  type!: PageLinkType;

  pageId!: string;

  url!: Nullable<string>;

  constructor(object: PageLink) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for link
 */
@Injectable({
  providedIn: 'root',
})
export class PageLinkAdapter implements Adapter<PageLink> {
  clientAdapt(object: any): PageLink {
    return new PageLink(object);
  }

  serverAdapt(object: PageLink): any {
    return object;
  }
}
