/* eslint-disable no-plusplus */
import { Directive, InjectionToken, Input } from '@angular/core';

let nextUniqueId = 0;

export const FORM_FIELD_ERROR = new InjectionToken<FormFieldErrorDirective>('FormFieldError');

/**
 * Single error message to be shown underneath the form field.
 */
@Directive({
  selector: 'core-form-field-error, [coreFormFieldError]',
  host: {
    class: 'core-form-field-error',
    role: 'alert',
    '[attr.id]': 'id',
  },
  providers: [{ provide: FORM_FIELD_ERROR, useExisting: FormFieldErrorDirective }],
})
export class FormFieldErrorDirective {
  @Input() id = `form-field-error-${nextUniqueId++}`;
}
