<core-skeleton class="flex-fill" animation="none" roundness="md" [shadow]="true">
  
  <div *ngIf="header" class="d-flex align-items-center mx-3 my-2">
    <core-skeleton class="mr-3" level="3" animation="shimmer" roundness="circle" [style.height.px]="40" [style.width.px]="40"></core-skeleton>
    <core-skeleton level="3" animation="shimmer" roundness="md" [style.height.px]="24" [style.width.px]="180"></core-skeleton>
  </div>

  <core-skeleton variant="secondary" level="2" animation="shimmer" roundness="none" [style.height.px]="0" [style.width.%]="100" [style.padding-top.%]="((aspectRatio?.w || 1) / (aspectRatio?.h || 1)) * 100">
    <div class="d-flex flex-column align-items-center justify-content-center photo-icon-container">
      <core-svg svg="outline_image"></core-svg>
    </div>
  </core-skeleton>
  
  <div class="d-flex flex-column mx-3 my-2">
    <ng-container *ngIf="caption">
      <core-skeleton class="my-1" level="3" animation="shimmer" roundness="sm" [style.height.px]="18" [style.width.%]="100"></core-skeleton>
      <core-skeleton class="my-1" level="3" animation="shimmer" roundness="sm" [style.height.px]="18" [style.width.%]="100"></core-skeleton>
      <core-skeleton class="my-1" level="3" animation="shimmer" roundness="sm" [style.height.px]="18" [style.width.%]="70"></core-skeleton>
    </ng-container>

    <div class="mt-3 mb-1 d-flex align-items-center justify-content-between">
      <core-skeleton *ngIf="cta" class="mr-3" level="3" animation="shimmer" roundness="lg" [style.height.px]="48" [style.width.px]="140"></core-skeleton>
      <div></div>
      <core-skeleton level="3" animation="shimmer" roundness="circle" [style.height.px]="48" [style.width.px]="48"></core-skeleton>
    </div>
  </div>
</core-skeleton>
