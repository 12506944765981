import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@core/core.module';
import { AlertModule } from '@core/ui/alert/alert.module';
import { SnackbarUpdateComponent } from './snackbar-update.component';

@NgModule({
  declarations: [SnackbarUpdateComponent],
  imports: [CommonModule, TranslateModule, CoreModule, AlertModule],
  exports: [SnackbarUpdateComponent],
})
export class SnackbarUpdateModule {}
