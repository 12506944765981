import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { IntercomService } from '@core/services/intercom.service';
import { DialogService } from '@core/services/dialog.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'core-store-setup-header-options',
  templateUrl: './store-setup-header-options.component.html',
  styleUrls: ['./store-setup-header-options.component.scss'],
})
export class StoreSetupHeaderOptionsComponent {
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    public intercomService: IntercomService,
    private router: Router,
  ) {}

  logout(): void {
    this.dialogService.closeDialog();
    this.authService
      .logout()
      .pipe(first())
      .subscribe(() => this.router.navigate(['/'], { queryParamsHandling: 'merge' }));
  }

  openSupport(): void {
    this.intercomService.show();
  }
}
