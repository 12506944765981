<core-section-card padding="none"
  roundness="md"
  [config]="{
    title: title,
    subtitle: subtitle,
    icon: icon,
    iconType: iconType,
    headerPadding: 'md',
    footerPadding: 'md',
    headerFooterSize: 'md'
  }">
  <ng-container coreSectionCardHeader>
    <ng-content select="[corePostHeader]"></ng-content>
  </ng-container>

  <div class="post-media">
    <core-overlay
      padding="none"
      roundness="none"
      variant="primary"
      overlayStrength="medium" 
      [visible]="mediaOverlay"
      [class.card-type-content-overlay]="cardVariant === 'content-overlay'">
      <ng-container coreOverlayProtectedContent>
        <core-post-media
          [autoplayVideo]="autoplayVideo"
          [slides]="slides"
          [slideIndex]="slideIndex"
          [carouselOptions]="carouselOptions"
          [variant]="mediaVariant"
          [imageFit]="imageFit"
          (loaded)="loaded.emit($event)"
          (fallbackLoaded)="loaded.emit($event)"
          (error)="error.emit($event)"
          (slideChange)="slideChange.emit($event)">
        </core-post-media>
      </ng-container>
      <div class="media-overlay-content">
        <ng-content select="[corePostMediaOverlay]"></ng-content>
      </div>
    </core-overlay>
    <div *ngIf="cardVariant === 'content-overlay'" class="post-media__overlay">
      <div class="post-media__top">
        <div *ngIf="groupLength && groupLength > 1" class="d-flex align-items-center">
          <ng-container
            *ngFor="let item of [].constructor(groupLength); let index = index">
            <core-linear-progress-bar
              *ngIf="groupIndex === index; else outOfViewBar"
              class="flex-grow-1"
              [style.margin.px]="2"
              variant="light"
              [value]="progress"
              [total]="durationMilliseconds">
            </core-linear-progress-bar>
            <ng-template #outOfViewBar>
              <core-linear-progress-bar
                *ngIf="groupIndex > index; else unfilledBar"
                class="flex-grow-1"
                [style.margin.px]="2"
                variant="light"
                [value]="durationMilliseconds"
                [total]="durationMilliseconds">
              </core-linear-progress-bar>
              <ng-template #unfilledBar>
                <core-linear-progress-bar
                  class="flex-grow-1"
                  [style.margin.px]="2"
                  variant="light"
                  [value]="0"
                  [total]="durationMilliseconds">
                </core-linear-progress-bar>
              </ng-template>
            </ng-template>
          </ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="postInfo"></ng-container>
      </div>
      <div class="post-media__bottom">
        <ng-container [ngTemplateOutlet]="postFooter"></ng-container>
      </div>
    </div>
  </div>

  <ng-container coreSectionCardFooter *ngIf="cardVariant === 'default'">
    <ng-container [ngTemplateOutlet]="postFooter"></ng-container>
  </ng-container>
</core-section-card>

<ng-template #postFooter>
  <div class="post-footer pb-2">
    <ng-container *ngIf="cardVariant === 'default'">
      <ng-container [ngTemplateOutlet]="postInfo"></ng-container>
    </ng-container>
    <ng-container *ngIf="caption">
      <core-post-caption class="mb-3" *ngIf="!captionSkeleton; else spoiler" [text]="caption" [provider]="provider" [isTruncated]="isCaptionTruncated"></core-post-caption>

      <ng-template #spoiler>
        <div class="caption-spoiler mb-3">
          <div class="caption-spoiler__line"></div>
          <div class="caption-spoiler__line"></div>
          <div class="caption-spoiler__line caption-spoiler__line--short"></div>
        </div>
      </ng-template>
    </ng-container>

    <ng-content select="[corePostFooter]"></ng-content>
  </div>
</ng-template>

<ng-template #postInfo>
  <div class="post-info mb-3" *ngIf="owner || date || counts?.likes || counts?.comments || counts?.views">
    <core-post-owner class="mr-1" [avatar]="owner?.avatar" [username]="owner?.username" [followers]="owner?.followers" [link]="owner?.link"></core-post-owner>
    <div class="d-flex flex-column align-self-stretch justify-content-center ml-auto">
      <core-post-interactions
        class="justify-content-end"
        [counts]="counts"
        [clickable]="interactionStates?.clickable"
        [loading]="interactionStates?.loading"
        [showIfZero]="interactionStates?.showIfZero"
        (interactionClick)="interactionClick.emit($event)"></core-post-interactions>
      <core-post-date class="justify-content-end" [date]="date"></core-post-date>
    </div>
  </div>
</ng-template>
