import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PostCardVariant } from '@core/modules/post/types/post-card.type';
import { Resource } from '@core/interfaces/post.interface';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { ResourceType } from '@core/dialogs/post-manager/enums/resource-type.enum';

@Component({
  selector: 'photo-media-card',
  templateUrl: './photo-media-card.component.html',
  styleUrls: ['./photo-media-card.component.scss'],
})
export class PhotoMediaCardComponent implements OnChanges {
  @Input()
  photo!: Resource;

  @Output()
  updateLayout = new EventEmitter();

  @Input()
  repostDisabled = false;

  @Input()
  repostLoading = false;

  @Input()
  deletable = false;

  @Input()
  deleting = false;

  @Output()
  repostClick = new EventEmitter();

  @Output()
  deleteClick = new EventEmitter();

  slides: MediaConfig[] = [];

  hasError = false;

  private minAspectRatioForContentOverlay = 10 / 23;

  cardVariant: PostCardVariant = 'content-overlay';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.photo && changes.photo.currentValue !== changes.photo.previousValue) {
      this.hasError = false;
      this.cardVariant = 'content-overlay';
      const { archiveUrl, url } = this.photo?.media || {};
      this.slides = [
        {
          src: archiveUrl || url || '',
          variant: (this.photo.type as any as ResourceType) === ResourceType.Video ? 'VIDEO' : 'IMAGE',
        },
      ];
    }
  }

  onMediaLoaded(event: Event): void {
    const { clientHeight = 1, clientWidth = 1 } = event.target as HTMLImageElement;
    const mediaAspectRatio = clientHeight / clientWidth;
    if (this.minAspectRatioForContentOverlay > mediaAspectRatio) {
      this.cardVariant = 'default';
    }
  }
}
