import { SupportedCurrency, SupportedLanguage } from '@core/enums/user-preference.enum';
import { PreferenceOption } from '@core/interfaces/user-preferences.interface';

/**
 * Default language
 */
export const DEFAULT_LANGUAGE: SupportedLanguage = SupportedLanguage.en;

/**
 * Default currency
 */
export const DEFAULT_CURRENCY: SupportedCurrency = SupportedCurrency.EUR;

/**
 * Select picker options for language
 */
export const languageOptions: PreferenceOption<SupportedLanguage>[] = [
  {
    label: 'English',
    symbol: '🇬🇧',
    value: SupportedLanguage.en,
  },
  {
    label: 'Deutsch',
    symbol: '🇩🇪',
    value: SupportedLanguage.de,
  },
];

/**
 * Select picker options for currency
 */
export const currencyOptions: PreferenceOption<SupportedCurrency>[] = [
  {
    label: 'Euro',
    symbol: '€',
    value: SupportedCurrency.EUR,
  },
  {
    label: 'Pounds',
    symbol: '£',
    value: SupportedCurrency.GBP,
  },
  {
    label: 'Shekel',
    symbol: '₪',
    value: SupportedCurrency.ILS,
  },
  {
    label: 'US Dollars',
    symbol: '$',
    value: SupportedCurrency.USD,
  },
];
