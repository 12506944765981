import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import _get from 'lodash/get';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { AuthUser, AuthUserAdapter } from '@core/models/auth-user.model';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { StoreAdapter, Store } from '@core/models/store.model';
import { ConnectStoreConfig } from '@core/interfaces/connect-store.interface';
import { USER_EVENT } from '@core/variables/webview-events.variable';
import { CSManagerInfo, CSManagerInfoAdapter } from '@core/models/cs-manager-info.model';
import { ApiService } from './api.service';
import { MessageEventService } from './message-event.service';

/**
 * Service for handing the authenticated user's data
 */
@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private authUserSubject$: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null as any);

  public authUser$: Observable<AuthUser>;

  public requestInProgress = false;

  constructor(
    private apiService: ApiService,
    private authUserAdapter: AuthUserAdapter,
    private csManagerInfoAdapter: CSManagerInfoAdapter,
    private storeAdapter: StoreAdapter,
    private messageEventService: MessageEventService,
  ) {
    this.authUser$ = this.authUserSubject$.asObservable();
  }

  /**
   * Attaches a store to the given user
   * @param config the store config
   */
  connectStore(config: ConnectStoreConfig): Observable<Store> {
    return this.apiService
      .post(`onboarding/create-company-store`, config)
      .pipe(map((res: ApiSuccessResponse<Store>) => this.storeAdapter.clientAdapt(res.result)));
  }

  /**
   * Requests the most up-to-date data for the user and attach the cs manager info
   */
  public getAuthUser(): Observable<AuthUser> {
    this.requestInProgress = true;

    return forkJoin([this.apiService.get('users/me'), this.apiService.get('users/me/support-information')]).pipe(
      map(([userRes, csManagerRes]: [ApiSuccessResponse<AuthUser>, ApiSuccessResponse<CSManagerInfo>]) => {
        const user = this.authUserAdapter.clientAdapt(userRes.result);
        const csManagerInfo = this.csManagerInfoAdapter.clientAdapt(csManagerRes.result);

        user.csManagerInfo = csManagerInfo;

        return user;
      }),
      tap((authUser: AuthUser) => {
        this.authUserSubject$.next(authUser);
        this.messageEventService.postMessageToWebView(USER_EVENT.detailsFetched, { authUser });
        this.requestInProgress = false;
      }),
    );
  }

  /**
   * Requests the most up-to-date data for the user
   */
  public updateUserOptions(settingsValues: any = {}): Observable<AuthUser> {
    this.requestInProgress = true;

    return this.apiService
      .put('users/me', { contact: this.authUserSubject$.value?.contact, settings: settingsValues })
      .pipe(
        map((res: ApiSuccessResponse<AuthUser>) => this.authUserAdapter.clientAdapt(res.result)),
        tap((authUser) => {
          this.authUserSubject$.next(authUser);
          this.messageEventService.postMessageToWebView(USER_EVENT.detailsFetched, { authUser });
          this.requestInProgress = false;
        }),
      );
  }

  /**
   * Checks an access right for a given module name
   * @param moduleName the name of the module
   * @param right the access right to check
   */
  public checkAccessRight(moduleName: string, right: string) {
    return _get(this.authUserSubject$.value, `accessRights[${moduleName}][${right}]`, false);
  }

  /**
   * Returns the user's data
   */
  get authUser(): AuthUser {
    return this.authUserSubject$.value;
  }
}
