import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, Input } from '@angular/core';
import {
  PlatformAvatarBadgePosition,
  PlatformAvatarSize,
  PlatformAvatarSrcType,
  PlatformAvatarVariant,
} from '../types/platform-avatar.type';

@Component({
  selector: 'core-platform-avatar',
  templateUrl: './platform-avatar.component.html',
  styleUrls: ['./platform-avatar.component.scss'],
  host: {
    '[class]': `"platform-avatar"
    + " size-"+size
    + " variant-"+variant`,
  },
})
export class PlatformAvatarComponent {
  @Input()
  url?: string | SafeResourceUrl;

  @Input()
  iconName = 'integration_fill';

  @Input()
  variant: PlatformAvatarVariant = 'enabled';

  @Input()
  srcType: PlatformAvatarSrcType = 'url';

  @Input()
  size: PlatformAvatarSize = 'md';

  @Input()
  badgePosition: PlatformAvatarBadgePosition = 'bottom-left';

  fallback = 'integration_fill';

  error = false;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnChanges(changes: any) {
    if ('url' in changes) {
      this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(changes.url.currentValue);
    }
  }

  /**
   * Sets the fallback badge if an error occurred
   */
  setFallback(): void {
    this.error = true;
    this.iconName = 'integration_fill';
    this.srcType = 'iconName';
  }
}
