import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IconSize } from '@core/ui/svg/types/icon.type';
import { IconButtonSize, IconButtonVariant } from '../../types/icon-button.type';

@Component({
  selector: 'core-icon-button, button[coreIconButton], a[coreIconButton]',
  exportAs: 'coreIconButton',
  host: {
    '[class]': `"core-icon-button"
    + " size-"+size
    + " variant-"+variant`,
    '[attr.disabled]': 'disabled || actionInProgress || null',
    '[class.core-icon-button--disabled]': 'disabled || actionInProgress',
    '[class.core-icon-button--shadow]': 'shadow',
  },
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  inputs: ['disabled', 'variant', 'icon', 'size'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent implements OnChanges {
  @Input()
  icon = '';

  @Input()
  placeholderIcon = '';

  @Input()
  material = false;

  @Input()
  disabled = false;

  @Input()
  shadow = false;

  @Input()
  actionInProgress = false;

  @Input()
  size: IconButtonSize = 'md';

  @Input()
  variant: IconButtonVariant = 'default';

  iconSize: IconSize = 'sm';

  ngOnChanges(): void {
    this.iconSize = this.getIconSize();
  }

  private getIconSize(): IconSize {
    switch (this.size) {
      case 'xl':
        return 'lg';
      case 'lg':
        return 'md';
      case 'sm':
        return 'xs';
      case 'md':
      default:
        return 'sm';
    }
  }
}
