<div class="core-option-icon" #icon>
  <ng-content select="[coreOptionIcon]"></ng-content>
</div>

<span class="core-list-item__label" #label>
  <ng-content></ng-content>
</span>

<div
  class="core-option-ripple core-focus-indicator"
  aria-hidden="true"
  matRipple
  [matRippleTrigger]="getHostElement()">
</div>

@if (multiple || (selected && !hideSingleSelectionIndicator)) {
  <core-icon
    class="ml-2"
    size="sm"
    aria-hidden="true"
    [style.fill]="selected ? 'rgb(var(--success))' : 'rgb(var(--secondary2))'"
    [icon]="selected ? 'check_circle' : 'circle'">
  </core-icon>
}
