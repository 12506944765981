import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '../../types/button.type';
import { ButtonPosition, SubmitButtonVariant, SecondaryButtonVariant } from '../../types/form-actions.type';

/**
 * TODO:
 * - Change Submit to Primary
 * - Change Cancel to Secondary
 */
@Component({
  selector: 'core-form-actions',
  host: {
    '[class]': `"core-form-actions"
    + " position-"+buttonPosition`,
  },
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})
export class FormActionsComponent {
  @Input()
  submitDisabled = false;

  @Input()
  submitLabel?: string;

  @Input()
  primaryIcon?: string;

  @Input()
  variant: SubmitButtonVariant = 'primary';

  @Input()
  cancelDisabled = false;

  @Input()
  cancelLabel?: string;

  @Input()
  secondaryIcon?: string;

  @Input()
  secondaryVariant: SecondaryButtonVariant = 'default';

  @Input()
  size: ButtonSize = 'md';

  @Input()
  actionInProgress = false;

  @Input()
  buttonPosition: ButtonPosition = 'responsive';

  @Output()
  cancelClick = new EventEmitter();

  @Output()
  submitClick = new EventEmitter();
}
