import { RouterModule, Routes } from '@angular/router';

import { StoreSetupFormComponent } from './screens/store-setup-form/store-setup-form.component';
import { IntroComponent } from './screens/intro/intro.component';
import { storeSetupOutlet } from './variables/outlet.variable';
import { StoreSetupRoute } from './enums/store-setup-route.enum';

const outlet = storeSetupOutlet;

export const makeStoreSetupWorkflowRoutes = () => [
  {
    path: StoreSetupRoute.Form,
    outlet,
    component: StoreSetupFormComponent,
  },
  {
    path: StoreSetupRoute.Intro,
    outlet,
    component: IntroComponent,
  },
];

const routes: Routes = [...makeStoreSetupWorkflowRoutes()];

export const StoreSetupWorkflowRoutingModule = RouterModule.forChild(routes);
