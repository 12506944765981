import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { postViewerOutlet } from '@core/dialogs/post-viewer/variables/outlet.variable';
import { MatDialogRef } from '@angular/material/dialog';
import { PostViewerComponent } from '../../post-viewer.component';
import { PostViewerConfig } from '../../types/post-viewer.type';

@Component({
  selector: 'core-post-insights',
  templateUrl: './post-insights.component.html',
  styleUrls: ['./post-insights.component.scss'],
})
export class PostInsightsComponent implements OnInit {
  data?: PostViewerConfig;

  showVideoViews = false;

  constructor(
    private dialogRef: MatDialogRef<PostViewerComponent>,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.data = this.dialogRef.componentInstance?.data;
    this.showVideoViews =
      this.data.post?.mediaVariant === 'VIDEO' || !!this.data.post?.slides?.some((slide) => slide.variant === 'VIDEO');
  }

  removeOutlet(): void {
    this.dialogService.closeDialog();
    this.router.navigate([{ outlets: { [postViewerOutlet]: null } }]);
  }
}
