import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProviderAccountPost, ProviderAccountPostAdapter } from '../models/provider-account-post.model';
import {
  ProviderAccountPostListQueryParams,
  ProviderPostCommentListQueryParams,
} from '../interfaces/query-params.interface';
import { ProviderPostComment, ProviderPostCommentAdapter } from '../models/provider-post-comment.model';
import { AccountMediaAdapter, AccountMedia } from '../models/account-media.model';

@Injectable({
  providedIn: 'root',
})
export class ProviderAccountPostDataService {
  constructor(
    private apiService: ApiService,
    private providerAccountPostAdapter: ProviderAccountPostAdapter,
    private providerPostCommentAdapter: ProviderPostCommentAdapter,
    private accountMediaAdapter: AccountMediaAdapter,
  ) {}

  /**
   * Fetches a list of the user's posts from the provider's platform
   * @param storeId the store id
   * @param queryParams the config for the request
   */
  getPostList(
    storeId: string,
    queryParams?: ProviderAccountPostListQueryParams,
  ): Observable<ApiSuccessResponse<ProviderAccountPost[]>> {
    const params = new HttpParams({
      fromObject: {
        ...queryParams,
      },
    });

    return this.apiService.get(`stores/${storeId}/socials/posts`, params).pipe(
      map((res: ApiSuccessResponse<ProviderAccountPost[]>) => ({
        ...res,
        result: res.result.map((item) => this.providerAccountPostAdapter.clientAdapt(item)),
      })),
    );
  }

  /**
   * Deletes a single post from the provider's platform
   * @param storeId the store id
   * @param postId the post id
   */
  deletePost(storeId: string, postId: string): Observable<ApiSuccessResponse<ProviderAccountPost>> {
    return this.apiService.delete(`stores/${storeId}/socials/posts/${postId}`).pipe(
      map((res: ApiSuccessResponse<ProviderAccountPost>) => ({
        ...res,
        result: this.providerAccountPostAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Fetches a list of comments on a specific post
   * @param storeId the store id
   * @param postId the internal post id
   * @param queryParams the config for the request
   */
  getPostComments(
    storeId: string,
    postId: string,
    queryParams?: ProviderPostCommentListQueryParams,
  ): Observable<ApiSuccessResponse<ProviderPostComment[]>> {
    const params = new HttpParams({
      fromObject: {
        ...queryParams,
      },
    });

    return this.apiService.get(`stores/${storeId}/socials/posts/${postId}/comments`, params).pipe(
      map((res: ApiSuccessResponse<ProviderPostComment[]>) => ({
        ...res,
        result: res.result.map((item) => this.providerPostCommentAdapter.clientAdapt(item)),
      })),
    );
  }

  /**
   * Create comment for a specific post
   * @param storeId the store id
   * @param postId the internal post id
   * @param body content of comment
   */
  createPostComment(
    storeId: string,
    postId: string,
    body: { text: string },
  ): Observable<ApiSuccessResponse<ProviderPostComment>> {
    return this.apiService.post(`stores/${storeId}/socials/posts/${postId}/comments`, body).pipe(
      map((res: ApiSuccessResponse<ProviderPostComment>) => ({
        ...res,
        result: this.providerPostCommentAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Update comment for a specific post
   * @param storeId the store id
   * @param postId the internal post id
   * @param commentId the internal comment id
   * @param body updated content of comment
   */
  updatePostComment(
    storeId: string,
    postId: string,
    commentId: string,
    body: { text: string },
  ): Observable<ApiSuccessResponse<ProviderPostComment>> {
    return this.apiService.put(`stores/${storeId}/socials/posts/${postId}/comments/${commentId}`, body).pipe(
      map((res: ApiSuccessResponse<ProviderPostComment>) => ({
        ...res,
        result: this.providerPostCommentAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Delete comment for a specific post
   * @param storeId the store id
   * @param postId the internal post id
   * @param commentId the internal comment id
   */
  deletePostComment(
    storeId: string,
    postId: string,
    commentId: string,
  ): Observable<ApiSuccessResponse<ProviderPostComment>> {
    return this.apiService.delete(`stores/${storeId}/socials/posts/${postId}/comments/${commentId}`).pipe(
      map((res: ApiSuccessResponse<ProviderPostComment>) => ({
        ...res,
        result: this.providerPostCommentAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Gets replies for a specific comment
   * @param storeId the store id
   * @param postId the internal post id
   * @param commentId the internal comment id
   * @param queryParams the config for the request
   */
  getPostCommentReplies(
    storeId: string,
    postId: string,
    commentId: string,
    queryParams?: ProviderPostCommentListQueryParams,
  ): Observable<ApiSuccessResponse<ProviderPostComment[]>> {
    const params = new HttpParams({
      fromObject: {
        ...queryParams,
      },
    });

    return this.apiService.get(`stores/${storeId}/socials/posts/${postId}/comments/${commentId}/replies`, params).pipe(
      map((res: ApiSuccessResponse<ProviderPostComment[]>) => ({
        ...res,
        result: res.result.map((item) => this.providerPostCommentAdapter.clientAdapt(item)),
      })),
    );
  }

  /**
   * Create reply for a specific comment
   * @param storeId the store id
   * @param postId the internal post id
   * @param commentId the internal comment id
   * @param body content of comment
   */
  createPostCommentReply(
    storeId: string,
    postId: string,
    commentId: string,
    body: { text: string },
  ): Observable<ApiSuccessResponse<ProviderPostComment>> {
    return this.apiService.post(`stores/${storeId}/socials/posts/${postId}/comments/${commentId}/replies`, body).pipe(
      map((res: ApiSuccessResponse<ProviderPostComment>) => ({
        ...res,
        result: this.providerPostCommentAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Update existing reply for a specific comment
   * @param storeId the store id
   * @param postId the internal post id
   * @param commentId the internal comment id
   * @param replyId the internal comment id
   * @param body content of comment
   */
  updatePostCommentReply(
    storeId: string,
    postId: string,
    commentId: string,
    replyId: string,
    body: { text: string },
  ): Observable<ApiSuccessResponse<ProviderPostComment>> {
    return this.apiService
      .put(`stores/${storeId}/socials/posts/${postId}/comments/${commentId}/replies/${replyId}`, body)
      .pipe(
        map((res: ApiSuccessResponse<ProviderPostComment>) => ({
          ...res,
          result: this.providerPostCommentAdapter.clientAdapt(res.result),
        })),
      );
  }

  /**
   * Delete existing reply for a specific comment
   * @param storeId the store id
   * @param postId the internal post id
   * @param commentId the internal comment id
   * @param replyId the internal comment id
   */
  deletePostCommentReply(
    storeId: string,
    postId: string,
    commentId: string,
    replyId: string,
  ): Observable<ApiSuccessResponse<ProviderPostComment>> {
    return this.apiService
      .delete(`stores/${storeId}/socials/posts/${postId}/comments/${commentId}/replies/${replyId}`)
      .pipe(
        map((res: ApiSuccessResponse<ProviderPostComment>) => ({
          ...res,
          result: this.providerPostCommentAdapter.clientAdapt(res.result),
        })),
      );
  }

  /**
   * Fetches a list of the medias from the account
   * @param storeId the store id
   * @param providerName provider full name
   */
  getMedia(storeId: string, providerName: string): Observable<AccountMedia[]> {
    return this.apiService
      .get(`stores/${storeId}/socials/providers/${providerName}/account/medias`)
      .pipe(
        map((res: ApiSuccessResponse<AccountMedia[]>) =>
          res.result.map((item) => this.accountMediaAdapter.clientAdapt(item)),
        ),
      );
  }
}
