import { Component, OnInit, Renderer2 } from '@angular/core';
import { GoalSettingDialogService } from '@core/dialogs/goal-setting-workflow/services/goal-setting-dialog.service';
import { GoalSettingService } from '@core/dialogs/goal-setting-workflow/services/goal-setting.service';
import { catchError, first } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Goals, RecommendedProduct } from '@core/dialogs/goal-setting-workflow/types/goal-setting.types';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { storeSetupOutlet } from '@core/dialogs/store-setup-workflow/variables/outlet.variable';
import { StoreSetupRoute } from '@core/dialogs/store-setup-workflow/enums/store-setup-route.enum';
import { Router } from '@angular/router';
import { goalSettingOutlet } from '@core/dialogs/goal-setting-workflow/variables/outlet.variable';
import { StoreSetupWorkflowComponent } from '@core/dialogs/store-setup-workflow/store-setup-workflow.component';
import { DialogService } from '@core/services/dialog.service';
import { GoalSettingRoute } from '../../enums/goal-setting-route.enum';

@Component({
  selector: 'core-goal-selection',
  templateUrl: './goal-selection.component.html',
  styleUrls: ['./goal-selection.component.scss'],
})
export class GoalSelection implements OnInit {
  maxNumberOfGoals = 4;

  actionInProgress = false;

  loading = true;

  goals: Goals = [];

  selectedGoals: string[] = [];

  showNoGoalsSelectedError = false;

  constructor(
    private goalSettingDialogService: GoalSettingDialogService,
    private goalSettingService: GoalSettingService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.goals = JSON.parse(sessionStorage.getItem('Goals') || '[]');
    if (this.goals.length) {
      this.selectedGoals = JSON.parse(sessionStorage.getItem('SelectedGoals') || '[]');
      this.selectedGoals.forEach((selectedGoal) => {
        this.goals.forEach((goal) => {
          if (selectedGoal === goal.key) {
            goal.value = true; // eslint-disable-line no-param-reassign
          }
        });
      });
      this.loading = false;
    } else {
      this.fetchGoals();
    }
  }

  handleRequestErrors() {
    this.loading = false;
    this.actionInProgress = false;
    this.showErrorSnackbar('ERROR.REQUEST');

    return EMPTY;
  }

  showErrorSnackbar(showErrorMessage: string) {
    this.snackBarService.openSnackBar({
      variant: 'default',
      icon: 'warning',
      text: this.translateService.instant(showErrorMessage),
      duration: 10000,
      dismissable: true,
    });
  }

  fetchGoals() {
    this.goalSettingService
      .getGoals()
      .pipe(
        first(),
        catchError(() => this.handleRequestErrors()),
      )
      .subscribe((res) => {
        this.goals = res;
        sessionStorage.setItem('Goals', JSON.stringify(res));
        this.loading = false;
      });
  }

  submitGoals() {
    this.actionInProgress = true;
    this.goalSettingService
      .setGoals({ selectedGoals: this.selectedGoals })
      .pipe(
        first(),
        catchError(() => this.handleRequestErrors()),
      )
      .subscribe((products: RecommendedProduct[]) => {
        this.actionInProgress = false;
        this.goalSettingDialogService.navigateToDialogRoute(GoalSettingRoute.ProductSelection, { products });
      });
  }

  goalChangeHandler(goalKey: string) {
    if (this.isSelected(goalKey)) {
      this.selectedGoals = this.selectedGoals.filter((keys) => keys !== goalKey);
    } else {
      this.showNoGoalsSelectedError = false;
      this.selectedGoals.push(goalKey);
    }
  }

  isSelected(goalKey: string) {
    return Boolean(this.selectedGoals.find((key) => key === goalKey));
  }

  onSubmit(): void {
    if (this.selectedGoals.length === 0) {
      this.showNoGoalsSelectedError = true;

      return;
    }

    if (this.selectedGoals.length > 0 && this.selectedGoals.length <= this.maxNumberOfGoals) {
      sessionStorage.setItem('SelectedGoals', JSON.stringify(this.selectedGoals));
      this.submitGoals();
    }
  }

  goBack() {
    const navigation = [
      {
        outlets: {
          [goalSettingOutlet]: null,
          [storeSetupOutlet]: StoreSetupRoute.Form,
        },
      },
    ];

    this.dialogService.closeDialog();

    this.router.navigate(navigation, { queryParamsHandling: 'merge' }).then(() => {
      this.dialogService.openCoreDialog(StoreSetupWorkflowComponent, this.renderer, { disableClose: true });
    });
  }
}
