import { ApiService } from '@core/services/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { OrderCardsQueryParams } from '../interfaces/query-params.interface';

@Injectable({
  providedIn: 'root',
})
export class ReviewCardService {
  constructor(private apiService: ApiService) {}

  order(body: OrderCardsQueryParams): Observable<ApiSuccessResponse<any>> {
    return this.apiService.post('tags/order', body);
  }
}
