import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ImageService, ImgixUrlQueryParams } from '@core/services/image.service';
import { Nullable } from '@core/types/nullable.type';
import { ImageUrlOptions, ImageFit } from '../../types/image.type';
import { CrossOrigin, MediaSrcVariant } from '../../types/media.type';

@Component({
  selector: 'core-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  host: {
    '[class]': '"image-fit--" + fit',
  },
})
export class ImageComponent implements OnChanges {
  imageSrc?: SafeResourceUrl | string;

  @Input()
  variant: MediaSrcVariant = 'url';

  @Input()
  imageUrlOptions: ImageUrlOptions = {};

  @Input()
  src = '';

  @Input()
  fit: ImageFit = 'contain';

  @Input()
  crossOrigin: Nullable<CrossOrigin> = null;

  @Output()
  loaded = new EventEmitter();

  @Output()
  error = new EventEmitter();

  constructor(
    private imageService: ImageService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src || changes.variant || changes.imageUrlOptions) {
      if (this.src && this.variant && this.variant !== 'asset' && this.variant !== 'blob') {
        const options = <ImgixUrlQueryParams>{
          ...this.imageUrlOptions,
          fit: 'crop',
        };
        this.imageSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.imageService.getURL(this.src as string, options),
        );

        return;
      }
      this.imageSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.src!);
    }
  }
}
