export const CORE_ROUTE = {
  presale: 'presale',
  presaleConnectionSetup: 'presale-setup',
};

export const INTEGRATION_ROUTE = {
  appPrefix: 'integrations',
  pages: {
    providers: 'providers',
    provider: (providerId = ':providerId') => `providers/${providerId}`,
  },
};
