import { socialOutlet } from '@social/modules/social-workflow/variables/outlet.variable';
import { StorageService } from '@core/services/storage.service';
import { Component, Inject } from '@angular/core';
import { IG_CONNECTION_DIALOG_SKIPPED } from '@social/common/variables/storage.variable';

import { ALPHIN, INSTAGRAM } from '@core/ui/platform/variables/icons-active.variable';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '@core/services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@core/models/store.model';

@Component({
  selector: 'social-recommend-connection-dialog',
  templateUrl: './recommend-connection-dialog.component.html',
  styleUrls: ['./recommend-connection-dialog.component.scss'],
})
export class RecommendConnectionDialogComponent {
  alphinUrl = ALPHIN;

  instagramUrl = INSTAGRAM;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      store: Store;
      onCtaClick: () => void;
      onSkip: () => void;
    },
  ) {}

  connect(): void {
    this.dialogService.closeDialog();
    this.router
      .navigate([{ outlets: { [socialOutlet]: null } }], { queryParams: this.activatedRoute.snapshot.queryParams })
      .then(() => {
        this.data.onCtaClick?.();
      });
  }

  skip(): void {
    this.dialogService.closeDialog();
    this.router
      .navigate([{ outlets: { [socialOutlet]: null } }], { queryParams: this.activatedRoute.snapshot.queryParams })
      .then(() => {
        const storageValue = this.storageService.getSessionItem(IG_CONNECTION_DIALOG_SKIPPED) || {};
        this.storageService.setSessionItem(IG_CONNECTION_DIALOG_SKIPPED, {
          ...storageValue,
          [this.data?.store?._id]: true,
        });
        this.data.onSkip?.();
      });
  }
}
