import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { ButtonComponent } from './components/button/button.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ResponsiveCtaComponent } from './components/responsive-cta/responsive-cta.component';
import { SpinnerModule } from '../progress/components/spinner/spinner.module';

@NgModule({
  declarations: [ButtonComponent, IconButtonComponent, FormActionsComponent, ResponsiveCtaComponent],
  imports: [CommonModule, SvgModule, MatRippleModule, TranslateModule, SpinnerModule],
  exports: [ButtonComponent, IconButtonComponent, FormActionsComponent, ResponsiveCtaComponent],
})
export class ButtonModule {}
