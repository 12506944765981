import {
  getLoadListState,
  getLoadListSuccessState,
  getLoadListFailState,
  getLoadObjectState,
  getLoadObjectSuccessState,
  getLoadObjectFailState,
  getCreateObjectState,
  getCreateObjectSuccessState,
  getCreateObjectFailState,
  getUpdateObjectState,
  getUpdateObjectSuccessState,
  getUpdateObjectFailState,
  getDeleteObjectState,
  getDeleteObjectSuccessState,
  getDeleteObjectFailState,
  CrudEntityState,
  crudInitialState,
} from '@core/redux/crud/crud.reducer';
import { AppState } from '@core/interfaces/app-state.interface';
import { Store } from '@core/models/store.model';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { StoreActionTypes, StoreAction } from './company-store.action';

export const companyStoreAdapter: EntityAdapter<Store> = createEntityAdapter<Store>({
  selectId: (companyStore) => companyStore._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export type StoreState = CrudEntityState<Store>;

export function companyStoreReducer(
  state: StoreState = companyStoreAdapter.getInitialState(crudInitialState),
  action: StoreAction,
): StoreState {
  switch (action.type) {
    /**********************************************/
    // Load company store list
    /*********************************************/
    case StoreActionTypes.LoadStoreList:
      return getLoadListState(state, action);

    case StoreActionTypes.LoadStoreListSuccess:
      return companyStoreAdapter.upsertMany(action.payload, getLoadListSuccessState(state, action));

    case StoreActionTypes.LoadStoreListFail:
      return getLoadListFailState(state, action);

    /**********************************************/
    // Load company store
    /*********************************************/
    case StoreActionTypes.LoadStore:
      return getLoadObjectState(state);

    case StoreActionTypes.LoadStoreSuccess:
      return companyStoreAdapter.upsertOne(action.payload, getLoadObjectSuccessState(state));

    case StoreActionTypes.LoadStoreFail:
      return getLoadObjectFailState(state);

    /**********************************************/
    // Create company store
    /*********************************************/
    case StoreActionTypes.CreateStore:
      return getCreateObjectState(state);

    case StoreActionTypes.CreateStoreSuccess:
      return companyStoreAdapter.addOne(action.payload, getCreateObjectSuccessState(state));

    case StoreActionTypes.CreateStoreFail:
      return getCreateObjectFailState(state);

    /**********************************************/
    // Update company store
    /*********************************************/
    case StoreActionTypes.UpdateStore:
      return getUpdateObjectState(state);

    case StoreActionTypes.UpdateStoreSuccess:
      return companyStoreAdapter.upsertOne(action.payload, getUpdateObjectSuccessState(state));

    case StoreActionTypes.UpdateStoreFail:
      return getUpdateObjectFailState(state);

    /**********************************************/
    // Delete company store
    /*********************************************/
    case StoreActionTypes.DeleteStore:
      return getDeleteObjectState(state);

    case StoreActionTypes.DeleteStoreSuccess:
      return companyStoreAdapter.removeOne(action.id, getDeleteObjectSuccessState(state));

    case StoreActionTypes.DeleteStoreFail:
      return getDeleteObjectFailState(state);

    default:
      return state;
  }
}

/**
 * Return company store state from the app state.
 */
export const getStoreState = (state: AppState) => state.companyStores;

export const {
  selectAll: selectAllStores,
  selectIds: selectStoreIds,
  selectEntities: selectStoreEntities,
} = companyStoreAdapter.getSelectors(getStoreState);
