
<div class="account d-flex flex-column flex-fill">
  <div class="row mx-0">
    <div *ngIf="visibleColumns.name" class="p-2 d-flex align-items-center" [ngClass]="nameColumnClass">
      <ng-container
        *ngTemplateOutlet="nameTemplate; context: { account: account }">
      </ng-container>
    </div>

    <div *ngIf="visibleColumns.rating || visibleColumns.followers" class="p-2 d-flex align-items-center" [ngClass]="metricColumnClass">
      <div class="row flex-fill mx-n2">
        <div *ngIf="visibleColumns.rating" class="px-2 py-1 d-flex align-items-center" [ngClass]="metricInnerColumnClass">
          <ng-container
            *ngTemplateOutlet="ratingTemplate; context: { account: account }">
          </ng-container>
        </div>

        <div *ngIf="visibleColumns.followers" class="px-2 py-1 d-flex align-items-center" [ngClass]="metricInnerColumnClass">
          <ng-container
            *ngTemplateOutlet="followersTemplate; context: { account: account }">
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="visibleColumns.location" class="p-2 d-flex align-items-center" [ngClass]="locationColumnClass">
      <ng-container
        *ngTemplateOutlet="locationTemplate; context: { account: account }">
      </ng-container>
    </div>
  </div>
</div>

<ng-template #nameTemplate let-account="account">
  <core-avatar [src]="account.profilePicUrl" size="md" variant="store" class="mr-3" badgePosition="bottom-left">
    <core-platform-avatar size="xxs" class="account__platform-badge" [url]="icons[account?.provider]"></core-platform-avatar>
  </core-avatar>
  <div class="account__column mr-auto">
    <div class="account__name d-flex align-items-center">
      <div class="truncate-text">{{ account?.name }}</div>
    </div>
    <div class="account__username" *ngIf="account?.username">{{ account?.username }}</div>
    <a class="account__label truncate-text d-flex align-items-center" *ngIf="account?.url" [href]="account?.url" target="_blank">
      <div class="truncate-text mr-1">{{ account?.providerId }}</div>
      <core-icon size="xxs" icon="open"></core-icon>
    </a>
  </div>
  <div *ngIf="bestFit" class="ml-3 mr-auto flex-grow-0">
    <core-tag-small variant="emphasis">
      {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.BEST_FIT' | translate }}
    </core-tag-small>
  </div>
</ng-template>

<ng-template #ratingTemplate let-account="account">
  <div class="account__column">
    <ng-container *ngIf="account?.ratings?.total; else noRatings">
      <core-star-rating [rating]="account?.ratings?.average" size="sm" [maxStars]="starCount"></core-star-rating>
      <div class="account__label">
        <core-kpi-value
          [value]="account?.ratings?.total"
          [abbreviateValue]="true"
          [unit]="'INTEGRATION_DIALOG.METRIC.RATINGS' | pluralFormat: account?.ratings?.total | translate">
        </core-kpi-value>
      </div>
    </ng-container>
    <ng-template #noRatings>
      <div class="account__column">
        <core-star-rating [rating]="0" size="sm" [showNumber]="false" [maxStars]="starCount" class="mr-2"></core-star-rating>
        <div class="account__label">{{ 'INTEGRATION_DIALOG.NOT_AVAILABLE.RATINGS' | translate }}</div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #followersTemplate let-account="account">
  <div class="account__column">
    <ng-container *ngIf="account?.socialMetrics?.followers; else noFollowers">
      <div class="account__kpi d-flex align-items-center">
        <core-kpi-value [value]="account?.socialMetrics?.followers" [abbreviateValue]="true"></core-kpi-value>
        <core-icon size="xs" icon="group" class="ml-1" [style.fill]="'rgb(var(--primary1))'"></core-icon>
      </div>
      <div class="account__label">
        {{ 'INTEGRATION_DIALOG.METRIC.FOLLOWERS' | pluralFormat: account?.socialMetrics?.followers | translate }}
      </div>
    </ng-container>
    <ng-template #noFollowers>
      <div class="account__column">
        <div [style.margin-bottom.px]="2" class="mr-2">
          <core-icon size="xs" icon="group" [style.fill]="'rgb(var(--secondary2))'"></core-icon>
        </div>
        <div class="account__label">{{ 'INTEGRATION_DIALOG.NOT_AVAILABLE.FOLLOWERS' | translate }}</div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #locationTemplate let-account="account">
  <div class="d-flex align-items-center justify-content-center" class="mr-2">
    <core-svg [style.fill]="account?.location ? 'rgb(var(--secondary3))' : 'rgb(var(--secondary2))'" svg="store_pin" [style.width.px]="24"></core-svg>
  </div>
  <div class="account__column flex-fill" *ngIf="account?.location; else noLocation">
    <div class="account__location">
      <ng-container *ngIf="account?.location?.street">{{ account?.location?.street }}</ng-container>
    </div>
    <div class="account__location">
      <ng-container *ngIf="account?.location?.zip">{{ account?.location?.zip }}&nbsp;</ng-container>
      <ng-container *ngIf="account?.location?.city">{{ account?.location?.city }}</ng-container>
    </div>
  </div>
  <core-account-state-reason *ngIf="!account?.state?.selectable" class="ml-2" [account]="account"></core-account-state-reason>
  <ng-template #noLocation>
    <div class="account__column flex-fill">
      <div class="account__label">{{ 'INTEGRATION_DIALOG.NOT_AVAILABLE.LOCATION' | translate }}</div>
    </div>
  </ng-template>
</ng-template>
