import { Injectable } from '@angular/core';
import { ClientViewDataAdapter } from '@core/interfaces/adapter.interface';
import { AuthToken, TokenPayload } from '../interfaces/auth-token.interface';
import { AuthMethod } from '../enums/auth-method.enum';

/**
 * Frontend model for auth token document
 */
export class AuthTokenDocument implements AuthToken {
  readonly _id: string;

  readonly createdAt: string;

  readonly updatedAt: string;

  userId: string;

  authMethod: AuthMethod;

  tokenId: string;

  tokenValue: string;

  tokenPayload: TokenPayload;

  lastUsedAt: Date;

  constructor(object: AuthTokenDocument) {
    this._id = object._id;
    this.createdAt = object.createdAt;
    this.updatedAt = object.updatedAt;
    this.userId = object.userId;
    this.authMethod = object.authMethod;
    this.tokenId = object.tokenId;
    this.tokenValue = object.tokenValue;
    this.tokenPayload = object.tokenPayload;
    this.lastUsedAt = object.lastUsedAt;
  }
}

/**
 * Adapter for the auth token document
 */
@Injectable({
  providedIn: 'root',
})
export class AuthTokenDocumentAdapter implements ClientViewDataAdapter<AuthTokenDocument> {
  clientAdapt(object: any): AuthTokenDocument {
    return new AuthTokenDocument(object);
  }
}
