import { opacity } from '@core/variables/layout.variable';
import { CartesianScaleOptions, GridLineOptions, LegendOptions } from 'chart.js';
import { BrandChartConfig } from '@core/interfaces/brand-chart-config.interface';
import { colors } from '@core/variables/colors.variable';
import _merge from 'lodash/merge';

/**
 * Define color map
 */
const chartColors = {
  audience: {
    male: colors.yellow.base,
    female: colors.mint.base,
    unknown: colors.electric.base,
  },
  ticks: colors.slate.light,
  gridLines: opacity(colors.black.base, 0.15),
  background: colors.white.dark,
  datasets: [
    colors.indigo.base,
    colors.electric.base,
    colors.mint.base,
    colors.indigo.dark,
    colors.blue.light,
    colors.yellow.base,
    colors.orange.base,
    colors.silver.base,
    colors.flush.light,
  ],
};

/**
 * Grid line options
 */
const gridLineOptionsX: Partial<GridLineOptions> = {
  display: true,
  drawOnChartArea: false,
  drawBorder: false,
  tickLength: 12,
  tickBorderDash: [2, 1],
  color: 'transparent',
};

const gridLineOptionsY: Partial<GridLineOptions> = {
  tickLength: 0,
  display: true,
  drawTicks: false,
  drawBorder: false,
  lineWidth: 1,
  borderDash: [2, 1],
  color: (ctx) => {
    if (ctx.index === 0 || !ctx.tick.label) {
      return 'transparent';
    }

    return chartColors.gridLines;
  },
};

/**
 * Tick options
 */
const tickOptionsX: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  padding: 4,
  maxRotation: 0,
  minRotation: 0,
  color: (ctx: any) => {
    if (!ctx.tick.label) {
      return 'transparent';
    }

    return chartColors.ticks;
  },
  display: true,
};

const tickOptionsY: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  includeBounds: false,
  padding: 4,
  labelOffset: -8,
  mirror: true,
  color: chartColors.ticks,
  display: true,
  maxTicksLimit: 5,
};

/**
 * Chart config
 */
const chartConfig: BrandChartConfig = {
  general: {
    colors: {
      audience: chartColors.audience,
      datasets: chartColors.datasets,
    },
    pluginOptions: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          font: { size: 12 },
          color: chartColors.ticks,
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 16,
        } as LegendOptions<any>['labels'],
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  bar: {
    pluginOptions: {
      chartBackground: {
        backgroundColor: chartColors.background,
        borderRadius: 8,
      },
    },
  },
  doughnut: {
    chartOptions: {
      radius: '85%',
    },
    pluginOptions: {
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
  },
  line: {
    datasetOptions: {
      fill: true,
      fillGradientOpacity: [
        { stop: 0, opacity: 0.08, fillColor: 'line' },
        { stop: 0.2, opacity: 0.12, fillColor: 'line' },
        { stop: 1, opacity: 1, fillColor: 'background' },
      ],
      tension: 0.2,
      borderWidth: 2,
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        showAxisTitle: true,
        options: {
          grid: gridLineOptionsY,
          ticks: _merge(tickOptionsY, { precision: 0 }),
        },
      },
    },
    pluginOptions: {
      chartBackground: {
        backgroundColor: chartColors.background,
        borderRadius: 8,
      },
    },
  },
  horizontalBar: {
    barColors: {
      filled: colors.electric.base,
      unfilled: colors.white.dark,
    },
    labelColors: {
      filled: colors.white.base,
      unfilled: colors.blue.base,
    },
    barThickness: 40,
    borderRadius: 4,
  },
  gauge: {
    colors: {
      low: colors.flush.dark,
      medium: colors.yellow.light,
      high: colors.mint.base,
      needle: colors.navy.base,
      unfilled: colors.silver.light,
    },
  },
};

export default chartConfig;
