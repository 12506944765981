@if (avatarSrc) {
  <core-avatar [src]="avatarSrc" size="xs" variant="store" (avatarError)="avatarSrc = ''"></core-avatar>
} @else {
  @if (icon) {
    <core-icon [icon]="icon" size="xs"> </core-icon>
  }
}
<div class="chip-label" coreChipAction [isInteractive]="false">
  @if (title) {
    <div class="d-flex flex-column">
      <div class="chip-label__title" [style.opacity]="titleOpacity">{{ title }}</div>
      <div class="chip-label__text">
        <ng-content></ng-content>
      </div>
    </div>
  } @else {
    <ng-content></ng-content>
  }
</div>

@if (removeIcon) {
  <ng-content select="[coreChipRemove]"></ng-content>
}
