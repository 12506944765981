import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { MediaItemComponent } from './components/media-item/media-item.component';
import { MediaCarouselComponent } from './components/media-carousel/media-carousel.component';
import { VideoComponent } from './components/video/video.component';
import { VideoThumbnailComponent } from './components/video-thumbnail/video-thumbnail.component';
import { ImageComponent } from './components/image/image.component';
import { CardModule } from '../card/card.module';
import { SvgModule } from '../svg/svg.module';
import { EmptyStateModule } from '../empty-state/empty-state.module';
import { SpinnerModule } from '../progress/components/spinner/spinner.module';

@NgModule({
  declarations: [MediaItemComponent, MediaCarouselComponent, VideoComponent, VideoThumbnailComponent, ImageComponent],
  imports: [CommonModule, CardModule, SvgModule, EmptyStateModule, TranslateModule, SwiperModule, SpinnerModule],
  exports: [MediaItemComponent, MediaCarouselComponent, VideoComponent, VideoThumbnailComponent, ImageComponent],
})
export class MediaModule {}
