import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientViewDataAdapter } from '@core/interfaces/adapter.interface';
import { ErrorObject } from '@core/interfaces/error-object.interface';

/**
 * Frontend model for API error responses
 */
export class ApiErrorResponse {
  readonly code?: number;

  readonly status: string;

  readonly error: ErrorObject;

  readonly response: HttpErrorResponse;

  constructor(object: ApiErrorResponse) {
    this.code = object.code;
    this.status = object.status;
    this.error = object.error;
    this.response = object.response;
  }
}

/**
 * Adapter for API error responses
 */
@Injectable({
  providedIn: 'root',
})
export class ApiErrorResponseAdapter implements ClientViewDataAdapter<ApiErrorResponse> {
  clientAdapt(object: any): ApiErrorResponse {
    return new ApiErrorResponse(object);
  }
}
