import { Injectable, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthType } from '@core/enums/provider.enum';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationWorkflowComponent } from '@core/dialogs/integration-workflow/integration-workflow.component';
import { integrationOutlet } from '@core/dialogs/integration-workflow/variables/outlet.variable';
import { DialogService } from '@core/services/dialog.service';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { first } from 'rxjs/operators';
import { IntegrationDataService } from '@core/dialogs/integration-workflow/services/integration-data.service';
import { AppTypeConfigService } from '@core/services/app-type-config.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { INTEGRATION_ROUTE } from '@core/variables/routing.variable';

/**
 * Dialog service for Integrations
 */
@Injectable()
export class IntegrationDialogService {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private themeService: ThemeService,
    private dialogService: DialogService,
    private integrationDataService: IntegrationDataService,
    private appTypeConfigService: AppTypeConfigService,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
  ) {}

  getConnectIllustration(provider: string): string {
    const lang = this.translateService.currentLang || 'de';
    const theme = this.themeService.current || 'light';
    if (provider !== 'GOOGLE') {
      return 'img_connect_provider';
    }

    return `google_permissions_error_${theme}_${lang}`;
  }

  closeDialog(): void {
    this.router.navigate([{ outlets: { [integrationOutlet]: null } }], {
      queryParamsHandling: 'preserve',
    });
    this.dialogService.closeDialog();
  }

  navigateToDialogRoute(workflow: AuthType, provider: string, screenName?: string): void {
    this.router.navigate(
      [
        {
          outlets: {
            [integrationOutlet]: `${workflow}/${provider}${screenName ? `/${screenName}` : ''}`,
          },
        },
      ],
      { queryParamsHandling: 'preserve' },
    );
  }

  openDialog(
    renderer: Renderer2,
    {
      workflow,
      provider,
      screenName,
    }: {
      workflow: AuthType;
      provider: string;
      screenName?: string;
    },
    data: Params,
  ): void {
    this.navigateToDialogRoute(workflow, provider, screenName);
    this.dialogService.openCoreDialog(IntegrationWorkflowComponent, renderer, {
      disableClose: true,
      data: {
        integration: data?.integration,
        integrationId: data?._id,
        provider,
        queryParamsString: (data?.queryParamsString || '').replace('?', '&'),
        onConnect: data?.onConnect,
      },
    });
  }

  openProviderConnection(
    renderer: Renderer2,
    storeId: string,
    provider: ManagedPostProvider,
    onConnect = this.showConnectionSuccess,
    urlOptions?: {
      prefix: string;
      path: string;
    },
  ): void {
    this.integrationDataService
      .getIntegration(provider, storeId)
      .pipe(first())
      .subscribe((integration) => {
        const authType = integration?.authenticationMethod.type as AuthType;
        let { prefix, path } = urlOptions || {};

        if (!prefix && !path) {
          prefix = this.appTypeConfigService.getPathPrefix(`/${INTEGRATION_ROUTE.appPrefix}/`, false);
          path = `${INTEGRATION_ROUTE.pages.provider(integration?._id)}`;
        }

        const basePath = [`${prefix}${path}`, integrationOutlet, authType, integration?.provider].join('/');

        const queryParamsString = this.appTypeConfigService.getQueryParamsString({
          prompt: 'consent',
          success_url: this.appTypeConfigService.createRedirectUrl(
            `${basePath}/connect`,
            this.activatedRoute.snapshot.queryParams,
            true,
          ),
          failure_url: this.appTypeConfigService.createRedirectUrl(
            `${basePath}/empty`,
            this.activatedRoute.snapshot.queryParams,
            true,
          ),
        });

        const [url] = this.router.url.split('?') || [];
        // First navigate to the dialog route
        this.navigateToDialogRoute(authType, integration._id);
        const dialogPath = url.replace(/.*\(|\).*/g, '') || '';
        const pathSegments = this.appTypeConfigService.includesOutlet(integrationOutlet)
          ? dialogPath.replace(`${integrationOutlet}:`, '').split('/')
          : undefined;

        // Open the dialog with the correct data
        this.openDialog(
          renderer,
          {
            workflow: (pathSegments?.[0] as AuthType) || authType,
            provider: pathSegments?.[1] || integration?.provider!,
            screenName: pathSegments?.[2],
          },
          {
            integration,
            _id: integration?._id,
            queryParamsString,
            provider: integration?.provider!,
            onConnect,
          },
        );
      });
  }

  private showConnectionSuccess = (): void => {
    this.snackBarService.openSnackBar({
      icon: 'check_circle',
      text: this.translateService.instant('IAM.SNACKBAR.INSTAGRAM_CONNECTED'),
    });
  };
}
