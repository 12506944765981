import { Inject, Injectable } from '@angular/core';
import { SUPPORT_EVENT } from '@core/variables/webview-events.variable';
import { MessageEventService } from './message-event.service';
import { WindowWrapperService } from './window-wrapper.service';

/**
 * Service for handling Intercom
 */
@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  isWebView = this.window.ReactNativeWebView;

  constructor(
    private messageEventService: MessageEventService,
    @Inject(WindowWrapperService) private window: WindowWrapperService,
  ) {}

  get available() {
    return this.window?.Intercom || this.window?.ReactNativeWebView;
  }

  show(): void {
    if (this.window.ReactNativeWebView) {
      this.messageEventService.postMessageToWebView(SUPPORT_EVENT.clicked);
    } else if (this.available) {
      this.window?.Intercom?.('show');
    }
  }

  /**
   * Toggles the floating action button in the app
   *
   * Typically used to hide the button when displaying a dialog.
   * Not applicable to web views
   */
  updateFab(visibility: boolean): void {
    if (!this.window.Intercom) {
      return;
    }
    this.window?.Intercom?.('update', {
      hide_default_launcher: !visibility,
    });
  }
}
