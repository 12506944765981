import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { assetsBasePath } from '@core/variables/asset-paths.variable';
import { StepperStatus } from '../../types/stepper-progress.type';

@Component({
  selector: 'core-stepper-progress',
  templateUrl: './stepper-progress.component.html',
  styleUrls: ['./stepper-progress.component.scss'],
})
export class StepperProgressComponent implements OnInit, OnChanges {
  /**
   * How far along the progress is, i.e. whether it's just started, ongoing or finished.
   */
  @Input() status: StepperStatus = 'processing';

  /**
   * Whether the progress indicator should show the next or previous step
   */
  @Input() showAdjacentStep = true;

  @Input() title!: string;

  @Input() description?: string;

  imageUrl?: SafeUrl;

  imageType?: string;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setImageUrl();
  }

  ngOnChanges(): void {
    this.setImageUrl();
  }

  private setImageUrl(): void {
    this.imageType = this.status || 'processing';
    if (this.status !== 'start' && !this.showAdjacentStep) {
      this.imageType = `${this.status || 'processing'}_short`;
    }
    this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(
      `${assetsBasePath}/svg/progress/progress_${this.imageType}.svg`,
    );
  }
}
