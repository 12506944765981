import { Nullable } from '@core/types/nullable.type';
import { Component, Input, OnChanges } from '@angular/core';
import { AccountDetails } from '@core/interfaces/integration.interface';

@Component({
  selector: 'core-account-state-reason',
  templateUrl: './account-state-reason.component.html',
  styleUrls: ['./account-state-reason.component.scss'],
})
export class AccountStateReasonComponent implements OnChanges {
  @Input()
  account: Nullable<AccountDetails> = null;

  icon = 'info';

  variant = 'secondary';

  ngOnChanges(): void {
    this.initStateConfig(this.account?.state.reason?.[0] ?? null);
  }

  initStateConfig(reason: Nullable<string> = null) {
    switch (reason) {
      case 'DUPLICATE_LOCATION':
      case 'VERIFICATION_PENDING':
        this.icon = 'warning';
        this.variant = 'warn';
        break;
      case 'UNVERIFIED':
      case 'VERIFICATION_FAILED':
        this.icon = 'error';
        this.variant = 'error';
        break;
      default:
        this.icon = 'info';
        this.variant = 'default';
        break;
    }
  }
}
