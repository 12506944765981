import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SocialPost } from '@social/common/types/social-post.type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { socialOutlet } from '../../variables/outlet.variable';

@Component({
  selector: 'social-delete-post-confirmation-dialog',
  templateUrl: './delete-post-confirmation-dialog.component.html',
  styleUrls: ['./delete-post-confirmation-dialog.component.scss'],
})
export class DeletePostConfirmationDialogComponent {
  formGroup = new FormGroup({
    warningAccepted: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private dialogRef: MatDialogRef<{ post: SocialPost }>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { post: SocialPost },
  ) {}

  removeOutlet(deleted: boolean): void {
    this.router.navigate([{ outlets: { [socialOutlet]: null } }], { queryParamsHandling: 'merge' }).then(() => {
      this.dialogRef.close(deleted);
    });
  }
}
