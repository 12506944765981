import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Attribute,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { ButtonVariant } from '@core/ui/button/types/button.type';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-comment-field',
  templateUrl: './comment-field.component.html',
  styleUrls: ['./comment-field.component.scss'],
})
export class CommentFieldComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('textarea')
  textareaElement!: ElementRef<HTMLInputElement>;

  /**
   * The placeholder if the textarea is empty
   */
  @Input()
  placeholder = '';

  /**
   * The text area title
   */
  @Input()
  title = '';

  /**
   * Initial input field value
   */
  @Input()
  value = '';

  /**
   * If the field is disabled
   */
  @Input()
  disabled = false;

  /**
   * Whether or not the CTA should be disabled
   */
  @Input()
  actionInProgress = false;

  /**
   * Label of the submit button
   */
  @Input()
  ctaLabel?: string;

  /**
   * If the field should focus after initializing
   */
  @Input()
  focusOnInit = false;

  /**
   * Config for the CTA style
   */
  @Input()
  ctaConfig?: {
    variant?: ButtonVariant;
    icon?: string;
  };

  /**
   * Emits an event when the submit CTA is clicked
   */
  @Output()
  ctaClick = new EventEmitter();

  /**
   * Emits an event when the close button is clicked
   */
  @Output()
  close = new EventEmitter();

  commentForm: FormGroup;

  focused = false;

  tabIndex = 0;

  textfieldRows = 2;

  private destroyed$!: Subject<void>;

  constructor(
    private focusMonitor: FocusMonitor,
    @Attribute('tabindex') tabIndex: string,
  ) {
    this.destroyed$ = new Subject<void>();
    this.tabIndex = parseInt(tabIndex, 10) || 0;
    this.commentForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });

    this.commentForm.controls.comment.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      const valueLength = value?.length || 0;
      this.textfieldRows =
        window.innerWidth < 800
          ? Math.ceil((valueLength + window.innerHeight + 10) / 100)
          : Math.ceil(valueLength / 80);
      this.textfieldRows += (`${value}`.match(/\n/g) || []).length;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.commentForm.controls.comment.setValue(changes.value.currentValue);
      const valueLength = changes.value.currentValue?.length || 0;
      this.textfieldRows =
        window.innerWidth < 800
          ? Math.ceil((valueLength + window.innerHeight + 10) / 100)
          : Math.ceil(valueLength / 80);
      this.textfieldRows += (`${changes.value.currentValue}`.match(/\n/g) || []).length;
    }
    if (changes.disabled) {
      if (this.disabled) {
        this.commentForm.controls.comment.disable();
      } else {
        this.commentForm.controls.comment.enable();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      setTimeout(() => {
        this.focus({ preventScroll: false }, 'program');
      }, 0);
    }
  }

  onFocus(): void {
    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
  }

  focus(options?: FocusOptions, origin?: FocusOrigin): void {
    if (origin) {
      this.focusMonitor.focusVia(this.textareaElement, origin, options);
    } else {
      this.textareaElement.nativeElement.focus(options);
    }
  }

  submit(value: string): void {
    if (!value.trim()) {
      this.commentForm.controls.comment.setErrors({ required: true });
      this.commentForm.controls.comment.markAsDirty();

      return;
    }
    this.ctaClick.emit(value);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
