/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import {
  AfterContentInit,
  Directive,
  Input,
  Output,
  QueryList,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Nullable } from '@core/types/nullable.type';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { RadioContentAlignment, RadioLabelPosition, RadioLabelPadding, RadioVariant } from '../../types/radio.type';

let uniqueId = 0;

/**
 * Change event object emitted by radio groups and radio buttons
 */
export class RadioChangeEvent {
  constructor(
    /** The MatRadioButton that emits the change event. */
    public source: RadioButtonBaseDirective,
    /** The value of the MatRadioButton. */
    public value: any,
  ) {}
}

@Directive()
export abstract class RadioButtonBaseDirective implements OnInit, AfterViewInit, OnDestroy {
  private _uniqueId = `core-radio-button-${++uniqueId}`;

  /**
   * The unique ID for the radio button
   */
  @Input()
  id: string = this._uniqueId;

  /**
   * HTML 'name' attribute used to group radios for unique selection
   */
  @Input()
  name!: string;

  /**
   * Variant used for setting color scheme
   */
  @Input()
  variant: RadioVariant = 'emphasis';

  /**
   * Used to set the 'aria-label' attribute on the underlying input element
   */
  @Input('aria-label')
  ariaLabel?: string;

  /**
   * The 'aria-labelledby' attribute takes precedence as the element's text alternative
   */
  @Input('aria-labelledby')
  ariaLabelledby?: string;

  /**
   * The 'aria-describedby' attribute is read after the element's label and field type
   */
  @Input('aria-describedby')
  ariaDescribedby?: string;

  /**
   * Whether this radio button is checked
   */
  @Input()
  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    const newCheckedState = coerceBooleanProperty(value);
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState;
      if (newCheckedState && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (!newCheckedState && this.radioGroup && this.radioGroup.value === this.value) {
        // When unchecking the selected radio button, update the selected radio
        // property on the group.
        this.radioGroup.selected = null;
      }

      if (newCheckedState) {
        // Notify all radio buttons with the same name to uncheck.
        this.radioDispatcher.notify(this.id, this.name);
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * The value of this radio button
   */
  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup !== null) {
        if (!this.checked) {
          // Update checked when the value changed to match the radio group's value
          this.checked = this.radioGroup?.value === value;
        }
        if (this.checked) {
          this.radioGroup!.selected = this;
        }
      }
    }
  }

  /**
   * Whether the label should appear after or before the radio button
   */
  @Input()
  get labelPosition(): RadioLabelPosition {
    return this._labelPosition || (this.radioGroup && this.radioGroup.labelPosition) || 'after';
  }

  set labelPosition(value) {
    this._labelPosition = value;
  }

  private _labelPosition?: RadioLabelPosition;

  /**
   * The padding size on the label
   *
   * Adding the padding inside the label ensures that it is included in the click area
   * for changing the checked value
   */
  @Input()
  get labelPadding(): Nullable<RadioLabelPadding> {
    return this._labelPadding;
  }

  set labelPadding(value) {
    this._labelPadding = value;
    const classes: string[] = [];
    if (value instanceof Object) {
      Object.entries(value).forEach(([position, padding]) => {
        classes.push(`padding-${position}-${padding}`);
      });
    } else {
      classes.push(`padding-${value}`);
    }
    this.labelPaddingClassList = classes;
  }

  private _labelPadding: Nullable<RadioLabelPadding> = null;

  /**
   * Padding classes to be added to the label
   */
  labelPaddingClassList: string[] = [];

  /**
   * Whether longer labels should span over multiple lines or be truncated on the same line
   */
  @Input()
  multiLineLabel = false;

  /**
   * How the label content should be positioned in the row
   */
  @Input()
  contentAlignment: RadioContentAlignment = 'center';

  /**
   * Whether the radio button is disabled
   */
  @Input()
  get disabled(): boolean {
    return this._disabled || (this.radioGroup !== null && this.radioGroup.disabled);
  }

  set disabled(value: boolean) {
    this.setDisabled(coerceBooleanProperty(value));
  }

  /**
   * Whether the radio button is required
   */
  @Input()
  get required(): boolean {
    return this._required || (!!this.radioGroup && this.radioGroup.required);
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * Event emitted when the checked state of this radio button changes.
   * Change events are only emitted when the value changes due to user interaction with
   * the radio button
   */
  @Output()
  readonly change: EventEmitter<RadioChangeEvent> = new EventEmitter<RadioChangeEvent>();

  /**
   * The parent radio group. May or may not be present.
   */
  radioGroup: Nullable<any> = null; // Nullable<RadioGroupBaseDirective<RadioButtonBaseDirective>>

  /**
   * ID of the native input element
   */
  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  tabIndex = 0;

  /**
   * Whether this radio is checked
   */
  private _checked = false;

  /**
   * Whether this radio is disabled
   */
  private _disabled = false;

  /**
   * Whether this radio is required
   */
  private _required = false;

  /**
   * Value assigned to this radio
   */
  private _value: any = null;

  /**
   * Unregister function for radioDispatcher
   */
  private removeUniqueSelectionListener: () => void;

  /** The native `<input type=radio>` element */
  @ViewChild('input')
  inputElement!: ElementRef<HTMLInputElement>;

  constructor(
    radioGroup: any, // RadioGroupBaseDirective<RadioButtonBaseDirective>
    private elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef,
    private focusMonitor: FocusMonitor,
    private radioDispatcher: UniqueSelectionDispatcher,
  ) {
    this.radioGroup = radioGroup;

    this.removeUniqueSelectionListener = radioDispatcher.listen((id: string, name: string) => {
      if (id !== this.id && name === this.name) {
        this.checked = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.radioGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this._value;
      // Copy name from parent radio group
      this.name = this.radioGroup.name;
    }
  }

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef, true).subscribe((focusOrigin: FocusOrigin) => {
      if (!focusOrigin && this.radioGroup) {
        this.radioGroup.touch();
      }
    });
  }

  onLabelChange(): void {
    this.changeDetectorRef.detectChanges();
  }

  /** Focuses the radio button. */
  focus(options?: FocusOptions): void {
    this.focusMonitor.focusVia(this.inputElement, 'keyboard', options);
  }

  /**
   * Marks the radio button as needing checking for change detection.
   * This method is exposed because the parent radio group will directly
   * update bound properties of the radio button.
   */
  markForCheck(): void {
    // When group value changes, the button will not be notified. Use `markForCheck` to explicit
    // update radio button's status
    this.changeDetectorRef.markForCheck();
  }

  /** Dispatch change event with current value. */
  private emitChangeEvent(): void {
    this.change.emit(new RadioChangeEvent(this, this._value));
  }

  onInputClick(event: Event): void {
    // We have to stop propagation for click events on the visual hidden input element.
    // By default, when a user clicks on a label element, a generated click event will be
    // dispatched on the associated input element. Since we are using a label element as our
    // root container, the click event on the `radio-button` will be executed twice.
    // The real click event will bubble up, and the generated click event also tries to bubble up.
    // This will lead to multiple click events.
    // Preventing bubbling for the second event will solve that issue.
    event.stopPropagation();
  }

  /**
   * Triggered when the radio button received a click or the input recognized any change.
   * Clicking on a label element, will trigger a change event on the associated input.
   */
  onInputChange(event: Event): void {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();

    const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
    this.checked = true;
    this.emitChangeEvent();

    if (this.radioGroup) {
      this.radioGroup.controlValueAccessorChangeFn(this.value);
      if (groupValueChanged) {
        this.radioGroup.emitChangeEvent();
      }
    }
  }

  /** Sets the disabled state and marks for check if a change occurred. */
  protected setDisabled(value: boolean): void {
    if (this._disabled !== value) {
      this._disabled = value;
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
    this.removeUniqueSelectionListener?.();
  }
}

@Directive()
export abstract class RadioGroupBaseDirective<T extends RadioButtonBaseDirective>
  implements AfterContentInit, ControlValueAccessor
{
  /** Selected value for the radio group. */
  private _value: any = null;

  private _name = `core-radio-group-${uniqueId++}`;

  /** The currently selected radio button. Should match value. */
  private _selected: Nullable<T> = null;

  /** Whether the `value` has been set to its initial value. */
  private isInitialized = false;

  /** Whether the labels should appear after or before the radio-buttons. Defaults to 'after' */
  private _labelPosition: RadioLabelPosition = 'after';

  /** Whether the radio group is disabled. */
  private _disabled = false;

  /** Whether the radio group is required. */
  private _required = false;

  /** The method to be called in order to update ngModel */
  controlValueAccessorChangeFn: (value: any) => void = () => {};

  onTouched: () => any = () => {};

  /**
   * Event emitted when the group value changes.
   * Change events are only emitted when the value changes due to user interaction with
   * a radio button
   */
  @Output()
  readonly change: EventEmitter<RadioChangeEvent> = new EventEmitter<RadioChangeEvent>();

  /**
   * Child radio buttons
   */
  abstract _radios: QueryList<T>;

  /**
   * Name of the radio button group. All radio buttons inside this group will use this name
   */
  @Input()
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this.updateRadioButtonNames();
  }

  /**
   * Whether the labels should appear after or before the radio-button
   */
  @Input()
  get labelPosition(): 'before' | 'after' {
    return this._labelPosition;
  }

  set labelPosition(v) {
    this._labelPosition = v === 'before' ? 'before' : 'after';
    this.markRadiosForCheck();
  }

  /**
   * Value for the radio-group. Should equal the value of the selected radio button if there is
   * a corresponding radio button with a matching value. If there is not such a corresponding
   * radio button, this value persists to be applied in case a new radio button is added with a
   * matching value.
   */
  @Input()
  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue;

      this.updateSelectedRadioFromValue();
      this.checkSelectedRadioButton();
    }
  }

  checkSelectedRadioButton(): void {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  /**
   * The currently selected radio button
   */
  @Input()
  get selected() {
    return this._selected;
  }

  set selected(selected: T | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this.checkSelectedRadioButton();
  }

  /**
   * Whether the radio group is disabled
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this.markRadiosForCheck();
  }

  /**
   * Whether the radio group is required
   */
  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.markRadiosForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.isInitialized = true;
  }

  /**
   * Mark this group as being "touched"
   */
  touch(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach((radio) => {
        radio.name = this.name;
        radio.markForCheck();
      });
    }
  }

  /**
   * Updates the `selected` radio button from the internal _value state
   */
  private updateSelectedRadioFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected = this._selected !== null && this._selected?.value === this._value;

    if (this._radios && !isAlreadySelected) {
      this._selected = null;
      this._radios.forEach((radio) => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  /**
   * Dispatch change event with current selection and group value
   */
  emitChangeEvent(): void {
    if (this.isInitialized) {
      this.change.emit(new RadioChangeEvent(this._selected!, this._value));
    }
  }

  markRadiosForCheck(): void {
    if (this._radios) {
      this._radios.forEach((radio) => radio.markForCheck());
    }
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this.controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
