import { Component, Input } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { LinearProgressBarVariant, LinearProgressBarMode } from '@core/ui/progress/types/linear-progress-bar.variant';

@Component({
  selector: 'core-linear-progress-bar',
  templateUrl: './linear-progress-bar.component.html',
  styleUrls: ['./linear-progress-bar.component.scss'],
})
export class LinearProgressBarComponent {
  /**
   * Determinate for a fixed percentage value or indeterminate when the exact progress is unknown
   */
  @Input()
  mode: LinearProgressBarMode = 'determinate';

  /**
   * The color variant
   */
  @Input()
  variant: LinearProgressBarVariant = 'emphasis';

  /**
   * The unfilled bar color
   */
  @Input()
  unfilledBarColor?: Nullable<string> = null;

  /**
   * Applies custom color to the progress bar, only works when variant === 'custom'
   */
  @Input()
  customColor?: Nullable<string> = null;

  /**
   * Whether or not the bar should flash
   */
  @Input()
  flash = false;

  /**
   * The value of the percentage completed of the total.
   * It can be written as a decimal or as whole fraction which
   * will need to be divided by the input total to be output as a percentage
   */
  @Input()
  value = 0;

  /**
   * The value of the total from which will be divided the value of the percentage.
   * By default is set as one so the percentage can be expressed as decimal
   */
  @Input()
  total = 1;
}
