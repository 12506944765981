import { DialCodeOption } from '@core/interfaces/contact.interface';

export const dialCodeQuickOptions: DialCodeOption[] = [
  {
    name: {
      en: 'Germany',
      de: 'Deutschland',
    },
    flag: '🇩🇪',
    code: 'DE',
    dialCode: '+49',
  },
  {
    name: {
      en: 'Austria',
      de: 'Österreich',
    },
    flag: '🇦🇹',
    code: 'AT',
    dialCode: '+43',
  },
  {
    name: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich',
    },
    flag: '🇬🇧',
    code: 'GB',
    dialCode: '+44',
  },
];

export const dialCodeOptions: DialCodeOption[] = [
  {
    name: {
      en: 'Afghanistan',
      de: 'Afghanistan',
    },
    flag: '🇦🇫',
    code: 'AF',
    dialCode: '+93',
  },
  {
    name: {
      en: 'Åland Islands',
      de: 'Åland-Inseln',
    },
    flag: '🇦🇽',
    code: 'AX',
    dialCode: '+358',
  },
  {
    name: {
      en: 'Albania',
      de: 'Albania',
    },
    flag: '🇦🇱',
    code: 'AL',
    dialCode: '+355',
  },
  {
    name: {
      en: 'Algeria',
      de: 'Algerien',
    },
    flag: '🇩🇿',
    code: 'DZ',
    dialCode: '+213',
  },
  {
    name: {
      en: 'American Samoa',
      de: 'Amerikanisch-Samoa',
    },
    flag: '🇦🇸',
    code: 'AS',
    dialCode: '+1684',
  },
  {
    name: {
      en: 'Andorra',
      de: 'Andorra',
    },
    flag: '🇦🇩',
    code: 'AD',
    dialCode: '+376',
  },
  {
    name: {
      en: 'Angola',
      de: 'Angola',
    },
    flag: '🇦🇴',
    code: 'AO',
    dialCode: '+244',
  },
  {
    name: {
      en: 'Anguilla',
      de: 'Anguilla',
    },
    flag: '🇦🇮',
    code: 'AI',
    dialCode: '+1264',
  },
  {
    name: {
      en: 'Antarctica',
      de: 'Antarktis',
    },
    flag: '🇦🇶',
    code: 'AQ',
    dialCode: '+672',
  },
  {
    name: {
      en: 'Antigua and Barbuda',
      de: 'Antigua und Barbuda',
    },
    flag: '🇦🇬',
    code: 'AG',
    dialCode: '+1268',
  },
  {
    name: {
      en: 'Argentina',
      de: 'Argentinien',
    },
    flag: '🇦🇷',
    code: 'AR',
    dialCode: '+54',
  },
  {
    name: {
      en: 'Armenia',
      de: 'Armenien',
    },
    flag: '🇦🇲',
    code: 'AM',
    dialCode: '+374',
  },
  {
    name: {
      en: 'Aruba',
      de: 'Aruba',
    },
    flag: '🇦🇼',
    code: 'AW',
    dialCode: '+297',
  },
  {
    name: {
      en: 'Australia',
      de: 'Australien',
    },
    flag: '🇦🇺',
    code: 'AU',
    dialCode: '+61',
  },
  {
    name: {
      en: 'Austria',
      de: 'Österreich',
    },
    flag: '🇦🇹',
    code: 'AT',
    dialCode: '+43',
  },
  {
    name: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
    },
    flag: '🇦🇿',
    code: 'AZ',
    dialCode: '+994',
  },
  {
    name: {
      en: 'Bahamas',
      de: 'Bahamas',
    },
    flag: '🇧🇸',
    code: 'BS',
    dialCode: '+1242',
  },
  {
    name: {
      en: 'Bahrain',
      de: 'Bahrain',
    },
    flag: '🇧🇭',
    code: 'BH',
    dialCode: '+973',
  },
  {
    name: {
      en: 'Bangladesh',
      de: 'Bangladesch',
    },
    flag: '🇧🇩',
    code: 'BD',
    dialCode: '+880',
  },
  {
    name: {
      en: 'Barbados',
      de: 'Barbados',
    },
    flag: '🇧🇧',
    code: 'BB',
    dialCode: '+1246',
  },
  {
    name: {
      en: 'Belarus',
      de: 'Weißrussland',
    },
    flag: '🇧🇾',
    code: 'BY',
    dialCode: '+375',
  },
  {
    name: {
      en: 'Belgium',
      de: 'Belgien',
    },
    flag: '🇧🇪',
    code: 'BE',
    dialCode: '+32',
  },
  {
    name: {
      en: 'Belize',
      de: 'Belize',
    },
    flag: '🇧🇿',
    code: 'BZ',
    dialCode: '+501',
  },
  {
    name: {
      en: 'Benin',
      de: 'Benin',
    },
    flag: '🇧🇯',
    code: 'BJ',
    dialCode: '+229',
  },
  {
    name: {
      en: 'Bermuda',
      de: 'Bermuda',
    },
    flag: '🇧🇲',
    code: 'BM',
    dialCode: '+1441',
  },
  {
    name: {
      en: 'Bhutan',
      de: 'Bhutan',
    },
    flag: '🇧🇹',
    code: 'BT',
    dialCode: '+975',
  },
  {
    name: {
      en: 'Bolivia',
      de: 'Bolivia',
    },
    flag: '🇧🇴',
    code: 'BO',
    dialCode: '+591',
  },
  {
    name: {
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina',
    },
    flag: '🇧🇦',
    code: 'BA',
    dialCode: '+387',
  },
  {
    name: {
      en: 'Botswana',
      de: 'Botswana',
    },
    flag: '🇧🇼',
    code: 'BW',
    dialCode: '+267',
  },
  {
    name: {
      en: 'Bouvet Island',
      de: 'Bouvetinsel',
    },
    flag: '🇧🇻',
    code: 'BV',
    dialCode: '+47',
  },
  {
    name: {
      en: 'Brazil',
      de: 'Brasilien',
    },
    flag: '🇧🇷',
    code: 'BR',
    dialCode: '+55',
  },
  {
    name: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium im Indischen Ozean',
    },
    flag: '🇮🇴',
    code: 'IO',
    dialCode: '+246',
  },
  {
    name: {
      en: 'Brunei Darussalam',
      de: 'Brunei Darussalam',
    },
    flag: '🇧🇳',
    code: 'BN',
    dialCode: '+673',
  },
  {
    name: {
      en: 'Bulgaria',
      de: 'Bulgarien',
    },
    flag: '🇧🇬',
    code: 'BG',
    dialCode: '+359',
  },
  {
    name: {
      en: 'Burkina Faso',
      de: 'Burkina Faso',
    },
    flag: '🇧🇫',
    code: 'BF',
    dialCode: '+226',
  },
  {
    name: {
      en: 'Burundi',
      de: 'Burundi',
    },
    flag: '🇧🇮',
    code: 'BI',
    dialCode: '+257',
  },
  {
    name: {
      en: 'Cambodia',
      de: 'Kambodscha',
    },
    flag: '🇰🇭',
    code: 'KH',
    dialCode: '+855',
  },
  {
    name: {
      en: 'Cameroon',
      de: 'Kamerun',
    },
    flag: '🇨🇲',
    code: 'CM',
    dialCode: '+237',
  },
  {
    name: {
      en: 'Canada',
      de: 'Kanada',
    },
    flag: '🇨🇦',
    code: 'CA',
    dialCode: '+1',
  },
  {
    name: {
      en: 'Cape Verde',
      de: 'Kap Verde',
    },
    flag: '🇨🇻',
    code: 'CV',
    dialCode: '+238',
  },
  {
    name: {
      en: 'Cayman Islands',
      de: 'Kaimaninseln',
    },
    flag: '🇰🇾',
    code: 'KY',
    dialCode: '+345',
  },
  {
    name: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik',
    },
    flag: '🇨🇫',
    code: 'CF',
    dialCode: '+236',
  },
  {
    name: {
      en: 'Chad',
      de: 'Tschad',
    },
    flag: '🇹🇩',
    code: 'TD',
    dialCode: '+235',
  },
  {
    name: {
      en: 'Chile',
      de: 'Chile',
    },
    flag: '🇨🇱',
    code: 'CL',
    dialCode: '+56',
  },
  {
    name: {
      en: 'China',
      de: 'China',
    },
    flag: '🇨🇳',
    code: 'CN',
    dialCode: '+86',
  },
  {
    name: {
      en: 'Christmas Island',
      de: 'Weihnachtsinsel',
    },
    flag: '🇨🇽',
    code: 'CX',
    dialCode: '+61',
  },
  {
    name: {
      en: 'Cocos (Keeling) Islands',
      de: 'Cocos (Keeling) Inseln ',
    },
    flag: '🇨🇨',
    code: 'CC',
    dialCode: '+61',
  },
  {
    name: {
      en: 'Colombia',
      de: 'Kolumbien',
    },
    flag: '🇨🇴',
    code: 'CO',
    dialCode: '+57',
  },
  {
    name: {
      en: 'Comoros',
      de: 'Komoren',
    },
    flag: '🇰🇲',
    code: 'KM',
    dialCode: '+269',
  },
  {
    name: {
      en: 'Congo',
      de: 'Kongo',
    },
    flag: '🇨🇬',
    code: 'CG',
    dialCode: '+242',
  },
  {
    name: {
      en: 'Congo, The Democratic Republic of the Congo',
      de: 'Kongo, die Demokratische Republik Kongo',
    },
    flag: '🇨🇩',
    code: 'CD',
    dialCode: '+243',
  },
  {
    name: {
      en: 'Cook Islands',
      de: 'Cookinseln',
    },
    flag: '🇨🇰',
    code: 'CK',
    dialCode: '+682',
  },
  {
    name: {
      en: 'Costa Rica',
      de: 'Costa Rica',
    },
    flag: '🇨🇷',
    code: 'CR',
    dialCode: '+506',
  },
  {
    name: {
      en: "Côte d'Ivoire",
      de: "Côte d'Ivoire",
    },
    flag: '🇨🇮',
    code: 'CI',
    dialCode: '+225',
  },
  {
    name: {
      en: 'Croatia',
      de: 'Kroatien',
    },
    flag: '🇭🇷',
    code: 'HR',
    dialCode: '+385',
  },
  {
    name: {
      en: 'Cuba',
      de: 'Kuba',
    },
    flag: '🇨🇺',
    code: 'CU',
    dialCode: '+53',
  },
  {
    name: {
      en: 'Cyprus',
      de: 'Zypern',
    },
    flag: '🇨🇾',
    code: 'CY',
    dialCode: '+357',
  },
  {
    name: {
      en: 'Czech Republic',
      de: 'Tschechische Republik',
    },
    flag: '🇨🇿',
    code: 'CZ',
    dialCode: '+420',
  },
  {
    name: {
      en: 'Denmark',
      de: 'Dänemark',
    },
    flag: '🇩🇰',
    code: 'DK',
    dialCode: '+45',
  },
  {
    name: {
      en: 'Djibouti',
      de: 'Djibouti',
    },
    flag: '🇩🇯',
    code: 'DJ',
    dialCode: '+253',
  },
  {
    name: {
      en: 'Dominica',
      de: 'Dominica',
    },
    flag: '🇩🇲',
    code: 'DM',
    dialCode: '+1767',
  },
  {
    name: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
    },
    flag: '🇩🇴',
    code: 'DO',
    dialCode: '+1849',
  },
  {
    name: {
      en: 'Ecuador',
      de: 'Ecuador',
    },
    flag: '🇪🇨',
    code: 'EC',
    dialCode: '+593',
  },
  {
    name: {
      en: 'Egypt',
      de: 'Ägypten',
    },
    flag: '🇪🇬',
    code: 'EG',
    dialCode: '+20',
  },
  {
    name: {
      en: 'El Salvador',
      de: 'El Salvador',
    },
    flag: '🇸🇻',
    code: 'SV',
    dialCode: '+503',
  },
  {
    name: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea',
    },
    flag: '🇬🇶',
    code: 'GQ',
    dialCode: '+240',
  },
  {
    name: {
      en: 'Eritrea',
      de: 'Eritrea',
    },
    flag: '🇪🇷',
    code: 'ER',
    dialCode: '+291',
  },
  {
    name: {
      en: 'Estonia',
      de: 'Estland',
    },
    flag: '🇪🇪',
    code: 'EE',
    dialCode: '+372',
  },
  {
    name: {
      en: 'Ethiopia',
      de: 'Äthiopien',
    },
    flag: '🇪🇹',
    code: 'ET',
    dialCode: '+251',
  },
  {
    name: {
      en: 'Falkland Islands (Malvinas)',
      de: 'Falklandinseln (Malwinen)',
    },
    flag: '🇫🇰',
    code: 'FK',
    dialCode: '+500',
  },
  {
    name: {
      en: 'Faroe Islands',
      de: 'Färöer Inseln',
    },
    flag: '🇫🇴',
    code: 'FO',
    dialCode: '+298',
  },
  {
    name: {
      en: 'Fiji',
      de: 'Fidschi',
    },
    flag: '🇫🇯',
    code: 'FJ',
    dialCode: '+679',
  },
  {
    name: {
      en: 'Finland',
      de: 'Finnland',
    },
    flag: '🇫🇮',
    code: 'FI',
    dialCode: '+358',
  },
  {
    name: {
      en: 'France',
      de: 'Frankreich',
    },
    flag: '🇫🇷',
    code: 'FR',
    dialCode: '+33',
  },
  {
    name: {
      en: 'French Guiana',
      de: 'Französisch-Guayana',
    },
    flag: '🇬🇫',
    code: 'GF',
    dialCode: '+594',
  },
  {
    name: {
      en: 'French Polynesia',
      de: 'Französisch-Polynesien',
    },
    flag: '🇵🇫',
    code: 'PF',
    dialCode: '+689',
  },
  {
    name: {
      en: 'French Southern Territories',
      de: 'Französische Süd-Territorien',
    },
    flag: '🇹🇫',
    code: 'TF',
    dialCode: '+262',
  },
  {
    name: {
      en: 'Gabon',
      de: 'Gabun',
    },
    flag: '🇬🇦',
    code: 'GA',
    dialCode: '+241',
  },
  {
    name: {
      en: 'Gambia',
      de: 'Gambia',
    },
    flag: '🇬🇲',
    code: 'GM',
    dialCode: '+220',
  },
  {
    name: {
      en: 'Georgia',
      de: 'Georgien',
    },
    flag: '🇬🇪',
    code: 'GE',
    dialCode: '+995',
  },
  {
    name: {
      en: 'Germany',
      de: 'Deutschland',
    },
    flag: '🇩🇪',
    code: 'DE',
    dialCode: '+49',
  },
  {
    name: {
      en: 'Ghana',
      de: 'Ghana',
    },
    flag: '🇬🇭',
    code: 'GH',
    dialCode: '+233',
  },
  {
    name: {
      en: 'Gibraltar',
      de: 'Gibraltar',
    },
    flag: '🇬🇮',
    code: 'GI',
    dialCode: '+350',
  },
  {
    name: {
      en: 'Greece',
      de: 'Griechenland',
    },
    flag: '🇬🇷',
    code: 'GR',
    dialCode: '+30',
  },
  {
    name: {
      en: 'Greenland',
      de: 'Grönland',
    },
    flag: '🇬🇱',
    code: 'GL',
    dialCode: '+299',
  },
  {
    name: {
      en: 'Grenada',
      de: 'Grenada',
    },
    flag: '🇬🇩',
    code: 'GD',
    dialCode: '+1473',
  },
  {
    name: {
      en: 'Guadeloupe',
      de: 'Guadeloupe',
    },
    flag: '🇬🇵',
    code: 'GP',
    dialCode: '+590',
  },
  {
    name: {
      en: 'Guam',
      de: 'Guam',
    },
    flag: '🇬🇺',
    code: 'GU',
    dialCode: '+1671',
  },
  {
    name: {
      en: 'Guatemala',
      de: 'Guatemala',
    },
    flag: '🇬🇹',
    code: 'GT',
    dialCode: '+502',
  },
  {
    name: {
      en: 'Guernsey',
      de: 'Guernsey',
    },
    flag: '🇬🇬',
    code: 'GG',
    dialCode: '+44',
  },
  {
    name: {
      en: 'Guinea',
      de: 'Guinea',
    },
    flag: '🇬🇳',
    code: 'GN',
    dialCode: '+224',
  },
  {
    name: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
    },
    flag: '🇬🇼',
    code: 'GW',
    dialCode: '+245',
  },
  {
    name: {
      en: 'Guyana',
      de: 'Guyana',
    },
    flag: '🇬🇾',
    code: 'GY',
    dialCode: '+592',
  },
  {
    name: {
      en: 'Haiti',
      de: 'Haiti',
    },
    flag: '🇭🇹',
    code: 'HT',
    dialCode: '+509',
  },
  {
    name: {
      en: 'Heard Island and Mcdonald Islands',
      de: 'Heard-Insel und Mcdonald-Inseln',
    },
    flag: '🇭🇲',
    code: 'HM',
    dialCode: '+672',
  },
  {
    name: {
      en: 'Holy See (Vatican City State)',
      de: 'Heiliger Stuhl (Staat Vatikanstadt)',
    },
    flag: '🇻🇦',
    code: 'VA',
    dialCode: '+379',
  },
  {
    name: {
      en: 'Honduras',
      de: 'Honduras',
    },
    flag: '🇭🇳',
    code: 'HN',
    dialCode: '+504',
  },
  {
    name: {
      en: 'Hong Kong',
      de: 'Hongkong',
    },
    flag: '🇭🇰',
    code: 'HK',
    dialCode: '+852',
  },
  {
    name: {
      en: 'Hungary',
      de: 'Ungarn',
    },
    flag: '🇭🇺',
    code: 'HU',
    dialCode: '+36',
  },
  {
    name: {
      en: 'Iceland',
      de: 'Island',
    },
    flag: '🇮🇸',
    code: 'IS',
    dialCode: '+354',
  },
  {
    name: {
      en: 'India',
      de: 'Indien',
    },
    flag: '🇮🇳',
    code: 'IN',
    dialCode: '+91',
  },
  {
    name: {
      en: 'Indonesia',
      de: 'Indonesien',
    },
    flag: '🇮🇩',
    code: 'ID',
    dialCode: '+62',
  },
  {
    name: {
      en: 'Iran',
      de: 'Iran',
    },
    flag: '🇮🇷',
    code: 'IR',
    dialCode: '+98',
  },
  {
    name: {
      en: 'Iraq',
      de: 'Irak',
    },
    flag: '🇮🇶',
    code: 'IQ',
    dialCode: '+964',
  },
  {
    name: {
      en: 'Ireland',
      de: 'Irland',
    },
    flag: '🇮🇪',
    code: 'IE',
    dialCode: '+353',
  },
  {
    name: {
      en: 'Isle of Man',
      de: 'Isle of Man',
    },
    flag: '🇮🇲',
    code: 'IM',
    dialCode: '+44',
  },
  {
    name: {
      en: 'Israel',
      de: 'Israel',
    },
    flag: '🇮🇱',
    code: 'IL',
    dialCode: '+972',
  },
  {
    name: {
      en: 'Italy',
      de: 'Italien',
    },
    flag: '🇮🇹',
    code: 'IT',
    dialCode: '+39',
  },
  {
    name: {
      en: 'Jamaica',
      de: 'Jamaika',
    },
    flag: '🇯🇲',
    code: 'JM',
    dialCode: '+1876',
  },
  {
    name: {
      en: 'Japan',
      de: 'Japan',
    },
    flag: '🇯🇵',
    code: 'JP',
    dialCode: '+81',
  },
  {
    name: {
      en: 'Jersey',
      de: 'Jersey',
    },
    flag: '🇯🇪',
    code: 'JE',
    dialCode: '+44',
  },
  {
    name: {
      en: 'Jordan',
      de: 'Jordanien',
    },
    flag: '🇯🇴',
    code: 'JO',
    dialCode: '+962',
  },
  {
    name: {
      en: 'Kazakhstan',
      de: 'Kasachstan',
    },
    flag: '🇰🇿',
    code: 'KZ',
    dialCode: '+7',
  },
  {
    name: {
      en: 'Kenya',
      de: 'Kenia',
    },
    flag: '🇰🇪',
    code: 'KE',
    dialCode: '+254',
  },
  {
    name: {
      en: 'Kiribati',
      de: 'Kiribati',
    },
    flag: '🇰🇮',
    code: 'KI',
    dialCode: '+686',
  },
  {
    name: {
      en: "Korea, Democratic People's Republic of Korea",
      de: 'Korea, Demokratische Volksrepublik Korea',
    },
    flag: '🇰🇵',
    code: 'KP',
    dialCode: '+850',
  },
  {
    name: {
      en: 'Korea, Republic of South Korea',
      de: 'Korea, Republik Südkorea',
    },
    flag: '🇰🇷',
    code: 'KR',
    dialCode: '+82',
  },
  {
    name: {
      en: 'Kosovo',
      de: 'Kosovo',
    },
    flag: '🇽🇰',
    code: 'XK',
    dialCode: '+383',
  },
  {
    name: {
      en: 'Kuwait',
      de: 'Kuwait',
    },
    flag: '🇰🇼',
    code: 'KW',
    dialCode: '+965',
  },
  {
    name: {
      en: 'Kyrgyzstan',
      de: 'Kirgisistan',
    },
    flag: '🇰🇬',
    code: 'KG',
    dialCode: '+996',
  },
  {
    name: {
      en: 'Laos',
      de: 'Laos',
    },
    flag: '🇱🇦',
    code: 'LA',
    dialCode: '+856',
  },
  {
    name: {
      en: 'Latvia',
      de: 'Lettland',
    },
    flag: '🇱🇻',
    code: 'LV',
    dialCode: '+371',
  },
  {
    name: {
      en: 'Lebanon',
      de: 'Libanon',
    },
    flag: '🇱🇧',
    code: 'LB',
    dialCode: '+961',
  },
  {
    name: {
      en: 'Lesotho',
      de: 'Lesotho',
    },
    flag: '🇱🇸',
    code: 'LS',
    dialCode: '+266',
  },
  {
    name: {
      en: 'Liberia',
      de: 'Liberia',
    },
    flag: '🇱🇷',
    code: 'LR',
    dialCode: '+231',
  },
  {
    name: {
      en: 'Libyan Arab Jamahiriya',
      de: 'Libysch-Arabische Dschamahirija ',
    },
    flag: '🇱🇾',
    code: 'LY',
    dialCode: '+218',
  },
  {
    name: {
      en: 'Liechtenstein',
      de: 'Liechtenstein',
    },
    flag: '🇱🇮',
    code: 'LI',
    dialCode: '+423',
  },
  {
    name: {
      en: 'Lithuania',
      de: 'Litauen',
    },
    flag: '🇱🇹',
    code: 'LT',
    dialCode: '+370',
  },
  {
    name: {
      en: 'Luxembourg',
      de: 'Luxemburg',
    },
    flag: '🇱🇺',
    code: 'LU',
    dialCode: '+352',
  },
  {
    name: {
      en: 'Macao',
      de: 'Macao',
    },
    flag: '🇲🇴',
    code: 'MO',
    dialCode: '+853',
  },
  {
    name: {
      en: 'Macedonia',
      de: 'Mazedonien',
    },
    flag: '🇲🇰',
    code: 'MK',
    dialCode: '+389',
  },
  {
    name: {
      en: 'Madagascar',
      de: 'Madagaskar',
    },
    flag: '🇲🇬',
    code: 'MG',
    dialCode: '+261',
  },
  {
    name: {
      en: 'Malawi',
      de: 'Malawi',
    },
    flag: '🇲🇼',
    code: 'MW',
    dialCode: '+265',
  },
  {
    name: {
      en: 'Malaysia',
      de: 'Malaysia',
    },
    flag: '🇲🇾',
    code: 'MY',
    dialCode: '+60',
  },
  {
    name: {
      en: 'Maldives',
      de: 'Malediven',
    },
    flag: '🇲🇻',
    code: 'MV',
    dialCode: '+960',
  },
  {
    name: {
      en: 'Mali',
      de: 'Mali',
    },
    flag: '🇲🇱',
    code: 'ML',
    dialCode: '+223',
  },
  {
    name: {
      en: 'Malta',
      de: 'Malta',
    },
    flag: '🇲🇹',
    code: 'MT',
    dialCode: '+356',
  },
  {
    name: {
      en: 'Marshall Islands',
      de: 'Marshallinseln',
    },
    flag: '🇲🇭',
    code: 'MH',
    dialCode: '+692',
  },
  {
    name: {
      en: 'Martinique',
      de: 'Martinique',
    },
    flag: '🇲🇶',
    code: 'MQ',
    dialCode: '+596',
  },
  {
    name: {
      en: 'Mauritania',
      de: 'Mauretanien',
    },
    flag: '🇲🇷',
    code: 'MR',
    dialCode: '+222',
  },
  {
    name: {
      en: 'Mauritius',
      de: 'Mauritius',
    },
    flag: '🇲🇺',
    code: 'MU',
    dialCode: '+230',
  },
  {
    name: {
      en: 'Mayotte',
      de: 'Mayotte',
    },
    flag: '🇾🇹',
    code: 'YT',
    dialCode: '+262',
  },
  {
    name: {
      en: 'Mexico',
      de: 'Mexiko',
    },
    flag: '🇲🇽',
    code: 'MX',
    dialCode: '+52',
  },
  {
    name: {
      en: 'Micronesia, Federated States of Micronesia',
      de: 'Mikronesien, Föderierte Staaten von Mikronesien',
    },
    flag: '🇫🇲',
    code: 'FM',
    dialCode: '+691',
  },
  {
    name: {
      en: 'Moldova',
      de: 'Moldova',
    },
    flag: '🇲🇩',
    code: 'MD',
    dialCode: '+373',
  },
  {
    name: {
      en: 'Monaco',
      de: 'Monaco',
    },
    flag: '🇲🇨',
    code: 'MC',
    dialCode: '+377',
  },
  {
    name: {
      en: 'Mongolia',
      de: 'Mongolei',
    },
    flag: '🇲🇳',
    code: 'MN',
    dialCode: '+976',
  },
  {
    name: {
      en: 'Montenegro',
      de: 'Montenegro',
    },
    flag: '🇲🇪',
    code: 'ME',
    dialCode: '+382',
  },
  {
    name: {
      en: 'Montserrat',
      de: 'Montserrat',
    },
    flag: '🇲🇸',
    code: 'MS',
    dialCode: '+1664',
  },
  {
    name: {
      en: 'Morocco',
      de: 'Marokko',
    },
    flag: '🇲🇦',
    code: 'MA',
    dialCode: '+212',
  },
  {
    name: {
      en: 'Mozambique',
      de: 'Mosambik',
    },
    flag: '🇲🇿',
    code: 'MZ',
    dialCode: '+258',
  },
  {
    name: {
      en: 'Myanmar',
      de: 'Myanmar',
    },
    flag: '🇲🇲',
    code: 'MM',
    dialCode: '+95',
  },
  {
    name: {
      en: 'Namibia',
      de: 'Namibia',
    },
    flag: '🇳🇦',
    code: 'NA',
    dialCode: '+264',
  },
  {
    name: {
      en: 'Nauru',
      de: 'Nauru',
    },
    flag: '🇳🇷',
    code: 'NR',
    dialCode: '+674',
  },
  {
    name: {
      en: 'Nepal',
      de: 'Nepal',
    },
    flag: '🇳🇵',
    code: 'NP',
    dialCode: '+977',
  },
  {
    name: {
      en: 'Netherlands',
      de: 'Niederlande',
    },
    flag: '🇳🇱',
    code: 'NL',
    dialCode: '+31',
  },
  {
    name: {
      en: 'Netherlands Antilles',
      de: 'Niederländische Antillen',
    },
    flag: '🇧🇶',
    code: 'AN',
    dialCode: '+599',
  },
  {
    name: {
      en: 'New Caledonia',
      de: 'Neukaledonien',
    },
    flag: '🇳🇨',
    code: 'NC',
    dialCode: '+687',
  },
  {
    name: {
      en: 'New Zealand',
      de: 'Neuseeland',
    },
    flag: '🇳🇿',
    code: 'NZ',
    dialCode: '+64',
  },
  {
    name: {
      en: 'Nicaragua',
      de: 'Nicaragua',
    },
    flag: '🇳🇮',
    code: 'NI',
    dialCode: '+505',
  },
  {
    name: {
      en: 'Niger',
      de: 'Niger',
    },
    flag: '🇳🇪',
    code: 'NE',
    dialCode: '+227',
  },
  {
    name: {
      en: 'Nigeria',
      de: 'Nigeria',
    },
    flag: '🇳🇬',
    code: 'NG',
    dialCode: '+234',
  },
  {
    name: {
      en: 'Niue',
      de: 'Niue',
    },
    flag: '🇳🇺',
    code: 'NU',
    dialCode: '+683',
  },
  {
    name: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
    },
    flag: '🇳🇫',
    code: 'NF',
    dialCode: '+672',
  },
  {
    name: {
      en: 'Northern Mariana Islands',
      de: 'Nördliche Marianen',
    },
    flag: '🇲🇵',
    code: 'MP',
    dialCode: '+1670',
  },
  {
    name: {
      en: 'Norway',
      de: 'Norwegen',
    },
    flag: '🇳🇴',
    code: 'NO',
    dialCode: '+47',
  },
  {
    name: {
      en: 'Oman',
      de: 'Oman',
    },
    flag: '🇴🇲',
    code: 'OM',
    dialCode: '+968',
  },
  {
    name: {
      en: 'Pakistan',
      de: 'Pakistan',
    },
    flag: '🇵🇰',
    code: 'PK',
    dialCode: '+92',
  },
  {
    name: {
      en: 'Palau',
      de: 'Palau',
    },
    flag: '🇵🇼',
    code: 'PW',
    dialCode: '+680',
  },
  {
    name: {
      en: 'Palestine',
      de: 'Palästina',
    },
    flag: '🇵🇸',
    code: 'PS',
    dialCode: '+970',
  },
  {
    name: {
      en: 'Panama',
      de: 'Panama',
    },
    flag: '🇵🇦',
    code: 'PA',
    dialCode: '+507',
  },
  {
    name: {
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea',
    },
    flag: '🇵🇬',
    code: 'PG',
    dialCode: '+675',
  },
  {
    name: {
      en: 'Paraguay',
      de: 'Paraguay',
    },
    flag: '🇵🇾',
    code: 'PY',
    dialCode: '+595',
  },
  {
    name: {
      en: 'Peru',
      de: 'Peru',
    },
    flag: '🇵🇪',
    code: 'PE',
    dialCode: '+51',
  },
  {
    name: {
      en: 'Philippines',
      de: 'Philippinen',
    },
    flag: '🇵🇭',
    code: 'PH',
    dialCode: '+63',
  },
  {
    name: {
      en: 'Pitcairn',
      de: 'Pitcairn',
    },
    flag: '🇵🇳',
    code: 'PN',
    dialCode: '+64',
  },
  {
    name: {
      en: 'Poland',
      de: 'Polen',
    },
    flag: '🇵🇱',
    code: 'PL',
    dialCode: '+48',
  },
  {
    name: {
      en: 'Portugal',
      de: 'Portugal',
    },
    flag: '🇵🇹',
    code: 'PT',
    dialCode: '+351',
  },
  {
    name: {
      en: 'Puerto Rico',
      de: 'Puerto Rico',
    },
    flag: '🇵🇷',
    code: 'PR',
    dialCode: '+1939',
  },
  {
    name: {
      en: 'Qatar',
      de: 'Katar',
    },
    flag: '🇶🇦',
    code: 'QA',
    dialCode: '+974',
  },
  {
    name: {
      en: 'Romania',
      de: 'Rumänien',
    },
    flag: '🇷🇴',
    code: 'RO',
    dialCode: '+40',
  },
  {
    name: {
      en: 'Russia',
      de: 'Russland',
    },
    flag: '🇷🇺',
    code: 'RU',
    dialCode: '+7',
  },
  {
    name: {
      en: 'Rwanda',
      de: 'Ruanda',
    },
    flag: '🇷🇼',
    code: 'RW',
    dialCode: '+250',
  },
  {
    name: {
      en: 'Réunion',
      de: 'Réunion',
    },
    flag: '🇷🇪',
    code: 'RE',
    dialCode: '+262',
  },
  {
    name: {
      en: 'Saint Barthelemy',
      de: 'St. Barthelemy',
    },
    flag: '🇧🇱',
    code: 'BL',
    dialCode: '+590',
  },
  {
    name: {
      en: 'Saint Helena, Ascension and Tristan Da Cunha',
      de: 'St. Helena, Ascension und Tristan Da Cunha',
    },
    flag: '🇸🇭',
    code: 'SH',
    dialCode: '+290',
  },
  {
    name: {
      en: 'Saint Kitts and Nevis',
      de: 'St. Kitts und Nevis',
    },
    flag: '🇰🇳',
    code: 'KN',
    dialCode: '+1869',
  },
  {
    name: {
      en: 'Saint Lucia',
      de: 'St. Lucia',
    },
    flag: '🇱🇨',
    code: 'LC',
    dialCode: '+1758',
  },
  {
    name: {
      en: 'Saint Martin',
      de: 'St. Martin',
    },
    flag: '🇲🇫',
    code: 'MF',
    dialCode: '+590',
  },
  {
    name: {
      en: 'Saint Pierre and Miquelon',
      de: 'St. Pierre und Miquelon',
    },
    flag: '🇵🇲',
    code: 'PM',
    dialCode: '+508',
  },
  {
    name: {
      en: 'Saint Vincent and the Grenadines',
      de: 'St. Vincent und die Grenadinen',
    },
    flag: '🇻🇨',
    code: 'VC',
    dialCode: '+1784',
  },
  {
    name: {
      en: 'Samoa',
      de: 'Samoa',
    },
    flag: '🇼🇸',
    code: 'WS',
    dialCode: '+685',
  },
  {
    name: {
      en: 'San Marino',
      de: 'San Marino',
    },
    flag: '🇸🇲',
    code: 'SM',
    dialCode: '+378',
  },
  {
    name: {
      en: 'Sao Tome and Principe',
      de: 'Sao Tome und Principe',
    },
    flag: '🇸🇹',
    code: 'ST',
    dialCode: '+239',
  },
  {
    name: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien',
    },
    flag: '🇸🇦',
    code: 'SA',
    dialCode: '+966',
  },
  {
    name: {
      en: 'Senegal',
      de: 'Senegal',
    },
    flag: '🇸🇳',
    code: 'SN',
    dialCode: '+221',
  },
  {
    name: {
      en: 'Serbia',
      de: 'Serbien',
    },
    flag: '🇷🇸',
    code: 'RS',
    dialCode: '+381',
  },
  {
    name: {
      en: 'Seychelles',
      de: 'Seychellen',
    },
    flag: '🇸🇨',
    code: 'SC',
    dialCode: '+248',
  },
  {
    name: {
      en: 'Sierra Leone',
      de: 'Sierra Leone',
    },
    flag: '🇸🇱',
    code: 'SL',
    dialCode: '+232',
  },
  {
    name: {
      en: 'Singapore',
      de: 'Singapur',
    },
    flag: '🇸🇬',
    code: 'SG',
    dialCode: '+65',
  },
  {
    name: {
      en: 'Slovakia',
      de: 'Slowakei',
    },
    flag: '🇸🇰',
    code: 'SK',
    dialCode: '+421',
  },
  {
    name: {
      en: 'Slovenia',
      de: 'Slowenien',
    },
    flag: '🇸🇮',
    code: 'SI',
    dialCode: '+386',
  },
  {
    name: {
      en: 'Solomon Islands',
      de: 'Salomoninseln',
    },
    flag: '🇸🇧',
    code: 'SB',
    dialCode: '+677',
  },
  {
    name: {
      en: 'Somalia',
      de: 'Somalia',
    },
    flag: '🇸🇴',
    code: 'SO',
    dialCode: '+252',
  },
  {
    name: {
      en: 'South Africa',
      de: 'Südafrika',
    },
    flag: '🇿🇦',
    code: 'ZA',
    dialCode: '+27',
  },
  {
    name: {
      en: 'South Sudan',
      de: 'Südsudan',
    },
    flag: '🇸🇸',
    code: 'SS',
    dialCode: '+211',
  },
  {
    name: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
    },
    flag: '🇬🇸',
    code: 'GS',
    dialCode: '+500',
  },
  {
    name: {
      en: 'Spain',
      de: 'Spanien',
    },
    flag: '🇪🇸',
    code: 'ES',
    dialCode: '+34',
  },
  {
    name: {
      en: 'Sri Lanka',
      de: 'Sri Lanka',
    },
    flag: '🇱🇰',
    code: 'LK',
    dialCode: '+94',
  },
  {
    name: {
      en: 'Sudan',
      de: 'Sudan',
    },
    flag: '🇸🇩',
    code: 'SD',
    dialCode: '+249',
  },
  {
    name: {
      en: 'Suriname',
      de: 'Surinam',
    },
    flag: '🇸🇷',
    code: 'SR',
    dialCode: '+597',
  },
  {
    name: {
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard und Jan Mayen',
    },
    flag: '🇸🇯',
    code: 'SJ',
    dialCode: '+47',
  },
  {
    name: {
      en: 'Swaziland',
      de: 'Swasiland',
    },
    flag: '🇸🇿',
    code: 'SZ',
    dialCode: '+268',
  },
  {
    name: {
      en: 'Sweden',
      de: 'Schweden',
    },
    flag: '🇸🇪',
    code: 'SE',
    dialCode: '+46',
  },
  {
    name: {
      en: 'Switzerland',
      de: 'Schweiz',
    },
    flag: '🇨🇭',
    code: 'CH',
    dialCode: '+41',
  },
  {
    name: {
      en: 'Syrian Arab Republic',
      de: 'Arabische Republik Syrien',
    },
    flag: '🇸🇾',
    code: 'SY',
    dialCode: '+963',
  },
  {
    name: {
      en: 'Taiwan',
      de: 'Taiwan',
    },
    flag: '🇹🇼',
    code: 'TW',
    dialCode: '+886',
  },
  {
    name: {
      en: 'Tajikistan',
      de: 'Tadschikistan',
    },
    flag: '🇹🇯',
    code: 'TJ',
    dialCode: '+992',
  },
  {
    name: {
      en: 'Tanzania, United Republic of Tanzania',
      de: 'Tansania, Vereinigte Republik Tansania',
    },
    flag: '🇹🇿',
    code: 'TZ',
    dialCode: '+255',
  },
  {
    name: {
      en: 'Thailand',
      de: 'Thailand',
    },
    flag: '🇹🇭',
    code: 'TH',
    dialCode: '+66',
  },
  {
    name: {
      en: 'Timor-Leste',
      de: 'Timor-Leste',
    },
    flag: '🇹🇱',
    code: 'TL',
    dialCode: '+670',
  },
  {
    name: {
      en: 'Togo',
      de: 'Togo',
    },
    flag: '🇹🇬',
    code: 'TG',
    dialCode: '+228',
  },
  {
    name: {
      en: 'Tokelau',
      de: 'Tokelau',
    },
    flag: '🇹🇰',
    code: 'TK',
    dialCode: '+690',
  },
  {
    name: {
      en: 'Tonga',
      de: 'Tonga',
    },
    flag: '🇹🇴',
    code: 'TO',
    dialCode: '+676',
  },
  {
    name: {
      en: 'Trinidad and Tobago',
      de: 'Trinidad und Tobago',
    },
    flag: '🇹🇹',
    code: 'TT',
    dialCode: '+1868',
  },
  {
    name: {
      en: 'Tunisia',
      de: 'Tunesien',
    },
    flag: '🇹🇳',
    code: 'TN',
    dialCode: '+216',
  },
  {
    name: {
      en: 'Turkey',
      de: 'Türkei',
    },
    flag: '🇹🇷',
    code: 'TR',
    dialCode: '+90',
  },
  {
    name: {
      en: 'Turkmenistan',
      de: 'Turkmenistan',
    },
    flag: '🇹🇲',
    code: 'TM',
    dialCode: '+993',
  },
  {
    name: {
      en: 'Turks and Caicos Islands',
      de: 'Turks- und Caicosinseln',
    },
    flag: '🇹🇨',
    code: 'TC',
    dialCode: '+1649',
  },
  {
    name: {
      en: 'Tuvalu',
      de: 'Tuvalu',
    },
    flag: '🇹🇻',
    code: 'TV',
    dialCode: '+688',
  },
  {
    name: {
      en: 'Uganda',
      de: 'Uganda',
    },
    flag: '🇺🇬',
    code: 'UG',
    dialCode: '+256',
  },
  {
    name: {
      en: 'Ukraine',
      de: 'Ukraine',
    },
    flag: '🇺🇦',
    code: 'UA',
    dialCode: '+380',
  },
  {
    name: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
    },
    flag: '🇦🇪',
    code: 'AE',
    dialCode: '+971',
  },
  {
    name: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich',
    },
    flag: '🇬🇧',
    code: 'GB',
    dialCode: '+44',
  },
  {
    name: {
      en: 'United States',
      de: 'Vereinigte Staaten',
    },
    flag: '🇺🇸',
    code: 'US',
    dialCode: '+1',
  },
  {
    name: {
      en: 'Uruguay',
      de: 'Uruguay',
    },
    flag: '🇺🇾',
    code: 'UY',
    dialCode: '+598',
  },
  {
    name: {
      en: 'Uzbekistan',
      de: 'Usbekistan',
    },
    flag: '🇺🇿',
    code: 'UZ',
    dialCode: '+998',
  },
  {
    name: {
      en: 'Vanuatu',
      de: 'Vanuatu',
    },
    flag: '🇻🇺',
    code: 'VU',
    dialCode: '+678',
  },
  {
    name: {
      en: 'Venezuela, Bolivarian Republic of Venezuela',
      de: 'Venezuela, Bolivarische Republik Venezuela',
    },
    flag: '🇻🇪',
    code: 'VE',
    dialCode: '+58',
  },
  {
    name: {
      en: 'Vietnam',
      de: 'Vietnam',
    },
    flag: '🇻🇳',
    code: 'VN',
    dialCode: '+84',
  },
  {
    name: {
      en: 'Virgin Islands, British',
      de: 'Jungferninseln, Britisch',
    },
    flag: '🇻🇬',
    code: 'VG',
    dialCode: '+1284',
  },
  {
    name: {
      en: 'Virgin Islands, U.S.',
      de: 'Jungferninseln, U.S.A.',
    },
    flag: '🇻🇮',
    code: 'VI',
    dialCode: '+1340',
  },
  {
    name: {
      en: 'Wallis and Futuna',
      de: 'Wallis und Futuna',
    },
    flag: '🇼🇫',
    code: 'WF',
    dialCode: '+681',
  },
  {
    name: {
      en: 'Yemen',
      de: 'Jemen',
    },
    flag: '🇾🇪',
    code: 'YE',
    dialCode: '+967',
  },
  {
    name: {
      en: 'Zambia',
      de: 'Sambia',
    },
    flag: '🇿🇲',
    code: 'ZM',
    dialCode: '+260',
  },
  {
    name: {
      en: 'Zimbabwe',
      de: 'Simbabwe',
    },
    flag: '🇿🇼',
    code: 'ZW',
    dialCode: '+263',
  },
];
