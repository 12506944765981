export const AUTH_ROUTE = {
  prefix: 'auth',
  pages: {
    register: 'register',
    login: 'login',
    forgotPassword: 'forgot-password',
    setPassword: 'set-password',
    resetPassword: 'reset-password',
    passwordTokenExpired: 'password-token-expired',
  },
};
