<circle-progress
  #progressTooltip="matTooltip"
  [matTooltip]="tooltip"
  (touchstart)="toggleTooltip(progressTooltip)"
  [percent]="percent"
  [radius]="radius"
  [space]="-10"
  [outerStrokeWidth]="10"
  [outerStrokeColor]="selectedColor"
  [outerStrokeGradientStopColor]="selectedColor"
  [innerStrokeColor]="deselectedColor"
  [innerStrokeWidth]="10"
  [showSubtitle]="false"
  [unitsColor]="valueColor"
  [titleColor]="valueColor"
  [animation]="true"
  [animationDuration]="500">
</circle-progress>
