import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { CarouselOptions, MediaConfig } from '@core/ui/media/types/carousel.type';
import { MediaVariant } from '@core/ui/media/types/media.type';
import { CardHeaderIconType } from '@core/ui/card/types/section-card.type';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { ImageFit } from '@core/ui/media/types/image.type';
import { PostCardVariant } from '../../types/post-card.type';
import { PostOwner } from '../../types/post-owner.type';
import { PostInteractionType } from '../../types/post-interaction.type';

@Component({
  selector: 'core-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
  host: {
    '[id]': 'id',
  },
})
export class PostCardComponent implements OnChanges {
  @Input()
  id?: string;

  @Input()
  provider?: ManagedPostProvider;

  @Input()
  title?: string;

  @Input()
  subtitle?: string;

  @Input()
  icon?: string;

  @Input()
  iconType?: CardHeaderIconType;

  @Input()
  slides: MediaConfig[] = [];

  @Input()
  slideIndex = 0;

  @Input()
  owner?: PostOwner;

  @Input()
  counts?: Record<PostInteractionType, Nullable<number>>;

  @Input()
  interactionStates?: {
    showIfZero?: Partial<Record<PostInteractionType, boolean>>;
    clickable?: Partial<Record<PostInteractionType, boolean>>;
    loading?: Partial<Record<PostInteractionType, boolean>>;
  };

  @Input()
  date?: string;

  @Input()
  caption?: string;

  @Input()
  isCaptionTruncated = true;

  @Input()
  captionSkeleton = false;

  @Input()
  mediaVariant?: MediaVariant;

  @Input()
  cardVariant: PostCardVariant = 'default';

  @Input()
  mediaOverlay = false;

  @Input()
  imageFit: ImageFit = 'contain';

  @Input()
  autoplayVideo = false;

  @Input()
  carouselOptions: Nullable<CarouselOptions> = null;

  @Input()
  groupLength = 1;

  @Input()
  groupIndex = 0;

  @Input()
  duration: Nullable<number> = null;

  durationMilliseconds: Nullable<number> = null;

  progress = 0;

  interval?: any; // NodeJS.Timeout;

  @Output()
  durationEnded = new EventEmitter();

  @Output()
  error = new EventEmitter();

  @Output()
  loaded = new EventEmitter();

  @Output()
  fallbackLoaded = new EventEmitter();

  @Output()
  slideChange = new EventEmitter();

  @Output()
  interactionClick = new EventEmitter<PostInteractionType>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupIndex) {
      clearInterval(this.interval);
      if (this.duration) {
        this.durationMilliseconds = this.duration * 1000;
        this.progress = 0;
        const step = 1000;
        this.interval = setInterval(() => {
          this.progress += step;
          if (this.progress >= this.durationMilliseconds!) {
            if (this.interval) {
              setTimeout(() => this.durationEnded.emit());
            }
            clearInterval(this.interval!);
          }
        }, step);
      }
    }
  }
}
