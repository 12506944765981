import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateModule } from '@core/ui/empty-state/empty-state.module';
import { PostModule } from '@core/modules/post/post.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { SvgModule } from '@core/ui/svg/svg.module';

import { TagModule } from '@core/ui/tag/tag.module';
import { SocialPostComponent } from './social-post.component';

const components = [SocialPostComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    PostModule,
    EmptyStateModule,
    ButtonModule,
    TagModule,
    SvgModule,
  ],
  exports: [...components],
  providers: [],
})
export class SocialPostModule {}
