import _isBoolean from 'lodash/isBoolean';
import { Component, Input, Output, EventEmitter, OnChanges, Inject, Optional } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { SnackBarConfig } from '@core/services/snack-bar.service';
import { SnackBarVariant } from '../../types/snack-bar.type';

@Component({
  selector: 'core-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  host: {
    '[class]': `"snack-bar"
      + " variant-"+variant`,
    '[class.snack-bar--action]': 'ctaLabel || link',
    '[class.snack-bar--has-title]': 'title',
    '[class.snack-bar--dismissable]': 'dismissable',
  },
})
export class SnackBarComponent implements OnChanges {
  @Input()
  variant: SnackBarVariant = 'default';

  @Input()
  icon = '';

  @Input()
  title = '';

  @Input()
  link = '';

  @Input()
  ctaLabel = '';

  @Input()
  dismissable = true;

  @Input()
  updating = false;

  @Output()
  dismiss = new EventEmitter();

  @Output()
  ctaClick = new EventEmitter();

  cardVariant = this.getCardVariant();

  dismissIconVariant = this.getDismissIconVariant();

  text = '';

  ctaCallback?: (...props: any) => any;

  theme$ = this.themeService.currentTheme$;

  constructor(
    @Optional()
    private themeService: ThemeService,
    @Optional()
    private snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Optional()
    @Inject(MAT_SNACK_BAR_DATA)
    data: SnackBarConfig,
  ) {
    if (data) {
      const { variant, icon, title, text, dismissable, isCtaVisible, ctaLabel, ctaCallback, updating } = data;
      if (variant === 'error') {
        this.variant = 'error';
      }
      if (icon) {
        this.icon = icon;
      }
      if (title) {
        this.title = title;
      }
      if (text) {
        this.text = text;
      }
      if (_isBoolean(dismissable)) {
        this.dismissable = dismissable;
      }
      if (_isBoolean(updating)) {
        this.updating = updating;
      }
      if (isCtaVisible && ctaLabel) {
        this.ctaLabel = ctaLabel;
      }
      if (ctaCallback) {
        this.ctaCallback = ctaCallback;
      }
    }
  }

  ngOnChanges(): void {
    this.cardVariant = this.getCardVariant();
    this.dismissIconVariant = this.getDismissIconVariant();
  }

  private getCardVariant(): string {
    switch (this.variant) {
      case 'error':
        return 'emphasis';
      case 'default':
      default:
        return 'primary';
    }
  }

  private getDismissIconVariant(): string {
    switch (this.variant) {
      case 'error':
        return 'emphasis';
      case 'default':
      default:
        return 'inverted';
    }
  }

  /**
   * Triggered when the close button is clicked
   */
  onDismiss(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
    this.dismiss.emit();
  }

  /**
   * Triggered when the CTA is clicked
   */
  onCtaClick(): void {
    if (this.ctaCallback) {
      this.ctaCallback();
    }
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
    this.ctaClick.emit();
  }
}
