import { ReviewWorkflowService } from '@review/common/services/review-workflow.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ManageCardsRoute } from '@review/modules/manage-cards/enums/manage-cards-route.enum';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageLink } from '@core/models/page-link.model';
import { Subject } from 'rxjs';
import { takeUntil, first, debounceTime, tap, concatMap } from 'rxjs/operators';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { ReviewPageDataService } from '@review/common/services/review-page-data.service';
import { PageDocument } from '@core/models/page-document.model';
import { Nullable } from '@core/types/nullable.type';
import { PageLinkType } from '@core/enums/page.enum';

const TAG_LENGTH = 14;

@Component({
  selector: 'review-manage-cards-assign',
  templateUrl: './manage-cards-assign.component.html',
  styleUrls: ['./manage-cards-assign.component.scss'],
})
export class ManageCardsAssignComponent implements OnInit, OnDestroy {
  reviewPage: Nullable<PageDocument> = null;

  card: Partial<PageLink> = {
    type: PageLinkType.Card,
  };

  editMode = false;

  cardForm?: FormGroup;

  destroyed$ = new Subject<void>();

  validating = false;

  duplicate = false;

  actionInProgress = false;

  scanned = false;

  constructor(
    private reviewWorkflowService: ReviewWorkflowService,
    private reviewPageDataService: ReviewPageDataService,
    private globalFilterService: GlobalFilterService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params?.id;
    const method = this.activatedRoute.snapshot.queryParams?.method;
    this.scanned = method === 'QR' || method === 'NFC';
    if (id) {
      this.reviewPageDataService
        .getReviewPageConfig(this.globalFilterService.store?._id)
        .pipe(
          tap((page) => {
            this.reviewPage = page;
          }),
          concatMap((page) =>
            this.reviewPageDataService.getPageLink(this.globalFilterService.store?._id, page!._id, id),
          ),
          tap((pageLink) => {
            this.editMode = !!pageLink; // If a tag with this id exists, set edit mode
            if (pageLink) {
              this.card = pageLink;
            }
            this.initForm();
          }),
        )
        .subscribe();
    } else {
      this.reviewPageDataService
        .getReviewPageConfig(this.globalFilterService.store?._id)
        .pipe(
          tap((page) => {
            this.reviewPage = page;
            this.initForm();
          }),
        )
        .subscribe();

      this.cardForm?.controls.cardId.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
        if (value?.split('-').join('').length === TAG_LENGTH) {
          this.validateCard();
        }
      });
    }
  }

  checkCardId(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const strippedCard = control.value.split('-').join('');
      if (strippedCard.length !== TAG_LENGTH) {
        return { cardIdInvalid: true };
      }
    }

    return null;
  }

  validateCard(): void {
    if (this.cardForm?.controls.cardId.invalid || !this.reviewPage) {
      return;
    }
    this.validating = true;
    this.cardForm?.controls.cardId.setErrors(null);
    this.duplicate = false;
    this.reviewPageDataService
      .getPageLink(this.reviewPage.storeId, this.reviewPage._id, this.cardForm?.get('cardId')?.value)
      .pipe(first(), debounceTime(1000))
      .subscribe((res) => {
        if (res) {
          this.duplicate = true;
          this.cardForm?.controls.cardId.setErrors({ duplicate: true });
        }
        this.validating = false;
      });
  }

  initForm(): void {
    this.cardForm = new FormGroup({
      cardId: new FormControl(
        {
          value: this.card?.id || this.activatedRoute.snapshot.params?.id || '',
          disabled: this.editMode,
        },
        [Validators.required, Validators.pattern('^[a-zA-Z0-9-]+$'), this.checkCardId],
      ),
      name: new FormControl(this.card?.name || '', [Validators.required]),
    });
  }

  /**
   * The method assigns card and if successfull,
   * pushes to the cardAssigned$ Subject in the cockpitService
   * @returns void
   */
  assignCard(): void {
    if (!this.reviewPage) {
      return;
    }
    if (this.cardForm?.invalid) {
      this.cardForm?.get('cardId')?.markAsTouched();
      this.cardForm?.get('cardId')?.markAsDirty();
      this.cardForm?.get('name')?.markAsTouched();
      this.cardForm?.get('name')?.markAsDirty();

      return;
    }
    this.actionInProgress = true;

    const name = this.cardForm?.controls?.name?.value!;
    const id = this.cardForm?.controls?.cardId!.value.split('-').join();
    const card: PageLink = {
      type: PageLinkType.Card,
      pageId: this.reviewPage._id,
      url: null,
      ...this.card,
      name,
      id,
    };

    const request$ = this.editMode
      ? this.reviewPageDataService.updatePageLink(this.reviewPage.storeId, this.reviewPage._id, id, card)
      : this.reviewPageDataService.createPageLink(this.reviewPage.storeId, this.reviewPage._id, card);

    request$.pipe(first()).subscribe({
      next: () => {
        this.actionInProgress = false;
        this.snackBarService.openSnackBar({
          variant: 'default',
          icon: 'check_circle',
          text: this.translateService.instant('REVIEW.MANAGE_CARDS.ASSIGN.SUCCESS'),
        });
        this.reviewWorkflowService.updateCards$.next();
        this.reviewWorkflowService.setCurrentStage(ManageCardsRoute.List);
      },
      error: (e) => {
        this.actionInProgress = false;
        this.snackBarService.openSnackBar({
          variant: 'error',
          icon: 'danger',
          text: e.error.errorMessage || e.error.codeName || e.error.message,
        });
      },
    });
  }

  goBack(): void {
    return this.reviewWorkflowService.setCurrentStage(ManageCardsRoute.List);
  }

  dismiss(): void {
    this.reviewWorkflowService.clearStage();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
