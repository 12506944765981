<ng-container *ngFor="let interaction of interactions; let first = first">
  <div
    *ngIf="counts?.[interaction] || showIfZero?.[interaction]"
    class="interaction"
    [class.ml-3]="!first"
    [class.interaction--clickable]="clickable?.[interaction]"
    (click)="interactionClick.emit(interaction)">
    <core-spinner *ngIf="loading?.[interaction]; else showInteraction" [diameter]="16" variant="primary"></core-spinner>
    <ng-template #showInteraction>
      <core-icon [icon]="icons?.[interaction]" size="xs"></core-icon>
    </ng-template>
    <span class="ml-1 mt-1">{{ counts?.[interaction] | numberSuffixes : 2 }}</span>
  </div>
</ng-container>
