import { Injectable } from '@angular/core';
import { Contact } from '@core/interfaces/contact.interface';
import { UserPreferences } from '@core/interfaces/user-preferences.interface';
import { User } from '@core/models/user.model';
import { ApiService } from '@core/services/api.service';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InviteUserService {
  constructor(
    private apiService: ApiService,
    private userService: UserService,
  ) {}

  invite(body: {
    contact: Partial<Contact>;
    settings: Partial<UserPreferences>;
    companyIds: string[];
  }): Observable<User> {
    return this.userService.createObject(body as User);
  }

  assignCompaniesToUser(email: string, body: { companyIds: string[] }): Observable<User> {
    return this.apiService.post(`users/${email}/companies`, body);
  }
}
