import { RouterModule, Routes } from '@angular/router';
import { GoalSettingRoute } from '@core/dialogs/goal-setting-workflow/enums/goal-setting-route.enum';
import { GoalSelection } from '@core/dialogs/goal-setting-workflow/components/goal-selection/goal-selection.component';
import { goalSettingOutlet } from './variables/outlet.variable';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';

const outlet = goalSettingOutlet;

export const makeGoalSettingWorkflowRoutes = () => [
  {
    path: GoalSettingRoute.GoalSelection,
    outlet,
    component: GoalSelection,
  },
  {
    path: GoalSettingRoute.ProductSelection,
    outlet,
    component: ProductSelectionComponent,
  },
];

const routes: Routes = [...makeGoalSettingWorkflowRoutes()];

export const GoalSettingWorkflowRoutingModule = RouterModule.forChild(routes);
