import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { catchError, first, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { Store } from '@core/models/store.model';
import * as activeIcons from '@core/ui/platform/variables/icons-active.variable';
import * as inactiveIcons from '@core/ui/platform/variables/icons-inactive.variable';
import { ProviderConnectionSync } from '@core/dialogs/integration-workflow/interfaces/provider-connection-sync.interface';
import { ProviderConnectionStatus } from '@core/dialogs/integration-workflow/enums/integration.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { Nullable } from '@core/types/nullable.type';
import _uniq from 'lodash/uniq';
import _difference from 'lodash/difference';
import _isEqual from 'lodash/isEqual';
import { RoutingService } from '@core/services/routing.service';
import { Product } from '@core/enums/product.enum';
import { Router } from '@angular/router';
import { SocialProviderConnectionSyncService } from '../../services/social-provider-connection-sync.service';

const INTEGRATION_ROUTE = {
  appPrefix: 'integrations',
  pages: {
    providers: 'providers',
    provider: (providerId = ':providerId') => `providers/${providerId}`,
  },
};

@Component({
  selector: 'core-provider-selector',
  templateUrl: './provider-selector.component.html',
  styleUrls: ['./provider-selector.component.scss'],
})
export class ProviderSelectorComponent implements OnInit, OnDestroy {
  displayedColumns = ['provider'];

  private store?: Store;

  private initialProviders: ManagedPostProvider[] = [];

  private disabled: Partial<Record<ManagedPostProvider, boolean>> = Object.fromEntries(
    Object.values(ManagedPostProvider).map((provider: ManagedPostProvider) => [provider, false]),
  );

  @Input()
  set providers(value: ManagedPostProvider[]) {
    if (!value || _isEqual(value, this.initialProviders)) {
      return;
    }
    this.initialProviders = value;
    if (!this.store) {
      return;
    }
    this.initConnections(this.store);
  }

  @Input()
  set selectedStore(value: Store) {
    if (!value || this.store?._id === value?._id) {
      return;
    }
    this.store = value;
    this.initConnections(value);
  }

  @Input()
  set disabledProviders(value: ManagedPostProvider[]) {
    if (!value) {
      return;
    }
    this.disabled = Object.fromEntries(
      Object.values(ManagedPostProvider).map((provider: ManagedPostProvider) => [provider, false]),
    );
    value.forEach((provider) => {
      this.disabled[provider] = true;
    });
    if (!this.connections.length) {
      return;
    }
    this.providersForm.setControl(
      'providers',
      new FormGroup({
        ...this.connections.reduce(
          (acc, item) => ({
            ...acc,
            [item.provider]: new FormControl(
              item.status === ProviderConnectionStatus.Active &&
                this.providersForm.get(`providers.${item.provider}`)?.value &&
                !this.disabled?.[item.provider as ManagedPostProvider],
            ),
          }),
          {},
        ),
      }),
    );
  }

  @Input()
  disabledReason: Nullable<string> = null;

  @Input()
  preselectAll = false;

  @Input()
  hasError = false;

  @Output()
  providersChange: EventEmitter<ManagedPostProvider[]> = new EventEmitter();

  @Output()
  connectClick: EventEmitter<ManagedPostProvider[]> = new EventEmitter();

  connections: ProviderConnectionSync[] = [];

  avatars: Record<string, Nullable<string>> = {};

  platformIcons = {
    [ProviderConnectionStatus.Active]: activeIcons,
    [ProviderConnectionStatus.Inactive]: inactiveIcons,
  };

  actionInProgress = false;

  allInactive = false;

  readonly recommendedProviders = Object.values(ManagedPostProvider);

  activeConnections: ProviderConnectionSync[] = [];

  inactiveConnections: ProviderConnectionSync[] = [];

  connectableProviders: ManagedPostProvider[] = [];

  providersForm: FormGroup = new FormGroup({
    providers: new FormControl({}),
  });

  destroyed$ = new Subject<void>();

  showRefresh = false;

  constructor(
    private socialProviderConnectionSyncService: SocialProviderConnectionSyncService,
    private routingService: RoutingService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.providersForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(({ providers }) => {
      this.providersChange.emit((Object.keys(providers) as ManagedPostProvider[]).filter((key) => providers[key]));
    });
  }

  initConnections(store: Store): void {
    this.actionInProgress = true;
    this.allInactive = false;
    this.avatars = {};

    this.socialProviderConnectionSyncService
      .getConnections(store?._id!)
      .pipe(
        first(),
        catchError(() => {
          this.actionInProgress = false;
          this.allInactive = true;

          return EMPTY;
        }),
        tap((connections) => {
          const ordering: Record<string, number> = this.recommendedProviders.reduce(
            (dict, value, index) => ({ ...dict, [value]: index }),
            {} as Record<string, number>,
          );
          this.connections = connections.sort((a, b) => ordering[a.provider] - ordering[b.provider]);
          this.allInactive = connections.every(({ status }) => status === ProviderConnectionStatus.Inactive);
          this.connections.forEach(({ provider, profilePicUrl }) => {
            this.avatars[provider] = profilePicUrl;
          });

          this.activeConnections = connections.filter(({ status }) => status === ProviderConnectionStatus.Active);

          this.inactiveConnections = connections.filter(({ status }) => status !== ProviderConnectionStatus.Active);

          const allProviders = _uniq(
            connections.map(({ provider }) => provider as ManagedPostProvider).concat(this.recommendedProviders),
          );

          this.connectableProviders = _difference(
            allProviders,
            connections.map(({ provider }) => provider as ManagedPostProvider),
          ).sort((a, b) => ordering[a] - ordering[b]);

          this.providersForm.setControl(
            'providers',
            new FormGroup({
              ...connections.reduce(
                (acc, value) => ({
                  ...acc,
                  [value.provider]: new FormControl(
                    (this.initialProviders.includes(value.provider as ManagedPostProvider) || this.preselectAll) &&
                      value.status === ProviderConnectionStatus.Active &&
                      !this.disabled[value.provider as ManagedPostProvider],
                  ),
                }),
                {},
              ),
            }),
          );
          this.actionInProgress = false;
        }),
      )
      .subscribe();
  }

  connect(provider: ManagedPostProvider) {
    // this.showRefresh = true;
    // if (this.routingService.isWebView || environment.microfrontend) {
    this.connectClick.emit();
    this.routingService.navigateOrEmitEvent(
      `/${INTEGRATION_ROUTE.appPrefix}/${INTEGRATION_ROUTE.pages.provider(provider)}`,
      {
        productName: Product.INTEGRATION,
        storeId: this.store?._id,
        prefix: `/${INTEGRATION_ROUTE.appPrefix}`,
      },
    );
    // } else {
    //   const url = this.router.serializeUrl(
    //     this.router.createUrlTree([`${INTEGRATION_ROUTE.appPrefix}/${INTEGRATION_ROUTE.pages.provider(provider)}`], {
    //       queryParams: GlobalFilter.toUrlParams({ ...new GlobalFilter(), store: this.store! }),
    //     }),
    //   );

    //   window.open(url, '_blank');
    // }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
