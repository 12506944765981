import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { HumanizeDatePipe } from '@core/pipes/humanize-date.pipe';

@Component({
  selector: 'core-posted-comment',
  templateUrl: './posted-comment.component.html',
  styleUrls: ['./posted-comment.component.scss'],
  providers: [HumanizeDatePipe],
})
export class PostedCommentComponent implements OnChanges {
  @Input()
  author?: string;

  @Input()
  text?: string;

  @Input()
  date?: string;

  @Input()
  actions?: Record<string, { label: string; disabled?: boolean }>;

  @Output()
  actionClick = new EventEmitter<string>();

  actionsList: string[];

  constructor() {
    this.actionsList = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actions) {
      this.actionsList = Object.keys(this.actions ?? {});
    }
  }
}
