import { AbstractControl, ValidatorFn } from '@angular/forms';

export class ContentValidator {
  static isContainsMoreThanSpecifiedHashtagNumber(limitNumber: number): ValidatorFn {
    return (control: AbstractControl) => {
      const hashtagRegExp = /#[^\s#]+/g;
      const matchesNumber = control.value?.match(hashtagRegExp)?.length || 0;

      if (matchesNumber > limitNumber) {
        return { exceedingHashtagNumber: true };
      }

      return null;
    };
  }

  static isContainsMoreThanSpecifiedMentionNumber(limitNumber: number): ValidatorFn {
    return (control: AbstractControl) => {
      const mentionRegExp = /@[^\s@]+/g;
      const matchesNumber = control.value?.match(mentionRegExp)?.length || 0;

      if (matchesNumber > limitNumber) {
        return { exceedingMentionNumber: true };
      }

      return null;
    };
  }
}
