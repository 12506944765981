import { Component, Input } from '@angular/core';
import { TagVariant } from '../../types/tag-large.type';

@Component({
  selector: 'core-tag-large',
  templateUrl: './tag-large.component.html',
  styleUrls: ['./tag-large.component.scss'],
  host: {
    '[class]': `"tag-large"
      + " variant-"+variant`,
  },
})
export class TagLargeComponent {
  @Input()
  icon?: string;

  @Input()
  variant: TagVariant = 'default';
}
