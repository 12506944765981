<span *ngIf="date">
  <ng-container [ngSwitch]="format">
    <ng-container *ngSwitchCase="'humanize'">
      {{ date | humanizeDate }}
    </ng-container>
    <ng-container *ngSwitchCase="'datetime'">
      {{ today ? ('CALENDAR.TODAY' | translate) : (date | date : 'shortDate' : undefined : locale ) }}{{','}}
      {{ date | date : 'shortTime' : undefined : locale }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ date }}
    </ng-container>
  </ng-container>
</span>
