/**
 * User manually skipped the connect your Instagram dialog
 */
export const IG_CONNECTION_DIALOG_SKIPPED = 'instagramConnectionDialogSkipped';

/**
 * Preview layout to filter by
 */
export const POST_LAYOUT = 'postLayout';

/**
 * Preview provider to filter by
 */
export const SOCIALS_PROVIDER_FILTER = 'socialsProvider';

/**
 * Filters social posts by status and created by
 */
export const SOCIAL_POST_FILTER = 'socialPreviewFilter';
