import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { IntegrationDialogConfig } from '@core/dialogs/integration-workflow/interfaces/dialog-config.interface';
import { IntegrationDataService } from '@core/dialogs/integration-workflow/services/integration-data.service';
import { IntegrationDialogService } from '@core/dialogs/integration-workflow/services/integration-dialog.service';
import { IntercomService } from '@core/services/intercom.service';
import { catchError, first } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountDetails } from '@core/interfaces/integration.interface';
import { TRACK_INTEGRATIONS } from '@core/dialogs/integration-workflow/variables/tracking.variable';
import { SegmentService } from '@core/services/segment.service';
import { AuthService } from '@core/services/auth.service';
import { IntegrationDataHelperService } from '@core/dialogs/integration-workflow/services/integration-data-helper.service';
import { AuthType } from '@core/enums/provider.enum';

@Component({
  selector: 'core-none-intg-connect-dialog',
  templateUrl: './none-intg-connect-dialog.component.html',
  styleUrls: ['./none-intg-connect-dialog.component.scss'],
})
export class NoneIntgConnectDialogComponent implements OnInit {
  selectedStore = this.globalFilterService.store;

  actionInProgress = false;

  locations: AccountDetails[] = [];

  selectableLocations: AccountDetails[] = [];

  selectedLocations: AccountDetails[] = [];

  loading = false;

  providerId = '';

  showSelectable = true;

  showLocationSelectError = false;

  selfConnected = !this.authService.isSessionLogin();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IntegrationDialogConfig,
    private integrationDialogService: IntegrationDialogService,
    private integrationDataHelperService: IntegrationDataHelperService,
    private globalFilterService: GlobalFilterService,
    private intercomService: IntercomService,
    private integrationDataService: IntegrationDataService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private segmentService: SegmentService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.createAuthentication();
  }

  showSelectableLocation(show: boolean) {
    this.showSelectable = show;
    this.selectedLocations = show ? this.selectableLocations : this.locations;
  }

  handleErrors() {
    this.actionInProgress = false;
    this.loading = false;
    this.snackBarService.openSnackBar({
      variant: 'error',
      icon: 'warning',
      text: this.translateService.instant('ERROR.REQUEST'),
      duration: 10000,
      dismissable: true,
    });

    return EMPTY;
  }

  fetchLocations() {
    this.integrationDataService
      .getUserAccounts(this.data.integrationId, this.selectedStore._id)
      .pipe(
        first(),
        catchError(() => this.handleErrors()),
      )
      .subscribe((locations) => {
        if (!locations?.length) {
          this.integrationDialogService.navigateToDialogRoute(AuthType.NONE, this.providerId, 'empty');

          return;
        }

        this.locations = this.integrationDataHelperService.getSortedAccounts(locations);
        this.selectableLocations = this.locations.filter(this.isSelectableAccount);
        this.selectedLocations = this.selectableLocations;
        this.providerId = this.selectableLocations?.[0]?.providerId || '';
        this.loading = false;
      });
  }

  isSelectableAccount(account: AccountDetails) {
    return account?.state?.selectable;
  }

  createAuthentication() {
    this.integrationDataService
      .createAuthenticationforUser(this.data.integrationId!, this.selectedStore._id, {
        credential: null,
        authTokenId: null,
      })
      .pipe(
        first(),
        catchError(() => this.handleErrors()),
      )
      .subscribe(() => {
        this.fetchLocations();
      });
  }

  createConnection() {
    this.actionInProgress = true;
    this.integrationDataService
      .connectAccountForIntegration(this.data.integrationId!, this.selectedStore._id, {
        configuration: null,
        providerId: this.providerId,
      })
      .pipe(
        first(),
        catchError(() => this.handleErrors()),
      )
      .subscribe(() => {
        this.segmentService.track(TRACK_INTEGRATIONS.connectedAccount(this.data.integration?.provider!), {
          provider: this.data.integration?.provider,
          storeId: this.selectedStore._id,
          selfConnected: this.selfConnected,
        });
        this.integrationDialogService.closeDialog();
        if (this.data.onConnect) {
          this.actionInProgress = false;
          this.data.onConnect();
        }
      });
  }

  contactSupport(): void {
    this.intercomService.show();
  }

  onCancel(): void {
    this.integrationDialogService.closeDialog();
  }

  locationChangeHandler(locationId: string): void {
    this.providerId = locationId;
    this.showLocationSelectError = !this.providerId;
  }

  onSubmit(): void {
    if (this.locations.length === 0) {
      return;
    }
    if (!this.selectedStore || !this.providerId) {
      this.showLocationSelectError = true;
    } else {
      this.actionInProgress = true;
      this.createConnection();
    }
  }
}
