/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import {
  LoadList,
  LoadListSuccess,
  LoadListFail,
  LoadObject,
  LoadObjectSuccess,
  LoadObjectFail,
  CreateObject,
  CreateObjectSuccess,
  CreateObjectFail,
  UpdateObject,
  UpdateObjectSuccess,
  UpdateObjectFail,
  DeleteObject,
  DeleteObjectSuccess,
  DeleteObjectFail,
} from '@core/redux/crud/crud.action';
import { User } from '@core/models/user.model';

export enum UserActionTypes {
  LoadUserList = '[User] Load user list',
  LoadUserListSuccess = '[User] Load user list success',
  LoadUserListFail = '[User] Load user list fail',

  LoadUser = '[User] Load user',
  LoadUserSuccess = '[User] Load user success',
  LoadUserFail = '[User] Load user fail',

  CreateUser = '[User] Create user',
  CreateUserSuccess = '[User] Create user success',
  CreateUserFail = '[User] Create user fail',

  UpdateUser = '[User] Update user',
  UpdateUserSuccess = '[User] Update user success',
  UpdateUserFail = '[User] Update user fail',

  DeleteUser = '[User] Delete user',
  DeleteUserSuccess = '[User] Delete user success',
  DeleteUserFail = '[User] Delete user fail',
}

/** ******************************************* */
// Load user list
/** ****************************************** */
export class LoadUserList extends LoadList implements Action {
  readonly type = UserActionTypes.LoadUserList;
}

export class LoadUserListSuccess extends LoadListSuccess<User> implements Action {
  readonly type = UserActionTypes.LoadUserListSuccess;
}

export class LoadUserListFail extends LoadListFail implements Action {
  readonly type = UserActionTypes.LoadUserListFail;
}

/** ******************************************* */
// Load user
/** ****************************************** */
export class LoadUser extends LoadObject implements Action {
  readonly type = UserActionTypes.LoadUser;
}

export class LoadUserSuccess extends LoadObjectSuccess<User> implements Action {
  readonly type = UserActionTypes.LoadUserSuccess;
}

export class LoadUserFail extends LoadObjectFail implements Action {
  readonly type = UserActionTypes.LoadUserFail;
}

/** ******************************************* */
// Create user
/** ****************************************** */
export class CreateUser extends CreateObject<User> implements Action {
  readonly type = UserActionTypes.CreateUser;
}

export class CreateUserSuccess extends CreateObjectSuccess<User> implements Action {
  readonly type = UserActionTypes.CreateUserSuccess;
}

export class CreateUserFail extends CreateObjectFail implements Action {
  readonly type = UserActionTypes.CreateUserFail;
}

/** ******************************************* */
// Update user
/** ****************************************** */
export class UpdateUser extends UpdateObject<User> implements Action {
  readonly type = UserActionTypes.UpdateUser;
}

export class UpdateUserSuccess extends UpdateObjectSuccess<User> implements Action {
  readonly type = UserActionTypes.UpdateUserSuccess;
}

export class UpdateUserFail extends UpdateObjectFail implements Action {
  readonly type = UserActionTypes.UpdateUserFail;
}

/** ******************************************* */
// Delete user
/** ****************************************** */
export class DeleteUser extends DeleteObject implements Action {
  readonly type = UserActionTypes.DeleteUser;
}

export class DeleteUserSuccess extends DeleteObjectSuccess implements Action {
  readonly type = UserActionTypes.DeleteUserSuccess;
}

export class DeleteUserFail extends DeleteObjectFail implements Action {
  readonly type = UserActionTypes.DeleteUserFail;
}

export type UserAction =
  | LoadUserList
  | LoadUserListSuccess
  | LoadUserListFail
  | LoadUser
  | LoadUserSuccess
  | LoadUserFail
  | CreateUser
  | CreateUserSuccess
  | CreateUserFail
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserFail;
