<div class="actions-container p-1 mx-auto">
  <button coreIconButton variant="light" icon="close" (click)="removeOutlet()"></button>
</div>
<div class="preview-container">
  <core-post-device-preview
    class="m-auto"
    [mediaVariant]="data?.post?.mediaVariant"
    [slides]="data?.post?.slides"
    [counts]="data?.post?.counts"
    [owner]="data?.post?.owner"
    [date]="data?.post?.date"
    [caption]="data?.post?.caption">
  </core-post-device-preview>
</div>
