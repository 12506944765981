<core-dialog
  [canGoBack]="false"
  [headerConfig]="{
    title: 'USER.INVITE.DIALOG.TITLE' | translate,
    subtitle: 'USER.INVITE.DIALOG.SUBTITLE' | translate
  }">
  <div class="row my-auto mx-n2">
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-0">
      <core-svg
        svg="img_celebrate">
      </core-svg>
    </div>
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <h5>{{ 'USER.INVITE.SUCCESS.TITLE' | translate }}</h5>
      <p [innerHTML]="'USER.INVITE.SUCCESS.TEXT' | translate: user"></p>
    </div>
  </div>
  <div
    coreDialogFooter
    class="d-flex flex-column flex-md-row justify-content-md-end flex-grow-1">
    <core-form-actions
      [submitLabel]="'USER.INVITE.SUCCESS.DONE' | translate"
      [cancelLabel]="'USER.INVITE.SUCCESS.NEW_INVITE' | translate"
      (submitClick)="closeDialog()"
      (cancelClick)="sendNewInvitation()">
    </core-form-actions>
  </div>
</core-dialog>
