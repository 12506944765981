import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { StarComponent } from './components/star/star.component';

@NgModule({
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
  declarations: [StarRatingComponent, StarComponent],
  exports: [StarRatingComponent, StarComponent],
})
export class StarRatingModule {}
