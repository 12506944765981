<core-dialog
  [style.width.px]="1024"
  [style.height]="'unset'"
  [actionInProgress]="actionInProgress"
  [headerConfig]="{
    title: 'POST_MANAGER.TITLE.UPDATE' | translate
  }"
  [footerConfig]="{
    submitLabel: (!formGroup.controls?.schedule?.value ? 'POST_MANAGER.ACTION.PUBLISH' : 'POST_MANAGER.ACTION.UPDATE') | translate,
    cancelLabel: 'ACTION.CANCEL' | translate
  }"
  [changesConfig]="{
    title: 'POST_MANAGER.CONFIRMATION.UNSAVED_CHANGES.TITLE' | translate,
    text: 'POST_MANAGER.CONFIRMATION.UNSAVED_CHANGES.TEXT' | translate,
    submitLabel: 'POST_MANAGER.CONFIRMATION.UNSAVED_CHANGES.ACTION.SUBMIT' | translate
  }"
  [confirmSubmitConfig]="{
    title: 'POST_MANAGER.CONFIRMATION.PUBLISH.TITLE' | translate,
    text: 'POST_MANAGER.CONFIRMATION.PUBLISH.TEXT' | translate,
    submitLabel: 'POST_MANAGER.CONFIRMATION.PUBLISH.ACTION.SUBMIT' | translate
  }"
  [unsavedChanges]="formGroup?.dirty"
  [confirmSubmit]="!formGroup.controls?.schedule?.value && formGroup.valid && !generating"
  (dismiss)="cancel()"
  (submit)="submit()">
  <form [formGroup]="formGroup" class="row my-auto mx-0 mx-sm-n2 flex-grow-1">
    <div class="col-12 col-sm-8 px-0 px-sm-2 d-flex flex-column justify-content-start align-items-stretch pb-2 pb-sm-0">

      <section class="mb-2 d-flex flex-column">
        <h6 class="mb-2">
          {{ 'POST_MANAGER.SECTION.PROVIDERS' | translate }}
          <strong
            *ngIf="formGroup.get('providers')?.hasError('required') && formGroup.get('providers')?.touched"
            class="form-validation-error">
            {{ 'POST_MANAGER.VALIDATION.PROVIDERS' | translate }}
          </strong>
        </h6>
        <core-provider-selector
          [selectedStore]="selectedStore"
          [disabledProviders]="disabledProviders"
          [disabledReason]="'POST_MANAGER.ACCOUNT.FEED_ONLY_BADGE' | translate"
          [providers]="post?.providers"
          [hasError]="formGroup.controls.providers.invalid"
          (providersChange)="formGroup.controls.providers.setValue($event); formGroup.get('providers')?.markAsTouched()"
          (connectClick)="removeOutlet()">
        </core-provider-selector>
      </section>

      <section class="mb-3 d-flex flex-column">
        <h6>{{ 'POST_MANAGER.SECTION.PUBLISH_AT' | translate }}
          <strong
            *ngIf="formGroup.controls?.publishAt?.invalid && formGroup.controls?.publishAt?.touched"
            class="form-validation-error">
            <ng-container *ngIf="formGroup?.controls.publishAt?.hasError('required'); else invalidTemplate">
              {{ 'POST_MANAGER.VALIDATION.PUBLISH_DATE.REQUIRED' | translate: { toggleLabel: ('POST_MANAGER.ACTION.SCHEDULE' | translate) } }}
            </ng-container>
            <ng-template #invalidTemplate>
              {{ 'POST_MANAGER.VALIDATION.PUBLISH_DATE.INVALID_TIME' | translate: { minutes: minsInFutureRequired } }}
            </ng-template>
          </strong>
        </h6>
        <core-publish-date-input
          [formGroup]="formGroup"
          [minsInFutureRequired]="minsInFutureRequired"
          [publishAtValidators]="publishAtValidators">
        </core-publish-date-input>
      </section>

      <ng-container
        *ngIf="formGroup.get('managedPostType')?.value !== 'STORY'">
        <section class="mb-0 d-flex flex-column">
          <ng-container *ngIf="showOutline; else showCaption">
            <div class="d-flex align-items-end justify-content-between mb-2">
              <h6 class="mb-0">
                {{ 'POST_MANAGER.SECTION.CAPTION_OUTLINE' | translate }}
              </h6>
              <button coreButton type="button" size="sm" variant="text" (click)="showOutline = false">
                {{ 'POST_MANAGER.OUTLINE_TOGGLE.HIDE' | translate }}
              </button>
            </div>
            <div class="d-flex align-items-start mb-3">
              <core-form-field
                class="flex-grow-1 d-flex flex-column"
                [includeSubscriptSpacer]="false">
                <textarea coreInput formControlName="captionOutline" rows="2" [placeholder]="'POST_MANAGER.PLACEHOLDER.CAPTION_OUTLINE' | translate"></textarea>
                <div class="textarea-action-container">
                  <div>{{ 'POST_MANAGER.HELPER_TEXT.AI_CAPTION' | translate }}</div>
                  <button
                    [style.min-width.px]="124"
                    class="ml-2"
                    coreButton
                    size="sm"
                    variant="primary"
                    [actionInProgress]="generating"
                    icon="magic_wand"
                    (click)="generateCaption()"
                    type="button">
                    {{ 'ACTION.GENERATE' | translate }}
                  </button>
                </div>
              </core-form-field>
            </div>
          </ng-container>

          <ng-template #showCaption>
            <div class="d-flex align-items-end justify-content-between mb-2">
              <h6 class="mb-0">
                {{ 'POST_MANAGER.SECTION.CAPTION' | translate }}
                <strong
                  *ngIf="formGroup.get('captionText')?.hasError('required') && formGroup.get('captionText')?.touched && !generating"
                  class="form-validation-error">
                  {{ 'POST_MANAGER.VALIDATION.CAPTION' | translate: { ctaLabel: ('POST_MANAGER.OUTLINE_TOGGLE.SHOW' | translate) } }}
                </strong>
                <strong
                  *ngIf="formGroup.get('captionText')?.hasError('exceedingHashtagNumber') && formGroup.get('captionText')?.touched && !generating"
                  class="form-validation-error">
                    {{ 'POST_MANAGER.VALIDATION.CAPTION_HASHTAG_LIMIT' | translate: { limit: hashtagLimit } }}
                </strong>
                <strong
                  *ngIf="formGroup.get('captionText')?.hasError('exceedingMentionNumber') && formGroup.get('captionText')?.touched && !generating"
                  class="form-validation-error">
                    {{ 'POST_MANAGER.VALIDATION.CAPTION_MENTION_LIMIT' | translate: { limit: mentionLimit } }}
                </strong>
                <strong
                  *ngIf="generating"
                  class="generating">
                  {{ 'POST_MANAGER.SUBSCRIPT.CAPTION.GENARATING' | translate }}
                </strong>
              </h6>

              <div class="d-flex align-items-center" *ngIf="!showOutline && !generating">
                @if (!isWebView) {
                  <core-emoji-picker class="mx-2" (emojiSelect)="onEmojiSelect($event)"></core-emoji-picker>
                }
                <button
                  coreButton
                  type="button" icon="magic_wand" size="sm"
                  (click)="showOutline = true"
                  variant="default">
                  {{ 'POST_MANAGER.OUTLINE_TOGGLE.SHOW' | translate }}
                </button>
              </div>
            </div>
            <core-form-field
              [style.pointer-events]="generating ? 'none' : null"
              [includeSubscriptSpacer]="false"
              class="flex-grow-1 d-flex flex-column">
              <core-icon class="align-self-start" coreFormFieldPrefix icon="content" size="sm"></core-icon>
              <textarea
                coreInput
                cdkTextareaAutosize
                formControlName="captionText"
                [placeholder]="'POST_MANAGER.PLACEHOLDER.CAPTION.DEFAULT' | translate"
                cdkAutosizeMinRows="6">
              </textarea>
            </core-form-field>
            <core-linear-progress-bar class="mt-1" *ngIf="generating" mode="indeterminate" variant="primary"></core-linear-progress-bar>
            <div class="mb-3"></div>
          </ng-template>
        </section>
      </ng-container>
    </div>

    <div class="image-column col-12 col-sm-4 px-0 px-sm-2 d-flex flex-column justify-content-stretch align-items-stretch pb-4">
      <core-post-card
        class="flex-grow-0"
        [title]="'POST.HEADER.POST_KIND.' + formGroup.controls.managedPostType.value | translate"
        icon="social"
        [cardVariant]="formGroup.controls.managedPostType?.value === 'STORY' ? 'content-overlay' : 'default'"
        [mediaVariant]="post?.post?.resources?.[0]?.type === 'VIDEO' ? 'VIDEO' : 'IMAGE'"
        [isCaptionTruncated]="false"
        [slides]="slides"
        [date]="formGroup.controls?.publishAt?.value"
        [caption]="formGroup.controls?.captionText?.value">
      </core-post-card>
    </div>
  </form>
  <div
    coreDialogFooter
    class="d-flex flex-column flex-md-row justify-content-md-between flex-grow-1">
    <button coreButton variant="text" (click)="rejectPost()">{{ 'POST_MANAGER.ACTION.GOTO_REJECT' | translate }}</button>
    <div class="spacer"></div>
  </div>
</core-dialog>
