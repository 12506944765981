import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { AddressTypes } from '@core/interfaces/address.interface';
import { ContactPerson } from '@core/interfaces/contact.interface';

/**
 * Frontend model for a company object
 */
export class Company {
  readonly _id: string;

  description: string;

  name: string;

  addresses: AddressTypes;

  contactPersons: ContactPerson;

  constructor(object: Company) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = object._id;
    this.description = object.description;
    this.name = object.name;
    this.addresses = object.addresses;
    this.contactPersons = object.contactPersons;
  }
}

/**
 * Adapter for the company object
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyAdapter implements Adapter<Company> {
  clientAdapt(object: any): Company {
    return new Company(object);
  }

  serverAdapt(object: any): Company {
    return new Company(object);
  }
}
