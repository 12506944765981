import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgModule } from '@core/ui/svg/svg.module';
import { SpinnerComponent } from './spinner.component';

const components = [SpinnerComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, SvgModule],
  exports: [...components],
})
export class SpinnerModule {}
