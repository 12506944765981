/**
 * Language local storage key
 */
export const CURRENT_LANGUAGE = 'language';

/**
 * Currency local storage key
 */
export const CURRENT_CURRENCY = 'currency';

/**
 * Filter local storage key
 */
export const CURRENT_FILTER = 'filter';

/**
 * Store id locale storage key
 */
export const CURRENT_STORE_ID = 'storeId';

/**
 * Customer Service Manager state storage key
 */
export const CS_MANAGER_STATE = 'csManagerState';

/**
 * Theme local storage key
 */
export const CURRENT_THEME = 'theme';

/**
 * Access token local storage key
 */
export const ACCESS_TOKEN = 'accessToken';

/**
 * Email address of last login local storage key
 */
export const LAST_LOGIN_USER = 'lastLoginUser';

/**
 * Email address of the current Intercom user
 */
export const INTERCOM_USER = 'intercomUser';

/**
 * Expand or collapse state of the navigation
 */
export const NAVIGATION_DEFAULT_STATE = 'navigationDefaultState';
