import { Injectable } from '@angular/core';
import { BrandConfig } from '@core/interfaces/brand-config.interface';
import { Subject } from 'rxjs';
import _merge from 'lodash/merge';

@Injectable({
  providedIn: 'root',
})
export class ImageAssetRegistryService {
  readonly assetsBasePath = 'assets/core';

  brandPath = '';

  readonly defaultAvatars = {
    company: `${this.assetsBasePath}/svg/avatar/avatar_company.svg`,
    store: `${this.assetsBasePath}/svg/avatar/avatar_store.svg`,
    user: `${this.assetsBasePath}/svg/avatar/avatar_person.svg`,
  };

  readonly defaultBackgrounds = {
    authBackground: `${this.assetsBasePath}/img/auth_bg_light.svg`,
    iphone: `${this.assetsBasePath}/img/iphone_light.svg`,
    pendingStateBackground: `${this.assetsBasePath}/img/alphin_icon_dialog_bg.svg`,
    noVideoThumbnailBackground: `${this.assetsBasePath}/img/alphin_pin_icon_bg.svg`,
  };

  readonly defaultBackgroundTextures = {
    kpiCard: `${this.assetsBasePath}/img/sample_background_image.png`,
    brokenMedia: `${this.assetsBasePath}/img/sample_background_image.png`,
  };

  avatars = this.defaultAvatars;

  backgrounds = this.defaultBackgrounds;

  backgroundTextures = this.defaultBackgroundTextures;

  imagesUpdated$ = new Subject<BrandConfig['images']>();

  setBrandImages(config: BrandConfig): void {
    if (!config.brand || !config.theme) {
      return;
    }
    this.brandPath = `${this.assetsBasePath}/brands/${config.brand}`;
    const imgConfig = this.getConfigWithFullPaths(config.images);
    this.avatars = _merge(this.avatars, imgConfig.avatars);
    this.backgrounds = _merge(this.backgrounds, imgConfig.backgrounds);
    this.backgroundTextures = _merge(this.backgroundTextures, imgConfig.backgroundTextures);
    this.imagesUpdated$.next(imgConfig);
  }

  private getConfigWithFullPaths(imgConfig: BrandConfig['images']): BrandConfig['images'] {
    const updatedConfig: BrandConfig['images'] = {
      avatars: {},
      backgrounds: {},
      backgroundTextures: {},
    };
    if (!imgConfig) {
      return updatedConfig;
    }
    Object.keys(imgConfig).forEach((typeKey) => {
      const type = typeKey as unknown as keyof BrandConfig['images'];
      const typeConfig = imgConfig?.[type];
      Object.keys(typeConfig).forEach((imageKey) => {
        const imagePath = (typeConfig as any)[imageKey];
        const fullPath = imagePath.startsWith('assets') ? `${this.brandPath}/${imagePath}` : imagePath;
        (updatedConfig[type] as any)[imageKey] = fullPath;
      });
    });

    return updatedConfig;
  }
}
