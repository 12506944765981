/* eslint-disable no-plusplus */
import { getSupportedInputTypes, Platform } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  InjectionToken,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';
import { Nullable } from '@core/types/nullable.type';
import { getInputUnsupportedTypeError } from '../variables/form-field-usage-errors.variable';
import { FormFieldControlBaseDirective } from './form-field-control-base.directive';

export const INPUT_VALUE_ACCESSOR = new InjectionToken<{ value: any }>('INPUT_VALUE_ACCESSOR');

// Invalid input type. Using one of these will throw an InputUnsupportedTypeError.
const INPUT_INVALID_TYPES = ['button', 'checkbox', 'file', 'hidden', 'image', 'radio', 'range', 'reset', 'submit'];

let nextUniqueId = 0;

/** Directive that allows a native input to work inside a `FormField`. */
@Directive({
  selector: `input[coreInput], textarea[coreInput], select[coreNativeControl],
      input[coreNativeControl], textarea[coreNativeControl]`,
  exportAs: 'coreInput',
  host: {
    /**
     * @breaking-change 8.0.0 remove .form-field-autofill-control in favor of AutofillMonitor.
     */
    class: 'core-input-element core-form-field-autofill-control',
    '[class.core-input-server]': 'isServer',
    // Native input properties that are overwritten by Angular inputs need to be synced with
    // the native input element. Otherwise property bindings for those don't work.
    '[attr.id]': 'id',
    // At the time of writing, we have a lot of customer tests that look up the input based on its
    // placeholder. Since we sometimes omit the placeholder attribute from the DOM to prevent screen
    // readers from reading it twice, we have to keep it somewhere in the DOM for the lookup.
    '[attr.data-placeholder]': 'placeholder',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.readonly]': 'readonly && !isNativeSelect || null',
    '[attr.aria-invalid]': 'errorState',
    '[attr.aria-required]': 'required.toString()',
  },
  providers: [{ provide: FormFieldControlBaseDirective, useExisting: InputDirective }],
})
export class InputDirective
  implements FormFieldControlBaseDirective<any>, OnChanges, OnDestroy, AfterViewInit, DoCheck, CanUpdateErrorState
{
  protected _uid = `core-input-${nextUniqueId++}`;

  protected _previousNativeValue: any;

  private _inputValueAccessor: { value: any };

  private _previousPlaceholder: Nullable<string> = null;

  /** Whether the component is being rendered on the server. */
  readonly isServer: boolean;

  /** Whether the component is a native html select. */
  readonly isNativeSelect: boolean;

  /** Whether the component is a textarea. */
  readonly isTextarea: boolean;

  focused = false;

  readonly stateChanges$: Subject<void> = new Subject<void>();

  controlType = 'input';

  autofilled = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }

    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges$.next();
    }
  }

  protected _disabled = false;

  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  protected _id = this._uid;

  @Input()
  placeholder = '';

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  protected _required = false;

  /** Input type of the element. */
  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
    this.validateType();

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this.isTextarea && getSupportedInputTypes().has(this._type)) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }

  protected _type = 'text';

  /** An object used to control when error messages are shown. */
  @Input()
  errorStateMatcher!: ErrorStateMatcher;

  @Input('aria-describedby') userAriaDescribedBy = '';

  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }

  set value(value: string) {
    if (value !== this.value) {
      this._inputValueAccessor.value = coerceBooleanProperty(value);
      this.stateChanges$.next();
    }
  }

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(value: boolean) {
    this._readonly = value;
  }

  private _readonly = false;

  protected neverEmptyInputTypes = ['date', 'datetime', 'datetime-local', 'month', 'time', 'week'].filter((t) =>
    getSupportedInputTypes().has(t),
  );

  /** Whether the component is in an error state. */
  errorState = false;

  constructor(
    protected elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    protected platform: Platform,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() @Inject(INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    private autofillMonitor: AutofillMonitor,
    ngZone: NgZone,
  ) {
    const element = this.elementRef.nativeElement;
    const nodeName = element.nodeName.toLowerCase();

    // If no input value accessor was explicitly specified, use the element as the input value
    // accessor.
    this._inputValueAccessor = inputValueAccessor || element;

    this._previousNativeValue = this.value;

    // On some versions of iOS the caret gets stuck in the wrong place when holding down the delete
    // key. In order to get around this we need to "jiggle" the caret loose. Since this bug only
    // exists on iOS, we only bother to install the listener on iOS.
    if (platform.IOS) {
      ngZone.runOutsideAngular(() => {
        elementRef.nativeElement.addEventListener('keyup', (event: Event) => {
          const el = event.target as HTMLInputElement;
          if (!el.value && !el.selectionStart && !el.selectionEnd) {
            // Note: Just setting `0, 0` doesn't fix the issue. Setting
            // `1, 1` fixes it for the first time that you type text and
            // then hold delete. Toggling to `1, 1` and then back to
            // `0, 0` seems to completely fix it.
            el.setSelectionRange(1, 1);
            el.setSelectionRange(0, 0);
          }
        });
      });
    }

    this.isServer = !this.platform.isBrowser;
    this.isNativeSelect = nodeName === 'select';
    this.isTextarea = nodeName === 'textarea';

    if (this.isNativeSelect) {
      this.controlType = (element as HTMLSelectElement).multiple ? 'native-select-multiple' : 'native-select';
    }
    if (this.isTextarea) {
      this.controlType = 'textarea';
    }
  }

  ngAfterViewInit() {
    if (this.platform.isBrowser) {
      this.autofillMonitor.monitor(this.elementRef.nativeElement).subscribe((event) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges$.next();
      });
    }
  }

  ngOnChanges() {
    this.stateChanges$.next();
  }

  ngOnDestroy() {
    this.stateChanges$.complete();

    if (this.platform.isBrowser) {
      this.autofillMonitor.stopMonitoring(this.elementRef.nativeElement);
    }
  }

  updateErrorState(): void {
    // TBD
  }

  ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }

    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this.dirtyCheckNativeValue();

    // We need to dirty-check and set the placeholder attribute ourselves, because whether it's
    // present or not depends on a query which is prone to "changed after checked" errors.
    this.dirtyCheckPlaceholder();
  }

  /** Focuses the input. */
  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  // We have to use a `HostListener` here in order to support both Ivy and ViewEngine.
  // In Ivy the `host` bindings will be merged when this class is extended, whereas in
  // ViewEngine they're overwritten.
  // TODO(crisbeto): we move this back into `host` once Ivy is turned on by default.
  /** Callback for the cases where the focused state of the input changes. */
  // tslint:disable:no-host-decorator-in-concrete
  @HostListener('focus', ['true'])
  @HostListener('blur', ['false'])
  // tslint:enable:no-host-decorator-in-concrete
  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges$.next();
    }
  }

  // We have to use a `HostListener` here in order to support both Ivy and ViewEngine.
  // In Ivy the `host` bindings will be merged when this class is extended, whereas in
  // ViewEngine they're overwritten.
  // TODO(crisbeto): we move this back into `host` once Ivy is turned on by default.
  // tslint:disable-next-line:no-host-decorator-in-concrete
  @HostListener('input')
  _onInput() {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }

  /** Does some manual dirty checking on the native input `placeholder` attribute. */
  private dirtyCheckPlaceholder() {
    // If we're hiding the native placeholder, it should also be cleared from the DOM, otherwise
    // screen readers will read it out twice: once from the label and once from the attribute.
    // TODO: can be removed once we get rid of the `legacy` style for the form field, because it's
    // the only one that supports promoting the placeholder to a label.
    const { placeholder } = this;
    if (placeholder !== this._previousPlaceholder) {
      const element = this.elementRef.nativeElement;
      this._previousPlaceholder = placeholder;
      if (placeholder) {
        element.setAttribute('placeholder', placeholder);
      } else {
        element.removeAttribute('placeholder');
      }
    }
  }

  /** Does some manual dirty checking on the native input `value` property. */
  protected dirtyCheckNativeValue() {
    const newValue = this.elementRef.nativeElement.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges$.next();
    }
  }

  /** Make sure the input is a supported type. */
  protected validateType() {
    if (INPUT_INVALID_TYPES.indexOf(this._type) > -1) {
      throw getInputUnsupportedTypeError(this._type);
    }
  }

  /** Checks whether the input type is one of the types that are never empty. */
  protected isNeverEmpty() {
    return this.neverEmptyInputTypes.indexOf(this._type) > -1;
  }

  /** Checks whether the input is invalid based on the native validation. */
  protected isBadInput() {
    // The `validity` property won't be present on platform-server.
    const { validity } = this.elementRef.nativeElement as HTMLInputElement;

    return validity && validity.badInput;
  }

  get empty(): boolean {
    return !this.isNeverEmpty() && !this.elementRef.nativeElement.value && !this.isBadInput() && !this.autofilled;
  }

  get shouldLabelFloat(): boolean {
    if (this.isNativeSelect) {
      // For a single-selection `<select>`, the label should float when the selected option has
      // a non-empty display value. For a `<select multiple>`, the label *always* floats to avoid
      // overlapping the label with the options.
      const selectElement = this.elementRef.nativeElement as HTMLSelectElement;
      const firstOption: HTMLOptionElement | undefined = selectElement.options[0];

      // On most browsers the `selectedIndex` will always be 0, however on IE and Edge it'll be
      // -1 if the `value` is set to something, that isn't in the list of options, at a later point.
      return (
        this.focused ||
        selectElement.multiple ||
        !this.empty ||
        !!(selectElement.selectedIndex > -1 && firstOption && firstOption.label)
      );
    }

    return this.focused || !this.empty;
  }

  setDescribedByIds(ids: string[]) {
    if (ids.length) {
      this.elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    } else {
      this.elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  onContainerClick() {
    // Do not re-focus the input element if the element is already focused. Otherwise it can happen
    // that someone clicks on a time input and the cursor resets to the "hours" field while the
    // "minutes" field was actually clicked. See: https://github.com/angular/components/issues/12849
    if (!this.focused) {
      this.focus();
    }
  }
}
