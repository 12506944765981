// Based on Material Select: https://github.com/angular/components/blob/abbc044545e9575fdf79e9b8e216e8ce01359236/src/material/select/select.ts

/* eslint-disable no-plusplus */
/* eslint-disable max-classes-per-file */

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Nullable } from '@core/types/nullable.type';
import {
  ActiveDescendantKeyManager,
  LiveAnnouncer,
  addAriaReferencedId,
  removeAriaReferencedId,
} from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { SelectionModel } from '@angular/cdk/collections';
import { hasModifierKey } from '@angular/cdk/keycodes';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedPosition,
  Overlay,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  booleanAttribute,
  inject,
  numberAttribute,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { defer, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { FormFieldControlBaseDirective } from '@core/ui/form-field/directives/form-field-control-base.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FORM_FIELD, FormFieldComponent } from '@core/ui/form-field/form-field.component';
import { ErrorStateTracker } from '@core/ui/form-field/models/error-state-tracker.model';
import { SELECT_TRIGGER, SelectTriggerDirective } from '../../directives/select-trigger.directive';
import { SelectConfig } from '../../types/select.type';
import { OptionComponent, getOptionScrollPosition } from '../option/option.component';
import { OPTION_PARENT_COMPONENT } from '../../types/option.type';
import { SELECT_CONFIG } from '../../variables/select.variable';

let nextUniqueId = 0;

/** Injection token that determines the scroll handling while a select is open. */
export const SELECT_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('core-select-scroll-strategy', {
  providedIn: 'root',
  factory: () => {
    const overlay = inject(Overlay);

    return () => overlay.scrollStrategies.reposition();
  },
});

export function scrollStrategyProviderFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const SELECT_SCROLL_STRATEGY_PROVIDER = {
  provide: SELECT_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: scrollStrategyProviderFactory,
};

/** Change event object that is emitted when the select value has changed. */
export class SelectChangeEvent {
  constructor(
    /** Reference to the select that emitted the change event. */
    public source: SelectComponent,
    /** Current value of the select that emitted the event. */
    public value: any,
  ) {}
}

@Component({
  selector: 'core-select',
  exportAs: 'coreSelect',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[role]': '"combobox"',
    'aria-autocomplete': 'none',
    'aria-haspopup': 'listbox',
    '[class]': '"core-select core-input-element"',
    '[attr.id]': 'id',
    '[attr.tabindex]': 'disabled ? -1 : tabIndex',
    '[attr.aria-controls]': 'panelOpen ? id + "-panel" : null',
    '[attr.aria-expanded]': 'panelOpen',
    '[attr.aria-label]': 'ariaLabel || null',
    '[attr.aria-required]': 'required.toString()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[attr.aria-invalid]': 'errorState',
    '[attr.aria-activedescendant]': 'getAriaActiveDescendant()',
    '[class.core-select-disabled]': 'disabled',
    '[class.core-select-invalid]': 'errorState',
    '[class.core-select-required]': 'required',
    '[class.core-select-empty]': 'empty',
    '[class.core-select-multiple]': 'multiple',
    '(keydown)': 'handleKeydown($event)',
    '(focus)': 'onFocus()',
    '(blur)': 'onBlur()',
  },
  providers: [
    { provide: FormFieldControlBaseDirective, useExisting: SelectComponent },
    { provide: OPTION_PARENT_COMPONENT, useExisting: SelectComponent },
  ],
  animations: [
    trigger('selectTriggerRotate', [
      state('down', style({ transform: 'rotate(0deg)' })),
      state('up', style({ transform: 'rotate(180deg)' })),
      transition('down <=> up', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class SelectComponent
  implements
    AfterContentInit,
    OnChanges,
    OnDestroy,
    OnInit,
    DoCheck,
    ControlValueAccessor,
    FormFieldControlBaseDirective<any>
{
  /**
   * All of the defined select options
   */
  @ContentChildren(OptionComponent, { descendants: true })
  options?: QueryList<OptionComponent>;

  /**
   * User-supplied override of the trigger element
   */
  @ContentChild(SELECT_TRIGGER)
  customTrigger?: SelectTriggerDirective;

  /**
   * This position config ensures that the top "start" corner of the overlay
   * is aligned with with the top "start" of the origin by default (overlapping
   * the trigger completely). If the panel cannot fit below the trigger, it
   * will fall back to a position above the trigger.
   */
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: 'core-select-panel-above',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      panelClass: 'core-select-panel-above',
    },
  ];

  /**
   * Scrolls a particular option into the view
   */
  scrollOptionIntoView(index: number): void {
    const option = this.options?.toArray()[index];

    if (option) {
      const panel: HTMLElement = this.panel.nativeElement;
      const labelCount = 1;
      const element = option.getHostElement();

      if (index === 0 && labelCount === 1) {
        // If we've got one group label before the option and we're at the top option,
        // scroll the list to the top. This is better UX than scrolling the list to the
        // top of the option, because it allows the user to read the top group's label.
        panel.scrollTop = 0;
      } else {
        panel.scrollTop = getOptionScrollPosition(
          element.offsetTop,
          element.offsetHeight,
          panel.scrollTop,
          panel.offsetHeight,
        );
      }
    }
  }

  /**
   * Called when the panel has been opened and the overlay has settled on its final position
   */
  private _positioningSettled(): void {
    this.scrollOptionIntoView(this.keyManager.activeItemIndex || 0);
  }

  /**
   * Creates a change event object that should be emitted by the select
   */
  private getChangeEvent(value: any) {
    return new SelectChangeEvent(this, value);
  }

  /**
   * Factory function used to create a scroll strategy for this select
   */
  private _scrollStrategyFactory: () => ScrollStrategy;

  /**
   * Whether or not the overlay panel is open
   */
  private _panelOpen = false;

  /**
   * Comparison function to specify which option is displayed. Defaults to object equality
   */
  private _compareWith = (o1: any, o2: any) => o1 === o2;

  /**
   * Unique id for this input
   */
  private _uid = `core-select-${nextUniqueId++}`;

  /**
   * Current `aria-labelledby` value for the select trigger
   */
  private _triggerAriaLabelledBy: Nullable<string> = null;

  /**
   * Keeps track of the previous form control assigned to the select.
   * Used to detect if it has changed.
   */
  private _previousControl: AbstractControl | null | undefined;

  /**
   * Emits whenever the component is destroyed
   */
  protected readonly destroyed$ = new Subject<void>();

  /**
   * Tracks the error state of the select
   */
  private _errorStateTracker: ErrorStateTracker;

  /**
   * Emits whenever the component state changes and should cause the parent
   * form-field to update. Implemented as part of `MatFormFieldControl`.
   * @docs-private
   */
  readonly stateChanges$ = new Subject<void>();

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  @Input('aria-describedby')
  userAriaDescribedBy = null;

  /**
   * Deals with the selection logic
   */
  selectionModel!: SelectionModel<OptionComponent>;

  /**
   * Manages keyboard events for options in the panel
   */
  keyManager!: ActiveDescendantKeyManager<OptionComponent>;

  /**
   * Ideal origin for the overlay panel
   */
  preferredOverlayOrigin?: CdkOverlayOrigin | ElementRef;

  /**
   * Width of the overlay panel
   */
  _overlayWidth?: string | number;

  /**
   * View -> model callback called when value changes
   */
  onChange: (value: any) => void = () => {};

  /**
   * View -> model callback called when select has been touched
   */
  onTouched = () => {};

  /**
   * ID for the DOM node containing the select's value
   */
  _valueId = `core-select-value-${nextUniqueId++}`;

  /**
   * Emits when the panel element is finished transforming in
   */
  readonly panelDoneAnimatingStream$ = new Subject<string>();

  /**
   * Strategy that will be used to handle scrolling while the select panel is open
   */
  _scrollStrategy: ScrollStrategy;

  _overlayPanelClass: string | string[] = this._defaultOptions?.overlayPanelClass || '';

  /**
   * Whether the select is focused.
   */
  get focused(): boolean {
    return this._focused || this._panelOpen;
  }

  private _focused = false;

  /**
   * A name for this control that can be used by `core-form-field`
   */
  controlType = 'custom-select';

  /**
   * Trigger that opens the select
   */
  @ViewChild('trigger')
  trigger!: ElementRef;

  /**
   * Panel containing the select options
   */
  @ViewChild('panel')
  panel!: ElementRef;

  /**
   * Overlay pane containing the options
   */
  @ViewChild(CdkConnectedOverlay)
  protected _overlayDir?: CdkConnectedOverlay;

  /**
   * Classes to be passed to the select panel. Supports the same syntax as `ngClass`
   */
  @Input()
  panelClass?: string | string[] | Set<string> | { [key: string]: any };

  /**
   * Whether the select is disabled
   */
  @Input({ transform: booleanAttribute })
  disabled: boolean = false;

  /**
   * Tab index of the select
   */
  @Input({
    transform: (value: unknown) => (value == null ? 0 : numberAttribute(value)),
  })
  tabIndex: number = 0;

  /**
   * Whether checkmark indicator for single-selection options is hidden
   */
  @Input({ transform: booleanAttribute })
  get hideSingleSelectionIndicator(): boolean {
    return this._hideSingleSelectionIndicator;
  }

  set hideSingleSelectionIndicator(value: boolean) {
    this._hideSingleSelectionIndicator = value;
    this.syncParentProperties();
  }

  private _hideSingleSelectionIndicator: boolean = this._defaultOptions?.hideSingleSelectionIndicator ?? false;

  /**
   * Placeholder to be shown if no value has been selected
   */
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges$.next();
  }

  private _placeholder = '';

  /**
   * Whether the component is required
   */
  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }

  set required(value: boolean) {
    this._required = value;
    this.stateChanges$.next();
  }

  private _required?: boolean;

  /**
   * Whether the user should be allowed to select multiple options
   */
  @Input({ transform: booleanAttribute })
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = value;
  }

  private _multiple = false;

  /**
   * Whether the select has a value
   */
  get empty(): boolean {
    return !this.selectionModel || this.selectionModel.isEmpty();
  }

  /**
   * Function to compare the option values with the selected values. The first argument
   * is a value from an option. The second is a value from the selection. A boolean
   * should be returned.
   */
  @Input()
  get compareWith() {
    return this._compareWith;
  }

  set compareWith(fn: (o1: any, o2: any) => boolean) {
    this.compareWith = fn;
    if (this.selectionModel) {
      // A different comparator means the selection could change.
      this.initializeSelection();
    }
  }

  /**
   * Value of the select control
   */
  @Input()
  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    const hasAssigned = this.assignValue(newValue);

    if (hasAssigned) {
      this.onChange(newValue);
    }
  }

  private _value: any;

  /**
   * Aria label of the select
   */
  @Input()
  selectAllLabel = 'FILTER.PRESET.SELECT_ALL';

  /**
   * Aria label of the select
   */
  @Input('aria-label')
  ariaLabel: string = '';

  /**
   * Input that can be used to specify the `aria-labelledby` attribute
   */
  @Input('aria-labelledby')
  ariaLabelledby?: string;

  /**
   * Object used to control when error messages are shown
   */
  @Input()
  get errorStateMatcher() {
    return this._errorStateTracker.matcher;
  }

  set errorStateMatcher(value: ErrorStateMatcher) {
    this._errorStateTracker.matcher = value;
  }

  /**
   * Time to wait in milliseconds after the last keystroke before moving focus to an item
   */
  @Input({ transform: numberAttribute })
  typeaheadDebounceInterval?: number;

  /**
   * Function used to sort the values in a select in multiple mode.
   * Follows the same logic as `Array.prototype.sort`.
   */
  @Input()
  sortComparator?: (a: OptionComponent, b: OptionComponent, options: OptionComponent[]) => number;

  /**
   * Unique id of the element
   */
  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
    this.stateChanges$.next();
  }

  private _id = '';

  /**
   * Whether the select is in an error state
   */
  get errorState() {
    return this._errorStateTracker.errorState;
  }

  set errorState(value: boolean) {
    this._errorStateTracker.errorState = value;
  }

  /**
   * Width of the panel. If set to `auto`, the panel will match the trigger width.
   * If set to null or an empty string, the panel will grow to match the longest option's text.
   */
  @Input() panelWidth: Nullable<string | number> =
    this._defaultOptions && typeof this._defaultOptions.panelWidth !== 'undefined'
      ? this._defaultOptions.panelWidth
      : 'auto';

  /**
   * Combined stream of all of the child options' change events
   */
  readonly optionSelectionChanges$: Observable<any> = defer(() => {
    const { options } = this;

    if (options) {
      return options.changes.pipe(
        startWith(options),
        switchMap(() => merge(...options.map((option) => option.onSelectionChange))),
      );
    }

    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.optionSelectionChanges$),
    );
  }) as Observable<any>;

  /**
   * Event emitted when the select panel has been toggled
   */
  @Output()
  readonly openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Event emitted when the select has been opened
   */
  @Output('opened') readonly openedStream$: Observable<void> = this.openedChange.pipe(
    filter((o) => o),
    map(() => {}),
  );

  /**
   * Event emitted when the select has been closed
   */
  @Output('closed') readonly closedStream$: Observable<void> = this.openedChange.pipe(
    filter((o) => !o),
    map(() => {}),
  );

  /**
   * Event emitted when the selected value has been changed by the user
   */
  @Output()
  readonly selectionChange = new EventEmitter<SelectChangeEvent>();

  /**
   * Event that emits whenever the raw value of the select changes. This is here primarily
   * to facilitate the two-way binding for the `value` input.
   * @docs-private
   */
  @Output()
  readonly valueChange: EventEmitter<any> = new EventEmitter<any>();

  autofilled = false;

  constructor(
    protected _viewportRuler: ViewportRuler,
    protected changeDetectorRef: ChangeDetectorRef,
    protected _ngZone: NgZone,
    defaultErrorStateMatcher: ErrorStateMatcher,
    readonly elementRef: ElementRef,
    private domSanitizer: DomSanitizer,
    @Optional() private _dir: Directionality,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    @Optional() @Inject(FORM_FIELD) protected parentFormField: FormFieldComponent,
    @Self() @Optional() public ngControl: NgControl,
    @Attribute('tabindex') tabIndex: string,
    @Inject(SELECT_SCROLL_STRATEGY) scrollStrategyFactory: any,
    private _liveAnnouncer: LiveAnnouncer,
    @Optional() @Inject(SELECT_CONFIG) protected _defaultOptions?: SelectConfig,
  ) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    // Note that we only want to set this when the defaults pass it in, otherwise it should
    // stay as `undefined` so that it falls back to the default in the key manager.
    if (_defaultOptions?.typeaheadDebounceInterval != null) {
      this.typeaheadDebounceInterval = _defaultOptions.typeaheadDebounceInterval;
    }

    this._errorStateTracker = new ErrorStateTracker(
      defaultErrorStateMatcher,
      ngControl,
      parentFormGroup,
      parentForm,
      this.stateChanges$,
    );
    this._scrollStrategyFactory = scrollStrategyFactory;
    this._scrollStrategy = this._scrollStrategyFactory();
    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }

  ngOnInit() {
    this.selectionModel = new SelectionModel<OptionComponent>(this.multiple);
    this.stateChanges$.next();

    // We need `distinctUntilChanged` here, because some browsers will
    // fire the animation end event twice for the same animation. See:
    // https://github.com/angular/angular/issues/24084
    this.panelDoneAnimatingStream$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(() => this.panelDoneAnimating(this.panelOpen));

    this._viewportRuler
      .change()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.panelOpen) {
          this._overlayWidth = this.getOverlayWidth(this.preferredOverlayOrigin);
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  ngAfterContentInit(): void {
    this.initKeyManager();

    this.selectionModel.changed.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      event.added.forEach((option) => option.select());
      event.removed.forEach((option) => option.deselect());
    });

    this.options?.changes.pipe(startWith(null), takeUntil(this.destroyed$)).subscribe(() => {
      this.resetOptions();
      this.initializeSelection();
    });
  }

  ngDoCheck() {
    const newAriaLabelledby = this.getTriggerAriaLabelledby();
    const { ngControl } = this;

    // We have to manage setting the `aria-labelledby` ourselves, because part of its value
    // is computed as a result of a content query which can cause this binding to trigger a
    // "changed after checked" error.
    if (newAriaLabelledby !== this._triggerAriaLabelledBy) {
      const element: HTMLElement = this.elementRef.nativeElement;
      this._triggerAriaLabelledBy = newAriaLabelledby;
      if (newAriaLabelledby) {
        element.setAttribute('aria-labelledby', newAriaLabelledby);
      } else {
        element.removeAttribute('aria-labelledby');
      }
    }

    if (ngControl) {
      // The disabled state might go out of sync if the form group is swapped out. See #17860.
      if (this._previousControl !== ngControl.control) {
        if (
          this._previousControl !== undefined &&
          ngControl.disabled !== null &&
          ngControl.disabled !== this.disabled
        ) {
          this.disabled = ngControl.disabled;
        }

        this._previousControl = ngControl.control;
      }

      this.updateErrorState();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Updating the disabled state is handled by the input, but we need to additionally let
    // the parent form field know to run change detection when the disabled state changes.
    if (changes.disabled || changes.userAriaDescribedBy) {
      this.stateChanges$.next();
    }

    if (changes.typeaheadDebounceInterval && this.keyManager) {
      this.keyManager.withTypeAhead(this.typeaheadDebounceInterval);
    }
  }

  ngOnDestroy() {
    this.keyManager?.destroy();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stateChanges$.complete();
    this.clearFromModal();
  }

  /**
   * Toggles the overlay panel open or closed
   */
  toggle(): void {
    if (!this.panelOpen) {
      return this.open();
    }

    this.close();
  }

  /**
   * Opens the overlay panel
   */
  open(): void {
    if (!this.canOpen()) {
      return;
    }

    // It's important that we read this as late as possible, because doing so earlier will
    // return a different element since it's based on queries in the form field which may
    // not have run yet. Also this needs to be assigned before we measure the overlay width.
    if (this.parentFormField) {
      this.preferredOverlayOrigin = this.parentFormField.getConnectedOverlayOrigin();
    }

    this._overlayWidth = this.getOverlayWidth(this.preferredOverlayOrigin);
    this.applyModalPanelOwnership();
    this._panelOpen = true;
    this.keyManager.withHorizontalOrientation(null);
    this.highlightCorrectOption();
    this.changeDetectorRef.markForCheck();

    // Required for the MDC form field to pick up when the overlay has been opened.
    this.stateChanges$.next();
  }

  /**
   * Track which modal we have modified the `aria-owns` attribute of. When the combobox trigger is
   * inside an aria-modal, we apply aria-owns to the parent modal with the `id` of the options
   * panel. Track the modal we have changed so we can undo the changes on destroy.
   */
  private _trackedModal: Element | null = null;

  /**
   * If the autocomplete trigger is inside of an `aria-modal` element, connect
   * that modal to the options panel with `aria-owns`.
   *
   * For some browser + screen reader combinations, when navigation is inside
   * of an `aria-modal` element, the screen reader treats everything outside
   * of that modal as hidden or invisible.
   *
   * This causes a problem when the combobox trigger is _inside_ of a modal, because the
   * options panel is rendered _outside_ of that modal, preventing screen reader navigation
   * from reaching the panel.
   *
   * We can work around this issue by applying `aria-owns` to the modal with the `id` of
   * the options panel. This effectively communicates to assistive technology that the
   * options panel is part of the same interaction as the modal.
   *
   * At time of this writing, this issue is present in VoiceOver.
   * See https://github.com/angular/components/issues/20694
   */
  private applyModalPanelOwnership() {
    const modal = this.elementRef.nativeElement.closest('body > .cdk-overlay-container [aria-modal="true"]');

    if (!modal) {
      // Most commonly, the autocomplete trigger is not inside a modal.
      return;
    }

    const panelId = `${this.id}-panel`;

    if (this._trackedModal) {
      removeAriaReferencedId(this._trackedModal, 'aria-owns', panelId);
    }

    addAriaReferencedId(modal, 'aria-owns', panelId);
    this._trackedModal = modal;
  }

  /**
   * Clears the reference to the listbox overlay element from the modal it was added to
   */
  private clearFromModal() {
    if (!this._trackedModal) {
      // Most commonly, the autocomplete trigger is not used inside a modal.
      return;
    }

    const panelId = `${this.id}-panel`;

    removeAriaReferencedId(this._trackedModal, 'aria-owns', panelId);
    this._trackedModal = null;
  }

  /**
   * Closes the overlay panel and focuses the host element
   */
  close(): void {
    if (this._panelOpen) {
      this._panelOpen = false;
      this.keyManager.withHorizontalOrientation(this.isRtl() ? 'rtl' : 'ltr');
      this.changeDetectorRef.markForCheck();
      this.onTouched();
      // Required for the MDC form field to pick up when the overlay has been closed.
      this.stateChanges$.next();
    }
  }

  /**
   * Selects or deselects all options
   */
  toggleSelectAll(): void {
    if (!this.allSelected) {
      this.options?.forEach((option) => option.select());
    } else {
      this.options?.forEach((option) => option.deselect());
    }
  }

  /**
   * Sets the select's value. Part of the ControlValueAccessor interface
   * required to integrate with Angular's core forms API.
   *
   * @param value New value to be written to the model.
   */
  writeValue(value: any): void {
    this.assignValue(value);
  }

  /**
   * Saves a callback function to be invoked when the select's value
   * changes from user input. Part of the ControlValueAccessor interface
   * required to integrate with Angular's core forms API.
   *
   * @param fn Callback to be triggered when the value changes.
   */
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Saves a callback function to be invoked when the select is blurred
   * by the user. Part of the ControlValueAccessor interface required
   * to integrate with Angular's core forms API.
   *
   * @param fn Callback to be triggered when the component has been touched.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Disables the select. Part of the ControlValueAccessor interface required
   * to integrate with Angular's core forms API.
   *
   * @param isDisabled Sets whether the component is disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
    this.stateChanges$.next();
  }

  /**
   * Whether or not the overlay panel is open
   */
  get panelOpen(): boolean {
    return this._panelOpen;
  }

  /**
   * The currently selected option
   */
  get selected(): OptionComponent | OptionComponent[] {
    return this.multiple ? this.selectionModel?.selected || [] : this.selectionModel?.selected[0];
  }

  /**
   * If all options are selected
   */
  get allSelected(): boolean {
    return this.selectionModel?.selected.length === this.options?.length;
  }

  /**
   * The value displayed in the trigger
   */
  get triggerValue(): string {
    if (this.empty) {
      return '';
    }

    if (this._multiple) {
      const selectedOptions = this.selectionModel.selected.map((option) => option.viewValue);

      if (this.isRtl()) {
        selectedOptions.reverse();
      }

      return selectedOptions.join(', ');
    }

    return this.selectionModel.selected[0].viewValue;
  }

  get iconElement(): Nullable<SafeHtml> {
    const el = this.selectionModel.selected?.[0]?.iconElement;

    if (el) {
      return this.domSanitizer.bypassSecurityTrustHtml(el);
    }

    return null;
  }

  /**
   * Refreshes the error state of the select
   */
  updateErrorState(): void {
    this._errorStateTracker.updateErrorState();
  }

  /**
   * Whether the element is in RTL mode
   */
  isRtl(): boolean {
    return this._dir ? this._dir.value === 'rtl' : false;
  }

  /**
   * Handles all keydown events on the select
   */
  handleKeydown(event: KeyboardEvent): void {
    if (!this.disabled) {
      if (!this.panelOpen) {
        return this.handleClosedKeydown(event);
      }

      this.handleOpenKeydown(event);
    }
  }

  /**
   * Handles keyboard events while the select is closed
   */
  private handleClosedKeydown(event: KeyboardEvent): void {
    const { key } = event;
    const isArrowKey = key === 'ArrowDown' || key === 'ArrowUp' || key === 'ArrowLeft' || key === 'ArrowRight';
    const isOpenKey = key === 'Enter' || key === ' ';
    const manager = this.keyManager;

    // Open the select on ALT + arrow key to match the native <select>
    if (
      (!manager.isTyping() && isOpenKey && !hasModifierKey(event)) ||
      ((this.multiple || event.altKey) && isArrowKey)
    ) {
      event.preventDefault(); // prevents the page from scrolling down when pressing space
      this.open();
    } else if (!this.multiple) {
      const previouslySelectedOption = this.selected;
      manager.onKeydown(event);
      const selectedOption = this.selected;

      // Since the value has changed, we need to announce it ourselves.
      if (selectedOption && previouslySelectedOption !== selectedOption) {
        // We set a duration on the live announcement, because we want the live element to be
        // cleared after a while so that users can't navigate to it using the arrow keys.
        this._liveAnnouncer.announce((selectedOption as OptionComponent).viewValue, 10000);
      }
    }
  }

  /**
   * Handles keyboard events when the selected is open
   */
  private handleOpenKeydown(event: KeyboardEvent): void {
    const manager = this.keyManager;
    const { key } = event;
    const isArrowKey = key === 'ArrowDown' || key === 'ArrowUp';
    const isTyping = manager.isTyping();

    if (isArrowKey && event.altKey) {
      // Close the select on ALT + arrow key to match the native <select>
      event.preventDefault();
      this.close();
      // Don't do anything in this case if the user is typing,
      // because the typing sequence can include the space key.
    } else if (!isTyping && (key === 'Enter' || key === ' ') && manager.activeItem && !hasModifierKey(event)) {
      event.preventDefault();
      manager.activeItem.selectViaInteraction();
    } else if (!isTyping && this._multiple && key === 'A' && event.ctrlKey) {
      event.preventDefault();
      const hasDeselectedOptions = this.options?.some((opt) => !opt.disabled && !opt.selected);

      this.options?.forEach((option) => {
        if (!option.disabled) {
          if (!hasDeselectedOptions) {
            option.deselect();
          } else {
            option.select();
          }
        }
      });
    } else {
      const previouslyFocusedIndex = manager.activeItemIndex;

      manager.onKeydown(event);

      if (
        this._multiple &&
        isArrowKey &&
        event.shiftKey &&
        manager.activeItem &&
        manager.activeItemIndex !== previouslyFocusedIndex
      ) {
        manager.activeItem.selectViaInteraction();
      }
    }
  }

  onFocus(): void {
    if (!this.disabled) {
      this._focused = true;
      this.stateChanges$.next();
    }
  }

  /**
   * Calls the touched callback only if the panel is closed. Otherwise, the trigger will
   * "blur" to the panel when it opens, causing a false positive.
   */
  onBlur(): void {
    this._focused = false;
    this.keyManager?.cancelTypeahead();

    if (!this.disabled && !this.panelOpen) {
      this.onTouched();
      this.changeDetectorRef.markForCheck();
      this.stateChanges$.next();
    }
  }

  /**
   * Callback that is invoked when the overlay panel has been attached.
   */
  onAttached(): void {
    this._overlayDir?.positionChange.pipe(take(1)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
      this._positioningSettled();
    });
  }

  private initializeSelection(): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    Promise.resolve().then(() => {
      if (this.ngControl) {
        this._value = this.ngControl.value;
      }

      this.setSelectionByValue(this._value);
      this.stateChanges$.next();
    });
  }

  /**
   * Sets the selected option based on a value. If no option can be
   * found with the designated value, the select trigger is cleared.
   */
  private setSelectionByValue(value: any | any[]): void {
    this.options?.forEach((option) => option.setInactiveStyles());
    this.selectionModel.clear();

    if (this.multiple && value) {
      value.forEach((currentValue: any) => this.selectOptionByValue(currentValue));
      this.sortValues();
    } else {
      const correspondingOption = this.selectOptionByValue(value);

      // Shift focus to the active item. Note that we shouldn't do this in multiple
      // mode, because we don't know what option the user interacted with last.
      if (correspondingOption) {
        this.keyManager.updateActiveItem(correspondingOption);
      } else if (!this.panelOpen) {
        // Otherwise reset the highlighted option. Note that we only want to do this while
        // closed, because doing it while open can shift the user's focus unnecessarily.
        this.keyManager.updateActiveItem(-1);
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Finds and selects and option based on its value.
   * @returns Option that has the corresponding value.
   */
  private selectOptionByValue(value: any): OptionComponent | undefined {
    const correspondingOption = this.options?.find((option: OptionComponent) => {
      // Skip options that are already in the model. This allows us to handle cases
      // where the same primitive value is selected multiple times.
      if (this.selectionModel.isSelected(option)) {
        return false;
      }

      try {
        // Treat null as a special reset value.
        return option.value != null && this.compareWith(option.value, value);
      } catch (error) {
        return false;
      }
    });

    if (correspondingOption) {
      this.selectionModel.select(correspondingOption);
    }

    return correspondingOption;
  }

  /**
   * Assigns a specific value to the select. Returns whether the value has changed
   */
  private assignValue(newValue: any | any[]): boolean {
    // Always re-assign an array, because it might have been mutated.
    if (newValue !== this._value || (this._multiple && Array.isArray(newValue))) {
      if (this.options) {
        this.setSelectionByValue(newValue);
      }

      this._value = newValue;

      return true;
    }

    return false;
  }

  // `skipPredicate` determines if key manager should avoid putting a given option in the tab
  // order. Allow disabled list items to receive focus via keyboard to align with WAI ARIA
  // recommendation.
  //
  // Normally WAI ARIA's instructions are to exclude disabled items from the tab order, but it
  // makes a few exceptions for compound widgets.
  //
  // From [Developing a Keyboard Interface](
  // https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/):
  //   "For the following composite widget elements, keep them focusable when disabled: Options in a
  //   Listbox..."
  //
  // The user can focus disabled options using the keyboard, but the user cannot click disabled
  // options.
  private skipPredicate = (option: OptionComponent) => {
    if (this.panelOpen) {
      // Support keyboard focusing disabled options in an ARIA listbox.
      return false;
    }

    // When the panel is closed, skip over disabled options. Support options via the UP/DOWN arrow
    // keys on a closed select. ARIA listbox interaction pattern is less relevant when the panel is
    // closed.
    return option.disabled;
  };

  /**
   * Gets how wide the overlay panel should be
   */
  private getOverlayWidth(preferredOrigin: ElementRef<ElementRef> | CdkOverlayOrigin | undefined): string | number {
    if (this.panelWidth === 'auto') {
      const refToMeasure =
        preferredOrigin instanceof CdkOverlayOrigin ? preferredOrigin.elementRef : preferredOrigin || this.elementRef;

      return refToMeasure.nativeElement.getBoundingClientRect().width;
    }

    return this.panelWidth === null ? '' : this.panelWidth;
  }

  /**
   * Syncs the parent state with the individual options
   */
  syncParentProperties(): void {
    if (this.options) {
      this.options.forEach((option) => {
        option.changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * Sets up a key manager to listen to keyboard events on the overlay panel
   */
  private initKeyManager(): void {
    this.keyManager = new ActiveDescendantKeyManager<OptionComponent>(this.options!)
      .withTypeAhead(this.typeaheadDebounceInterval)
      .withVerticalOrientation()
      .withHorizontalOrientation(this.isRtl() ? 'rtl' : 'ltr')
      .withHomeAndEnd()
      .withPageUpDown()
      .withAllowedModifierKeys(['shiftKey'])
      .skipPredicate(this.skipPredicate);

    this.keyManager.tabOut.subscribe(() => {
      if (this.panelOpen) {
        // Select the active item when tabbing away. This is consistent with how the native
        // select behaves. Note that we only want to do this in single selection mode.
        if (!this.multiple && this.keyManager.activeItem) {
          this.keyManager.activeItem.selectViaInteraction();
        }

        // Restore focus to the trigger before closing. Ensures that the focus
        // position won't be lost if the user got focus into the overlay.
        this.focus();
        this.close();
      }
    });

    this.keyManager.change.subscribe(() => {
      if (this._panelOpen && this.panel) {
        this.scrollOptionIntoView(this.keyManager.activeItemIndex || 0);
      } else if (!this._panelOpen && !this.multiple && this.keyManager.activeItem) {
        this.keyManager.activeItem.selectViaInteraction();
      }
    });
  }

  /**
   * Drops current option subscriptions and IDs and resets from scratch
   */
  private resetOptions(): void {
    const changedOrDestroyed$ = merge(this.options?.changes!, this.destroyed$);

    this.optionSelectionChanges$.pipe(takeUntil(changedOrDestroyed$)).subscribe((event) => {
      this.onSelect(event.source, event.isUserInput);

      if (event.isUserInput && !this.multiple && this._panelOpen) {
        this.close();
        this.focus();
      }
    });

    // Listen to changes in the internal state of the options and react accordingly.
    // Handles cases like the labels of the selected options changing.
    merge(...(this.options ?? []).map((option) => option.stateChanges$))
      .pipe(takeUntil(changedOrDestroyed$))
      .subscribe(() => {
        // `stateChanges$` can fire as a result of a change in the label's DOM value which may
        // be the result of an expression changing. We have to use `detectChanges` in order
        // to avoid "changed after checked" errors (see #14793).
        this.changeDetectorRef.detectChanges();
        this.stateChanges$.next();
      });
  }

  /**
   * Invoked when an option is clicked
   */
  private onSelect(option: OptionComponent, isUserInput: boolean): void {
    const wasSelected = this.selectionModel.isSelected(option);

    if (option.value == null && !this._multiple) {
      option.deselect();
      this.selectionModel.clear();

      if (this.value != null) {
        this.propagateChanges(option.value);
      }
    } else {
      if (wasSelected !== option.selected) {
        if (!option.selected) {
          this.selectionModel.deselect(option);
        } else {
          this.selectionModel.select(option);
        }
      }

      if (isUserInput) {
        this.keyManager.setActiveItem(option);
      }

      if (this.multiple) {
        this.sortValues();

        if (isUserInput) {
          // In case the user selected the option with their mouse, we
          // want to restore focus back to the trigger, in order to
          // prevent the select keyboard controls from clashing with
          // the ones from `core-option`.
          this.focus();
        }
      }
    }

    if (wasSelected !== this.selectionModel.isSelected(option)) {
      this.propagateChanges();
    }

    this.stateChanges$.next();
  }

  /**
   * Sorts the selected values in the selected based on their order in the panel
   */
  private sortValues() {
    if (this.multiple) {
      const options = this.options?.toArray()!;

      this.selectionModel.sort((a, b) =>
        this.sortComparator ? this.sortComparator(a, b, options) : options.indexOf(a) - options.indexOf(b),
      );
      this.stateChanges$.next();
    }
  }

  /**
   * Emits change event to set the model value
   */
  private propagateChanges(fallbackValue?: any): void {
    let valueToEmit: any;

    if (this.multiple) {
      valueToEmit = (this.selected as OptionComponent[]).map((option) => option.value);
    } else {
      valueToEmit = this.selected ? (this.selected as OptionComponent).value : fallbackValue;
    }

    this._value = valueToEmit;
    this.valueChange.emit(valueToEmit);
    this.onChange(valueToEmit);
    this.selectionChange.emit(this.getChangeEvent(valueToEmit));
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Highlights the selected item. If no option is selected, it will highlight
   * the first *enabled* option.
   */
  private highlightCorrectOption(): void {
    if (this.keyManager) {
      if (this.empty) {
        // Find the index of the first *enabled* option. Avoid calling `keyManager.setActiveItem`
        // because it activates the first option that passes the skip predicate, rather than the
        // first *enabled* option.
        let firstEnabledOptionIndex = -1;
        for (let index = 0; index < this.options!.length; index++) {
          const option = this.options?.get(index)!;
          if (!option.disabled) {
            firstEnabledOptionIndex = index;
            break;
          }
        }

        this.keyManager.setActiveItem(firstEnabledOptionIndex);
      } else {
        this.keyManager.setActiveItem(this.selectionModel.selected[0]);
      }
    }
  }

  /**
   * Whether the panel is allowed to open
   */
  protected canOpen(): boolean {
    return !this._panelOpen && !this.disabled && this.options!.length > 0;
  }

  /**
   * Focuses the select element
   */
  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  /**
   * Gets the aria-labelledby for the select panel
   */
  getPanelAriaLabelledby(): Nullable<string> {
    if (this.ariaLabel) {
      return null;
    }

    const labelId = this.parentFormField?.labelId;
    const labelExpression = labelId ? `${labelId} ` : '';

    return this.ariaLabelledby ? labelExpression + this.ariaLabelledby : labelId;
  }

  /**
   * Determines the `aria-activedescendant` to be set on the host
   */
  getAriaActiveDescendant(): Nullable<string> {
    if (this.panelOpen && this.keyManager && this.keyManager.activeItem) {
      return this.keyManager.activeItem.id;
    }

    return null;
  }

  /**
   * Gets the aria-labelledby of the select component trigger
   */
  private getTriggerAriaLabelledby(): Nullable<string> {
    if (this.ariaLabel) {
      return null;
    }

    const labelId = this.parentFormField?.labelId;
    let value = (labelId ? `${labelId} ` : '') + this._valueId;

    if (this.ariaLabelledby) {
      value += ` ${this.ariaLabelledby}`;
    }

    return value;
  }

  /**
   * Called when the overlay panel is done animating
   */
  protected panelDoneAnimating(isOpen: boolean): void {
    this.openedChange.emit(isOpen);
  }

  setDescribedByIds(ids: string[]): void {
    if (ids.length) {
      this.elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    } else {
      this.elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  onContainerClick(): void {
    this.focus();
    this.open();
  }
}
