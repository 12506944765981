import { BrandChartConfig } from '@core/interfaces/brand-chart-config.interface';
import { CartesianScaleOptions, GridLineOptions, LegendOptions } from 'chart.js';

const sidesPalette = {
  whiteBase: '#ffffff',
  whiteDark: '#f4f4f4',

  grayExtraLight: '#e9e9e9',
  grayLight: '#d0d0d0',
  grayBase: '#d3d3d3',
  grayDark: '#a8a8a8',

  blackBase: '#262626',

  color1: '#5fe7a7',
  color2: '#026261',
  color3: '#5a0024',
  color4: '#ff4b00',
  color5: '#ff9505',
  color6: '#ffe162',
  color7: '#48acf0',
  color8: '#0cce6b',
};

/**
 * Grid line options
 */
const gridLineOptionsX: Partial<GridLineOptions> = {
  display: true,
  drawOnChartArea: false,
  drawBorder: true,
  tickLength: 12,
  borderDash: [1, 0],
  color: 'transparent',
  borderColor: sidesPalette.grayExtraLight,
};

const gridLineOptionsY: Partial<GridLineOptions> = {
  tickLength: 0,
  display: true,
  drawTicks: false,
  drawBorder: false,
  lineWidth: 1,
  borderDash: [1, 0],
  color: (ctx) => {
    if (ctx.index === 0 || !ctx.tick.label) {
      return 'transparent';
    }

    return sidesPalette.grayExtraLight;
  },
};

/**
 * Tick options
 */
const tickOptionsX: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  padding: 4,
  maxRotation: 0,
  minRotation: 0,
  color: (ctx: any) => {
    if (!ctx.tick.label) {
      return 'transparent';
    }

    return sidesPalette.grayDark;
  },
  display: true,
};

const tickOptionsY: Partial<CartesianScaleOptions['ticks']> = <any>{
  z: 5,
  includeBounds: true,
  padding: 4,
  labelOffset: -8,
  mirror: true,
  color: sidesPalette.grayDark,
  display: true,
  maxTicksLimit: 5,
};

const config: BrandChartConfig = {
  general: {
    colors: {
      audience: {
        male: sidesPalette.color7,
        female: sidesPalette.color4,
        unknown: sidesPalette.color3,
      },
      datasets: [
        sidesPalette.color1,
        sidesPalette.color2,
        sidesPalette.color3,
        sidesPalette.color4,
        sidesPalette.color5,
        sidesPalette.color6,
        sidesPalette.color7,
      ],
      axisTitle: sidesPalette.grayDark,
    },
    pluginOptions: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          font: { size: 12 },
          color: sidesPalette.grayDark,
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 16,
        } as LegendOptions<any>['labels'],
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  doughnut: {
    chartOptions: {
      cutout: '90%',
      radius: '90%',
    },
    pluginOptions: {
      chartBorder: {
        borderColor: 'transparent',
        borderWidth: 0,
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
  },
  bar: {
    chartOptions: {},
    datasetOptions: {},
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  line: {
    chartOptions: {},
    datasetOptions: {
      fill: false,
      fillGradientOpacity: [],
    },
    pluginOptions: {
      chartBorder: {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      chartBackground: {
        backgroundColor: 'transparent',
      },
    },
    scaleOptions: {
      x: {
        options: {
          grid: gridLineOptionsX,
          ticks: tickOptionsX,
        },
      },
      y: {
        options: {
          grid: gridLineOptionsY,
          ticks: tickOptionsY,
        },
      },
    },
  },
  horizontalBar: {
    barColors: {
      filled: sidesPalette.color2,
      unfilled: sidesPalette.grayExtraLight,
    },
    labelColors: {
      filled: sidesPalette.whiteBase,
      unfilled: sidesPalette.blackBase,
    },
    barThickness: 30,
    borderRadius: 0,
  },
  gauge: {
    colors: {
      low: sidesPalette.color4,
      medium: sidesPalette.color5,
      high: sidesPalette.color8,
      needle: sidesPalette.grayDark,
      unfilled: sidesPalette.grayExtraLight,
    },
  },
};

export default config;
