import { Injectable } from '@angular/core';
import { concat, EMPTY, interval, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AiCaptionService } from '@core/dialogs/post-manager/services/ai-caption.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { AICaptionBody } from '@core/dialogs/post-manager/interfaces/ai-caption-request.interface';

@Injectable({
  providedIn: 'root',
})
export class CaptionGenerationService {
  captionTextSubject$ = new Subject<string>();

  isCaptionGenerationLoadingSubject$ = new Subject<boolean>();

  private readonly GHOST_WRITE_INTERVAL_MS = 50;

  private captionChunkAdded$ = new Subject<void>();

  private unsubscribeCaptionGeneration$ = new Subject<void>();

  private captionText = '';

  private captionChunks: { value?: string; inProgress: boolean }[] = [];

  constructor(
    private aiCaptionService: AiCaptionService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {
    this.watchCaption();

    this.captionTextSubject$.subscribe((newCaptionText: string) => {
      this.captionText = newCaptionText;
    });
  }

  private ghostWriteEffect = (word: string) => concat(this.ghostWriteObservable({ word }));

  private ghostWriteObservable = ({ word }: { word: string }) =>
    interval(this.GHOST_WRITE_INTERVAL_MS).pipe(
      map((x) => word.substring(x, x + 1)),
      take(word.length),
    );

  private watchCaption(): void {
    this.captionChunkAdded$
      .asObservable()
      .pipe(
        switchMap(() => {
          const items = [...this.captionChunks];
          const currentItem = items.pop();

          if (!currentItem?.inProgress) {
            this.captionChunks = [];
          }

          return this.ghostWriteEffect(currentItem?.value ?? '');
        }),
        tap((item) => {
          this.captionTextSubject$.next(`${this.captionText ?? ''}${item}`);
        }),
        takeUntil(this.unsubscribeCaptionGeneration$),
      )
      .subscribe();
  }

  resetToDefaultState() {
    this.captionChunks = [];
    this.captionTextSubject$.next('');
    this.isCaptionGenerationLoadingSubject$.next(false);
    this.unsubscribeCaptionGeneration$ = new Subject<void>();
  }

  abortGeneration(): void {
    this.unsubscribeCaptionGeneration$.next();
    this.unsubscribeCaptionGeneration$.complete();
  }

  generateCaption(formData: AICaptionBody, usePreviewEndpoint = false): void {
    this.isCaptionGenerationLoadingSubject$.next(true);
    this.captionTextSubject$.next('');
    this.captionChunks = [];
    let captionStream$;

    if (usePreviewEndpoint) {
      captionStream$ = this.aiCaptionService.generateCaptionStreamPreview(formData);
    } else {
      captionStream$ = this.aiCaptionService.generateCaptionStream(formData);
    }

    captionStream$
      .pipe(
        tap((res: { value?: string; inProgress: boolean }) => {
          this.isCaptionGenerationLoadingSubject$.next(res.inProgress);
          this.captionTextSubject$.next(this.captionChunks.map(({ value }) => value).join(''));

          setTimeout(() => {
            this.captionChunks.push(res);
            this.captionChunkAdded$.next();
          });
        }),
        catchError(() => {
          this.isCaptionGenerationLoadingSubject$.next(false);

          this.snackBarService.openSnackBar({
            icon: 'danger',
            variant: 'error',
            text: this.translateService.instant('ERROR.REQUEST'),
          });

          return EMPTY;
        }),
        takeUntil(this.unsubscribeCaptionGeneration$),
      )
      .subscribe();
  }
}
