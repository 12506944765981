import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { AppState } from '@core/interfaces/app-state.interface';
import { User } from '@core/models/user.model';
import {
  getLoadListState,
  getLoadListSuccessState,
  getLoadListFailState,
  getLoadObjectState,
  getLoadObjectSuccessState,
  getLoadObjectFailState,
  getCreateObjectState,
  getCreateObjectSuccessState,
  getCreateObjectFailState,
  getUpdateObjectState,
  getUpdateObjectSuccessState,
  getUpdateObjectFailState,
  getDeleteObjectState,
  getDeleteObjectSuccessState,
  getDeleteObjectFailState,
  CrudEntityState,
  crudInitialState,
} from '@core/redux/crud/crud.reducer';
import { UserActionTypes, UserAction } from './user.action';

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  // eslint-disable-next-line no-underscore-dangle
  selectId: (user) => user._id,
  sortComparer: (a, b) => a.contact.firstName.localeCompare(b.contact.firstName),
});

export type UserState = CrudEntityState<User>;

export function userReducer(
  state: UserState = userAdapter.getInitialState(crudInitialState as any),
  action: UserAction,
): UserState {
  switch (action.type) {
    /** ******************************************* */
    // Load user list
    /** ****************************************** */
    case UserActionTypes.LoadUserList:
      return getLoadListState(state, action);

    case UserActionTypes.LoadUserListSuccess:
      return userAdapter.upsertMany(action.payload, getLoadListSuccessState(state, action));

    case UserActionTypes.LoadUserListFail:
      return getLoadListFailState(state, action);

    /** ******************************************* */
    // Load user
    /** ****************************************** */
    case UserActionTypes.LoadUser:
      return getLoadObjectState(state);

    case UserActionTypes.LoadUserSuccess:
      return userAdapter.upsertOne(action.payload, getLoadObjectSuccessState(state));

    case UserActionTypes.LoadUserFail:
      return getLoadObjectFailState(state);

    /** ******************************************* */
    // Create user
    /** ****************************************** */
    case UserActionTypes.CreateUser:
      return getCreateObjectState(state);

    case UserActionTypes.CreateUserSuccess:
      return userAdapter.addOne(action.payload, getCreateObjectSuccessState(state));

    case UserActionTypes.CreateUserFail:
      return getCreateObjectFailState(state);

    /** ******************************************* */
    // Update user
    /** ****************************************** */
    case UserActionTypes.UpdateUser:
      return getUpdateObjectState(state);

    case UserActionTypes.UpdateUserSuccess:
      return userAdapter.upsertOne(action.payload, getUpdateObjectSuccessState(state));

    case UserActionTypes.UpdateUserFail:
      return getUpdateObjectFailState(state);

    /** ******************************************* */
    // Delete user
    /** ****************************************** */
    case UserActionTypes.DeleteUser:
      return getDeleteObjectState(state);

    case UserActionTypes.DeleteUserSuccess:
      return userAdapter.removeOne(action.id, getDeleteObjectSuccessState(state));

    case UserActionTypes.DeleteUserFail:
      return getDeleteObjectFailState(state);

    default:
      return state;
  }
}

/**
 * Return user state from the app state.
 */
export const getUserState = (state: AppState) => state.users;

export const {
  selectAll: selectAllUsers,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
} = userAdapter.getSelectors(getUserState as any);
