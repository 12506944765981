import { SnackbarUpdateComponent } from '@app/components/snackbar-update/snackbar-update.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { ApplicationRef, Component, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, concat, interval, combineLatest, from } from 'rxjs';
import { first, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { SegmentService } from '@core/services/segment.service';
import { TitleService } from '@core/services/title.service';
import { SwUpdate } from '@angular/service-worker';
import { AppSettingsService } from '@core/services/app-settings.service';
import { CustomTranslateLoader } from '@core/services/custom-translate-loader.service';

@Component({
  selector: 'bc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  appTitleTranslationString = 'APP.TITLE';

  snackBarTemplate?: TemplateRef<any>;

  SEGMENT_INIT_TIMEOUT = 2000;

  destroyed$ = new Subject<void>();

  constructor(
    private appRef: ApplicationRef,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private segmentService: SegmentService,
    private titleService: TitleService,
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.appSettingsService.setTranslationConfig(this.translateService);
    // Subscribe to user data changes
    combineLatest([
      (this.translateService.currentLoader as CustomTranslateLoader).translations.en,
      (this.translateService.currentLoader as CustomTranslateLoader).translations.de,
      this.appSettingsService.language$.pipe(distinctUntilChanged()),
      this.titleService.pageTitle$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        // Initializate the translation config
        this.appSettingsService.setTranslationConfig(this.translateService);
        const appTitle = this.translateService.instant(this.appTitleTranslationString);
        if (appTitle !== this.appTitleTranslationString) {
          this.titleService.setTitle(appTitle);
        }
      });

    if (environment.segment.web) {
      // Heavy third-party libraries initialization happens slightly after application first contentful paint
      setTimeout(() => {
        this.segmentService.configure(environment.segment.web);
      }, this.SEGMENT_INIT_TIMEOUT);
    }

    // add service worker/app update listener
    // Allow the app to stabilize first, before starting polling for updates with `interval()`
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
    const checkInterval$ = interval(2 * 60 * 60 * 1000); // 2 hours
    const everyIntervalOnceAppIsStable$ = concat(appIsStable$, checkInterval$);

    everyIntervalOnceAppIsStable$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
    });

    if (this.swUpdate.isEnabled) {
      from(this.swUpdate.checkForUpdate())
        .pipe(takeUntil(this.destroyed$))
        .subscribe((hasUpdate) => {
          if (hasUpdate) {
            this.snackBar.openFromComponent(SnackbarUpdateComponent);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
