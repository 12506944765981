import { BrandConfig } from '@core/interfaces/brand-config.interface';

const config: BrandConfig = {
  brand: 'alphin',
  theme: 'dark',
  images: {
    avatars: {},
    backgrounds: {},
    backgroundTextures: {},
  },
  elementStyles: {
    card: 'raised',
    button: 'outlined',
    dialog: 'raised',
  },
};

export default config;
