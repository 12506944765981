import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, booleanAttribute } from '@angular/core';
import { ChipBaseComponent } from '../chip-base/chip-base.component';
import { ChipButtonIconPosition } from '../../types/chip-button.type';

@Component({
  selector: 'core-chip-button, [coreChipButton]',
  templateUrl: './chip-button.component.html',
  styleUrls: ['./chip-button.component.scss', '../chip-base/chip-base.component.scss'],
  host: {
    '[class]': '"chip chip-button"',
    '[class.chip--highlighted]': 'highlighted',
    '[class.chip--icon-after]': 'iconPosition === "after"',
    '[role]': '"button"',
  },
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class ChipButtonComponent extends ChipBaseComponent {
  /**
   * Whether or not the chip is selectable
   */
  @Input({ transform: booleanAttribute })
  get selectable(): boolean {
    return this._selectable;
  }

  set selectable(value: boolean) {
    this._selectable = value;
    this.changeDetectorRef.markForCheck();
  }

  protected override _selectable: boolean = true;

  /**
   * Whether the chip is selected
   */
  @Input({ transform: booleanAttribute })
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  protected override _selected = false;

  @Input()
  dropdown = false;

  @Input()
  iconPosition: ChipButtonIconPosition = 'before';
}
