<core-dialog
  [style.height]="'unset'"
  [headerConfig]="{
    title: 'IAM.POST_COMMENTS.DIALOG.TITLE' | translate,
    showClose: true
  }"
  (dismiss)="removeOutlet()">

  <div class="d-flex flex-column flex-md-row align-items-stretch">
    <div *ngIf="data?.post" class="media-preview px-3 pb-3 pb-md-0">
      <social-post-thumbnail
        [post]="data.post"
        [size]="204"
        [aspectRatio]="{ w: 164, h: 204 }">
      </social-post-thumbnail>
    </div>

    <div class="comments-list d-flex flex-column flex-grow-1 justify-content-start px-3">
      <core-comment-field
        *ngIf="showAddCommentField"
        class="mb-3"
        [title]="'IAM.POST_COMMENTS.COMMENT.INPUT.TITLE' | translate"
        [placeholder]="'IAM.POST_COMMENTS.COMMENT.INPUT.PLACEHOLDER' | translate"
        [focusOnInit]="true"
        [actionInProgress]="actionInProgress"
        [ctaLabel]="'ACTION.POST' | translate"
        (ctaClick)="createComment($event)"
        (close)="showAddCommentField = false">
      </core-comment-field>

      <core-empty-state
        *ngIf="!comments?.length"
        [svgMaxWidth]="150"
        svgMaxWidthUnit="px"
        svg="outline_inbox"
        [title]="'EMPTY.NO_COMMENTS' | translate">
        {{ 'EMPTY.CHECK_LATER' | translate }}
      </core-empty-state>

      <ng-container *ngFor="let comment of comments || []">
        <ng-container 
          *ngIf="commentIdToEdit !== comment.providerCommentId && commentIdToDelete !== comment.providerCommentId">
          <core-posted-comment
            class="mb-3"
            [author]="comment.user?.username"
            [text]="comment.text | formattedCaption: data?.provider"
            [actions]="data?.post?.raw?.providerAccountId === comment?.user?.userId ? commentActionsForPostOwner : commentActionsForOtherUsers"
            (actionClick)="onActionClick($event, comment.providerCommentId)"
            [date]="comment.commentCreatedAt">
          </core-posted-comment>

          <core-comment-field
            *ngIf="commentIdToReplyTo === comment.providerCommentId"
            class="ml-3 ml-sm-5 mb-3"
            [title]="'IAM.POST_COMMENTS.CHILD_COMMENT.INPUT.TITLE' | translate"
            [placeholder]="'IAM.POST_COMMENTS.CHILD_COMMENT.INPUT.PLACEHOLDER' | translate"
            [value]="replyPrefillText(comment.user?.username)"
            [focusOnInit]="true"
            [actionInProgress]="actionInProgress"
            [ctaLabel]="'ACTION.POST' | translate"
            (ctaClick)="createReply($event, comment.providerCommentId)"
            (close)="commentIdToReplyTo = null">
          </core-comment-field>
        </ng-container>

        <ng-container *ngIf="commentIdToEdit === comment.providerCommentId">
          <core-comment-field
            class="mb-3"
            [title]="'IAM.POST_COMMENTS.COMMENT.DELETE.TITLE' | translate"
            [value]="comment.text"
            [ctaLabel]="'ACTION.SAVE' | translate"
            (ctaClick)="updateComment($event, comment.providerCommentId)"
            (close)="commentIdToEdit = null">
          </core-comment-field>
        </ng-container>

        <ng-container *ngIf="commentIdToDelete === comment.providerCommentId">
          <core-alert
            class="mb-3"
            variant="danger"
            [title]="'IAM.POST_COMMENTS.COMMENT.DELETE.TITLE' | translate">
            {{ 'IAM.POST_COMMENTS.COMMENT.DELETE.TEXT.' + (comment.comments ? 'HAS_CHILD' : 'NO_CHILD') | translate }}
            <div class="delete-comment-preview my-3 mx-2 px-2">
              <div class="delete-comment-author mb-1">{{ comment.user?.username }}</div>
              <div>{{ comment.text }}</div>
            </div>
            <core-form-actions
              class="justify-content-end"
              [actionInProgress]="actionInProgress"
              (cancelClick)="commentIdToDelete = null"
              (submitClick)="deleteComment(comment.providerCommentId)"
              [buttonPosition]="buttonPosition"
              [cancelLabel]="'ACTION.CANCEL' | translate"
              [submitLabel]="'ACTION.DELETE' | translate"
              variant="danger">
            </core-form-actions>
          </core-alert>
        </ng-container>

        <ng-container *ngTemplateOutlet="repliesTemplate; context: { comment }"></ng-container>
      </ng-container>
    </div>
  </div>

  <div coreDialogFooter class="d-flex justify-content-end w-100">
    <button
      [disabled]="showAddCommentField"
      icon="comment"
      class="flex-grow-1 flex-md-grow-0"
      coreButton
      (click)="onAddComment()">
      {{ 'IAM.POST_COMMENTS.COMMENT.ADD.CTA' | translate }}
    </button>
  </div>
</core-dialog>


<ng-template #repliesTemplate let-comment="comment">
  <div *ngIf="comment.comments" class="ml-3 ml-sm-5 d-flex flex-column">
    <div *ngIf="!comment.replies" class="mb-3 d-flex align-items-center">
      <core-divider [style.max-width.px]="32"></core-divider>
      <core-button size="sm" variant="text" [disabled]="commentIdToFetchRepliesFor" [actionInProgress]="commentIdToFetchRepliesFor === comment.providerCommentId" (click)="getReplies(comment.providerCommentId)">
        {{ 'IAM.POST_COMMENTS.VIEW_CHILDREN.CTA' | translate: comment }}
      </core-button>
      <core-divider></core-divider>
    </div>

    <ng-container *ngFor="let reply of comment.replies || []">
      <core-posted-comment
        *ngIf="commentIdToEdit !== reply.providerCommentId && commentIdToDelete !== reply.providerCommentId"
        class="mb-3"
        [author]="reply.user?.username"
        [text]="reply.text | formattedCaption: data?.provider"
        [actions]="data?.post?.raw?.providerAccountId === reply?.user?.userId ? replyActionsForPostOwner : replyActionsForOtherUsers"
        (actionClick)="onActionClick($event, reply.providerCommentId)"
        [date]="reply.commentCreatedAt">
      </core-posted-comment>

      <core-comment-field
        *ngIf="commentIdToReplyTo === reply.providerCommentId"
        class="ml-3 ml-sm-5 mb-3"
        [title]="'IAM.POST_COMMENTS.CHILD_COMMENT.INPUT.TITLE' | translate"
        [placeholder]="'IAM.POST_COMMENTS.CHILD_COMMENT.INPUT.PLACEHOLDER' | translate"
        [value]="replyPrefillText(reply.user?.username)"
        [focusOnInit]="true"
        [actionInProgress]="actionInProgress"
        [ctaLabel]="'ACTION.POST' | translate"
        (ctaClick)="createReply($event, reply.providerCommentId)"
        (close)="commentIdToReplyTo = null">
      </core-comment-field>

      <core-comment-field
        *ngIf="commentIdToEdit === reply.providerCommentId"
        class="mb-3"
        [title]="'IAM.POST_COMMENTS.COMMENT.DELETE.TITLE' | translate"
        [value]="reply.text"
        [ctaLabel]="'ACTION.SAVE' | translate"
        (ctaClick)="updateReply($event, comment.providerCommentId, reply.providerCommentId)"
        (close)="commentIdToEdit = null">
      </core-comment-field>

      <core-alert
        *ngIf="commentIdToDelete === reply.providerCommentId"
        class="mb-3"
        variant="danger"
        [title]="'IAM.POST_COMMENTS.COMMENT.DELETE.TITLE' | translate">
        {{ 'IAM.POST_COMMENTS.COMMENT.DELETE.TEXT.NO_CHILD' | translate }}
        <div class="delete-comment-preview my-3 mx-2 px-2">
          <div class="delete-comment-author mb-1">{{ reply.user?.username }}</div>
          <div>{{ reply.text }}</div>
        </div>
        <core-form-actions
          class="justify-content-end"
          [actionInProgress]="actionInProgress"
          (cancelClick)="commentIdToDelete = null"
          (submitClick)="deleteReply(comment.providerCommentId, reply.providerCommentId)"
          [buttonPosition]="buttonPosition"
          [cancelLabel]="'ACTION.CANCEL' | translate"
          [submitLabel]="'ACTION.DELETE' | translate"
          variant="emphasis">
        </core-form-actions>
      </core-alert>
    </ng-container>
  </div>
</ng-template>
