import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { TagMediumConfig } from '@core/ui/tag/types/tag-medium.type';
import { ManagedPost } from '@core/dialogs/post-manager/models/managed-post.model';
import { ProviderAccountPost } from '@social/common/models/provider-account-post.model';
import { ManagedPostStatus } from '@core/dialogs/post-manager/enums/managed-post-status.enum';
import { ProviderConnectionSync } from '@core/dialogs/integration-workflow/interfaces/provider-connection-sync.interface';
import { SocialPost } from '@social/common/types/social-post.type';
import { PostOwner } from '@core/modules/post/types/post-owner.type';
import { ManagedPostConfigService } from '@core/dialogs/post-manager/services/managed-post-config.service';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { CardHeaderIconType } from '@core/ui/card/types/section-card.type';
import { PostCardVariant } from '@core/modules/post/types/post-card.type';
import { ManagedPostType } from '@core/dialogs/post-manager/enums/managed-post-type.enum';
import { PostInteractionType } from '@core/modules/post/types/post-interaction.type';
import { CreatorType } from '@core/dialogs/post-manager/enums/creator-type.enum';

@Component({
  selector: 'social-post',
  templateUrl: './social-post.component.html',
  styleUrls: ['./social-post.component.scss'],
  host: {
    '[id]': 'post?.id',
  },
})
export class SocialPostComponent implements OnChanges {
  @Input()
  index = 0;

  @Input()
  groupLength = 0;

  @Input()
  duration = 0;

  @Input()
  icon = 'social';

  @Input()
  iconType: CardHeaderIconType = 'default';

  @Input()
  post!: SocialPost;

  @Input()
  provider!: ManagedPostProvider;

  @Input()
  owner?: PostOwner;

  @Input()
  commentsLoadingForPost: Nullable<string> = null;

  @Input()
  insightsLoadingForPost: Nullable<string> = null;

  @Input()
  editLoadingForPost: Nullable<string> = null;

  @Input()
  connection?: Nullable<ProviderConnectionSync> = null;

  @Input()
  autoplayVideo = false;

  @Output()
  updateLayout = new EventEmitter();

  @Output()
  insightsClick = new EventEmitter();

  @Output()
  interactionClick = new EventEmitter<PostInteractionType>();

  @Output()
  ctaClick = new EventEmitter();

  @Output()
  deleteClick = new EventEmitter();

  @Output()
  durationEnded = new EventEmitter();

  slideIndex = 0;

  hasError = false;

  statusTagConfig: Nullable<TagMediumConfig> = null;

  canShowInsights = false;

  cardVariant: PostCardVariant = 'default';

  createdViaAlphin = false;

  private minAspectRatioForContentOverlay = 10 / 23;

  constructor(private managedPostConfigService: ManagedPostConfigService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post && changes.post.currentValue !== changes.post.previousValue) {
      this.hasError = false;
      this.cardVariant = this.post.type === ManagedPostType.Story ? 'content-overlay' : 'default';
      this.slideIndex = 0;
      this.statusTagConfig = this.managedPostConfigService.getStatusTagConfig(this.post?.status!);
      this.canShowInsights =
        this.post.status === ManagedPostStatus.Published &&
        this.post.raw instanceof ProviderAccountPost &&
        this.post.type !== ManagedPostType.Story;
      this.createdViaAlphin = (this.post.raw as ManagedPost).createdBy !== CreatorType.External;
    }
  }

  onMediaLoaded(event: Event): void {
    if (this.cardVariant === 'default') {
      return;
    }
    const eventTarget = event.target as HTMLImageElement | HTMLVideoElement;
    let width = 1;
    let height = 1;
    if ('videoWidth' in eventTarget) {
      width = eventTarget.videoWidth;
      height = eventTarget.videoHeight;
    } else {
      width = eventTarget.clientWidth;
      height = eventTarget.clientHeight;
    }
    const mediaAspectRatio = height / width;
    if (this.minAspectRatioForContentOverlay > mediaAspectRatio) {
      this.cardVariant = 'default';
    }
  }
}
