import { SupportedLanguage } from '@core/enums/user-preference.enum';
import { NumberLangSuffixes } from '@core/interfaces/number-lang-suffixes.interface';

/**
 * Abbreviations for large numbers grouped by language
 */
export const SUFFIXES: NumberLangSuffixes[] = [
  {
    lang: SupportedLanguage.en,
    suffixes: ['K', 'M', 'B', 'T'],
  },
  {
    lang: SupportedLanguage.de,
    suffixes: ['Tsd', 'Mio', 'Mrd', 'Bio'],
  },
];
