import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ButtonModule } from '@core/ui/button/button.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { DividerModule } from '@core/ui/divider/divider.module';
import { RouterModule } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { SvgModule } from '@core/ui/svg/svg.module';
import { CheckboxModule } from '@core/ui/checkbox/checkbox.module';
import { FormFieldModule } from '@core/ui/form-field/form-field.module';
import { SelectModule } from '@core/ui/select/select.module';
import { StoreSetupWorkflowComponent } from './store-setup-workflow.component';
import { StoreSetupWorkflowRoutingModule } from './store-setup-workflow-routing.module';
import { IntroComponent } from './screens/intro/intro.component';
import { StoreSetupFormComponent } from './screens/store-setup-form/store-setup-form.component';
import { StoreSetupHeaderOptionsComponent } from './components/store-setup-header-options/store-setup-header-options.component';

@NgModule({
  declarations: [
    StoreSetupWorkflowComponent,
    StoreSetupFormComponent,
    IntroComponent,
    StoreSetupHeaderOptionsComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    CoreModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    ButtonModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatMenuModule,
    DividerModule,
    RouterModule,
    SvgModule,
    TranslateModule,
    CheckboxModule,
    FormFieldModule,
    SelectModule,
    StoreSetupWorkflowRoutingModule,
  ],
  providers: [DialogService],
  exports: [StoreSetupWorkflowComponent],
})
export class StoreSetupWorkflowModule {}
