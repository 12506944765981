import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { StoreService } from '@core/services/store.service';
import { CrudEffects } from '@core/redux/crud/crud.effect';
import { Store } from '@core/models/store.model';
import { CrudActions } from '@core/redux/crud/crud.action';
import {
  LoadStoreList,
  LoadStoreListSuccess,
  LoadStoreListFail,
  LoadStore,
  LoadStoreSuccess,
  LoadStoreFail,
  CreateStore,
  CreateStoreSuccess,
  CreateStoreFail,
  UpdateStore,
  UpdateStoreSuccess,
  UpdateStoreFail,
  DeleteStore,
  DeleteStoreSuccess,
  DeleteStoreFail,
  StoreActionTypes,
} from './company-store.action';

const crudActions: CrudActions = {
  loadList: {
    type: StoreActionTypes.LoadStoreList,
    action: LoadStoreList,
    ActionSuccess: LoadStoreListSuccess,
    ActionFail: LoadStoreListFail,
  },

  loadObject: {
    type: StoreActionTypes.LoadStore,
    action: LoadStore,
    ActionSuccess: LoadStoreSuccess,
    ActionFail: LoadStoreFail,
  },

  createObject: {
    type: StoreActionTypes.CreateStore,
    action: CreateStore,
    ActionSuccess: CreateStoreSuccess,
    ActionFail: CreateStoreFail,
  },

  updateObject: {
    type: StoreActionTypes.UpdateStore,
    action: UpdateStore,
    ActionSuccess: UpdateStoreSuccess,
    ActionFail: UpdateStoreFail,
  },

  deleteObject: {
    type: StoreActionTypes.DeleteStore,
    action: DeleteStore,
    ActionSuccess: DeleteStoreSuccess,
    ActionFail: DeleteStoreFail,
  },
};

@Injectable()
export class StoreEffects extends CrudEffects<Store> {
  constructor(
    public override actions$: Actions,
    public override crudService: StoreService,
  ) {
    super(actions$, crudService, crudActions);
  }

  override loadList$: any;

  override loadObject$: any;

  override createObject$: any;

  override updateObject$: any;

  override deleteObject$: any;
}
