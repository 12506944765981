<core-post-card
  (loaded)="onMediaLoaded($event); updateLayout.emit()"
  (fallbackLoaded)="updateLayout.emit()"
  (error)="updateLayout.emit(); hasError = true"
  (slideChange)="updateLayout.emit(); slideIndex = $event?.index"
  (interactionClick)="interactionClick.emit($event)"
  (durationEnded)="durationEnded.emit()"
  [mediaVariant]="post?.mediaVariant"
  [slides]="post?.slides"
  [counts]="post?.counts"
  [interactionStates]="{
    showIfZero: { comments: provider !== 'GOOGLE' && post?.status === 'PUBLISHED' && post?.type !== 'STORY' },
    clickable: { comments: !commentsLoadingForPost },
    loading: { comments: commentsLoadingForPost === post?.id }
  }"
  [owner]="owner ? owner : post?.owner"
  [date]="post?.date"
  [title]="'POST.HEADER.POST_KIND.' + post?.type | translate"
  [subtitle]="'POST.HEADER.CREATED_BY' | translate :
  {platform: createdViaAlphin ? ('PLATFORM.ALPHIN' | translate) : (provider | titlecase)}"
  [icon]="icon"
  [iconType]="iconType"
  [caption]="post.caption"
  [cardVariant]="cardVariant"
  [provider]="provider"
  [groupLength]="groupLength"
  [groupIndex]="index"
  [duration]="duration"
  [autoplayVideo]="autoplayVideo">
  <div corePostHeader class="d-flex align-items-center">
    <core-tag-medium
      *ngIf="post?.status !== 'PUBLISHED'; else interactiveTag"
      [icon]="statusTagConfig?.icon"
      [outline]="statusTagConfig?.outline"
      [variant]="statusTagConfig?.variant">
      {{ statusTagConfig?.label | translate }}
    </core-tag-medium>
    <ng-template #interactiveTag>
      <core-tag-medium
        [matMenuTriggerFor]="statusOptions"
        [interactive]="true"
        [icon]="statusTagConfig?.icon"
        [outline]="statusTagConfig?.outline"
        [variant]="statusTagConfig?.variant">
        {{ statusTagConfig?.label | translate }}
      </core-tag-medium>
      <mat-menu
        xPosition="before"
        yPosition="below"
        #statusOptions="matMenu"
        class="core-menu-panel">
        <div mat-menu-item (click)="deleteClick.emit()">
          <div class="d-flex align-items-center">
            <core-icon icon="delete"></core-icon>
            <span>{{ 'ACTION.DELETE' | translate }}</span>
          </div>
        </div>
      </mat-menu>
    </ng-template>
  </div>

  <div
    corePostFooter
    class="d-flex align-items-center justify-content-between">
    <ng-container [ngSwitch]="post?.status">
      <ng-container *ngSwitchCase="'SCHEDULED'">
        <button
          coreButton
          [variant]="cardVariant === 'content-overlay' ? 'light' : 'primary'"
          [disabled]="!!editLoadingForPost"
          [actionInProgress]="editLoadingForPost === post?.id"
          (click)="ctaClick.emit({ action: 'edit', post: post, slideIndex: slideIndex })">
          {{ 'POST.CTA.UPDATE' | translate }}
        </button>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>

    <ng-container *ngIf="!hasError">
      <div></div>
      <div class="d-flex align-items-center">
        <button
          *ngIf="canShowInsights"
          coreIconButton
          icon="trending_up"
          [shadow]="true"
          [disabled]="!!insightsLoadingForPost"
          [actionInProgress]="insightsLoadingForPost === post?.id"
          variant="light"
          (click)="insightsClick.emit($event)">
        </button>
        <core-post-media-save
          class="ml-2"
          [mediaVariant]="post?.slides?.[slideIndex]?.variant"
          [url]="post?.slides?.[slideIndex]?.src">
        </core-post-media-save>
      </div>
    </ng-container>
  </div>
</core-post-card>
