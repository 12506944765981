import { RouterModule, Routes } from '@angular/router';

import { settingsOutlet } from './variables/outlet.variable';
import { GoogleAdRoute } from './enums/google-ad-route.enum';
import { RoiSetupDialogComponent } from './screens/roi-setup-dialog/roi-setup-dialog.component';

const outlet = settingsOutlet;

export const makeGoogleAdSettingRoutes = () => [
  {
    path: GoogleAdRoute.Roi,
    outlet,
    component: RoiSetupDialogComponent,
  },
];

const routes: Routes = [...makeGoogleAdSettingRoutes()];

export const GoogleAdWorkflowRoutingModule = RouterModule.forChild(routes);
