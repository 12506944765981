import { createFeatureSelector, createSelector } from '@ngrx/store';
import _chunk from 'lodash/chunk';
import { crudSelectors } from '@core/redux/crud/crud.selector';
import { UserState, getUserState, selectAllUsers, selectUserEntities } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>('users');
export const userSelectors = crudSelectors(getUserState as any);

/** ******************************************* */
// Selectors for list of users
/** ****************************************** */

export const getUsersByPage = (pageNumber: number) =>
  createSelector(
    selectAllUsers,
    userSelectors.getListPagination,
    userSelectors.getListPageLoading(pageNumber),
    userSelectors.getListPageLoaded(pageNumber),
    userSelectors.getErrorLimitReached,
    (allUsers, pagination, loading, loaded, errorLimit) => {
      const users = pagination.limit ? _chunk(allUsers, pagination.limit)[pageNumber] : allUsers;

      return {
        users,
        pagination,
        loading,
        loaded,
        errorLimit,
      };
    },
  );

/** ******************************************* */
// Selectors for one user
/** ****************************************** */

export const getUserById = (id: string) =>
  createSelector(selectUserEntities, userSelectors.getObjectLoading, (users, loading) => ({
    user: users[id],
    loading,
  }));
