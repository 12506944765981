import { CompanyService } from '@core/services/company.service';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { CrudEffects } from '@core/redux/crud/crud.effect';
import { Company } from '@core/models/company.model';
import { CrudActions } from '@core/redux/crud/crud.action';
import {
  LoadCompanyList,
  LoadCompanyListSuccess,
  LoadCompanyListFail,
  LoadCompany,
  LoadCompanySuccess,
  LoadCompanyFail,
  CreateCompany,
  CreateCompanySuccess,
  CreateCompanyFail,
  UpdateCompany,
  UpdateCompanySuccess,
  UpdateCompanyFail,
  DeleteCompany,
  DeleteCompanySuccess,
  DeleteCompanyFail,
  CompanyActionTypes,
} from './company.action';

const crudActions: CrudActions = {
  loadList: {
    type: CompanyActionTypes.LoadCompanyList,
    action: LoadCompanyList,
    ActionSuccess: LoadCompanyListSuccess,
    ActionFail: LoadCompanyListFail,
  },

  loadObject: {
    type: CompanyActionTypes.LoadCompany,
    action: LoadCompany,
    ActionSuccess: LoadCompanySuccess,
    ActionFail: LoadCompanyFail,
  },

  createObject: {
    type: CompanyActionTypes.CreateCompany,
    action: CreateCompany,
    ActionSuccess: CreateCompanySuccess,
    ActionFail: CreateCompanyFail,
  },

  updateObject: {
    type: CompanyActionTypes.UpdateCompany,
    action: UpdateCompany,
    ActionSuccess: UpdateCompanySuccess,
    ActionFail: UpdateCompanyFail,
  },

  deleteObject: {
    type: CompanyActionTypes.DeleteCompany,
    action: DeleteCompany,
    ActionSuccess: DeleteCompanySuccess,
    ActionFail: DeleteCompanyFail,
  },
};

@Injectable()
export class CompanyEffects extends CrudEffects<Company> {
  constructor(
    public override actions$: Actions,
    public override crudService: CompanyService,
  ) {
    super(actions$, crudService, crudActions);
  }

  override loadList$: any;

  override loadObject$: any;

  override createObject$: any;

  override updateObject$: any;

  override deleteObject$: any;
}
