import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { PostInteractionType } from '../../types/post-interaction.type';

@Component({
  selector: 'core-post-interactions',
  templateUrl: './post-interactions.component.html',
  styleUrls: ['./post-interactions.component.scss'],
})
export class PostInteractionsComponent {
  icons: Record<PostInteractionType, string> = {
    likes: 'like',
    comments: 'comment',
    views: 'visibility_on',
  };

  interactions = Object.keys(this.icons);

  @Input()
  counts?: Record<PostInteractionType, Nullable<number>>;

  @Input()
  showIfZero?: Partial<Record<PostInteractionType, boolean>>;

  @Input()
  loading?: Partial<Record<PostInteractionType, boolean>>;

  @Input()
  clickable?: Partial<Record<PostInteractionType, boolean>>;

  @Output()
  interactionClick = new EventEmitter<PostInteractionType>();
}
