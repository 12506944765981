import { BrandConfigFiles } from '@core/interfaces/brand-config-files.interface';

import configLight from './config.light';
import chartLight from './chart.light';

const files: BrandConfigFiles = {
  config: {
    light: configLight,
  },
  chart: {
    light: chartLight,
  },
};

export default files;
