/**
 * Base path for assets directory
 */
export const assetsBasePath = 'assets/core';

export const fallbackProfilePic = `${assetsBasePath}/svg/avatar/avatar_person.svg`;
export const fallbackProfilePicStore = `${assetsBasePath}/svg/avatar/avatar_store.svg`;
export const fallbackProfilePicCompany = `${assetsBasePath}/svg/avatar/avatar_company.svg`;

export const fallbackBackgroundImage = `${assetsBasePath}/img/sample_background_image.png`;
