/* eslint-disable max-classes-per-file */
import {
  LoadList,
  LoadListSuccess,
  LoadListFail,
  LoadObject,
  LoadObjectSuccess,
  LoadObjectFail,
  CreateObject,
  CreateObjectSuccess,
  CreateObjectFail,
  UpdateObject,
  UpdateObjectSuccess,
  UpdateObjectFail,
  DeleteObject,
  DeleteObjectSuccess,
  DeleteObjectFail,
} from '@core/redux/crud/crud.action';
import { Company } from '@core/models/company.model';
import { Action } from '@ngrx/store';

export enum CompanyActionTypes {
  LoadCompanyList = '[Company] Load company list',
  LoadCompanyListSuccess = '[Company] Load company list success',
  LoadCompanyListFail = '[Company] Load company list fail',

  LoadCompany = '[Company] Load company',
  LoadCompanySuccess = '[Company] Load company success',
  LoadCompanyFail = '[Company] Load company fail',

  CreateCompany = '[Company] Create company',
  CreateCompanySuccess = '[Company] Create company success',
  CreateCompanyFail = '[Company] Create company fail',

  UpdateCompany = '[Company] Update company',
  UpdateCompanySuccess = '[Company] Update company success',
  UpdateCompanyFail = '[Company] Update company fail',

  DeleteCompany = '[Company] Delete company',
  DeleteCompanySuccess = '[Company] Delete company success',
  DeleteCompanyFail = '[Company] Delete company fail',
}

/**********************************************/
// Load company list
/*********************************************/
export class LoadCompanyList extends LoadList implements Action {
  readonly type = CompanyActionTypes.LoadCompanyList;
}

export class LoadCompanyListSuccess extends LoadListSuccess<Company> implements Action {
  readonly type = CompanyActionTypes.LoadCompanyListSuccess;
}

export class LoadCompanyListFail extends LoadListFail implements Action {
  readonly type = CompanyActionTypes.LoadCompanyListFail;
}

/**********************************************/
// Load company
/*********************************************/
export class LoadCompany extends LoadObject implements Action {
  readonly type = CompanyActionTypes.LoadCompany;
}

export class LoadCompanySuccess extends LoadObjectSuccess<Company> implements Action {
  readonly type = CompanyActionTypes.LoadCompanySuccess;
}

export class LoadCompanyFail extends LoadObjectFail implements Action {
  readonly type = CompanyActionTypes.LoadCompanyFail;
}

/**********************************************/
// Create company
/*********************************************/
export class CreateCompany extends CreateObject<Company> implements Action {
  readonly type = CompanyActionTypes.CreateCompany;
}

export class CreateCompanySuccess extends CreateObjectSuccess<Company> implements Action {
  readonly type = CompanyActionTypes.CreateCompanySuccess;
}

export class CreateCompanyFail extends CreateObjectFail implements Action {
  readonly type = CompanyActionTypes.CreateCompanyFail;
}

/**********************************************/
// Update company
/*********************************************/
export class UpdateCompany extends UpdateObject<Company> implements Action {
  readonly type = CompanyActionTypes.UpdateCompany;
}

export class UpdateCompanySuccess extends UpdateObjectSuccess<Company> implements Action {
  readonly type = CompanyActionTypes.UpdateCompanySuccess;
}

export class UpdateCompanyFail extends UpdateObjectFail implements Action {
  readonly type = CompanyActionTypes.UpdateCompanyFail;
}

/**********************************************/
// Delete company
/*********************************************/
export class DeleteCompany extends DeleteObject implements Action {
  readonly type = CompanyActionTypes.DeleteCompany;
}

export class DeleteCompanySuccess extends DeleteObjectSuccess implements Action {
  readonly type = CompanyActionTypes.DeleteCompanySuccess;
}

export class DeleteCompanyFail extends DeleteObjectFail implements Action {
  readonly type = CompanyActionTypes.DeleteCompanyFail;
}

export type CompanyAction =
  | LoadCompanyList
  | LoadCompanyListSuccess
  | LoadCompanyListFail
  | LoadCompany
  | LoadCompanySuccess
  | LoadCompanyFail
  | CreateCompany
  | CreateCompanySuccess
  | CreateCompanyFail
  | UpdateCompany
  | UpdateCompanySuccess
  | UpdateCompanyFail
  | DeleteCompany
  | DeleteCompanySuccess
  | DeleteCompanyFail;
