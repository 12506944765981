import { IconRegistryService } from '@core/services/icon-registry.service';
import { SupportedLanguage, SupportedCurrency } from '@core/enums/user-preference.enum';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject, Subject, combineLatest } from 'rxjs';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import angularLocaleDe from '@angular/common/locales/de';
import angularLocaleEn from '@angular/common/locales/en-GB';
import { registerLocaleData, DOCUMENT } from '@angular/common';
import { SUFFIXES } from '@core/variables/number-formats.variable';
import { AuthUser } from '@core/models/auth-user.model';
import { NumberLangSuffixes } from '@core/interfaces/number-lang-suffixes.interface';
import { userLanguageStorageKey } from '@core/variables/language-storage.variable';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { AuthUserService } from './auth-user.service';
import { CustomTranslateLoader } from './custom-translate-loader.service';

registerLocaleData(angularLocaleDe, SupportedLanguage.de);
registerLocaleData(angularLocaleEn, SupportedLanguage.en);

/**
 * Service for global settings such as filters and locale
 */
@Injectable({
  providedIn: 'root',
})
export class AppSettingsService implements OnDestroy {
  private authUser?: AuthUser;

  private destroyed$ = new Subject<void>();

  private languageSubject$: BehaviorSubject<string>;

  public defaultLang: SupportedLanguage;

  public language$: Observable<string>;

  public currentLanguage: SupportedLanguage;

  public currentCurrency?: SupportedCurrency;

  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  authUserSettingsInitialised$ = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private authUserService: AuthUserService,
    private themeService: ThemeService,
    private iconRegistryService: IconRegistryService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
    // Default to English if localized string is missing
    this.translateService.setDefaultLang(SupportedLanguage.en);

    // Best fit language for the user
    this.defaultLang = navigator.language.slice(0, 2) === 'de' ? SupportedLanguage.de : SupportedLanguage.en;
    this.currentLanguage = this.defaultLang;
    this.languageSubject$ = new BehaviorSubject<string>(this.currentLanguage);
    this.language$ = this.languageSubject$.asObservable();
    this.iconRegistryService.init();
    const isAppleDevice =
      window.navigator.userAgent.includes('Macintosh') || window.navigator.userAgent.includes('iPhone');
    if (!isAppleDevice) {
      this.document.body.classList.add('core-scrollbar');
    }
    this.authUserService.authUser$
      .pipe(debounceTime(300), takeUntil(this.destroyed$))
      .subscribe((authUser: AuthUser) => {
        if (authUser && (!this.authUser || authUser._id !== this.authUser?._id) && authUser.settings) {
          // Darkmode
          if (this.activatedRoute.snapshot.queryParams.app_theme) {
            this.themeService.current = this.activatedRoute.snapshot.queryParams.app_theme;
          } else if (authUser.settings.darkMode !== null) {
            this.themeService.current = authUser.settings.darkMode ? 'dark' : 'light';
          } else {
            this.themeService.setThemeToPreferredColorScheme();
          }
          // Currency
          if (authUser.settings.currency) {
            this.currentCurrency = authUser.settings.currency;
          } else {
            this.currentCurrency = SupportedCurrency.EUR;
          }
          // Language
          this.changeLanguage(authUser.settings.language);
          this.authUserSettingsInitialised$.next(true);
        }
      });

    // ngx-translate TranslateLoader.getTranslation uses rxjs take(1)
    // therefore we need a workaround to show local translations first, followed by translations from Google storage
    const translateLoader = this.translateService.currentLoader as CustomTranslateLoader;
    combineLatest([
      translateLoader.translations[SupportedLanguage.en],
      translateLoader.translations[SupportedLanguage.de],
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([en, de]) => {
        if (en) {
          this.translateService.setTranslation(SupportedLanguage.en, en);
        }
        if (de) {
          this.translateService.setTranslation(SupportedLanguage.de, de);
        }
      });
  }

  /**
   * Sets the default configuration for translations
   */
  setTranslationConfig(translateService: TranslateService): Observable<any> {
    translateService.addLangs([SupportedLanguage.en, SupportedLanguage.de]);

    switch (this.currentLanguage) {
      case SupportedLanguage.de:
        dayjs.locale(SupportedLanguage.de);
        break;
      case SupportedLanguage.en:
      default:
        dayjs.locale('en-gb');
        break;
    }

    return translateService.use(this.currentLanguage);
  }

  /**
   * Updates the preferred language
   * @param language the selected language
   */
  changeLanguage(language: SupportedLanguage): void {
    this.currentLanguage = language || this.defaultLang;
    localStorage.setItem(userLanguageStorageKey, this.currentLanguage);
    this.translateService.use(this.currentLanguage);
    this.languageSubject$.next(this.currentLanguage);

    switch (this.currentLanguage) {
      case SupportedLanguage.de:
        dayjs.locale(SupportedLanguage.de);
        break;
      case SupportedLanguage.en:
      default:
        dayjs.locale('en-gb');
        break;
    }
  }

  /**
   * Get the current suffixes based in language
   * @param suffixIndex the index for the suffix in the array
   */
  getSuffixesByLanguage(suffixIndex: number): string {
    return (
      SUFFIXES.find((suffix: NumberLangSuffixes) => suffix.lang === this.currentLanguage)?.suffixes[suffixIndex] || ''
    );
  }

  /**
   * Get locale based in current language
   */
  getLocaleIdByLanguage(): SupportedLanguage {
    return this.currentLanguage === SupportedLanguage.de ? SupportedLanguage.de : SupportedLanguage.en;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
