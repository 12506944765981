import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { IntegrationDialogConfig } from '@core/dialogs/integration-workflow/interfaces/dialog-config.interface';
import { IntegrationDialogService } from '@core/dialogs/integration-workflow/services/integration-dialog.service';

@Component({
  selector: 'core-auth-integration-unauthorized-dialog',
  templateUrl: './auth-integration-unauthorized-dialog.component.html',
  styleUrls: ['./auth-integration-unauthorized-dialog.component.scss'],
})
export class AuthIntegrationUnauthorizedDialogComponent implements OnInit {
  selectedStore = this.globalFilterService.store;

  actionInProgress = false;

  svg?: string;

  errorText = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IntegrationDialogConfig,
    private integrationDialogService: IntegrationDialogService,
    private globalFilterService: GlobalFilterService,
  ) {}

  ngOnInit(): void {
    this.svg = this.integrationDialogService.getConnectIllustration(this.data.provider);
    this.errorText = `INTEGRATION_DIALOG.CONNECT_DIALOG_PERMISSIONS_ERROR.TEXT.${this.data.integration?.provider}`;
  }

  onCancel(): void {
    this.integrationDialogService.closeDialog();
  }

  onSubmit(): void {
    const redirectUrl = `${this.data.integration?.authenticationMethod.link}${this.data.queryParamsString?.replace(
      '?',
      '&',
    )}`;
    window!.location.href = redirectUrl;
  }
}
