import packageJson from 'package.json';

const baseUrl = 'https://api.alphin.dev/';

export const environment = {
  production: false,
  name: 'testing',
  baseUrl,
  baseUrlV1: `${baseUrl}v1/`,
  appVersion: `${packageJson.version}-test`,
  segment: {
    web: 'DnmnjnvjP1HYpte4Bm30Thuktj5UAksV',
    ios: 'EjDUUu0Tbl9NDHN55xnixjQxBfxI6vvL',
    android: 'w2ipwxYbuXVJFAmhyttM1vZPi7IKtbqE',
  },
  intercom: {
    appId: 'v19nuav2',
    ios: 'ios_sdk-e4b9abc29e9a48e4f33db8c1c6744731c34831e3',
    android: 'android_sdk-1c4482c3682ebe651d66e7480a326594a58fc713',
  },
  microfrontend: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAwo26hJ_Cfn9RlhRArqb0MvaVd4Ywh_Xk',
    authDomain: 'business-center-testing.firebaseapp.com',
    projectId: 'business-center-testing',
    storageBucket: 'business-center-testing.appspot.com',
    messagingSenderId: '610640737173',
    appId: '1:610640737173:web:f10ca8bc295b02a87cfc9c',
    measurementId: 'G-DX311JW92X',
  },
  googleMaps: {
    apiKey: 'AIzaSyAi8p68xGd4yV4dMyxfBesVs8bSSRvecL0',
  },
  apm: {
    serviceName: packageJson.name,
    serviceVersion: packageJson.version,
    serverUrl: 'https://85aa33cae73b42519be053d1b25ca5e3.apm.europe-west1.gcp.cloud.es.io',
    environment: 'testing',
  },
  contentful: {
    space: 'jeo3xcwzd5g0',
    accessToken: 'K5C9BWo6c5I1HYFy7INye31OgKQJ7pH7mDR8f8ruG8E',
    environment: 'master',
  },
  onesignal: {
    appId: 'a0eb6c3d-95b2-4e55-97e2-47ab3b1c0fc9',
  },
  routerUseHash: false,
  storybookUrl: 'https://ui.internal.alphin.dev/storybook',
};
