import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PostModule } from '@core/modules/post/post.module';
import { TagModule } from '@core/ui/tag/tag.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { SocialPostThumbnailComponent } from './social-post-thumbnail.component';

const components = [SocialPostThumbnailComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, TranslateModule, PostModule, TagModule, SvgModule],
  exports: [...components],
  providers: [],
})
export class SocialPostThumbnailModule {}
