<core-post-card
  (loaded)="updateLayout.emit()"
  (fallbackLoaded)="updateLayout.emit()"
  (error)="updateLayout.emit(); hasError = true"
  (slideChange)="updateLayout.emit(); slideIndex = $event?.index"
  [mediaVariant]="post?.mediaVariant"
  [cardVariant]="cardVariant"
  [slides]="post?.slides"
  [counts]="post?.counts"
  [owner]="post?.owner"
  [date]="post?.date"
  [caption]="post?.caption"
  [title]="'POST.HEADER.POST_KIND.' + post.type | translate"
  icon="influencer"
  provider="INSTAGRAM">
  <div
    corePostFooter
    *ngIf="!hasError"
    class="d-flex align-items-center justify-content-between">
    <button
      coreButton
      [disabled]="!!ctaLoadingForPost"
      [actionInProgress]="ctaLoadingForPost === post.id"
      [variant]="cardVariant === 'content-overlay' ? 'light' : 'primary'"
      (click)="ctaClick.emit({ action: 'repost', post: post, slideIndex: slideIndex })">
      {{ 'POST.CTA.SHARE' | translate }}
    </button>
    <div></div>
    <div class="d-flex align-items-center">
      <core-post-media-save
        [mediaVariant]="post.slides?.[slideIndex]?.type"
        [url]="post.slides?.[slideIndex]?.src">
      </core-post-media-save>
      <core-post-rating
        *ngIf="post.raw.dealInfo"
        class="ml-2"
        [feedback]="post.raw.feedback"
        (feedbackChange)="feedbackChange.emit({ feedback: $event, post: post })">
      </core-post-rating>
    </div>
  </div>
</core-post-card>
