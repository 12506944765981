<p class="p-0 m-0">
  <span
    [innerHTML]="
      (isTruncated
        ? (text | truncate : maxLength)
        : text
      ) | formattedCaption: provider
    ">
  </span>
  <span
    *ngIf="showTruncateToggle()"
    class="truncate-toggle"
    (click)="updateTruncateState($event)">
    {{ (isTruncated ? 'POST.SEE_MORE' : 'POST.SEE_LESS') | translate }}
  </span>
</p>
