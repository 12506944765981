/**
 * Returns the url to view a user account on Instagram
 * @param username the username value
 */
export const instagramAccountUrl = (username: string): string => `https://www.instagram.com/${username}/`;

/**
 * Returns the url to view a hashtag on Instagram
 * @param hashtag the hashtag value
 */
export const instagramHashtagUrl = (hashtag: string): string => `https://www.instagram.com/explore/tags/${hashtag}/`;

/**
 * Returns the url to view a user account on Facebook
 * @param username the username value
 */
export const facebookAccountUrl = (username: string): string => `https://www.facebook.com/${username}/`;

/**
 * Returns the url to view a hashtag on Facebook
 * @param hashtag the hashtag value
 */
export const facebookHashtagUrl = (hashtag: string): string => `https://www.facebook.com/hashtag/${hashtag}/`;
