import { Nullable } from '@core/types/nullable.type';
import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';
import { ResourceType } from '@core/dialogs/post-manager/enums/resource-type.enum';

export class AccountMedia {
  media!: {
    url: string;
    thumbnail: Nullable<string>;
  };

  meta!: {
    size: Nullable<number>;
    width: Nullable<number>;
    height: Nullable<number>;
    duration: Nullable<number>;
  };

  provider!: ManagedPostProvider;

  url?: Nullable<string>;

  type!: ResourceType;

  interactions!: {
    views: Nullable<number>;
    clicks: Nullable<number>;
    likes: Nullable<number>;
  };

  galleryMediaCreatedAt?: string;

  galleryMediaUpdatedAt?: string;

  galleryMediaId?: string;

  constructor(object: AccountMedia) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for the media data of particular account
 */
@Injectable({
  providedIn: 'root',
})
export class AccountMediaAdapter implements Adapter<AccountMedia> {
  clientAdapt(object: any): AccountMedia {
    return new AccountMedia(object);
  }

  serverAdapt(object: AccountMedia): any {
    return object;
  }
}
