import { Component, Input } from '@angular/core';
import { CardPadding, CardRoundness, CardLevel, HandsetGroupPosition } from '@core/ui/card/types/common.type';
import { CardOverlayStrength, CardVariant } from '../../types/card.type';

@Component({
  selector: 'core-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: {
    '[class]': `"card"
      + " roundness-"+roundness
      + " padding-"+padding
      + " level-"+level
      + " variant-"+variant
      + " overlay-strength-"+overlayStrength
      + " handset-group-"+handsetGroupPosition`,
    '[class.card--shadow]': 'shadow',
    '[class.card--selectable]': 'selectable',
    '[class.card--selected]': 'selected',
    '[class.card--overlay]': 'overlay',
    '[class.card--border]': 'border',
    '[class.background-image]': 'src',
    '[style.background-image]': 'src ? "url("+src+")" : ""',
  },
})
export class CardComponent {
  @Input()
  src?: string;

  @Input()
  padding: CardPadding = 'md';

  @Input()
  roundness: CardRoundness = 'sm';

  @Input()
  level: CardLevel = 1;

  @Input()
  variant: CardVariant = 'default';

  @Input()
  overlayStrength: CardOverlayStrength = 'full';

  /**
   * On the mobile view, changes the border radius so that
   * `start` has rounded corners at the top only
   * `between` has no rounded corners
   * `end` has rounded corners at the bottom only
   * `single` keeps the standard border radius but it acts as a mobile group
   * `none` keeps the standard border radius but it keeps the web margins
   */
  @Input()
  handsetGroupPosition: HandsetGroupPosition = 'none';

  @Input()
  shadow = true;

  @Input()
  selectable = false;

  @Input()
  selected = false;

  @Input()
  overlay = false;

  @Input()
  border = false;
}
