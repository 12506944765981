/**
 * CardSerialNumberPipe
 *
 * Pipe to format card numbers
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardSerialNumber',
})
export class CardSerialNumberPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return value;
    }

    return value.replace(/(\w{4})(\w{4})(\w{4})(.*)/, '$1-$2-$3-$4');
  }

  parse(value: string): string {
    return (value || '').split('-').join('');
  }
}
