import { Component, Input } from '@angular/core';
import { MessageEventService } from '@core/services/message-event.service';
import { Nullable } from '@core/types/nullable.type';
import { SingleMediaVariant } from '@core/ui/media/types/media.type';
import { MEDIA_EVENT } from '@core/variables/webview-events.variable';

@Component({
  selector: 'core-post-media-save',
  templateUrl: './post-media-save.component.html',
  styleUrls: ['./post-media-save.component.scss'],
})
export class PostMediaSaveComponent {
  isWebView = this.messageEventService.isWebView;

  @Input()
  mediaVariant: SingleMediaVariant = 'IMAGE';

  @Input()
  url?: Nullable<string>;

  constructor(private messageEventService: MessageEventService) {}

  saveMedia(): void {
    if (!this.url) {
      return;
    }
    if (this.isWebView) {
      this.messageEventService.postMessageToWebView(MEDIA_EVENT.downloaded, {
        url: this.url,
        mediaVariant: this.mediaVariant,
      });
    } else {
      window.open(this.url, '_blank');
    }
  }
}
