import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Campaign, CampaignListAdapter } from '@google-ad/common/models/campaign.model';
import {
  ExpectedRevenuePerVisit,
  ExpectedRevenuePerVisitAdapter,
} from '@google-ad/common/models/expected-revenue-per-visit.model';
import { Observable } from 'rxjs';
import { CampaignInsightsGeneral, CampaignInsightsGeneralAdapter } from '../models/campaign-insights-general.model';
import { GetAdCampaignInsightsGeneralParams, GetAdCampaignListParams } from '../interfaces/query-params.interface';

/**
 * Service for Google Ads data
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAdDataService {
  constructor(
    protected apiService: ApiService,
    protected campaignListAdapter: CampaignListAdapter,
    protected campaignInsightsGeneralAdapter: CampaignInsightsGeneralAdapter,
    protected expectedRevenuePerVisitAdapter: ExpectedRevenuePerVisitAdapter,
  ) {}

  /**
   * @param storeId the id of the store
   * @param queryParams the config for the request
   */
  getCampaignList(
    storeId: string,
    queryParams: GetAdCampaignListParams = { provider: 'GOOGLE' },
  ): Observable<Campaign[]> {
    const httpParams = new HttpParams({
      fromObject: {
        ...queryParams,
      },
    });

    return this.apiService
      .get(`stores/${storeId}/ads/campaigns`, httpParams)
      .pipe(map((res) => res.result.map((campaign: Campaign) => this.campaignListAdapter.clientAdapt(campaign))));
  }

  /**
   * @param storeId the id of the store
   * @param campaignId the id of the campaign
   * @param queryParams the config for the request
   */
  getCampaignInsightsGeneral(
    storeId: string,
    campaignId: string,
    queryParams: GetAdCampaignInsightsGeneralParams = {},
  ): Observable<CampaignInsightsGeneral[]> {
    const httpParams = new HttpParams({
      fromObject: {
        ...queryParams,
      },
    });

    return this.apiService
      .get(`stores/${storeId}/ads/campaigns/${campaignId}/insights/general`, httpParams)
      .pipe(
        map((res) =>
          res.result.map((insights: CampaignInsightsGeneral) =>
            this.campaignInsightsGeneralAdapter.clientAdapt(insights),
          ),
        ),
      );
  }

  /**
   * @param storeId the id of the store
   */
  getExpectedRevenuePerVisit(storeId: string): Observable<ExpectedRevenuePerVisit> {
    return this.apiService
      .get(`stores/${storeId}/ads/expected-revenue-per-visit`)
      .pipe(map((res: ApiSuccessResponse<ExpectedRevenuePerVisit>) => res.result));
  }

  /**
   * @param storeId the id of the store
   * @param expectedRevenuePerVisit expected revenue value to set
   */
  setExpectedRevenuePerVisit(storeId: string, expectedRevenuePerVisit: number): Observable<ExpectedRevenuePerVisit> {
    return this.apiService
      .put(`stores/${storeId}/ads/expected-revenue-per-visit`, {
        expectedRevenuePerVisit,
      })
      .pipe(map((res: ApiSuccessResponse<ExpectedRevenuePerVisit>) => res.result));
  }
}
