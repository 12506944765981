<div class="d-flex align-items-center w-100 mx-n1">
  <core-linear-progress-bar
    class="flex-grow-1 mx-1"
    *ngFor="let bar of [].constructor(bars); let i = index"
    [flash]="flashingBar === i + 1"
    [value]="i >= flashingBar ? 0 : 1"
    [variant]="variant"
    [total]="1">
  </core-linear-progress-bar>
</div>
