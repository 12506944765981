<div [formGroup]="formGroup" class="d-flex flex-column flex-md-row align-items-md-start"> 
  <core-card
    class="schedule-toggle mb-2 mb-md-0"
    [style.border-color]="formGroup?.controls.publishAt?.invalid && formGroup?.controls.publishAt?.touched ? 'rgb(var(--error))' : null"
    padding="none"
    [level]="3"
    roundness="xs"
    [border]="true"
    [shadow]="false">
    <core-toggle
      [labelPadding]="{ x: 'md', y: 'sm' }"
      formControlName="schedule"
      [multiLineLabel]="true"
      labelPosition="after"
      variant="success"
      (change)="onToggleChange($event)">
      {{ 'POST_MANAGER.ACTION.SCHEDULE' | translate }}
    </core-toggle>
  </core-card>

  <core-card
    class="publish-instantly align-items-start justify-content-center flex-grow-1"
    padding="none"
    [level]="1"
    roundness="xs"
    [border]="true"
    [shadow]="false"
    [class.d-flex]="!formGroup.controls?.schedule?.value"
    [class.d-none]="formGroup.controls?.schedule?.value">
    <span class="py-1 px-3">{{ 'POST_MANAGER.PUBLISH_INSTANTLY.TEXT' | translate }}</span>
  </core-card>

  <core-form-field
    [class.d-flex]="formGroup.controls?.schedule?.value"
    [class.d-none]="!formGroup.controls?.schedule?.value"
    class="flex-grow-1 d-flex flex-column"
    [includeSubscriptSpacer]="false" [groupPosition]="isScreenSmall ? 'none' : 'end'">
    <core-icon (click)="openDatePicker()" coreFormFieldPrefix icon="calendar" size="sm"></core-icon>
    <input
      #publishDateInput
      coreInput formControlName="publishAt"
      placeholder="'POST_MANAGER.PLACEHOLDER.PUBLISH_DATE' | translate" type="datetime-local" />
    <core-icon (click)="openDatePicker()" coreFormFieldSuffix icon="chevron_down" size="sm"></core-icon>
  </core-form-field>
</div>
