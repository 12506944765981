import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(duration);

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'humanizeDate',
})
export class HumanizeDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: any, suffix = true) {
    if (!date) {
      return date;
    }

    const dateDayJs = dayjs(date);
    const now = dayjs(new Date());

    return dayjs.duration(dateDayJs.diff(now)).locale(this.translateService.currentLang).humanize(suffix);
  }
}
