import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { PageLinkType } from '@core/enums/page.enum';

export class PageLinksAnalytics {
  total?: number;

  type?: PageLinkType;

  provider?: string;

  trend?: {
    after: {
      total: number;
    };
    before: {
      total: number;
    };
    delta: {
      total: number;
      totalPercentage: number;
    };
    series: {
      unit: string;
      unitAmount: number;
      unitStart: string;
      unitEnd: string;
      data: {
        date: string;
        count: number;
        countCumulative: number;
      }[];
    };
  };

  series?: {
    unit: string;
    unitAmount: number;
    unitStart: string;
    unitEnd: string;
    data: {
      date: string;
      count: number;
      countCumulative: number;
    }[];
  };

  constructor(object: PageLinksAnalytics) {
    Object.assign(this, object);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PageLinksAnalyticsAdapter implements Adapter<PageLinksAnalytics> {
  clientAdapt(object: any): PageLinksAnalytics {
    return new PageLinksAnalytics(object);
  }

  serverAdapt(object: PageLinksAnalytics): any {
    return object;
  }
}
