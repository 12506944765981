import { Component, Input } from '@angular/core';
import { SupportedCurrency, SupportedLanguage } from '@core/enums/user-preference.enum';
import { TranslateService } from '@ngx-translate/core';
import { getCountUpConfig } from '../../variables/common.variable';

@Component({
  selector: 'core-kpi-value',
  templateUrl: './kpi-value.component.html',
  styleUrls: ['./kpi-value.component.scss'],
  host: {
    '[class]': '"kpi-value"',
    '[class.kpi-value--empty]': 'value === 0',
  },
})
export class KpiValueComponent {
  @Input()
  value = 0;

  @Input()
  abbreviateValue = false;

  @Input()
  currency?: SupportedCurrency;

  @Input()
  unit = '';

  countUpOptions: any;

  countUpValue?: number;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.initCountUpOptions();
  }

  initCountUpOptions(): void {
    const { value, options } = getCountUpConfig(
      this.value,
      this.abbreviateValue,
      this.translateService.currentLang as SupportedLanguage,
      this.currency,
      this.unit,
    );
    this.countUpValue = value;
    this.countUpOptions = options;
  }
}
