import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@core/ui/alert/components/snack-bar/snack-bar.component';
import _isNil from 'lodash/isNil';
import { Nullable } from '@core/types/nullable.type';
import { SNACK_BAR_EVENT } from '@core/variables/webview-events.variable';
import { MessageEventService } from './message-event.service';

type MatSnackBarVerticalPosition = 'top' | 'bottom';
type MatSnackBarHorizontalPosition = 'start' | 'center' | 'end' | 'left' | 'right';
type MatSnackBarType = 'default' | 'error';

export interface SnackBarConfig {
  variant?: MatSnackBarType;
  icon?: string;
  title?: string;
  text?: string;
  dismissable?: boolean;
  isCtaVisible?: boolean;
  ctaLabel?: string;
  ctaCallback?: (...args: any[]) => void;
  duration?: number;
  panelClass?: string;
  autoHide?: boolean;
  updating?: boolean;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  verticalPosition?: MatSnackBarVerticalPosition;
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private defaultSnackBar: SnackBarConfig = {
    variant: 'default',
    text: '',
    dismissable: true,
    duration: 10000,
    autoHide: true,
    panelClass: '',
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  };

  constructor(
    private matSnackBar: MatSnackBar,
    private messageEventService: MessageEventService,
  ) {}

  /**
   * Displays the snack bar with the specified configuration
   * @param notificationOptions the config options
   */
  openSnackBar(notificationOptions: SnackBarConfig = {}): Nullable<MatSnackBarRef<SnackBarComponent>> {
    const options = notificationOptions;
    if (_isNil(notificationOptions.dismissable) && notificationOptions.ctaLabel) {
      options.dismissable = false;
    }

    const snackBarConfig: SnackBarConfig = { ...this.defaultSnackBar, ...options };
    if (this.messageEventService.isWebView) {
      this.messageEventService.postMessageToWebView(SNACK_BAR_EVENT.triggered, snackBarConfig);
    }
    const snackBarRef = this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        title: snackBarConfig.title,
        variant: snackBarConfig.variant,
        icon: snackBarConfig.icon,
        text: snackBarConfig.text,
        dismissable: snackBarConfig.dismissable,
        isCtaVisible: snackBarConfig.isCtaVisible,
        ctaCallback: snackBarConfig.ctaCallback,
        ctaLabel: snackBarConfig.ctaLabel,
        updating: snackBarConfig.updating,
      },
      panelClass: snackBarConfig.variant === 'error' ? snackBarConfig.variant : '',
      duration: snackBarConfig.autoHide ? snackBarConfig.duration : undefined,
      verticalPosition: snackBarConfig.verticalPosition,
      horizontalPosition: snackBarConfig.horizontalPosition,
    });

    return snackBarRef;
  }
}
