import { Directive, InjectionToken } from '@angular/core';

export const FORM_FIELD_PREFIX = new InjectionToken<FormFieldPrefixDirective>('FormFieldPrefix');

/**
 * Prefix to be placed at the end of the form field.
 */
@Directive({
  selector: '[coreFormFieldPrefix]',
  providers: [
    {
      provide: FORM_FIELD_PREFIX,
      useExisting: FormFieldPrefixDirective,
    },
  ],
})
export class FormFieldPrefixDirective {}
