import { CurrencySymbol, SupportedCurrency, SupportedLanguage } from '@core/enums/user-preference.enum';
import { SUFFIXES } from '@core/variables/number-formats.variable';

const localeFormats = {
  [SupportedLanguage.de]: {
    separator: '.',
    decimal: ',',
  },
  [SupportedLanguage.en]: {
    separator: ',',
    decimal: '.',
  },
};

const startVal = 0;

const duration = 2;

const abbreviatedDecimalPlaces = 2;

export const getCountUpConfig = (
  value: number,
  abbreviate = false,
  locale: SupportedLanguage = SupportedLanguage.en,
  currency?: SupportedCurrency,
  unit = '',
) => {
  const decimalPlaces = abbreviate || (currency && value % 1 !== 0) ? abbreviatedDecimalPlaces : 0;
  const exp = Math.floor(Math.log(value) / Math.log(1000));

  let currencyPrefix;
  let currencySuffix;
  if (locale === SupportedLanguage.de) {
    currencySuffix = currency ? CurrencySymbol[currency] : '';
    currencyPrefix = '';
  } else {
    currencyPrefix = currency ? CurrencySymbol[currency] : '';
    currencySuffix = '';
  }

  const abbreviationSuffix = abbreviate ? SUFFIXES.find((item) => item.lang === locale)?.suffixes[exp - 1] : '';
  const { separator, decimal } = localeFormats[locale];

  if (value < 1000) {
    return {
      value,
      options: {
        startVal,
        decimalPlaces: currency && value % 1 !== 0 ? abbreviatedDecimalPlaces : 0,
        duration,
        useEasing: true,
        prefix: currencyPrefix,
        suffix: currencySuffix || unit ? ` ${`${currencySuffix} ${unit}`.trim()}` : '',
      },
    };
  }

  return {
    value: abbreviate ? value / 1000 ** exp : value,
    options: {
      startVal,
      decimalPlaces,
      duration,
      useEasing: false,
      separator,
      decimal,
      prefix: currencyPrefix,
      suffix:
        abbreviationSuffix || currencySuffix || unit
          ? `${locale === SupportedLanguage.de || !abbreviationSuffix ? ' ' : ''}${`${abbreviationSuffix} ${
              currencySuffix ? `${currencySuffix} ` : ''
            }${unit}`.trim()}`
          : '',
    },
  };
};
