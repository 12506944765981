import { Directive, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `SelectTrigger`. It serves as
 * alternative token to the actual `SelectTrigger` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const SELECT_TRIGGER = new InjectionToken<SelectTriggerDirective>('SelectTrigger');

/**
 * Allows the user to customize the trigger that is displayed when the select has a value.
 */
@Directive({
  selector: '[coreSelectTrigger]',
  providers: [{ provide: SELECT_TRIGGER, useExisting: SelectTriggerDirective }],
})
export class SelectTriggerDirective {}
