import { AfterContentInit, Component, Inject, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Subject, Observable, of } from 'rxjs';
import { PlaceOption } from '@core/modules/auth/screens/register/interfaces/place-option.interface';
import { dialCodeOptions, dialCodeQuickOptions } from '@core/variables/dial-code-options.variable';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapsService } from '@core/modules/map/services/google-maps.service';
import { AppSettingsService } from '@core/services/app-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snack-bar.service';
import { SegmentService } from '@core/services/segment.service';
import { DOCUMENT } from '@angular/common';
import { catchError, debounceTime, first, takeUntil, tap, switchMap } from 'rxjs/operators';
import { TRACK_REGISTRATION } from '@core/variables/tracking-events.variable';
import { SupportedLanguage } from '@core/enums/user-preference.enum';
import { AuthUserService } from '@core/services/auth-user.service';
import { ConnectStoreConfig } from '@core/interfaces/connect-store.interface';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { Store } from '@core/models/store.model';
import { storeSetupOutlet } from '@core/dialogs/store-setup-workflow/variables/outlet.variable';
import { StoreService } from '@core/services/store.service';
import { goalSettingOutlet } from '@core/dialogs/goal-setting-workflow/variables/outlet.variable';
import { GoalSettingRoute } from '@core/dialogs/goal-setting-workflow/enums/goal-setting-route.enum';
import { GoalSettingWorkflowComponent } from '@core/dialogs/goal-setting-workflow/goal-setting-workflow.component';
import { DialogService } from '@core/services/dialog.service';
import { StoreSetupRoute } from '@core/dialogs/store-setup-workflow/enums/store-setup-route.enum';
import { DialCodeOption } from '@core/interfaces/contact.interface';

@Component({
  selector: 'core-store-setup-form',
  templateUrl: './store-setup-form.component.html',
  styleUrls: ['./store-setup-form.component.scss'],
})
export class StoreSetupFormComponent implements AfterContentInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  storeForm: FormGroup = new FormGroup({
    country: new FormControl(<DialCodeOption>{}, [Validators.required]),
    storeName: new FormControl('', [Validators.required]),
    enterManually: new FormControl(false),
  });

  autocompleteService?: google.maps.places.AutocompleteService;

  placesService?: google.maps.places.PlacesService;

  resultsLoading = false;

  options: PlaceOption[] = [];

  requestConfig?: ConnectStoreConfig;

  actionInProgress = false;

  isStoreValid = false;

  countryOptions = dialCodeOptions;

  countryQuickOptions = dialCodeQuickOptions;

  language = this.appSettingsService.currentLanguage;

  hasOneStoreAssigned$: Observable<boolean> = this.storeService
    .getStoreList()
    .pipe(switchMap((value) => of(!!value.data?.length)));

  constructor(
    private activatedRoute: ActivatedRoute,
    private googleMapsService: GoogleMapsService,
    private authUserService: AuthUserService,
    private storeService: StoreService,
    private ngZone: NgZone,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private segmentService: SegmentService,
    private globalFilterService: GlobalFilterService,
    private router: Router,
    private dialogService: DialogService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngAfterContentInit(): void {
    const { store_name: storeName } = this.activatedRoute.snapshot.queryParams;
    this.storeForm.patchValue({
      country: this.countryQuickOptions[0],
      storeName: storeName || '',
    });
    this.appSettingsService.language$.pipe(takeUntil(this.destroyed$)).subscribe((language) => {
      this.language = language as SupportedLanguage;
      this.countryOptions.sort((a, b) => a.name[this.language].localeCompare(b.name[this.language]));
    });

    // Load google maps api
    this.googleMapsService.ready().subscribe(() => {
      this.initMapServices();
    });

    this.storeForm.controls?.enterManually?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((enterManually) => {
      // If the user wants to enter the store manually, set the name input to valid.
      // If the user unchecks the field, mark it as invalid until they select
      // a value from the autocomplete
      this.isStoreValid = enterManually!;
      if (!enterManually) {
        // remove manual fields
        this.storeForm?.removeControl('street');
        this.storeForm?.removeControl('zip');
        this.storeForm?.removeControl('city');

        return;
      }
      // add manual fields
      this.storeForm?.addControl('street', new FormControl('', [Validators.required]));
      this.storeForm?.addControl('zip', new FormControl('', [Validators.required]));
      this.storeForm?.addControl('city', new FormControl('', [Validators.required]));
    });
  }

  setManualAddressChecked(value: boolean): void {
    this.storeForm.controls?.enterManually.setValue(value);
  }

  /**
   * Autocomplete inputs
   */
  initMapServices(): void {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(this.document.createElement('div'));
    this.storeForm.controls.storeName.valueChanges
      .pipe(
        tap(() => {
          this.resultsLoading = true;
        }),
        debounceTime(1000),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        this.onInputChange(value!);
        this.resultsLoading = false;
      });
  }

  onInputChange(input: string) {
    this.ngZone.run(() => {
      if (!this.autocompleteService || !input) {
        this.options = [];

        return;
      }
      this.autocompleteService.getPlacePredictions(
        {
          input,
          types: ['establishment'],
          componentRestrictions: { country: this.storeForm.controls.country.value?.code || 'de' },
        },
        (results: google.maps.places.AutocompletePrediction[] | null) => {
          this.options = (results || []).map((item: google.maps.places.AutocompletePrediction) => ({
            placeId: item.place_id,
            name: item.structured_formatting.main_text,
            address: item.structured_formatting.secondary_text,
          }));
        },
      );
    });
  }

  setValue(option: PlaceOption): void {
    if (!this.placesService) {
      return;
    }

    this.isStoreValid = true;
    this.placesService.getDetails({ placeId: option.placeId }, (result) => {
      if (result) {
        const streetNumber =
          result.address_components?.find((item) => item.types.includes('street_number'))?.long_name || '';
        const streetName = result.address_components?.find((item) => item.types.includes('route'))?.long_name || '';
        const zip = result.address_components?.find((item) => item.types.includes('postal_code'))?.long_name || '';
        const city = result.address_components?.find((item) => item.types.includes('locality'))?.long_name || '';
        const country = result.address_components?.find((item) => item.types.includes('country'))?.long_name || '';
        const countryCode = result.address_components?.find((item) => item.types.includes('country'))?.long_name || '';
        this.storeForm.patchValue({
          storeName: `${option.name}, ${result.formatted_address}`,
        });
        this.requestConfig = {
          name: result.name,
          contact: {
            website: result.website,
            phone: result.formatted_phone_number,
          },
          location: {
            street: `${streetName} ${streetNumber}`.trim(),
            zip,
            city,
            country,
            countryCode,
            geo: {
              type: 'Point',
              coordinates: result.geometry
                ? [result.geometry?.location?.lng()!, result.geometry?.location?.lat()!]
                : [],
            },
          },
        };
      }
      this.options = [];
    });
  }

  submit(): void {
    if (this.storeForm.invalid) {
      this.segmentService.track(TRACK_REGISTRATION.store.invalidForm, {
        ...this.requestConfig,
        formValue: this.storeForm.value,
      });

      return this.storeForm.markAllAsTouched();
    }
    if (!this.isStoreValid) {
      this.storeForm.controls.storeName.setErrors({ autocomplete: true });

      return;
    }
    this.actionInProgress = true;

    const isManualAddress = this.storeForm.controls?.enterManually.value;

    if (isManualAddress) {
      this.requestConfig = {
        name: this.storeForm.value.storeName,
        location: {
          city: this.storeForm.value.city,
          zip: this.storeForm.value.zip,
          street: this.storeForm.value.street,
          country: this.storeForm.value.country.name.en,
          countryCode: this.storeForm.value.country.code,
        },
      };
    }

    return this.attachStore();
  }

  attachStore(): void {
    const {
      /* eslint-disable @typescript-eslint/naming-convention */
      review_tag_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      utm_id,
    } = this.activatedRoute.snapshot.queryParams;
    /* eslint-enable @typescript-eslint/naming-convention */
    this.authUserService
      .connectStore({
        ...this.requestConfig!,
        params: {
          review_tag_id,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term,
          utm_id,
        },
      })
      .pipe(
        first(),
        catchError((error) => {
          this.actionInProgress = false;
          this.snackBarService.openSnackBar({
            variant: 'error',
            icon: 'warning',
            text: this.translateService.instant('ERROR.REQUEST'),
          });
          this.segmentService.track(TRACK_REGISTRATION.store.error, {
            ...this.requestConfig,
            formValue: this.storeForm.value,
            error,
          });

          return EMPTY;
        }),
      )
      .subscribe((store: Store) => {
        this.segmentService.track(TRACK_REGISTRATION.store.attached, {
          ...this.requestConfig,
          formValue: this.storeForm.value,
        });

        this.actionInProgress = false;

        this.storeService.updateStore(store);
        this.dialogService.closeDialog();
        this.navigateToGoalSetting();
      });
  }

  navigateToGoalSetting() {
    const navigation = [
      {
        outlets: {
          [storeSetupOutlet]: null,
          [goalSettingOutlet]: GoalSettingRoute.GoalSelection,
        },
      },
    ];

    this.router.navigate(navigation, { queryParamsHandling: 'merge' }).then(() => {
      this.dialogService.openCoreDialog(GoalSettingWorkflowComponent, this.renderer, { disableClose: true });
    });
  }

  clearStoreValue() {
    this.storeForm.controls.storeName?.setValue('');
    this.isStoreValid = false;
  }

  removeOutlet(): void {
    this.router.navigate([{ outlets: { [storeSetupOutlet]: null } }], { queryParamsHandling: 'merge' });
  }

  goBack() {
    this.router.navigate([{ outlets: { [storeSetupOutlet]: StoreSetupRoute.Intro } }], {
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
