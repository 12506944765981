import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Theme } from '@core/interfaces/user-preferences.interface';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { colors } from '@core/variables/colors.variable';
import { Subject } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'core-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent implements OnInit, OnDestroy {
  @Input() tooltip?: string;

  @Input() percent = 0;

  @Input() radius = 50;

  theme: Theme = this.themeService.current;

  selectedColor = colors.flush.base;

  deselectedColor = this.getDeselectedColor();

  valueColor = this.getValueColor();

  destroyed$ = new Subject<void>();

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.themeService.currentTheme$.pipe(takeUntil(this.destroyed$)).subscribe((theme: Theme) => {
      this.theme = theme;
      this.valueColor = this.getValueColor();
      this.deselectedColor = this.getDeselectedColor();
    });
  }

  private getValueColor(): string {
    return this.theme === 'dark' ? colors.white.base : colors.navy.base;
  }

  private getDeselectedColor(): string {
    return this.theme === 'dark' ? colors.charcoal.extraLight : colors.silver.base;
  }

  toggleTooltip(tooltip: MatTooltip): void {
    if (this.tooltip) {
      tooltip.toggle();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
