import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { RouterModule } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { CardModule } from '@core/ui/card/card.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { GoalSettingWorkflowComponent } from '@core/dialogs/goal-setting-workflow/goal-setting-workflow.component';
import { GoalSettingWorkflowRoutingModule } from '@core/dialogs/goal-setting-workflow/goal-setting-workflow-routing.module';
import { GoalSelection } from '@core/dialogs/goal-setting-workflow/components/goal-selection/goal-selection.component';
import { ProductSelectionComponent } from '@core/dialogs/goal-setting-workflow/components/product-selection/product-selection.component';
import { GoalSettingDialogService } from '@core/dialogs/goal-setting-workflow/services/goal-setting-dialog.service';
import { CheckboxModule } from '@core/ui/checkbox/checkbox.module';
import { ProgressModule } from '@core/ui/progress/progress.module';
import { GoalSettingService } from '@core/dialogs/goal-setting-workflow/services/goal-setting.service';

@NgModule({
  declarations: [GoalSettingWorkflowComponent, GoalSelection, ProductSelectionComponent],
  imports: [
    CommonModule,
    DialogModule,
    CoreModule,
    ButtonModule,
    RouterModule,
    SvgModule,
    CardModule,
    TranslateModule,
    CheckboxModule,
    ProgressModule,
    GoalSettingWorkflowRoutingModule,
  ],
  providers: [DialogService, GoalSettingDialogService, GoalSettingService],
  exports: [GoalSettingWorkflowComponent],
})
export class GoalSettingWorkflowModule {}
