import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { postManagerOutlet } from '@core/dialogs/post-manager/variables/outlet.variable';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { Store } from '@core/models/store.model';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EmojiClickEvent } from 'emoji-picker-element/shared';
import { TRACK_MANAGED_POST } from '@core/variables/tracking-events.variable';
import { SegmentService } from '@core/services/segment.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snack-bar.service';
import { AuthUserService } from '@core/services/auth-user.service';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { DateTimeValidator } from '@core/validators/date-time.validator';
import { Nullable } from '@core/types/nullable.type';
import { CaptionGenerationService } from '@core/services/caption-generation.service';
import { ContentValidator } from '@core/validators/content.validator';
import { PostManagerConfig } from '../../types/post-manager.type';
import { PostManagerComponent } from '../../post-manager.component';
import { ManagedPostDataService } from '../../services/managed-post-data.service';
import { ManagedPost } from '../../models/managed-post.model';
import { PostManagerRoute } from '../../enums/post-manager-route.enum';
import { CreatorType } from '../../enums/creator-type.enum';
import { ManagedPostType } from '../../enums/managed-post-type.enum';
import { ManagedPostProvider } from '../../enums/managed-post-provider.enum';
import { AiCaptionService } from '../../services/ai-caption.service';
import { CaptionSettings } from '../../interfaces/ai-caption-request.interface';
import { defaultCaptionSettings } from '../../variables/caption-settings.variable';
import { Action } from '../../enums/action.enum';

dayjs.extend(utc);

@Component({
  selector: 'core-update-post',
  templateUrl: './update-post.component.html',
  styleUrls: ['./update-post.component.scss'],
})
export class UpdatePostComponent implements OnInit, AfterViewInit, OnDestroy {
  minsInFutureRequired = 5;

  readonly hashtagLimit = 30;

  readonly mentionLimit = 20;

  publishAtValidators: ValidatorFn[] = [
    Validators.required,
    DateTimeValidator.isCurrentDateMoreThanSpecifiedMinutesValidator(this.minsInFutureRequired),
  ];

  formGroup: FormGroup = new FormGroup({
    captionOutline: new FormControl(''),
    captionText: new FormControl('', [
      Validators.required,
      ContentValidator.isContainsMoreThanSpecifiedHashtagNumber(this.hashtagLimit),
      ContentValidator.isContainsMoreThanSpecifiedMentionNumber(this.mentionLimit),
    ]),
    publishAt: new FormControl('', this.publishAtValidators),
    schedule: new FormControl(true),
    providers: new FormControl([], [Validators.required]),
    managedPostType: new FormControl(ManagedPostType.Feed, [Validators.required]),
  });

  actionInProgress = false;

  selectedStore?: Store;

  viewReady = false;

  showOutline = false;

  data?: PostManagerConfig;

  post?: ManagedPost;

  slides: MediaConfig[] = [];

  destroyed$ = new Subject<void>();

  disabledProviders: ManagedPostProvider[] = [];

  generating = false;

  captionSettings: Nullable<CaptionSettings> = null;

  constructor(
    private managedPostDataService: ManagedPostDataService,
    private globalFilterService: GlobalFilterService,
    private router: Router,
    private dialogRef: MatDialogRef<PostManagerComponent>,
    private activatedRoute: ActivatedRoute,
    private segmentService: SegmentService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private authUserService: AuthUserService,
    private aiCaptionService: AiCaptionService,
    private captionGenerationService: CaptionGenerationService,
  ) {}

  ngOnInit(): void {
    this.data = this.dialogRef.componentInstance.data;
    this.selectedStore = this.globalFilterService.store;
    this.initPost();
    this.formGroup.controls.managedPostType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.disabledProviders = this.getDisabledProviders();
      this.setCaptionTextValidator(value);
    });
    this.captionGenerationService.captionTextSubject$.subscribe((newCaptionText: string) => {
      this.formGroup.get('captionText')?.setValue(newCaptionText);
    });
    this.captionGenerationService.isCaptionGenerationLoadingSubject$.subscribe((isLoading: boolean) => {
      this.generating = isLoading;
    });
  }

  ngAfterViewInit(): void {
    this.viewReady = true;
  }

  private getDisabledProviders(): ManagedPostProvider[] {
    return this.formGroup.get('managedPostType')?.value === ManagedPostType.Feed ? [] : [ManagedPostProvider.Google];
  }

  private setCaptionTextValidator(managedPostType: ManagedPostType): void {
    switch (managedPostType) {
      case ManagedPostType.Story:
        this.formGroup.controls.captionText.clearValidators();
        break;
      case ManagedPostType.Feed:
      case ManagedPostType.Reel:
      default:
        this.formGroup.controls.captionText.addValidators([Validators.required]);
        break;
    }
    this.formGroup.controls.captionText.updateValueAndValidity();
  }

  private convertUTCToLocalTimeZone(isoStringDate: string, format = 'YYYY-MM-DDTHH:mm'): string {
    return dayjs.utc(isoStringDate).local().format(format);
  }

  private convertLocalTimeZoneToUTC(stringDate: string): string {
    return dayjs(stringDate).toISOString();
  }

  getCaptionSettings(): Observable<CaptionSettings> {
    return this.aiCaptionService.getCaptionSettings(this.selectedStore?._id!).pipe(
      first(),
      tap((captionSettings) => {
        this.captionSettings =
          captionSettings ?? defaultCaptionSettings(this.authUserService.authUser?.settings?.language);
      }),
      catchError(() => {
        this.captionSettings = defaultCaptionSettings(this.authUserService.authUser?.settings?.language);

        return EMPTY;
      }),
    );
  }

  generateCaption(): void {
    if (
      this.formGroup.controls.publishAt.invalid ||
      this.formGroup.controls.providers.invalid ||
      !this.formGroup.controls.captionOutline.value?.length
    ) {
      this.snackBarService.openSnackBar({
        icon: 'warning',
        text: this.translateService.instant('POST_MANAGER.SNACKBAR.ERROR.AI_CAPTION.MISSING_FIELDS'),
        variant: 'error',
        autoHide: false,
      });

      return;
    }
    this.generating = true;

    if (!this.captionSettings) {
      this.getCaptionSettings()
        .pipe(first())
        .subscribe(() => {
          this.generateCaption();
        });

      return;
    }

    const type = this.formGroup.controls.managedPostType.value;
    const publishAt = this.formGroup.controls.schedule.value
      ? this.convertLocalTimeZoneToUTC(this.formGroup.controls.publishAt.value)
      : dayjs().add(1, 'minute').toISOString();
    this.captionGenerationService.captionTextSubject$.next('');
    this.showOutline = false;

    this.captionGenerationService.generateCaption({
      post: {
        callToAction: this.post?.post.callToAction!,
        caption:
          type !== ManagedPostType.Story
            ? {
                text: this.formGroup.controls.captionText.value,
              }
            : { text: null },
        type,
        resources: this.post?.post?.resources!,
      },
      storeId: this.selectedStore?._id!,
      providers: this.formGroup.get('providers')?.value!,
      publishAt,
      files: this.formGroup.get('media')?.value!,
      captionSettings: this.captionSettings,
      choices: 1,
      outline: this.formGroup.get('captionOutline')?.value,
      seedCaptions: 10,
    });
  }

  initPost(): void {
    const { postId } = this.activatedRoute.snapshot.params;
    if (!postId) {
      this.router.navigate([{ outlets: { [postManagerOutlet]: PostManagerRoute.Create } }], {
        queryParamsHandling: 'merge',
      });
    }
    this.managedPostDataService
      .getPost(this.selectedStore?._id!, postId!)
      .pipe(first())
      .subscribe((res) => {
        this.post = res.result;
        if (!this.post) {
          return;
        }
        this.formGroup.patchValue({
          captionText: this.post.post.caption?.text,
          publishAt: this.post.publishAt ? this.convertUTCToLocalTimeZone(this.post.publishAt) : '',
          schedule: true,
          providers: this.post.providers,
          managedPostType: this.post.post.type,
        });
        this.slides = this.post.post.resources.map((resource) => ({
          src: resource.media.url!,
          thumbnail: resource.media.thumbnail!,
          imageSrcVariant: 'asset', // TODO: Change to 'url' when imgix service issue is fixed
          variant: resource.type === 'VIDEO' ? 'VIDEO' : 'IMAGE',
        }));
        this.disabledProviders = this.getDisabledProviders();
        this.formGroup.markAsPristine();
      });
  }

  cancel(): void {
    if (!this.viewReady) {
      return;
    }
    this.viewReady = false;
    this.removeOutlet();
  }

  submit(): void {
    this.showOutline = false;

    if (this.generating) {
      this.snackBarService.openSnackBar({
        icon: 'warning',
        variant: 'error',
        text: this.translateService.instant('POST_MANAGER.SNACKBAR.ERROR.AI_CAPTION.IN_PROGRESS'),
      });

      return;
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAsDirty();
      this.formGroup.markAllAsTouched();
      this.snackBarService.openSnackBar({
        icon: 'error',
        variant: 'error',
        text: this.translateService.instant('POST_MANAGER.VALIDATION.FORM'),
      });

      return;
    }

    if (!this.viewReady) {
      return;
    }
    this.viewReady = false;
    this.actionInProgress = true;

    const updateType = this.formGroup.controls.schedule.value ? 'UPDATE' : 'PUBLISH';
    const publishAt = this.formGroup.controls.schedule.value
      ? this.convertLocalTimeZoneToUTC(this.formGroup.controls.publishAt.value)
      : dayjs().add(1, 'minute').toISOString();
    const type = this.formGroup.controls.managedPostType.value;
    this.managedPostDataService
      .updatePost(this.selectedStore?._id!, this.post?._id!, {
        post: {
          ...this.post?.post!,
          caption:
            type !== ManagedPostType.Story
              ? {
                  text: this.formGroup.controls.captionText.value,
                }
              : { text: null },
          type,
        },
        publishAt,
        providers: this.formGroup.controls.providers.value,
        action: Action.Updated,
        actionBy: CreatorType.User,
        actionByEmail: this.authUserService.authUser.contact.email,
      })
      .pipe(
        switchMap((res) => {
          if (this.formGroup.controls.schedule.value) {
            return of(res);
          }

          return this.managedPostDataService
            .publishPost(this.selectedStore?._id!, this.post?._id!)
            .pipe(catchError(() => of(res)));
        }),
        tap(() => {
          this.actionInProgress = false;
          setTimeout(() => {
            this.snackBarService.openSnackBar({
              icon: 'check_circle',
              text: this.translateService.instant(`POST_MANAGER.SNACKBAR.SUCCESS.${updateType}`),
              variant: 'default',
            });
            this.segmentService.track(TRACK_MANAGED_POST.edited, {
              postId: this.post?._id,
              storeId: this.selectedStore?._id,
            });
            this.removeOutlet({ postId: this.post?._id! });
          }, 500);
        }),
        catchError(() => {
          this.actionInProgress = false;
          this.viewReady = true;
          this.snackBarService.openSnackBar({
            icon: 'warning',
            text: this.translateService.instant('POST_MANAGER.SNACKBAR.ERROR.UPDATE'),
            variant: 'error',
          });

          return EMPTY;
        }),
      )
      .subscribe();
  }

  rejectPost(): void {
    this.router.navigate([{ outlets: { [postManagerOutlet]: `${PostManagerRoute.Reject}/${this.post?._id}` } }], {
      queryParamsHandling: 'merge',
    });
  }

  removeOutlet(data?: { postId: string }): void {
    this.router
      .navigate([{ outlets: { [postManagerOutlet]: null } }], {
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.dialogRef.close(data);
      });
  }

  onEmojiSelect(event: EmojiClickEvent): void {
    const captionTextControl = this.formGroup.get('captionText');
    const captionText: string = captionTextControl?.value ?? '';
    captionTextControl?.setValue(captionText.concat(event?.detail?.unicode ?? ''));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
