import { Component, Input } from '@angular/core';
import { SpinnerVariant } from '../../types/spinner.type';

@Component({
  selector: 'core-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  host: {
    '[class]': `"core-spinner"
    + " variant-"+variant`,
  },
})
export class SpinnerComponent {
  @Input()
  diameter = 60;

  @Input()
  fullHeight = false;

  @Input()
  variant: SpinnerVariant = 'emphasis';
}
