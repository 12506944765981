/**
 * CleanStringPipe
 *
 * Pipe to clean the input given by user via input
 * We can keep adding more filters if we like here.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CleanString',
})
export class CleanStringPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return value;
    }

    return value.replace(/ /g, '');
  }
}
