import { RouterModule, Routes } from '@angular/router';

import { PostPreviewComponent } from './screens/post-preview/post-preview.component';
import { PostInsightsComponent } from './screens/post-insights/post-insights.component';
import { postViewerOutlet } from './variables/outlet.variable';
import { PostViewerRoute } from './enums/post-viewer-route.enum';

const outlet = postViewerOutlet;

export const makePostViewerRoutes = () => [
  {
    path: `${PostViewerRoute.Preview}/:id`,
    outlet,
    component: PostPreviewComponent,
  },
  {
    path: `${PostViewerRoute.Insights}/:id`,
    outlet,
    component: PostInsightsComponent,
  },
];

const routes: Routes = [...makePostViewerRoutes()];

export const PostViewerRoutingModule = RouterModule.forChild(routes);
