<div cdk-overlay-origin
     class="core-select-trigger"
     (click)="open()"
     #fallbackOverlayOrigin="cdkOverlayOrigin"
     #trigger>

  <div class="core-select-value" [attr.id]="_valueId">
    @if (iconElement && !customTrigger && !empty) {
      <div class="core-select-icon-selected" [innerHTML]="iconElement"></div>
    }
    @if (empty) {
      <span class="core-select-placeholder core-select-min-line">{{ placeholder }}</span>
    } @else {
      <span class="core-select-value-text">
        @if (customTrigger) {
          <ng-content select="[coreSelectTrigger]"></ng-content>
        } @else {
          <span class="core-select-min-line">
            @if (allSelected && multiple) {
              {{ 'FILTER.SELECTED.ALL' | translate }} &mdash;
            }
            {{ triggerValue }}
          </span>
        }
      </span>
    }
  </div>

  <div
    class="core-select-trigger-arrow">
    <core-icon [@selectTriggerRotate]="panelOpen ? 'up' : 'down'" size="sm" icon="chevron_down"></core-icon>
  </div>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayPanelClass]="_overlayPanelClass"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOrigin]="preferredOverlayOrigin || fallbackOverlayOrigin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayWidth]="_overlayWidth"
  (backdropClick)="close()"
  (attach)="onAttached()"
  (detach)="close()">
  <div
    #panel
    role="listbox"
    tabindex="-1"
    class="core-select-panel core-menu-surface core-menu-surface--open"
    [attr.id]="id + '-panel'"
    [attr.aria-multiselectable]="multiple"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="getPanelAriaLabelledby()"
    [ngClass]="panelClass"
    (keydown)="handleKeydown($event)">
    @if (multiple) {
      <div class="core-option core-list-item" [class.core-list-item--selected]="allSelected" (click)="toggleSelectAll()">
        <span class="core-list-item__label">{{ selectAllLabel | translate }}</span>
        <core-icon
          size="sm"
          aria-hidden="true"
          [style.fill]="selected?.length ? 'rgb(var(--success))' : 'rgb(var(--secondary2))'"
          [icon]="selected?.length ? allSelected ? 'check_circle' : 'remove_circle' : 'circle'">
        </core-icon>
      </div>
      <core-divider></core-divider>
    }
    <ng-content></ng-content>
  </div>
</ng-template>