<core-card class="snack-bar-card" padding="none" roundness="sm" [variant]="cardVariant">
  @if (updating) {
    @if (variant === 'default') {
      <core-linear-progress-bar class="progress-bar" mode="indeterminate" variant="custom" [customColor]="'rgb(var(--surface1))'" [unfilledBarColor]="'rgba(var(--surface1), 0.6)'"></core-linear-progress-bar>
    } @else {
      <core-linear-progress-bar class="progress-bar" mode="indeterminate" variant="light" [unfilledBarColor]="'rgba(var(--white), 0.6)'"></core-linear-progress-bar>
    }
  }
  <core-card
    class="snack-bar-card__inner"
    roundness="none"
    padding="md"
    [variant]="cardVariant"
    [shadow]="false">
    <div class="card-content">
      <div class="icon-container" *ngIf="icon">
        <core-icon [icon]="icon" size="md"></core-icon>
      </div>
      <div class="text-container">
        <div class="title">
          {{ title }}
        </div>
        <div class="text">
          <span [innerHTML]="text"></span>
          <ng-content></ng-content>
        </div>
      </div>
      <div class="actions-container">
        <div class="cta-inline" *ngIf="ctaLabel">
          <ng-container [ngTemplateOutlet]="cta"></ng-container>
        </div>
        <a *ngIf="link" class="snack-bar-link" coreButton variant="text" size="sm" [href]="link">
          <span class="snack-bar-link__text">{{ 'ALERT.LINK' | translate }}</span>
        </a>
        <button
          coreIconButton
          *ngIf="dismissable"
          class="dismiss"
          icon="close"
          [variant]="dismissIconVariant"
          size="sm"
          (click)="onDismiss()"></button>
      </div>
    </div>
    <div class="cta-full-width" *ngIf="ctaLabel">
      <ng-container [ngTemplateOutlet]="cta"></ng-container>
    </div>
  </core-card>
</core-card>

<ng-template #cta>
  <button
    coreButton
    class="cta"
    [variant]="variant === 'error' ? 'outline-light' : 'inverted'"
    (click)="onCtaClick()">
    {{ ctaLabel }}
  </button>
</ng-template>
