/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { assetsBasePath } from '@core/variables/asset-paths.variable';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  public listOfIcons = {
    // New brand icons
    icon: [
      'add_circle',
      'add',
      'alarm',
      'albums',
      'all_posts',
      'alphin_loading',
      'alphin',
      'aspect_ratio_lock',
      'aspect_ratio_unlock',
      'back',
      'ban',
      'bank',
      'bell',
      'calendar_add',
      'calendar',
      'camera',
      'cards',
      'check_circle_fill',
      'check_circle',
      'check',
      'chevron_down',
      'chevron_left',
      'chevron_right',
      'chevron_up',
      'circle',
      'click',
      'clock',
      'close',
      'comment',
      'company',
      'content',
      'cost',
      'crop',
      'danger',
      'dark_mode',
      'decrease',
      'delete',
      'download',
      'down',
      'edit',
      'email',
      'engagement',
      'error_fill',
      'error',
      'filter',
      'forward',
      'grid',
      'group',
      'hashtag',
      'idea',
      'magic_wand',
      'move_left',
      'move_right',
      'image',
      'increase',
      'info',
      'interaction',
      'kpi_down',
      'kpi_up',
      'landscape',
      'light_mode',
      'like',
      'link',
      'loading',
      'location',
      'lock',
      'lock_open',
      'menu',
      'negative',
      'nfc',
      'open',
      'options_vertical',
      'options',
      'phone',
      'pin',
      'play',
      'portrait',
      'positive',
      'posts',
      'preferences',
      'preview',
      'profile',
      'reel',
      'refresh',
      'reject',
      'remove_circle',
      'reply',
      'request_card',
      'review_cards',
      'review_cash_register',
      'review_item',
      'review_table',
      'review_thank_you',
      'rotate',
      'satisfied',
      'save',
      'search',
      'sepa',
      'settings',
      'share',
      'social_wall',
      'sort',
      'square',
      'star',
      'store',
      'story',
      'support',
      'theme',
      'timer',
      'trash',
      'trash_check',
      'trending_up',
      'unsatisfied',
      'up',
      'video',
      'video_camera',
      'video_fill',
      'visibility_off',
      'visibility_on',
      'waiting',
      'warning',
      'zoom_in',
      'zoom_out',
    ],
    badge: ['badge_1', 'badge_2', 'badge_3', 'badge_4', 'badge_5', 'medal_1', 'medal_2', 'medal_3'],
    // Illustrations with color.
    // Please prefix with `img_`
    illustration: [
      'img_ad',
      'img_brainstorm',
      'img_card_assign',
      'img_card_scan',
      'img_celebrate',
      'img_connect_provider',
      'img_gallery',
      'img_influencer',
      'img_like_content',
      'img_maintenance',
      'img_metrics',
      'img_nfc_cards',
      'img_photo',
      'img_review',
      'img_rocket',
      'img_social',
      'img_store',
    ],
    misc: ['brand_logo', 'bullet', 'follower_pin', 'store_pin_emphasis', 'store_pin'],
    // Navigation icons
    navigation: [
      'ad',
      'ad_fill',
      'billing',
      'billing_fill',
      'dashboard_fill',
      'dashboard',
      'follower_fill',
      'follower',
      'google_ad_fill',
      'google_ad',
      'influencer_fill',
      'influencer',
      'integration_fill',
      'integration',
      'logout',
      'marketplace_fill',
      'marketplace',
      'meta_ad_fill',
      'meta_ad',
      'nav_company_fill',
      'nav_company',
      'nav_store_fill',
      'nav_store',
      'nav_user_fill',
      'nav_user',
      'photo_fill',
      'photo',
      'portal_fill',
      'portal',
      'review_fill',
      'review',
      'social_fill',
      'social',
      'user_help',
      'user_settings_fill',
      'user_settings',
    ],
    // Outlined larger illustrations without color.
    // The css `fill` property changes the outline color.
    // Please prefix with `outline_`
    outline: [
      'outline_ad_campaign',
      'outline_announcement',
      'outline_business_growth',
      'outline_camera',
      'outline_check_mark',
      'outline_chef',
      'outline_coffee',
      'outline_connect',
      'outline_cooking_pot',
      'outline_email',
      'outline_image',
      'outline_inbox',
      'outline_info',
      'outline_maintenance',
      'outline_media_broken',
      'outline_nfc_cards',
      'outline_ratings',
      'outline_refresh',
      'outline_revenue',
      'outline_rocket_launch',
      'outline_rocket',
      'outline_search_results',
      'outline_setup_facebook',
      'outline_setup_instagram',
      'outline_setup_google',
      'outline_thumbnail_broken',
      'outline_upload',
      'outline_trash',
      'outline_warn',
    ],
    permissions: [
      'google_permissions_error_dark_de',
      'google_permissions_error_dark_en',
      'google_permissions_error_light_de',
      'google_permissions_error_light_en',
    ],
    // Progress svgs
    progress: [
      'progress_start',
      'progress_processing',
      'progress_complete',
      'progress_processing_short',
      'progress_complete_short',
    ],
    // Platform svgs
    platform: [
      'badge_facebook',
      'badge_instagram',
      'active_facebook',
      'active_gloriafood',
      'active_google',
      'active_instagram',
      'active_resmio',
      'active_simplydelivery',
      'active_trip_advisor',
      'active_yelp_api',
      'connected_facebook',
      'connected_gloriafood',
      'connected_google',
      'connected_instagram',
      'connected_resmio',
      'connected_simplydelivery',
      'connected_trip_advisor',
      'connected_yelp_api',
      'disconnected_facebook',
      'disconnected_gloriafood',
      'disconnected_google',
      'disconnected_instagram',
      'disconnected_resmio',
      'disconnected_simplydelivery',
      'disconnected_trip_advisor',
      'disconnected_yelp_api',
      'google',
      'inactive_facebook',
      'inactive_gloriafood',
      'inactive_google',
      'inactive_instagram',
      'inactive_resmio',
      'inactive_simplydelivery',
      'inactive_trip_advisor',
      'inactive_yelp_api',
    ],
    cards: ['icon_nfc_card_assigned', 'icon_nfc_card_unassigned'],
  };

  iconsUpdated$ = new Subject<void>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.init();
  }

  init(): void {
    Object.entries(this.listOfIcons).forEach(([key, group]) => {
      for (const icon of group) {
        this.matIconRegistry.addSvgIcon(
          icon,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`${assetsBasePath}/svg/${key}/${icon}.svg`),
        );
      }
    });
  }

  setBrandIcons(brand: string, svgMap: Record<string, string>): void {
    Object.entries(svgMap).forEach(([key, value]) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/core/brands/${brand}/assets/${value}.svg`),
      );
    });
    this.iconsUpdated$.next();
  }
}
