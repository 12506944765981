/* eslint-disable no-restricted-syntax */
import { catchError, first, map, concatMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import _merge from 'lodash/merge';
import { SupportedLanguage } from '@core/enums/user-preference.enum';

export interface ITranslationResource {
  path: string; // path to get translations from local folder
  ext: string; // file extension
}

export class CustomTranslateLoader implements TranslateLoader {
  private initialized: Record<string, boolean> = {
    [SupportedLanguage.en]: false,
    [SupportedLanguage.de]: false,
  };

  private _translations: Record<string, BehaviorSubject<Record<string, any>>> = {
    [SupportedLanguage.en]: new BehaviorSubject<any>(undefined),
    [SupportedLanguage.de]: new BehaviorSubject<any>(undefined),
  };

  translations: Record<string, Observable<Record<string, any>>> = {
    [SupportedLanguage.en]: this._translations[SupportedLanguage.en].asObservable(),
    [SupportedLanguage.de]: this._translations[SupportedLanguage.de].asObservable(),
  };

  constructor(
    private httpClient: HttpClient,
    private resources: ITranslationResource[] = [],
    private baseUrl: string = '',
  ) {}

  /**
   * Extends getTranslation from TranslateLoader Class, to request to Lokalise the translation file
   * Resources receive an array of objects with information to request translations by microfrontend
   * Returns formatted JSON object with translations key: value
   */
  getTranslation(lang: string): Observable<any> {
    if (!this.initialized[lang]) {
      this.initialized[lang] = true;

      return this.initFromGoogleStorage(lang).pipe(
        catchError(() =>
          this.initLocalFallback(lang).pipe(
            tap((localTranslations) => {
              this._translations[lang].next(localTranslations);
            }),
          ),
        ),
        concatMap((cdnTranslations) => {
          if (!Object.keys(cdnTranslations)?.length) {
            return this.initLocalFallback(lang);
          }

          return of(cdnTranslations);
        }),
        tap((translations) => {
          const localTranslations = this._translations[lang].getValue();
          const mergedTranslations = _merge(localTranslations, translations);
          this._translations[lang].next(mergedTranslations);
        }),
      );
    }

    return this.translations[lang];
  }

  /**
   * Initializes the translations from Google Storage for the given language
   * @param lang the language to get
   */
  private initFromGoogleStorage(lang: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}${lang}.json`).pipe(
      map((response: any) => {
        let translations: any = {};
        [response].forEach((groupTranslations: any) => {
          translations = {
            ...translations,
            ...groupTranslations,
          };
        });

        return translations;
      }),
      catchError(() => of({})),
      first(),
    );
  }

  /**
   * Initializes the translations from local i18n files for the given language
   * @param lang the language to get
   */
  private initLocalFallback(lang: string): Observable<any> {
    return forkJoin(
      this.resources.map((config) =>
        this.httpClient.get(`${config.path}${lang}${config.ext}`).pipe(catchError(() => of({}))),
      ),
    ).pipe(
      map((responses: any[]) => {
        let translations: any = {};
        responses.forEach((groupTranslations) => {
          translations = {
            ...translations,
            ...groupTranslations,
          };
        });

        return translations;
      }),
      catchError(() => of({})),
      first(),
    );
  }
}
