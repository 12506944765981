import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { AvatarModule } from '../avatar/avatar.module';
import { CardModule } from '../card/card.module';
import { DividerModule } from '../divider/divider.module';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, SvgModule, ButtonModule, AvatarModule, CardModule, TranslateModule, DividerModule],
  exports: [DialogComponent],
})
export class DialogModule {}
