<div class="account d-flex flex-column flex-fill">
  <div class="row mx-0">
    <div *ngIf="visibleColumns.name" class="p-2 d-flex align-items-center flex-wrap" [ngClass]="nameColumnClass">
      <ng-container
        *ngTemplateOutlet="nameTemplate; context: { account: account }">
      </ng-container>
    </div>

    <div *ngIf="detailsVisible"
      class="px-1 pb-2 d-flex align-items-center"
      [ngClass]="nameColumnClass"
      [class.justify-content-between]="visibleColumns.location"
      [class.justify-content-end]="!visibleColumns.location">
      <div *ngIf="visibleColumns.location" class="d-flex align-items-center flex-grow-1 px-1">
        <ng-container
          *ngTemplateOutlet="locationTemplate; context: { account: account }">
        </ng-container>
      </div>
      <div *ngIf="visibleColumns.rating || visibleColumns.followers" class="account__column px-1">
        <div *ngIf="visibleColumns.rating" class="d-flex align-items-center" [class.mb-1]="visibleColumns.followers">
          <ng-container
            *ngTemplateOutlet="ratingTemplate; context: { account: account }">
          </ng-container>
        </div>

        <div *ngIf="visibleColumns.followers" class="d-flex align-items-center">
          <ng-container
            *ngTemplateOutlet="followersTemplate; context: { account: account }">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #nameTemplate let-account="account">
  <div class="d-flex align-items-center flex-fill" [style.max-width.%]="100">
    <core-avatar [src]="account.profilePicUrl" size="sm" variant="store" class="mr-3" badgePosition="bottom-left">
      <core-platform-avatar size="xxs" class="m-1 account__platform-badge" [url]="icons[account?.provider]"></core-platform-avatar>
    </core-avatar>
    <div class="account__column flex-fill">
      <div class="account__name">
        {{ account?.name }}
      </div>
      <div class="account__username" *ngIf="account?.username">{{ account?.username }}</div>
    </div>
  </div>
  <div class="ml-2 account__column flex-fill align-items-end">
    <div *ngIf="bestFit" class="d-flex">
      <core-tag-small variant="emphasis">
        {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.BEST_FIT' | translate }}
      </core-tag-small>
    </div>
    <core-account-state-reason *ngIf="!account?.state?.selectable" [account]="account" class="mb-1"></core-account-state-reason>
    <a class="account__label truncate-text d-flex align-items-center justify-content-end" *ngIf="account?.url" [href]="account?.url" target="_blank">
      <div class="truncate-text mr-1">{{ account?.providerId }}</div>
      <core-icon size="xxs" icon="open"></core-icon>
    </a>
  </div>
</ng-template>

<ng-template #ratingTemplate let-account="account">
  <div class="account__column align-items-end flex-fill">
    <ng-container *ngIf="account?.ratings?.total; else noRatings">
      <core-star-rating [rating]="account?.ratings?.average" size="sm" [maxStars]="1"></core-star-rating>
    </ng-container>
    <ng-template #noRatings>
      <div class="d-flex align-items-center">
        <div class="account__label">{{ 'INTEGRATION_DIALOG.NOT_AVAILABLE.GENERIC' | translate }}</div>
        <core-star-rating [rating]="0" size="sm" [showNumber]="false" [maxStars]="1" class="ml-2"></core-star-rating>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #followersTemplate let-account="account">
  <div class="account__column align-items-end flex-fill">
    <ng-container *ngIf="account?.socialMetrics?.followers; else noFollowers">
      <div class="account__kpi d-flex align-items-center">
        {{ account?.socialMetrics?.followers }}
        <core-icon size="xs" icon="group" class="ml-1" [style.fill]="'rgb(var(--primary1))'"></core-icon>
      </div>
    </ng-container>
    <ng-template #noFollowers>
      <div class="d-flex align-items-center">
        <div class="account__label">{{ 'INTEGRATION_DIALOG.NOT_AVAILABLE.GENERIC' | translate }}</div>
        <div [style.margin-bottom.px]="2" class="ml-2">
          <core-icon size="xs" icon="group" [style.fill]="'rgb(var(--secondary2))'"></core-icon>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #locationTemplate let-account="account">
  <div class="d-flex align-items-center justify-content-center" class="mr-3">
    <core-svg [style.fill]="account?.location ? 'rgb(var(--secondary3))' : 'rgb(var(--secondary2))'" svg="store_pin" [style.width.px]="24" class="mx-1"></core-svg>
  </div>
  <div class="account__column" *ngIf="account?.location; else noLocation">
    <div class="account__location">
      <ng-container *ngIf="account?.location?.street">{{ account?.location?.street }}</ng-container>
    </div>
    <div class="account__location">
      <ng-container *ngIf="account?.location?.zip">{{ account?.location?.zip }}&nbsp;</ng-container>
      <ng-container *ngIf="account?.location?.city">{{ account?.location?.city }}</ng-container>
    </div>
  </div>
  <ng-template #noLocation>
    <div class="account__label">{{ 'INTEGRATION_DIALOG.NOT_AVAILABLE.GENERIC' | translate }}</div>
  </ng-template>
</ng-template>
