import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from '@core/ui/button/button.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { AvatarModule } from '@core/ui/avatar/avatar.module';
import { ToggleModule } from '@core/ui/toggle/toggle.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { TagModule } from '@core/ui/tag/tag.module';
import { KpiModule } from '@core/ui/kpi/kpi.module';
import { RadioModule } from '@core/ui/radio/radio.module';
import { PlatformModule } from '@core/ui/platform/platform.module';
import { StarRatingModule } from '@core/ui/star-rating/star-rating.module';
import { ProviderAccountListComponent } from './provider-account-list.component';
import { ProviderAccountDesktopItemComponent } from './provider-account-desktop-item/provider-account-desktop-item.component';
import { ProviderAccountMobileItemComponent } from './provider-account-mobile-item/provider-account-mobile-item.component';
import { AccountStateReasonComponent } from './account-state-reason/account-state-reason.component';

const components = [
  ProviderAccountListComponent,
  ProviderAccountDesktopItemComponent,
  ProviderAccountMobileItemComponent,
  AccountStateReasonComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    SvgModule,
    ToggleModule,
    MatTooltipModule,
    TagModule,
    CoreModule,
    AvatarModule,
    RadioModule,
    StarRatingModule,
    PlatformModule,
    KpiModule,
  ],
  exports: components,
})
export class ProviderAccountListModule {}
