import { ImageService } from '@core/services/image.service';
import { ImageAssetRegistryService } from '@core/services/image-asset-registry.service';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AvatarBadgePosition, AvatarSize, AvatarVariant } from '../../types/avatar.type';
import { avatarSizes } from '../../variables/avatar.variable';

@Component({
  selector: 'core-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  host: {
    '[class]': `"avatar"
    + " size-"+size
    + " variant-"+variant`,
    '[class.avatar--loading]': 'loading',
    '[class.avatar--shadow]': 'shadow',
    '[class.avatar--outlined]': 'outlined && !error',
    '[style.background-image]': '"url(/" + imageAssetRegistryService.avatars?.[variant] + ")"',
  },
})
export class AvatarComponent {
  @Input()
  src = '';

  @Input()
  variant: AvatarVariant = 'user';

  @Input()
  size: AvatarSize = 'md';

  @Input()
  outlined = false;

  @Input()
  shadow = false;

  @Input()
  badgePosition: AvatarBadgePosition = 'bottom-left';

  @Output()
  avatarError = new EventEmitter();

  url?: string;

  loading = true;

  error = false;

  get fallback() {
    return this.imageAssetRegistryService.avatars?.[this.variant];
  }

  constructor(
    private imageService: ImageService,
    public imageAssetRegistryService: ImageAssetRegistryService,
  ) {
    this.imageAssetRegistryService.imagesUpdated$.subscribe(() => {
      if (this.error) {
        this.url = this.imageAssetRegistryService.avatars?.[this.variant];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src) {
      this.error = false;
      if (this.src) {
        const size = avatarSizes[this.size] * 2;
        if (!this.src.startsWith('http')) {
          this.url = this.src;

          return;
        }
        this.url = this.imageService.getURL(this.src, { w: size, h: size, fit: 'crop' });
      }
    }
  }

  /**
   * Sets the fallback avatar if an error occurred
   */
  setFallback(): void {
    this.error = true;
    this.url = this.imageAssetRegistryService.avatars?.[this.variant];
  }
}
