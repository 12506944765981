import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SvgModule } from '@core/ui/svg/svg.module';
import { DialogService } from '@core/services/dialog.service';
import { PostManagerModule } from '@core/dialogs/post-manager/post-manager.module';
import { PhotoMediaCardModule } from '@photo/common/components/photo-media-card/photo-media-card.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { PhotoWorkflowRoutingModule } from './photo-workflow-routing.module';
import { PhotoWorkflowComponent } from './photo-workflow.component';
import { PhotoDialogComponent } from './screens/photo-dialog/photo-dialog.component';

@NgModule({
  declarations: [PhotoWorkflowComponent, PhotoDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule,
    RouterModule,
    SvgModule,
    TranslateModule,
    PhotoMediaCardModule,
    PostManagerModule,
    ButtonModule,
    PhotoWorkflowRoutingModule,
  ],
  providers: [DialogService],
  exports: [PhotoWorkflowComponent],
})
export class PhotoWorkflowModule {}
