import { IconRegistryService } from '@core/services/icon-registry.service';
import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, map } from 'rxjs';
import { IconSize } from '../../types/icon.type';

@Component({
  selector: 'core-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input()
  icon = '';

  @Input()
  material = false;

  @Input()
  size: IconSize = 'md';

  destroyRef = inject(DestroyRef);

  constructor(private iconRegistryService: IconRegistryService) {
    this.iconRegistryService.iconsUpdated$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => {
          const { icon } = this;
          this.icon = null!;

          return icon;
        }),
        debounceTime(0),
      )
      .subscribe((svg) => {
        this.icon = svg;
      });
  }
}
