import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@core/ui/button/button.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HorizontalListComponent } from './components/horizontal-list/horizontal-list.component';
import { ScrollContainerComponent } from './components/scroll-container/scroll-container.component';
import { HoldPressDirective } from './directives/hold-press.directive';

@NgModule({
  declarations: [HorizontalListComponent, ScrollContainerComponent, HoldPressDirective],
  imports: [CommonModule, ButtonModule, InfiniteScrollModule],
  exports: [HorizontalListComponent, ScrollContainerComponent],
})
export class ScrollModule {}
