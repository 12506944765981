<ng-content select="[coreOverlayProtectedContent]"></ng-content>
<div
  class="overlay-card"
  [class.overlay-card--hidden]="!visible">
  <core-card
    [selectable]="selectable"
    [shadow]="false"
    [padding]="padding"
    [roundness]="roundness"
    [variant]="variant"
    [level]="level"
    [overlay]="true"
    [overlayStrength]="overlayStrength">
    <ng-content></ng-content>
  </core-card>
</div>
