import { isPlatformBrowser } from '@angular/common';
import { Injectable } from '@angular/core';

declare global {
  interface Window {
    analytics: any;
    Intercom: any;
    ReactNativeWebView: any;
  }
}

/**
 * Window Wrapper for Angular AOT
 */
@Injectable()
export class WindowWrapperService {
  /** Segment Analytics.js instance */
  public analytics: any;

  public Intercom: any;

  public ReactNativeWebView: any;
}

/**
 * Window Provider for Angular AOT
 * @returns Browser Window instance
 */
export function getWindow(platformId: any) {
  return isPlatformBrowser(platformId) ? window : {};
}
