<div class="d-flex flex-row space-between align-items-center mb-3">
  <div class="d-inline flex-grow-1 reply__header align-items-center">
    <div class="reply__header__author">{{ author }}</div>
    <span class="reply__header__update-time">{{ date | humanizeDate }}</span>
  </div>
  <div *ngIf="actionsList.length" class="flex-none d-flex flex-row align-items-end">
    <button
      coreIconButton
      [matMenuTriggerFor]="menu"
      aria-label="Comment options"
      icon="options_vertical">
    </button>
    <mat-menu
      #menu="matMenu"
      xPosition="before"
      yPosition="below"
      class="core-menu-panel">
      <button
        *ngFor="let action of actionsList"
        mat-menu-item
        [disabled]="actions[action]?.disabled"
        (click)="actionClick.emit(action)">
        <span>{{ actions[action]?.label | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="reply__comment" [innerHTML]="text"></div>
