import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, tap } from 'rxjs/operators';
import { Post } from '@core/dialogs/post-manager/models/post.model';
import { InfluencerPost } from '@influencer/common/models/influencer-post.model';
import { PostRating } from '@core/modules/post/types/post-rating.type';
import { influencerOutlet } from '../../variables/outlet.variable';
import { InfluencerPostDialogConfig } from '../../types/influencer-post-dialog.type';

@Component({
  selector: 'influencer-post-dialog',
  templateUrl: './influencer-post-dialog.component.html',
  styleUrls: ['./influencer-post-dialog.component.scss'],
})
export class InfluencerPostDialogComponent {
  ctaLoading = false;

  feedbackLoading = false;

  constructor(
    private dialogRef: MatDialogRef<InfluencerPostDialogConfig>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: InfluencerPostDialogConfig,
  ) {}

  onKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowRight':
        return this.data.onIndexChange(this.data.index + 1);
      case 'ArrowLeft':
        return this.data.onIndexChange(this.data.index - 1);
      default:
    }
  }

  onFeedbackChange(event: { feedback: { action: PostRating; value: number }; post: Post<InfluencerPost> }): void {
    this.feedbackLoading = true;
    this.data
      .onFeedbackChange(event)
      .pipe(
        tap(() => {
          this.feedbackLoading = false;
        }),
        first(),
      )
      .subscribe();
  }

  onCtaClick(event: { action: string; post: Post<InfluencerPost>; slideIndex: number }): void {
    this.ctaLoading = true;
    this.data
      .onCtaClick(event)
      .pipe(
        tap(() => {
          this.ctaLoading = false;
        }),
        first(),
      )
      .subscribe();
  }

  removeOutlet(): void {
    this.dialogRef.close();
    this.router.navigate([{ outlets: { [influencerOutlet]: null } }]);
  }
}
