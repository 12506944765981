<div
  *ngIf="username"
  class="owner"
  [class.owner--clickable]="link" 
  (click)="openLink()">
  <core-avatar
    [src]="avatar"
    size="sm">
  </core-avatar>
  <div class="owner__info">
    <div class="owner__username px-2">{{ username }}</div>
    <div
      *ngIf="followers"
      class="owner__followers px-2">
      {{ 'POST.USER.FOLLOWERS' | pluralFormat: followers | translate: { followedBy: followers | numberSuffixes : 2 } }}
    </div>
  </div>
</div>
