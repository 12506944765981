<core-card *ngIf="src; else content" padding="none" roundness="md" [src]="src">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</core-card>

<ng-template #content>
  <core-card
    [style.background-color]="variant === 'custom' ? ((customColorConfig?.background) + (src ? 'CC' : 'FF')) : null"
    [style.max-width.%]="100"
    [selectable]="selectable"
    padding="md"
    roundness="md"
    [shadow]="!src || variant === 'custom'"
    [overlay]="!!src"
    overlayStrength="high"
    [variant]="src && variant !== 'custom' ? 'primary' : variant">
    <div class="kpi-icons">
      <div *ngIf="icon" class="icon-container">
        <div class="icon-container__fill"></div>
        <core-icon size="sm" [icon]="icon"></core-icon>
      </div>
      <ng-content select="[coreKpiIcons]"></ng-content>
      <core-icon
        *ngIf="tooltip"
        class="tooltip"
        [matTooltip]="tooltip"
        #matTooltip="matTooltip"
        (touchstart)="matTooltip.toggle()"
        size="md"
        icon="info">
      </core-icon>
    </div>
    <ng-container *ngIf="!isEmpty; else emptyState">
      <div class="kpi-value">
        <ng-content></ng-content>
      </div>
      <div class="kpi-value">
        <ng-content select="[coreKpiAdditionalValue]"></ng-content>
      </div>
    </ng-container>
    <div class="kpi-label">{{ label }}</div>
    <div class="kpi-sublabel" *ngIf="sublabel">{{ sublabel }}</div>
    <ng-content select="[coreKpiFooterContent]"></ng-content>
  </core-card>
</ng-template>

<ng-template #emptyState>
  <div class="kpi-empty">{{ emptyStateText || ('EMPTY.KPI_DATA' | translate) }}</div>
</ng-template>
