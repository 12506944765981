/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { Nullable } from '@core/types/nullable.type';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { ManagedPostType } from '../enums/managed-post-type.enum';
import { ResourceType } from '../enums/resource-type.enum';
import { CallToActionType } from '../enums/call-to-action-type.enum';
import { ManagedPostProvider } from '../enums/managed-post-provider.enum';
import { ProviderStatusStatus } from '../enums/provider-status-status.enum';
import { CreatorType } from '../enums/creator-type.enum';
import { ManagedPostStatus } from '../enums/managed-post-status.enum';
import { Post } from './post.model';

export interface Interactions {
  likes: Nullable<number>;
  comments: Nullable<number>;
  views: Nullable<number>;
  clicks: Nullable<number>;
}

export interface Permissions {
  canComment: boolean;
}

export interface Resource {
  mediaId: Nullable<string>;
  media: {
    url: string;
    thumbnail: Nullable<string>;
  };
  meta: {
    size: Nullable<number>;
    width: Nullable<number>;
    height: Nullable<number>;
    duration: Nullable<number>;
  };
  type: ResourceType;
  url: Nullable<string>;
  mediaCreatedAt: Nullable<string>;
}

export interface CallToAction {
  type: CallToActionType;
  url: Nullable<string>;
  text: Nullable<string>;
}

export interface ManagedPostInput {
  callToAction: Nullable<number>;
  resources: Resource[];
  type: ManagedPostType;
  caption: {
    text: Nullable<string>;
  };
  _id?: string;
  provider?: ManagedPostProvider;
}

export interface ProviderStatus {
  provider: ManagedPostProvider;
  providerPostId: string;
  postedAt: string;
  statusMessage: Nullable<string>;
  status: ProviderStatusStatus;
}

export class ManagedPost {
  readonly _id!: string;

  publishAt!: string;

  providers!: ManagedPostProvider[];

  statusMessage!: Nullable<string>;

  taskId!: Nullable<string>;

  post!: ManagedPostInput;

  storeId!: string;

  createdBy!: CreatorType;

  createdByEmail!: string;

  status!: ManagedPostStatus;

  providerStatus!: ProviderStatus[];

  createdAt!: string;

  updatedAt!: string;

  constructor(object: ManagedPost) {
    Object.assign(this, object);
  }

  static toUIPost(object: ManagedPost): Post<ManagedPost> {
    const slides: MediaConfig[] = (object.post?.resources || []).map((resource: Resource) => ({
      src: resource.media.url,
      variant: resource.type === ResourceType.Video ? 'VIDEO' : 'IMAGE',
      thumbnail: resource.media.thumbnail,
      duration: resource.meta.duration,
    }));

    return new Post({
      id: `managed-${object._id}`,
      date: object.publishAt!,
      caption: object.post?.caption.text,
      mediaVariant: slides.length > 1 ? 'CAROUSEL' : slides[0]?.variant || null,
      slides,
      status: object.status!,
      type: object.post?.type!,
      raw: object,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class ManagedPostAdapter implements Adapter<ManagedPost> {
  clientAdapt(object: any): ManagedPost {
    return new ManagedPost(object);
  }

  serverAdapt(object: ManagedPost): any {
    return object;
  }
}
