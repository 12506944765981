<core-dialog
  [style.height]="'unset'"
  [style.width.px]="916"
  [actionInProgress]="actionInProgress"
  [unsavedChanges]="false"
  [canGoBack]="false"
  padding="none"
  [headerConfig]="{
    title: selectedStore?.name,
    avatarSrc: selectedStore?.images?.[0]?.file,
    padding: 'lg'
  }"
  [footerConfig]="{
    submitLabel: 'ACTION.CONFIRM' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate,
    padding: 'lg'
  }"
  (dismiss)="onCancel()"
  (submit)="onSubmit()">
  <div class="d-flex flex-column">
    <section class="pt-3">
      <h5 class="mb-1">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.TITLE' | translate }}</h5>
      <p class="mb-2">
        {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.HEADING.' + data.provider | translate }}
      </p>
      <div *ngIf="showErrorMessage" class="error-message d-flex align-items-center mb-2">
        <core-icon icon="error" class="mr-2" size="xs"></core-icon>
        <span>{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.SELECT_STORE_ERROR' | translate }}</span>
      </div>
    </section>
    <core-toggle
      *ngIf="selectableAccounts.length"
      (change)="showSelectableLocation($event)" [value]="showSelectable" labelPosition="before" [labelPadding]="{ y: 'md', x: 'lg' }" variant="success">
      <span class="toggle-label">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.SELECTABLE_TOGGLE' | translate }}</span>
    </core-toggle>
    <div class="d-flex flex-column" *ngIf="!loading; else spinnerTemplate">
      <core-provider-account-list
        [accounts]="selectedAccounts"
        [preselectedId]="selectableAccounts?.[0].providerId"
        (accountChange)="locationChangeHandler($event)">
      </core-provider-account-list>
      <section *ngIf="selectableAccounts.length === 0" class="py-3">
        <core-alert
          variant="danger"
          fontSize="sm"
          [title]="'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.NO_LISTINGS.TITLE' | translate">
          {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.NO_LISTINGS.DESCRIPTION' | translate : { provider: data.provider | titlecase } }}
        </core-alert>
      </section>
    </div>

    <ng-template #spinnerTemplate>
      <div class="d-flex flex-column align-items-center justify-content-center p-4">
        <core-spinner></core-spinner>
      </div>
    </ng-template>
  </div>

  <div coreDialogFooter class="pb-3 pb-md-0">
    <h6 class="mb-0">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.NEED_HELP' | translate }}</h6>
    <p class="change-account-link mb-0">
      <span>
        <a (click)="onChangeAccount()">
          {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.CHANGE_ACCOUNT.' + data.provider | translate }}
        </a>
        <span *ngIf="data.integration?.provider==='GOOGLE'">{{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.OR' | translate }}</span>
        <a *ngIf="data.integration?.provider==='GOOGLE'" (click)="verifyAccount()">
          {{ 'INTEGRATION_DIALOG.CONNECT_DIALOG_OPTIONS.VERIFY_ACCOUNT' | translate }}
        </a>
      </span>
    </p>
  </div>

</core-dialog>
