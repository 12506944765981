import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import _map from 'lodash/map';
import { PaginatedResult } from '@core/interfaces/pagination.interface';
import { GetListConfig } from '@core/interfaces/crud-service.interface';
import { Adapter } from '@core/interfaces/adapter.interface';
import { ApiService } from './api.service';

/**
 * Generic service to handle CRUD operations for an entity
 */
export abstract class CrudService<T> {
  constructor(
    protected apiService: ApiService,
    protected adapter: Adapter<T>,

    protected url: string,
  ) {}

  /**
   * Sends a request to get a list of objects
   * @param getListConfig the configuration to send
   * @param lean return the result with no pagination
   */
  public getList(getListConfig?: GetListConfig): Observable<PaginatedResult<T>> {
    return this.apiService.get(`${this.url}/`, this.buildQueryParams(getListConfig)).pipe(
      map((res) => {
        const resultObject: PaginatedResult<T> = {
          data: _map(res.result, (object: any) => this.adapter.clientAdapt(object)),
        };

        if (res.pagination) {
          resultObject.pagination = res.pagination;
        }

        return resultObject;
      }),
    );
  }

  /**
   * Sends a request to get an object using the id
   * @param id the id of the object
   */
  public getObject(id: string): Observable<T> {
    return this.apiService.get(`${this.url}/${id}/`).pipe(map((res) => this.adapter.clientAdapt(res)));
  }

  /**
   * Sends a request to create an object
   * @param object the new object to store
   */
  public createObject(object: T): Observable<T> {
    return this.apiService.post(`${this.url}/`, this.adapter.serverAdapt(object));
  }

  /**
   * Sends a request to update an object using the id
   * @param id the id of the object
   * @param object the object to store
   */
  public updateObject(id: string, object: T): Observable<T> {
    // ** Request Payload got 'No properties' if uses the adapter.
    // return this.apiService.post(`${this.url}/${id}`, this.adapter.serverAdapt(object));
    return this.apiService.post(`${this.url}/${id}`, object);
  }

  /**
   * Sends a request to delete an object using the id
   * @param id the id of the object
   */
  public deleteObject(id: string): Observable<T> {
    return this.apiService.delete(`${this.url}/${id}/`);
  }

  /**
   * Creates the HttpParams for the request
   * @param getListConfig the configuration to pass when requesting the list of objects
   */
  protected buildQueryParams(getListConfig?: GetListConfig): HttpParams {
    // TODO: Change implementation so filters can be easily set overriding this function;
    if (!getListConfig) {
      return new HttpParams({});
    }

    const queryParams: any = getListConfig;

    return new HttpParams({ fromObject: queryParams });
  }
}
