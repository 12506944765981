export const AUTH_EVENT = {
  signedUp: '[Auth] User signed up',
  signedIn: '[Auth] User signed in',
  signedOut: '[Auth] User signed out',
};

export const USER_EVENT = {
  detailsFetched: '[User] Details fetched',
};

export const REQUEST_EVENT = {
  errored: '[Request] Error response received',
};

export const INTEGRATION_EVENT = {
  startedAuthWorkflow: '[Integration] Auth workflow started',
};

export const SNACK_BAR_EVENT = {
  triggered: '[Snack bar] Triggered',
};

export const STORE_EVENT = {
  changed: '[Store] Changed',
  toggledAll: '[Store] Toggled all',
};

export const DATE_EVENT = {
  changed: '[Date] Changed',
};

export const GLOBAL_FILTER_EVENT = {
  changed: '[Global Filter] Changed',
};

export const SUPPORT_EVENT = {
  clicked: '[Support] Launcher clicked',
};

export const NAVIGATION_EVENT = {
  webViewPageChanged: '[Navigation] Web view page changed',
  externalUrlOpened: '[Navigation] External url opened',
  providerUserClicked: '[Navigation] Instagram user clicked',
  providerHashtagClicked: '[Navigation] Instagram hashtag clicked',
};

export const MEDIA_EVENT = {
  downloaded: '[Media] Downloaded',
};
