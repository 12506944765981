import { Inject, Injectable, Injector, Optional, Renderer2, SkipSelf } from '@angular/core';
import { MAT_DIALOG_SCROLL_STRATEGY, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT, Location } from '@angular/common';
import { DynamicOverlay } from '@core/modules/dynamic-overlay/dynamic-overlay';

@Injectable()
export class DynamicDialog extends MatDialog {
  private customOverlay: DynamicOverlay;

  constructor(
    overlay: DynamicOverlay,
    injector: Injector,
    @Optional() location: Location,
    @Inject(MAT_DIALOG_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() @SkipSelf() parentDialog: DynamicDialog,
    overlayContainer: OverlayContainer,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super(
      overlay,
      injector,
      location,
      {
        disableClose: true,
        panelClass: '',
        autoFocus: true,
        hasBackdrop: true,
      } as MatDialogConfig,
      scrollStrategy,
      parentDialog,
      overlayContainer,
    );

    this.customOverlay = overlay;
  }

  getDialogContainerElement(): HTMLElement {
    let container = this.document.body.getElementsByClassName('core-dialog-container').item(1);
    if (!container) {
      container = this.document.createElement('div');
      container.classList.add('core-dialog-container');
      this.document.body.append(container);
    }

    return container as HTMLElement;
  }

  public setContainerElement(containerElement: HTMLElement, renderer: Renderer2): void {
    // need to apply this styling to make the backdrop with position:
    // fixed styling cover only the containerElement
    renderer.setStyle(containerElement, 'transform', 'translateZ(0)');
    renderer.setStyle(containerElement, 'overflow', 'hidden');
    this.customOverlay.setContainerElement(containerElement);
  }
}
