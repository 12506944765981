import { animate, state, style, transition, trigger, AnimationTriggerMetadata, group } from '@angular/animations';

export const formFieldAnimations: {
  readonly transitionMessages: AnimationTriggerMetadata;
} = {
  /** Animation that transitions the form field's error messages. */
  transitionMessages: trigger('transitionMessages', [
    state(
      'visible',
      style({
        'max-height': '240px',
        opacity: '1',
        visibility: 'visible',
      }),
    ),
    state(
      'hidden',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
      }),
    ),
    transition('visible => hidden', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          }),
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          }),
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          }),
        ),
      ]),
    ]),
    transition('hidden => visible', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          }),
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '240px',
          }),
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          }),
        ),
      ]),
    ]),
  ]),
};
