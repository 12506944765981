import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { Contact } from '@core/interfaces/contact.interface';
import { UserPreferences } from '@core/interfaces/user-preferences.interface';
import { AccessRights } from '@core/interfaces/access-rights.interface';

/**
 * Frontend model for a user object
 */
export class User {
  readonly _id: string;

  readonly isAdmin: boolean;

  readonly isInternalAccount: boolean;

  contact: Contact;

  settings: UserPreferences;

  accessRights: AccessRights;

  companyIds: string[] = [];

  storeIds: string[] = [];

  constructor(object: User) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = object._id;
    this.isAdmin = object.isAdmin;
    this.isInternalAccount = object.isInternalAccount;
    this.contact = object.contact;
    this.settings = object.settings;
    this.accessRights = object.accessRights;
    this.companyIds = object.companyIds;
    this.storeIds = object.storeIds;
  }
}

/**
 * Adapter for the user object
 */
@Injectable({
  providedIn: 'root',
})
export class UserAdapter implements Adapter<User> {
  clientAdapt(object: any): User {
    return new User(object);
  }

  serverAdapt(object: any): User {
    return new User(object);
  }
}
