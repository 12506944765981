import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';

@Component({
  selector: 'core-post-caption',
  templateUrl: './post-caption.component.html',
  styleUrls: ['./post-caption.component.scss'],
})
export class PostCaptionComponent {
  @Input()
  provider?: ManagedPostProvider;

  @Input()
  text = '';

  @Input()
  isTruncated = true;

  @Input()
  maxLength = 120;

  @Input()
  allowShowLess = true;

  @Output()
  truncateStateChange = new EventEmitter<MouseEvent>();

  /**
   * Toggle boolean for show more/less text
   */
  updateTruncateState(event: MouseEvent): void {
    this.isTruncated = !this.isTruncated;
    this.truncateStateChange.emit(event);
  }

  /**
   * Whether or not to show the show more/less text
   */
  showTruncateToggle(): boolean {
    return this.text.length > this.maxLength && this.allowShowLess;
  }
}
