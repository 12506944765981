import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiSuccessResponseAdapter, ApiSuccessResponse } from '@core/models/api-success-response.model';
import { ApiErrorResponseAdapter } from '@core/models/api-error-response.model';
import { Params } from '@angular/router';

export const APISERVICE_BASEURL = new InjectionToken('api-service-base-url');

/**
 * Service for making http requests
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private apiSuccessResponseAdapter: ApiSuccessResponseAdapter,
    private apiErrorResponseAdapter: ApiErrorResponseAdapter,
    @Inject(APISERVICE_BASEURL) readonly baseUrl: string,
  ) {}

  /**
   * Handles the error on the frontend
   * @param error the error response
   */
  private formatError(error: HttpErrorResponse): any {
    const apiError = this.apiErrorResponseAdapter.clientAdapt({
      ...error.error,
      code: error.status,
      response: error,
    });

    return apiError;
  }

  /**
   * Handles the response on the frontend
   * @param response the http response
   */
  private formatResponse(response: any): ApiSuccessResponse<any> {
    const apiResponse = this.apiSuccessResponseAdapter.clientAdapt(response);

    return {
      ...apiResponse,
      result: apiResponse.result,
      pagination: apiResponse.pagination,
      status: apiResponse.status,
    };
  }

  /**
   * Sends a GET request to an endpoint
   * @param url the endpoint
   * @param params the parameters to send
   */
  public get(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.baseUrl}${url}`, { params }).pipe(
      map((res: any) => (res?.result ? this.formatResponse(res) : this.formatResponse({ result: res }))),
      catchError((err: HttpErrorResponse) => throwError(this.formatError(err))),
    );
  }

  /**
   * Sends a POST request to an endpoint
   * @param url the endpoint
   * @param body payload to send
   * @param params the parameters to send
   */
  public post(url: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(`${this.baseUrl}${url}`, body, { params }).pipe(
      map((res: any) => {
        if (!res) {
          return null;
        }

        return res?.result ? this.formatResponse(res) : this.formatResponse({ result: res });
      }),
      catchError((err) => throwError(this.formatError(err))),
    );
  }

  /**
   * Sends a DELETE request to an endpoint
   * @param url the endpoint
   * @param body payload to send
   * @param params the parameters to send
   */
  public delete(url: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(`${this.baseUrl}${url}`, { params, body }).pipe(
      map((res: any) => {
        if (!res) {
          return null;
        }

        return res?.result ? this.formatResponse(res) : this.formatResponse({ result: res });
      }),
      catchError((err) => throwError(this.formatError(err))),
    );
  }

  /**
   * Sends a PUT request to an endpoint
   * @param url the endpoint
   * @param body payload to send
   * @param params the parameters to send
   */
  public put(url: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.put(`${this.baseUrl}${url}`, body, { params }).pipe(
      map((res: any) => {
        if (!res) {
          return null;
        }

        return res?.result ? this.formatResponse(res) : this.formatResponse({ result: res });
      }),
      catchError((err) => throwError(this.formatError(err))),
    );
  }

  /**
   * Sends a POST request to an endpoint and reports the progress
   * @param url the endpoint
   * @param body payload to send
   * @param params the parameters to send
   *
   * https://stackoverflow.com/questions/75797022/angular-httpclient-how-to-read-stream-response-part-by-part-without-waiting-for/77220814#77220814
   */
  public postStream(url: string, body: any = {}, params: HttpParams): Observable<any> {
    const options: Params = {
      observe: 'events',
      responseType: 'text',
      reportProgress: true,
    };

    if (params) {
      options.params = params;
    }

    return this.http.post(`${this.baseUrl}${url}`, body, options).pipe(
      map((res) => (res ? this.formatResponse(res) : null)),
      catchError((err) => throwError(this.formatError(err))),
    );
  }

  /**
   * Fetches mock response from assets directory
   * @param url the endpoint
   * @param assetsDir the directory path
   */
  public getMock(url: string, assetsDir = '/assets/core/mocks'): Observable<any> {
    return this.http.get(`${assetsDir}/${url}`).pipe(
      map((res: any) => this.formatResponse(res)),
      catchError((err: HttpErrorResponse) => throwError(this.formatError(err))),
    );
  }
}
