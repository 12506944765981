import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { AuthUserService } from '@core/services/auth-user.service';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { AuthUser } from '@core/models/auth-user.model';
import { ReviewWorkflowService } from '@review/common/services/review-workflow.service';
import { RequestCardsRoute } from '@review/modules/request-cards/enums/request-cards-route.enum';
import { dialCodeOptions, dialCodeQuickOptions } from '@core/variables/dial-code-options.variable';
import _find from 'lodash/find';
import { AppSettingsService } from '@core/services/app-settings.service';
import { SupportedLanguage } from '@core/enums/user-preference.enum';
import { DialCodeOption } from '@core/interfaces/contact.interface';
import { ReviewCardService } from '@review/common/services/review-card.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { SegmentService } from '@core/services/segment.service';
import { TRACK_REVIEW_CARD } from '../../variables/tracking-event.variable';

@Component({
  selector: 'review-order-cards',
  templateUrl: './order-cards.component.html',
  styleUrls: ['./order-cards.component.scss'],
})
export class OrderCardsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  authUser = this.authUserService.authUser;

  dialCodeQuickOptions = dialCodeQuickOptions;

  dialCodeOptions = dialCodeOptions;

  orderForm = new FormGroup<any>({
    amount: new FormControl(3, Validators.required),
    address: new FormGroup({
      companyName: new FormControl(this.globalFilterService.store?.name, Validators.required),
      firstName: new FormControl(this.authUser?.contact?.firstName, Validators.required),
      lastName: new FormControl(this.authUser?.contact?.lastName, Validators.required),
      streetName: new FormControl(
        this.getStreetName(this.globalFilterService.store?.location?.street),
        Validators.required,
      ),
      houseNumber: new FormControl(
        this.getHouseNumber(this.globalFilterService.store?.location?.street),
        Validators.required,
      ),
      city: new FormControl(this.globalFilterService.store?.location?.city, Validators.required),
      zip: new FormControl(this.globalFilterService.store?.location?.zip, Validators.required),
      country: new FormControl(this.globalFilterService.store?.location?.country, Validators.required),
      dialCode: new FormControl(this.getDialogCode()),
      phoneNumber: new FormControl(
        this.authUser?.contact.phone?.work?.replace(this.getDialogCode().dialCode, '') ||
          this.globalFilterService.store?.contact?.phone?.replace(this.getDialogCode().dialCode, ''),
        Validators.required,
      ),
    }),
  });

  actionInProgress = false;

  language = this.appSettingsService.currentLanguage;

  constructor(
    private globalFilterService: GlobalFilterService,
    private authUserService: AuthUserService,
    private reviewCardService: ReviewCardService,
    private reviewWorkflowService: ReviewWorkflowService,
    private appSettingsService: AppSettingsService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private segmentService: SegmentService,
  ) {}

  ngOnInit(): void {
    this.appSettingsService.language$.pipe(takeUntil(this.destroyed$)).subscribe((language) => {
      this.language = language as SupportedLanguage;
      this.dialCodeOptions.sort((a, b) => a.name[this.language].localeCompare(b.name[this.language]));
    });
    this.authUserService.authUser$.pipe(takeUntil(this.destroyed$)).subscribe((authUser: AuthUser) => {
      this.authUser = authUser;
      if (this.authUser) {
        this.orderForm.controls.address.patchValue({
          firstName: this.authUser.contact.firstName,
          lastName: this.authUser.contact.lastName,
        });
      }
    });
  }

  onConfirm(): void {
    this.orderForm.markAllAsTouched();
    const address = this.orderForm.controls.address.value!;
    const requestBody = {
      address: {
        firstName: address.firstName,
        lastName: address.lastName,
        streetName: address.streetName,
        houseNumber: address.houseNumber,
        zip: address.zip,
        city: address.city,
        country: address.country,
        phoneNumber: `${address.dialCode?.dialCode} ${address.phoneNumber}`,
      },
      amount: this.orderForm.controls.amount.value!,
      companyName: this.orderForm.controls.address.value?.companyName!,
      storeId: this.globalFilterService.store?._id,
    };
    this.segmentService.track(TRACK_REVIEW_CARD.submittedOrder, {
      data: requestBody,
      valid: this.orderForm.valid,
    });
    if (this.orderForm.valid) {
      this.actionInProgress = true;
      this.reviewCardService
        .order(requestBody)
        .pipe(
          catchError(() => {
            this.actionInProgress = false;
            this.snackBarService.openSnackBar({
              variant: 'error',
              icon: 'warning',
              text: this.translateService.instant('ERROR.REQUEST'),
            });

            return EMPTY;
          }),
          tap(() => {
            this.actionInProgress = false;
            this.reviewWorkflowService.setCurrentStage(RequestCardsRoute.Confirmation);
          }),
        )
        .subscribe();
    }
  }

  getHouseNumber(address?: string): string {
    if (address) {
      const regex = /[0-9]+[a-zA-z]?/m;

      return address.match(regex)?.[0] || '';
    }

    return '';
  }

  getStreetName(address?: string): string {
    if (address) {
      const regex = /\b(?=\w)\D+/gm;

      return address.match(regex)?.join(' ') || '';
    }

    return '';
  }

  onClose(): void {
    this.reviewWorkflowService.clearStage();
  }

  getDialogCode(): DialCodeOption {
    const sortedDialCodeDesc = this.dialCodeOptions.sort((a, b) => a.dialCode.localeCompare(b.dialCode));

    return (
      _find(
        sortedDialCodeDesc,
        (item) =>
          this.authUser?.contact.phone?.work?.startsWith(item.dialCode) ||
          this.globalFilterService.store?.contact?.phone?.startsWith(item.dialCode),
      ) || this.dialCodeQuickOptions[0]
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
