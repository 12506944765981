<core-dialog
  [actionInProgress]="actionInProgress"
  [unsavedChanges]="false"
  [canGoBack]="false"
  [headerConfig]="{
    title: selectedStore?.name,
    avatarSrc: selectedStore?.images?.[0]?.file
  }"
  [footerConfig]="{
    submitLabel: 'INTEGRATION_DIALOG.NAVIGATION.CONNECT' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate
  }"
  (dismiss)="onCancel()"
  (submit)="onSubmit()">
  <div class="row mx-n2 flex-grow-1">
    <div
      class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-0">
      <core-svg svg="img_store"></core-svg>
    </div>

    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <p
        [innerHTML]="
          'INTEGRATION_DIALOG.CONNECT_DIALOG_INTRO.TEXT_PROVIDER.' + data.provider
            | translate
              : {
                  storeName: storeName,
                  storeAddress: storeAddress
                }
        "></p>
    </div>
  </div>
</core-dialog>
