/* eslint-disable no-plusplus */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  CardPadding,
  CardRoundness,
  CardLevel,
  HandsetGroupPosition,
  HeaderFooterSize,
} from '@core/ui/card/types/common.type';
import { IconSize } from '@core/ui/svg/types/icon.type';
import { CardVariant, CardHeaderIconType } from '../../types/section-card.type';

let uid = 0;

@Component({
  selector: 'core-section-card',
  templateUrl: './section-card.component.html',
  styleUrls: ['./section-card.component.scss'],
  host: {
    '[id]': `id`,
    '[class]': `"card"
      + " roundness-"+roundness
      + " padding-"+padding
      + " header-padding-"+config?.headerPadding
      + " footer-padding-"+config?.footerPadding
      + " headerfooter-"+config?.headerFooterSize
      + " level-"+level
      + " variant-"+variant
      + " handset-group-"+handsetGroupPosition`,
    '[class.card--shadow]': 'shadow',
    '[class.card--border]': 'border',
  },
})
export class SectionCardComponent implements AfterViewInit {
  @Input()
  id = `core-section-card-${uid++}`;

  @ViewChild('body')
  bodyElement!: ElementRef;

  @Input()
  config?: {
    icon?: string;
    iconType?: CardHeaderIconType;
    iconSize?: IconSize;
    titleActionIcon?: string;
    pretitle?: string;
    title?: string;
    subtitle?: string;
    headerClickable?: boolean;
    headerPadding?: CardPadding;
    footerPadding?: CardPadding;
    headerFooterSize?: HeaderFooterSize;
  };

  @Input()
  variant: CardVariant = 'default';

  @Input()
  padding: CardPadding = 'lg';

  @Input()
  level: CardLevel = 1;

  @Input()
  roundness: CardRoundness = 'sm';

  /**
   * On the mobile view, changes the border radius so that
   * `start` has rounded corners at the top only
   * `between` has no rounded corners
   * `end` has rounded corners at the bottom only
   * `single` keeps the standard border radius but it acts as a mobile group
   * `none` keeps the standard border radius but it keeps the web margins
   */
  @Input()
  handsetGroupPosition: HandsetGroupPosition = 'none';

  @Input()
  shadow = true;

  @Input()
  border = false;

  @Output()
  headerClick = new EventEmitter();

  @Output()
  titleIconClick = new EventEmitter();

  canScrollUp = false;

  canScrollDown = false;

  ngAfterViewInit(): void {
    this.checkScrollPosition(this.bodyElement.nativeElement);
  }

  onHeaderClick(): void {
    if (this.config?.headerClickable) {
      this.headerClick.emit();
    }
  }

  checkScrollPosition(element: HTMLElement): void {
    const isBodyScrollable = element.scrollHeight > element.clientHeight;
    if (!isBodyScrollable) {
      this.canScrollUp = false;
      this.canScrollDown = false;

      return;
    }
    this.canScrollUp = element.scrollTop > 0;
    this.canScrollDown = Math.ceil(element.scrollHeight - element.scrollTop) !== element.clientHeight;
  }
}
