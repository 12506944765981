import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private actionInProgress = false;

  private initialised = false;

  constructor(private httpClient: HttpClient) {}

  ready(): Observable<boolean> {
    if (!this.initialised && !this.actionInProgress) {
      this.actionInProgress = true;

      return this.httpClient
        .jsonp<boolean>(
          `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleMaps.apiKey}`,
          'callback',
        )
        .pipe(
          tap(() => {
            this.initialised = true;
            this.actionInProgress = false;
          }),
          first(),
        );
    }

    return of(this.initialised);
  }
}
