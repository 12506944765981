import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { AvatarModule } from '@core/ui/avatar/avatar.module';
import { PlatformModule } from '@core/ui/platform/platform.module';
import { CardComponent } from './components/card/card.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SectionCardComponent } from './components/section-card/section-card.component';
import { DividerModule } from '../divider/divider.module';

@NgModule({
  declarations: [CardComponent, SectionCardComponent, OverlayComponent],
  imports: [CommonModule, SvgModule, AvatarModule, PlatformModule, MatRippleModule, DividerModule],
  exports: [CardComponent, SectionCardComponent, OverlayComponent],
})
export class CardModule {}
