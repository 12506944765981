import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthUserService } from '@core/services/auth-user.service';
import { AuthService } from '@core/services/auth.service';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { RoutingService } from '@core/services/routing.service';
import _get from 'lodash/get';

/**
 * Protects routes when the user is not authenticated
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private authUserService: AuthUserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.validAccessToken()) {
      this.authService.clearTokens();
      this.routingService.navigateToLogin(state.url, route.queryParams);

      return false;
    }

    if (!this.authUserService.requestInProgress) {
      this.authUserService.getAuthUser().pipe(first()).subscribe();
    }

    const requiredRights = _get(route.data, 'requiredAccessRights', null);

    if (requiredRights) {
      return this.authUserService.checkAccessRight(requiredRights.moduleName, requiredRights.right);
    }

    return true;
  }
}

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => inject(AuthGuardService).canActivate(route, state);
