import _chunk from 'lodash/chunk';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crudSelectors } from '../crud/crud.selector';
import { StoreState, getStoreState, selectAllStores, selectStoreEntities } from './company-store.reducer';

export const selectStoreState = createFeatureSelector<StoreState>('companyStores');
export const companyStoreSelectors = crudSelectors(getStoreState);

/**********************************************/
// Selectors for list of company stores
/*********************************************/

export const getStoresByPage = (pageNumber: number) =>
  createSelector(
    selectAllStores,
    companyStoreSelectors.getListPagination,
    companyStoreSelectors.getListPageLoading(pageNumber),
    companyStoreSelectors.getListPageLoaded(pageNumber),
    companyStoreSelectors.getErrorLimitReached,
    (allStores, pagination, loading, loaded, errorLimit) => {
      const companyStores = pagination.limit ? _chunk(allStores, pagination.limit)[pageNumber] : allStores;

      return { companyStores, pagination, loading, loaded, errorLimit };
    },
  );

/**********************************************/
// Selectors for one company store
/*********************************************/

export const getStoreById = (id: string) =>
  createSelector(selectStoreEntities, companyStoreSelectors.getObjectLoading, (companyStores, loading) => ({
    companyStore: companyStores[id],
    loading,
  }));
