<core-dialog
  [style.width.px]="1024"
  [style.height.px]="832"
  [actionInProgress]="actionInProgress"
  [headerConfig]="{
    title: 'POST_MANAGER.TITLE.REJECT' | translate
  }"
  [footerConfig]="{
    submitLabel: 'POST_MANAGER.ACTION.REJECT' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate,
    submitDisabled: formGroup.invalid
  }"
  [confirmSubmitConfig]="{
    icon: 'warning',
    title: 'POST_MANAGER.CONFIRMATION.REJECT.TITLE' | translate,
    text: 'POST_MANAGER.CONFIRMATION.REJECT.TEXT' | translate,
    variant: 'emphasis'
  }"
  [unsavedChanges]="formGroup?.dirty"
  [confirmSubmit]="true"
  (dismiss)="cancel()"
  (submit)="submit()">
  <form [formGroup]="formGroup" class="row my-auto mx-0 mx-sm-n2 flex-grow-1">
    <div class="col-12 col-sm-8 px-0 px-sm-2 d-flex flex-column justify-content-start align-items-stretch pb-2 pb-sm-0">
      <h6>{{ 'POST_MANAGER.SECTION.DELETION_REASON' | translate }}
        <strong
          *ngIf="formGroup.get('message')?.hasError('required') && formGroup.get('message')?.touched"
          class="form-validation-error">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </strong>
      </h6>
      <core-form-field
        class="flex-grow-1 d-flex flex-column mb-3">
        <core-icon class="align-self-start" coreFormFieldPrefix icon="content" size="sm"></core-icon>
        <textarea
          coreInput
          formControlName="message"
          [placeholder]="'POST_MANAGER.PLACEHOLDER.DELETE_MESSAGE' | translate"
          rows="8">
        </textarea>
      </core-form-field>

      <div 
        *ngIf="post?.createdBy === 'MANAGER'"
        class="mx-n2">
        <core-alert
          class="m-2"
          variant="danger"
          fontSize="sm"
          [title]="'POST_MANAGER.ALERT.REJECT.TITLE' | translate">
          {{ 'POST_MANAGER.ALERT.REJECT.TEXT' | translate }}
        </core-alert>
      </div>
    </div>

    <div class="image-column col-12 col-sm-4 px-0 px-sm-2 d-flex flex-column justify-content-stretch align-items-stretch pb-4">
      <core-post-card
        *ngIf="post"
        class="flex-grow-0"
        [title]="('POST.HEADER.POST_KIND.' + post?.post?.type) | translate"
        icon="social"
        [cardVariant]="post?.post?.type === 'STORY' ? 'card-overlay' : 'default'"
        [mediaVariant]="post?.post?.resources?.length > 1 ? 'CAROUSEL' : post?.post?.resources?.[0]?.type === 'VIDEO' ? 'VIDEO' : 'IMAGE'"
        [slides]="slides"
        [date]="post?.publishAt"
        [caption]="post?.post?.caption?.text">
      </core-post-card>
    </div>
  </form>
  <div
    coreDialogFooter
    class="d-flex flex-column flex-md-row justify-content-md-between flex-grow-1">
    <button icon="back" coreButton variant="text" (click)="updatePost()">{{ 'POST_MANAGER.ACTION.GO_BACK' | translate }}</button>
    <div class="spacer"></div>
  </div>
</core-dialog>
