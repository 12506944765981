import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '@core/ui/svg/svg.module';
import { CardModule } from '@core/ui/card/card.module';
import { ThemeModule } from '@core/modules/theme/theme.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonModule } from '../button/button.module';
import { TipCustomComponent } from './components/tip-custom/tip-custom.component';
import { TipLargeComponent } from './components/tip-large/tip-large.component';
import { TipSmallComponent } from './components/tip-small/tip-small.component';

@NgModule({
  declarations: [TipCustomComponent, TipLargeComponent, TipSmallComponent],
  imports: [CommonModule, SvgModule, CardModule, ButtonModule, TranslateModule, ThemeModule, MatProgressSpinnerModule],
  exports: [TipCustomComponent, TipLargeComponent, TipSmallComponent],
})
export class TipModule {}
