import { Routes, RouterModule } from '@angular/router';
import { RequestCardsRoute } from '@review/modules/request-cards/enums/request-cards-route.enum';
import { OrderCardsComponent } from './components/order-cards/order-cards.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';

export const requestCardsOutlet = 'request-cards';
const outlet = requestCardsOutlet;

export const makeRequestCardsRoutes = () => [
  {
    path: RequestCardsRoute.Order,
    outlet,
    component: OrderCardsComponent,
  },
  {
    path: RequestCardsRoute.Confirmation,
    outlet,
    component: OrderConfirmationComponent,
  },
];

const routes: Routes = [...makeRequestCardsRoutes()];

export const RequestCardsRoutingModule = RouterModule.forChild(routes);
