/**
 * Enum for currency symbols
 */
export enum CurrencySymbol {
  EUR = '€',
  GBP = '£',
  ILS = '₪',
  USD = '$',
}

/**
 * Enum for currency position
 */
export enum CurrencyPosition {
  Prefix = 'prefix',
  Postfix = 'postfix',
}

/**
 * Enum for supported currencys
 */
export enum SupportedCurrency {
  EUR = 'EUR',
  GBP = 'GBP',
  ILS = 'ILS',
  USD = 'USD',
}

/**
 * Enum for supported languages
 */
export enum SupportedLanguage {
  en = 'en',
  de = 'de',
}
