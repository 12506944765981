import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { StarSize } from '@core/ui/star-rating/types/star.size';

@Component({
  selector: 'core-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit, OnChanges {
  @Input() rating = 0;

  @Input() numberFormat = '1.1-2';

  @Input() showStars = true;

  @Input() showNumber = true;

  @Input() maxStars = 5;

  @Input() inheritFontStyle = false;

  @Input() size: StarSize = 'sm';

  @Input() direction: 'column' | 'row' = 'row';

  starsList: number[] = [];

  ngOnInit(): void {
    this.initStars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxStars) {
      this.initStars();
    }
  }

  initStars(): void {
    this.starsList = [];
    if (this.showStars && (this.rating > 0 || !this.showNumber)) {
      for (let i = 1; i <= this.maxStars; i += 1) {
        this.starsList.push(Math.min(1, Math.max(0, this.rating - (i - 1))));
      }
    }
  }
}
