import { RoutingService } from '@core/services/routing.service';
import { tap, filter, first, map } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GetListConfig } from '@core/interfaces/crud-service.interface';
import { getUsersByPage, getUserById, userSelectors } from '@core/redux/user/user.selector';
import { LoadUserList, LoadUser, CreateUser, UpdateUser, DeleteUser } from '@core/redux/user/user.action';
import { PaginatedResult } from '@core/interfaces/pagination.interface';
import { User, UserAdapter } from '@core/models/user.model';
import { AppState } from '@core/interfaces/app-state.interface';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CrudService<User> {
  constructor(
    protected override apiService: ApiService,
    protected userAdapter: UserAdapter,
    private ngrxStore$: Store<AppState>,
    private routingService: RoutingService,
  ) {
    super(apiService, userAdapter, 'users');
  }

  /**
   * Requests the list of users or fetches them from the redux store
   * @param config the request configuration
   * @param forceRefresh requests the list of all stores again
   */
  getUserList(config: GetListConfig = {}, forceRefresh = false): Observable<PaginatedResult<User>> {
    return this.ngrxStore$.pipe(
      select(getUsersByPage(config.page || 0)),
      tap(({ pagination, loading, loaded, errorLimit }) => {
        const limitChanged = config.limit && config.limit !== pagination.limit && !loading;
        if (errorLimit) {
          return this.routingService.navigateToRedirect('error');
        }
        if (!(loading || loaded || errorLimit) || limitChanged || forceRefresh) {
          this.ngrxStore$.dispatch(
            new LoadUserList({
              sort: ['contact.firstName'],
              fields: ['contact.firstName', 'contact.lastName', 'contact.email', 'companyIds'],
              ...config,
            }),
          );
        }

        return EMPTY;
      }),
      filter(({ loaded, pagination }) => loaded || pagination.limit === config.limit || false),
      map(({ users, pagination }) => ({ data: users, pagination })),
      first(),
    );
  }

  /**
   * Requests the specified user or fetches them from the redux store
   * @param id the id of the user
   */
  getUser(id: string): Observable<User | undefined> {
    return this.ngrxStore$.pipe(
      select(getUserById(id)),
      tap(({ user, loading }) => {
        if (!loading && !(user && user.accessRights)) {
          this.ngrxStore$.dispatch(new LoadUser(id));
        }
      }),
      filter(({ user }) => Boolean(user && user.accessRights)),
      map(({ user }) => user),
      first(),
    );
  }

  /**
   * Dispatches an action to create a new user
   * @param user the data to send
   */
  createUser(user: User): Observable<boolean> {
    this.ngrxStore$.dispatch(new CreateUser(user));

    return this.ngrxStore$.pipe(select(userSelectors.getObjectCreated));
  }

  /**
   * Dispatches an action to update an existing user
   * @param id the id of the user
   * @param user the data to send
   */
  updateUser(id: string, user: User): Observable<boolean> {
    this.ngrxStore$.dispatch(new UpdateUser(id, user));

    return this.ngrxStore$.pipe(select(userSelectors.getObjectUpdated));
  }

  /**
   * Dispatches an action to delete an existing user
   * @param id the id of the user
   */
  deleteUser(id: string): Observable<boolean> {
    this.ngrxStore$.dispatch(new DeleteUser(id));

    return this.ngrxStore$.pipe(select(userSelectors.getObjectDeleted));
  }
}
