/* eslint-disable no-param-reassign */
import { DestroyRef, Injectable, inject } from '@angular/core';
import { ThemeService } from '@core/modules/theme/utils/theme.service';
import { BehaviorSubject, Observable, concatMap, of } from 'rxjs';
import { BrandChartConfig } from '@core/interfaces/brand-chart-config.interface';
import _merge from 'lodash/merge';
import { Theme } from '@core/interfaces/user-preferences.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import brandConfigFilesMap from '@core/assets/brands';
import { BrandConfigFiles } from '@core/interfaces/brand-config-files.interface';

/**
 * Chart options by brand
 */
@Injectable({
  providedIn: 'root',
})
export class BrandChartConfigService {
  brandConfigFilesMap: Record<string, BrandConfigFiles> = brandConfigFilesMap;

  chartConfig: BrandChartConfig = this.getDefaultConfig();

  chartConfig$ = new BehaviorSubject<BrandChartConfig>(this.chartConfig);

  destroyRef = inject(DestroyRef);

  constructor(private themeService: ThemeService) {
    this.themeService.currentTheme$
      ?.pipe(
        takeUntilDestroyed(this.destroyRef),
        concatMap((theme) => this.setChartConfig(this.themeService.brand, theme)),
      )
      .subscribe();
  }

  private getDefaultConfig(): BrandChartConfig {
    const config: any = brandConfigFilesMap;
    const brand: any = this.themeService.brand ?? 'alphin';
    const extendsAlphinConfig = config?.[brand]?.chart?.[this.themeService.current]?.extendsAlphinConfig ?? false;

    return _merge(
      {},
      extendsAlphinConfig ? config?.alphin?.chart?.[this.themeService.current] : {},
      config?.[brand]?.chart?.[this.themeService.current],
    );
  }

  setChartConfig(brand: string, theme: Theme): Observable<BrandChartConfig> {
    const desiredBrandExists = !!this.brandConfigFilesMap[brand];
    const desiredThemeExists = !!this.brandConfigFilesMap[brand]?.chart?.[theme];

    if (!desiredBrandExists) {
      brand = 'alphin';
    } else if (!desiredThemeExists) {
      [theme] = this.themeService.allThemes.filter((mode) => mode !== theme);
    }

    const chartConfig = this.brandConfigFilesMap[brand]?.chart?.[theme] ?? null;
    const defaultConfig = this.getDefaultConfig();
    this.chartConfig = _merge(defaultConfig, chartConfig);
    this.chartConfig$.next(this.chartConfig);

    return of(this.chartConfig);
  }
}
