import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from '@core/services/dialog.service';
import { PostModule } from '@core/modules/post/post.module';
import { StoreSelectorIconsModule } from '@core/modules/store/store-selector-icons/store-selector-icons.module';
import { DialogModule } from '@core/ui/dialog/dialog.module';
import { ProgressModule } from '@core/ui/progress/progress.module';
import { SvgModule } from '@core/ui/svg/svg.module';
import { MediaModule } from '@core/ui/media/media.module';
import { ButtonModule } from '@core/ui/button/button.module';
import { AlertModule } from '@core/ui/alert/alert.module';
import { EmptyStateModule } from '@core/ui/empty-state/empty-state.module';
import { CheckboxModule } from '@core/ui/checkbox/checkbox.module';
import { AvatarModule } from '@core/ui/avatar/avatar.module';
import { CardModule } from '@core/ui/card/card.module';
import { PlatformModule } from '@core/ui/platform/platform.module';
import { TagModule } from '@core/ui/tag/tag.module';
import { ToggleModule } from '@core/ui/toggle/toggle.module';
import { ChipModule } from '@core/ui/chip/chip.module';
import { FormFieldModule } from '@core/ui/form-field/form-field.module';
import { RadioModule } from '@core/ui/radio/radio.module';
import { EmojiPickerModule } from '@core/ui/emoji-picker/emoji-picker.module';

import { TextFieldModule } from '@angular/cdk/text-field';
import { PostManagerRoutingModule } from './post-manager-routing.module';
import { PostManagerComponent } from './post-manager.component';
import { UpdatePostComponent } from './screens/update-post/update-post.component';
import { RejectPostComponent } from './screens/reject-post/reject-post.component';
import { ProviderSelectorComponent } from './components/provider-selector/provider-selector.component';
import { PublishDateInputComponent } from './components/publish-date-input/publish-date-input.component';

@NgModule({
  declarations: [
    PostManagerComponent,
    ProviderSelectorComponent,
    PublishDateInputComponent,
    UpdatePostComponent,
    RejectPostComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    PostModule,
    AvatarModule,
    CardModule,
    PlatformModule,
    PostManagerRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    SvgModule,
    ProgressModule,
    MediaModule,
    ButtonModule,
    EmptyStateModule,
    AlertModule,
    StoreSelectorIconsModule,
    TagModule,
    CheckboxModule,
    RadioModule,
    ToggleModule,
    ChipModule,
    FormFieldModule,
    MatTooltipModule,
    TextFieldModule,
    MatMenuModule,
    EmojiPickerModule,
  ],
  providers: [DialogService],
  exports: [PostManagerComponent, ProviderSelectorComponent, PublishDateInputComponent],
})
export class PostManagerModule {}
