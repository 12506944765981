import { Nullable } from '@core/types/nullable.type';
import { Component, Input, OnChanges } from '@angular/core';
import { AccountDetails } from '@core/interfaces/integration.interface';
import * as activeIcons from '@core/ui/platform/variables/icons-active.variable';
import { ProviderAccountColumn } from '@core/dialogs/integration-workflow/types/provider-account.type';

@Component({
  selector: 'core-provider-account-mobile-item',
  templateUrl: './provider-account-mobile-item.component.html',
  styleUrls: ['./provider-account-mobile-item.component.scss'],
})
export class ProviderAccountMobileItemComponent implements OnChanges {
  icons = activeIcons;

  @Input()
  account: Nullable<AccountDetails> = null;

  @Input()
  visibleColumns: Record<ProviderAccountColumn, boolean> = {
    name: true,
    rating: false,
    followers: false,
    location: false,
  };

  @Input()
  bestFit = false;

  nameColumnClass = 'col-12';

  detailsColumnClass = 'col-12';

  get detailsVisible() {
    return this.visibleColumns.rating || this.visibleColumns.followers || this.visibleColumns.rating;
  }

  ngOnChanges(): void {
    const detailsColumnCount = [
      this.visibleColumns.followers || this.visibleColumns.rating,
      this.visibleColumns.location,
    ].filter(Boolean).length;

    this.detailsColumnClass = detailsColumnCount ? `col-${12 / detailsColumnCount}` : 'col-12';
  }
}
