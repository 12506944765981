import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from '@core/enums/product.enum';
import { environment } from '@env/environment';
import { GoalSettingDialogService } from '@core/dialogs/goal-setting-workflow/services/goal-setting-dialog.service';
import { goalSettingOutlet } from '@core/dialogs/goal-setting-workflow/variables/outlet.variable';
import { GoalSettingService } from '@core/dialogs/goal-setting-workflow/services/goal-setting.service';
import { GoalSettingRoute } from '@core/dialogs/goal-setting-workflow/enums/goal-setting-route.enum';
import { RecommendedProduct } from '@core/dialogs/goal-setting-workflow/types/goal-setting.types';
import { DialogService } from '@core/services/dialog.service';
import { SegmentService } from '@core/services/segment.service';
import { TRACK_GOAL_SETTING } from '@core/variables/tracking-events.variable';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { StoreProductStatusService } from '@core/services/store-product-status.service';

@Component({
  selector: 'core-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private globalFilterService: GlobalFilterService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private goalSettingDialogService: GoalSettingDialogService,
    private goalSettingService: GoalSettingService,
    private storeProductStatusService: StoreProductStatusService,
    private segmentService: SegmentService,
  ) {
    this.products = this.router.getCurrentNavigation()?.extras.state?.products || [];

    if (!this.products.length) {
      this.goalSettingDialogService.navigateToDialogRoute(GoalSettingRoute.GoalSelection);
    }
  }

  selectedProduct?: Product;

  showError = false;

  actionInProgress = false;

  products: RecommendedProduct[] = [];

  onSelectHandler(productName: Product): void {
    this.selectedProduct = productName;
    this.showError = false;
    this.onSubmit();
  }

  get introMessageProducts() {
    const firstProduct = this.products[0].label;
    const secondProduct = this.products.length > 1 ? this.products[1].label : '';

    return `<span class="font-weight-bold">${this.translateService.instant(firstProduct)}</span>${
      secondProduct ? ` or <span class="font-weight-bold">${this.translateService.instant(secondProduct)}</span>` : ''
    }`;
  }

  onSubmit(): void {
    if (!this.selectedProduct) {
      this.showError = true;

      return;
    }

    sessionStorage.removeItem('Goals');
    sessionStorage.removeItem('SelectedGoals');
    this.goalSettingService.setProduct({ selectedProduct: this.selectedProduct }).pipe(first()).subscribe();
    this.dialogService.closeDialog();
    this.navigateToProduct();
  }

  goBack(): void {
    this.segmentService.trackByPage(TRACK_GOAL_SETTING.clickedGoBackButton);

    this.goalSettingDialogService.navigateToDialogRoute(GoalSettingRoute.GoalSelection);
  }

  navigateToProduct(): void {
    const url = environment.microfrontend
      ? ''
      : this.storeProductStatusService.getRouteForProduct(this.selectedProduct!);

    // workaround for well-known issue with simultaneous clearing the outlets and redirecting to url
    // https://github.com/angular/angular/issues/15338
    this.router.navigate([{ outlets: { [goalSettingOutlet]: null } }]).then(() => {
      this.router.navigate([url], { queryParams: this.route.snapshot.queryParams });
    });
  }
}
