const sizes = {
  xSmall: 0,
  small: 576,
  medium: 768,
  large: 992,
  xLarge: 1200,
  xxLarge: 1800,
};

const boundaryValue = 0.02;

export const CustomBreakpoints = {
  XSmall: `(max-width: ${sizes.small - boundaryValue}px)`,
  Small: `(min-width: ${sizes.small}px) and (max-width: ${sizes.medium - boundaryValue}px)`,
  Medium: `(min-width: ${sizes.medium}px) and (max-width: ${sizes.large - boundaryValue}px)`,
  Large: `(min-width: ${sizes.large}px) and (max-width: ${sizes.xLarge - boundaryValue}px)`,
  XLarge: `(min-width: ${sizes.xLarge}px) and (max-width: ${sizes.xxLarge - boundaryValue}px)`,
  XXLarge: `(min-width: ${sizes.xxLarge}px)`,

  Handset:
    `(max-width: ${sizes.small - boundaryValue}px) and (orientation: portrait), ` +
    `(max-width: ${sizes.medium - boundaryValue}}px) and (orientation: landscape)`,
  Tablet:
    `(min-width: ${sizes.small}px) and (max-width: ${sizes.medium - boundaryValue}}px) and (orientation: portrait), ` +
    `(min-width: ${sizes.medium}px) and (max-width: ${sizes.large - boundaryValue}px) and (orientation: landscape)`,
  Web:
    `(min-width: ${sizes.medium}px) and (orientation: portrait), ` +
    `(min-width: ${sizes.xLarge}px) and (orientation: landscape)`,

  HandsetPortrait: `(max-width: ${sizes.small - boundaryValue}px) and (orientation: portrait)`,
  TabletPortrait: `(min-width: ${sizes.small}px) and (max-width: ${
    sizes.medium - boundaryValue
  }}px) and (orientation: portrait)`,
  WebPortrait: `(min-width: ${sizes.medium}px) and (orientation: portrait)`,

  HandsetLandscape: `(max-width: ${sizes.medium - boundaryValue}}px) and (orientation: landscape)`,
  TabletLandscape: `(min-width: ${sizes.large}px) and (max-width: ${
    sizes.xLarge - boundaryValue
  }px) and (orientation: landscape)`,
  WebLandscape: `(min-width: ${sizes.xLarge}px) and (orientation: landscape)`,
};
