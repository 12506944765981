<div class="wrapper">
  <div class="line-indicator"></div>
  <div class="body">
    <div class="card-content">
      <div class="icon-container">
        <core-icon [icon]="icon" size="md"></core-icon>
        <div class="round-indicator"></div>
      </div>
      <div class="text-container">
        <div class="title">
          {{ title }}
        </div>
        <div class="text">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="actions-container" [class.ml-0]="!ctaLabel && !link && !tagConfig">
        <div class="cta-inline" *ngIf="ctaLabel || tagConfig">
          <ng-container [ngTemplateOutlet]="cta"></ng-container>
        </div>
        <a *ngIf="link" class="alert-link" coreButton variant="text-link" size="sm" [href]="link">
          {{ 'ALERT.LINK' | translate }}
        </a>
        <button
          coreIconButton
          *ngIf="dismissable"
          class="dismiss"
          icon="close"
          size="sm"
          (click)="dismiss.emit()">
        </button>
      </div>
    </div>
    <div class="cta-full-width" *ngIf="ctaLabel || tagConfig">
      <ng-container [ngTemplateOutlet]="cta"></ng-container>
    </div>
  </div>
</div>

<ng-template #cta>
  <div class="cta-container">
    <core-tag-large
      *ngIf="tagConfig"
      class="ml-auto"
      [class.mb-3]="ctaLabel"
      [variant]="tagConfig?.variant"
      [icon]="tagConfig?.icon">
      {{ tagConfig?.label }}
      <ng-container coreTagLabelSubtext>
        {{ tagConfig?.labelSubtext }}
      </ng-container>
    </core-tag-large>
    <button
      *ngIf="ctaLabel"
      coreButton
      [actionInProgress]="actionInProgress"
      class="cta"
      (click)="ctaClick.emit()">
      {{ ctaLabel }}
    </button>
  </div>
</ng-template>
