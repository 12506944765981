/**
 * List of color variables to be used for theming
 */
export const colors = {
  // Dark mode
  charcoal: {
    extraDark: '#01041A',
    dark: '#1A1D31',
    base: '#1F2235',
    light: '#26293F',
    extraLight: '#333647',
  },
  gray: {
    dark: '#434555',
    base: '#666979',
    light: '#898B95',
  },
  indigo: {
    base: '#99A8F7',
    dark: '#726BBB',
  },
  // Light mode
  blue: {
    extraDark: '#03072C',
    dark: '#00004E',
    base: '#000062',
    light: '#192294',
  },
  slate: {
    base: '#595D7D',
    light: '#888BA2',
  },
  silver: {
    base: '#CFD0DA',
    light: '#E7E8EC',
  },
  // Shared
  black: {
    extraDark: '#00031B',
    dark: '#0A0E26',
    base: '#14172B',
    light: '#1B1D32',
  },
  navy: {
    base: '#00003A',
  },
  white: {
    dark: '#F7F7FA',
    base: '#FFFFFF',
  },
  flush: {
    dark: '#C00B4D',
    base: '#DD2B54',
    light: '#E45576',
  },
  yellow: {
    dark: '#FFA40E',
    base: '#FFBE2C',
    light: '#F8C46C',
  },
  mint: {
    base: '#1BBCA4',
  },
  electric: {
    base: '#0080FF',
  },
  orange: {
    base: '#F27D42',
  },
};
