import { Directive, InjectionToken } from '@angular/core';

export const FORM_FIELD_SUFFIX = new InjectionToken<FormFieldSuffixDirective>('FormFieldSuffix');

/**
 * Suffix to be placed at the end of the form field.
 */
@Directive({
  selector: '[coreFormFieldSuffix]',
  providers: [
    {
      provide: FORM_FIELD_SUFFIX,
      useExisting: FormFieldSuffixDirective,
    },
  ],
})
export class FormFieldSuffixDirective {}
