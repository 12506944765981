import { Injectable } from '@angular/core';
import { ClientViewDataAdapter } from '@core/interfaces/adapter.interface';
import { Nullable } from '../types/nullable.type';

/**
 * Frontend model for the CS Manager
 */
export class CSManagerInfo {
  email: string;

  name: string;

  phone: string;

  photoUrl: Nullable<string>;

  title: string;

  constructor(object: CSManagerInfo) {
    this.email = object.email;
    this.name = object.name;
    this.phone = object.phone;
    this.photoUrl = object.photoUrl;
    this.title = object.title;
  }
}

/**
 * Adapter for the CS Manager
 */
@Injectable({
  providedIn: 'root',
})
export class CSManagerInfoAdapter implements ClientViewDataAdapter<CSManagerInfo> {
  clientAdapt(object: any): CSManagerInfo {
    return new CSManagerInfo(object);
  }
}
