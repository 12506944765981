import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Params,
} from '@angular/router';
import { NAVIGATION_EVENT } from '@core/variables/webview-events.variable';
import { AUTH_ROUTE } from '@core/modules/auth/variables/routing.variable';
import { MessageEventService } from './message-event.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  isWebView = this.messageEventService.isWebView;

  navigating$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private messageEventService: MessageEventService,
  ) {
    router.events.subscribe((routerEvent: any) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  /**
   * Checks the current router event
   * @param routerEvent the router event
   */
  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.navigating$.next(true);
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.navigating$.next(false);
    }
  }

  /**
   * Navigate to the register page
   * @param redirectUrl the url to navigate to
   * @param queryParams the query params to send
   */
  public navigateToRegister(redirectUrl?: string, queryParams: Params = {}): void {
    if (redirectUrl) {
      this.router.navigate([`/${AUTH_ROUTE.prefix}/${AUTH_ROUTE.pages.register}`], {
        queryParams: { ...queryParams, redirect: redirectUrl },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([`/${AUTH_ROUTE.prefix}/${AUTH_ROUTE.pages.register}`], {
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * Navigate to the login page
   * @param redirectUrl the url to navigate to
   * @param queryParams the query params to send
   */
  public navigateToLogin(redirectUrl?: string, queryParams: Params = {}): void {
    if (redirectUrl) {
      this.router.navigate([`/${AUTH_ROUTE.prefix}/${AUTH_ROUTE.pages.login}`], {
        queryParams: { ...queryParams, redirect: redirectUrl },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([`/${AUTH_ROUTE.prefix}/${AUTH_ROUTE.pages.login}`], {
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * Navigate to the home page
   * @param queryParams the query params to send
   */
  public navigateToHome(queryParams: Params = {}) {
    this.router.navigate(['/'], { queryParams, queryParamsHandling: 'merge' });
  }

  /**
   * Navigate to the given string url page
   * else navigate to home
   * @param redirectUrl the url to navigate to
   * @param queryParams the query params to send
   */
  public navigateToRedirect(redirectUrl?: string, queryParams: Params = {}): void {
    if (redirectUrl) {
      this.router.navigate([decodeURIComponent(redirectUrl)], { queryParams, queryParamsHandling: 'merge' });

      return;
    }
    this.navigateToHome(queryParams);
  }

  /**
   * Navigate to an absolute url
   * @param url the url to navigate to
   */
  public navigateByUrl(url: string): void {
    const { queryParams } = this.router.routerState.snapshot.root;
    this.router.navigate([url], { queryParams, queryParamsHandling: 'merge' });
  }

  /**
   * Emits an event to navigate natively if a web view is being used,
   * otherwise, navigates using the Angular routing
   * @param url the url to navigate to
   * @param webViewParams additional params to send to the web view
   */
  navigateOrEmitEvent(url: string, webViewParams: Params = {}): void {
    const { isWebView } = this.messageEventService;

    if (isWebView) {
      this.messageEventService.postMessageToWebView(NAVIGATION_EVENT.webViewPageChanged, {
        url,
        queryParams: webViewParams,
      });
    } else {
      const { queryParams } = this.router.routerState.snapshot.root;
      const prefix = webViewParams?.prefix;
      // Strip the prefix for standalone application
      const navigateTo = environment.microfrontend && prefix ? url.substring(url.indexOf(prefix) + prefix.length) : url;
      this.router.navigate([navigateTo], { queryParams, queryParamsHandling: 'merge' });
    }
  }
}
