import { Nullable } from '@core/types/nullable.type';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { AccountDetails } from '@core/interfaces/integration.interface';
import * as activeIcons from '@core/ui/platform/variables/icons-active.variable';
import { ProviderAccountColumn } from '@core/dialogs/integration-workflow/types/provider-account.type';
import { Subject } from 'rxjs';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { CustomBreakpoints } from '@core/variables/custom-breakpoints.variable';

@Component({
  selector: 'core-provider-account-desktop-item',
  templateUrl: './provider-account-desktop-item.component.html',
  styleUrls: ['./provider-account-desktop-item.component.scss'],
})
export class ProviderAccountDesktopItemComponent implements OnChanges, OnDestroy {
  icons = activeIcons;

  @Input()
  account: Nullable<AccountDetails> = null;

  @Input()
  visibleColumns: Record<ProviderAccountColumn, boolean> = {
    name: true,
    rating: false,
    followers: false,
    location: false,
  };

  @Input()
  bestFit = false;

  nameColumnClass = ['col-12'];

  locationColumnClass = ['col-12'];

  metricColumnClass = ['col-12'];

  metricInnerColumnClass = ['col-12'];

  get starCount() {
    return this.visibleColumns.followers || this.isSmallScreen ? 1 : 5;
  }

  destroyed$ = new Subject<void>();

  isSmallScreen = false;

  constructor(private breakpointObserverService: BreakpointObserverService) {
    this.breakpointObserverService.observeBreakpoints(['xs', 'sm', 'md'], (state) => {
      this.isSmallScreen =
        state.breakpoints[CustomBreakpoints.XSmall] ||
        state.breakpoints[CustomBreakpoints.Small] ||
        state.breakpoints[CustomBreakpoints.Medium];
    });
  }

  ngOnChanges(): void {
    this.initClasses();
  }

  private initClasses(): void {
    const metricsColumnCount = [this.visibleColumns.followers, this.visibleColumns.rating].filter(Boolean).length;
    this.metricInnerColumnClass = metricsColumnCount
      ? ['col-12', `col-md-${12 / metricsColumnCount} py-md-0`]
      : ['col-12'];

    const hasMetrics = !!metricsColumnCount;
    const hasLocation = this.visibleColumns.location;

    if (hasMetrics || hasLocation) {
      if (hasLocation && hasMetrics) {
        this.nameColumnClass = ['col-12', 'col-sm-6', 'col-md-6'];
        this.metricColumnClass = ['col-12', 'col-sm-2', 'col-md-3'];
        this.locationColumnClass = ['col-12', 'col-sm-4', 'col-md-3'];
      } else if (hasLocation) {
        this.nameColumnClass = ['col-12', 'col-sm-6', 'col-md-8'];
        this.locationColumnClass = ['col-12', 'col-sm-6', 'col-md-4'];
      } else {
        this.nameColumnClass = ['col-12', 'col-sm-8'];
        this.metricColumnClass = ['col-12', 'col-sm-4'];
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
