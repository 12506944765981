<div
  class="star-rating star-rating--{{ size }} flex-{{ direction }}"
  [ngClass]="{
    'star-rating--vertical': direction === 'column',
    'star-rating--inherit': inheritFontStyle
  }">
  <div class="star-rating__rating-value m-0" *ngIf="showNumber">
    {{ rating ? (rating | number : numberFormat) : 0.0 }}
  </div>
  <div class="star-rating__star-wrapper" [class.ml-1]="showNumber" *ngIf="showStars">
    <core-star
      *ngFor="let percentage of starsList; let last = last"
      [starPercentage]="percentage"
      [ngClass]="{ 'last-star': last }"
      class="star-rating__star">
    </core-star>
  </div>
</div>
