import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { ApiSuccessResponse } from '@core/models/api-success-response.model';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import {
  ManagedPostListFiltersQueryParams,
  CreateManagedPostBody,
  RejectManagedPostBody,
  UpdateManagedPostBody,
} from '../interfaces/managed-post-request.interface';
import { ManagedPost, ManagedPostAdapter } from '../models/managed-post.model';

@Injectable({
  providedIn: 'root',
})
export class ManagedPostDataService {
  constructor(
    private apiService: ApiService,
    private managedPostAdapter: ManagedPostAdapter,
  ) {}

  /**
   * Fetches a list of managed posts created by our internal scheduling system
   * @param storeId the store id
   */
  getPostList(
    storeId: string,
    queryParams: ManagedPostListFiltersQueryParams = {},
  ): Observable<ApiSuccessResponse<ManagedPost[]>> {
    const params = new HttpParams({
      fromObject: {
        ...queryParams,
      },
    });

    return this.apiService.get(`stores/${storeId}/socials/managed-posts`, params).pipe(
      map((res: ApiSuccessResponse<ManagedPost[]>) => ({
        ...res,
        result: (res.result || []).map((item) => this.managedPostAdapter.clientAdapt(item)),
      })),
    );
  }

  /**
   * Fetches a single managed post created by our internal scheduling system
   * @param storeId the store id
   * @param postId the id of the post
   */
  getPost(storeId: string, postId: string): Observable<ApiSuccessResponse<ManagedPost>> {
    return this.apiService.get(`stores/${storeId}/socials/managed-posts/${postId}`).pipe(
      map((res: ApiSuccessResponse<ManagedPost[]>) => ({
        ...res,
        result: this.managedPostAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Creates a new managed post on our internal scheduling system
   * @param storeId the store id
   * @param body the request body
   */
  createPost(storeId: string, body: CreateManagedPostBody): Observable<ApiSuccessResponse<ManagedPost>> {
    const formData = new FormData();
    formData.append('storeId', storeId);

    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        if (key === 'files') {
          (value as File[]).forEach((file) => {
            formData.append('files', file, file.name || new Date().toISOString());
          });
        } else {
          formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
        }
      }
    });

    return this.apiService.post(`stores/${storeId}/socials/managed-posts`, formData).pipe(
      map((res: ApiSuccessResponse<ManagedPost>) => ({
        ...res,
        result: this.managedPostAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Updates an existing managed post on our internal scheduling system
   * @param storeId the store id
   * @param postId the id of the post
   * @param body the request body
   */
  updatePost(
    storeId: string,
    postId: string,
    body: UpdateManagedPostBody,
  ): Observable<ApiSuccessResponse<ManagedPost>> {
    const formData = new FormData();
    formData.append('storeId', storeId);

    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
    });

    return this.apiService.put(`stores/${storeId}/socials/managed-posts/${postId}`, formData).pipe(
      map((res: ApiSuccessResponse<ManagedPost>) => ({
        ...res,
        result: this.managedPostAdapter.clientAdapt(res.result),
      })),
    );
  }

  /**
   * Reject an existing managed post on our internal scheduling system
   * @param storeId the store id
   * @param postId the id of the post
   * @param body the request body
   */
  rejectPost(storeId: string, postId: string, body: RejectManagedPostBody): Observable<{ status: string }> {
    return this.apiService.post(`stores/${storeId}/socials/managed-posts/${postId}/reject`, body);
  }

  /**
   * Publishes a post immediately
   * @param storeId the store id
   * @param postId the id of the post
   */
  publishPost(storeId: string, postId: string): Observable<ApiSuccessResponse<ManagedPost>> {
    return this.apiService.post(`stores/${storeId}/socials/managed-posts/${postId}/publish`).pipe(
      map((res: ApiSuccessResponse<ManagedPost>) => ({
        ...res,
        result: this.managedPostAdapter.clientAdapt(res.result),
      })),
    );
  }
}
