import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IconRegistryService } from '@core/services/icon-registry.service';
import { debounceTime, map } from 'rxjs';

@Component({
  selector: 'core-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent {
  @Input()
  svg = '';

  destroyRef = inject(DestroyRef);

  constructor(private iconRegistryService: IconRegistryService) {
    this.iconRegistryService.iconsUpdated$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => {
          const { svg } = this;
          this.svg = null!;

          return svg;
        }),
        debounceTime(0),
      )
      .subscribe((svg) => {
        this.svg = svg;
      });
  }
}
