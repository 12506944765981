import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const bottomSheetAnimation = trigger('bottomSheetAnimation', [
  state('void, hidden', style({ transform: 'translateY(100%)' })),
  state('visible', style({ transform: 'translateY(0%)' })),
  transition(
    'visible => void, visible => hidden',
    animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`),
  ),
  transition('void => visible', animate(`${AnimationDurations.EXITING} ${AnimationCurves.DECELERATION_CURVE}`)),
]);

export const zoomInOutAnimation = trigger('zoomInOutAnimation', [
  state('void, hidden', style({ transform: 'scale(0)', opacity: 0 })),
  state('visible', style({ transform: 'scale(1)', opacity: 1 })),
  transition(
    'visible => void, visible => hidden',
    animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`),
  ),
  transition('void => visible', animate(`${AnimationDurations.EXITING} ${AnimationCurves.DECELERATION_CURVE}`)),
]);
