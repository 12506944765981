<div class="d-flex rating" #container>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="popupVisible"
    [cdkConnectedOverlayPositionStrategy]="positionStrategy"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (positionChange)="$event.offsetY = 0; $event.offsetX = 0"
    (detach)="popupVisible = false">
    <div #ratingOverlay class="rating__feedback">
      <div class="d-flex align-items-center justify-content-around">
        <button
          coreIconButton
          icon="positive"
          (click)="sendFeedback('like', 1); popupVisible = false"
          variant="light">
        </button>
        <button
          coreIconButton
          icon="negative"
          (click)="sendFeedback('dislike', -1); popupVisible = false"
          variant="light">
        </button>
      </div>
    </div>
  </ng-template>
  
  <button
    #trigger="cdkOverlayOrigin" 
    cdkOverlayOrigin
    coreIconButton
    [icon]="icon"
    [actionInProgress]="actionInProgress"
    placeholderIcon="positive"
    [shadow]="true"
    (touchstart)="popupVisible = !popupVisible"
    (click)="popupVisible = true"
    variant="light">
  </button>
</div>
