import { SafeResourceUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardRoundness } from '@core/ui/card/types/common.type';
import { Nullable } from '@core/types/nullable.type';
import { ImageFit, ImageUrlOptions } from '../../types/image.type';
import { MediaSrcVariant, CrossOrigin, MediaErrorVariant, MediaVariant } from '../../types/media.type';

@Component({
  selector: 'core-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss'],
})
export class MediaItemComponent {
  @Input()
  roundness: CardRoundness = 'none';

  @Input()
  variant: MediaVariant = 'IMAGE';

  @Input()
  errorVariant: MediaErrorVariant = 'image-text';

  @Input()
  src: SafeResourceUrl | string = '';

  @Input()
  thumbnail: SafeResourceUrl | string = '';

  @Input()
  videoSrcVariant: MediaSrcVariant = 'url';

  @Input()
  imageSrcVariant: MediaSrcVariant = 'url';

  @Input()
  imageUrlOptions: ImageUrlOptions = {};

  @Input()
  imageFit: ImageFit = 'contain';

  @Input()
  autoplayVideo = false;

  @Input()
  crossOrigin: Nullable<CrossOrigin> = null;

  @Output()
  actionChange = new EventEmitter();

  @Output()
  error = new EventEmitter();

  @Output()
  loaded = new EventEmitter();

  @Output()
  fallbackLoaded = new EventEmitter();

  hasError = false;
}
