/* eslint-disable no-plusplus */
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@core/types/nullable.type';
import {
  ToggleContentAlignment,
  ToggleLabelPadding,
  ToggleLabelPosition,
  ToggleVariant,
} from '../../types/toggle.type';

let uniqueId = 0;

@Component({
  selector: 'core-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
  host: {
    '[class]': `"core-toggle"
      + " label-position-"+labelPosition
      + " variant-"+variant`,
    '[id]': 'id',
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[class.core-toggle--checked]': 'checked',
    '[class.core-toggle--disabled]': 'disabled',
  },
  inputs: ['disabled', 'tabIndex', 'checked'],
})
export class ToggleComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @ViewChild('input')
  inputElement!: ElementRef<HTMLInputElement>;

  @Input()
  disabled = false;

  @Input()
  name: string | null = null;

  @Input()
  id: string | null = `core-toggle-${++uniqueId}`;

  @Input()
  required = false;

  @Input()
  variant: ToggleVariant = 'emphasis';

  @Input()
  set value(checked: boolean) {
    this.checked = checked;
    this.onChange(checked);
    this.changeDetectorRef.markForCheck();
  }

  @Input()
  labelPosition: ToggleLabelPosition = 'before';

  /**
   * The padding size on the label
   *
   * Adding the padding inside the label ensures that it is included in the click area
   * for changing the checked value
   */
  @Input()
  get labelPadding(): Nullable<ToggleLabelPadding> {
    return this._labelPadding;
  }

  set labelPadding(value) {
    this._labelPadding = value;
    const classes: string[] = [];
    if (value instanceof Object) {
      Object.entries(value).forEach(([position, padding]) => {
        classes.push(`padding-${position}-${padding}`);
      });
    } else {
      classes.push(`padding-${value}`);
    }
    this.labelPaddingClassList = classes;
  }

  private _labelPadding: Nullable<ToggleLabelPadding> = null;

  /**
   * Padding classes to be added to the label
   */
  labelPaddingClassList: string[] = [];

  @Input()
  multiLineLabel = false;

  @Input()
  contentAlignment: ToggleContentAlignment = 'center';

  @Input('aria-label')
  ariaLabel: string | null = null;

  @Input('aria-labelledby')
  ariaLabelledby: string | null = null;

  @Input('aria-describedby')
  ariaDescribedby: string | null = null;

  @Output()
  change = new EventEmitter<boolean>();

  get inputId(): string {
    return `${this.id}-input`;
  }

  checked = false;

  tabIndex = 0;

  onChange: any;

  onTouch: any;

  constructor(
    private elementRef: ElementRef,
    private focusMonitor: FocusMonitor,
    private changeDetectorRef: ChangeDetectorRef,
    @Attribute('tabindex') tabIndex: string,
  ) {
    this.tabIndex = parseInt(tabIndex, 10) || 0;
    this.onChange = () => {};
    this.onTouch = () => {};
  }

  ngAfterContentInit(): void {
    this.focusMonitor.monitor(this.elementRef, true).subscribe((focusOrigin) => {
      if (!focusOrigin) {
        Promise.resolve().then(() => this.onTouch());
      }
    });
  }

  writeValue(value: any): void {
    this.value = !!value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  toggleChecked(event: Event): void {
    event?.stopPropagation();
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onChange(this.checked);
      this.change.emit(this.checked);
    }
  }

  focus(options?: FocusOptions, origin?: FocusOrigin): void {
    if (origin) {
      this.focusMonitor.focusVia(this.inputElement, origin, options);
    } else {
      this.inputElement.nativeElement.focus(options);
    }
  }

  onInputClick(event: Event) {
    event?.stopPropagation();
  }

  onLabelChange(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
  }
}
