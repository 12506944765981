<div class="button-content" matRipple>
  <ng-container *ngIf="!actionInProgress; else spinnerTemplate">
    <core-icon
      *ngIf="icon"
      [icon]="icon"
      [size]="iconSize">
    </core-icon>
    <span class="button-label">
      <ng-content></ng-content>
    </span>
  </ng-container>
  <ng-template #spinnerTemplate>
    <core-spinner [diameter]="spinnerSize" [variant]="spinnerVariant"></core-spinner>
  </ng-template>
</div>
