import { takeUntil } from 'rxjs/operators';
import { IntercomService } from '@core/services/intercom.service';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegrationDialogConfig } from '@core/dialogs/integration-workflow/interfaces/dialog-config.interface';
import { IntegrationDialogService } from '@core/dialogs/integration-workflow/services/integration-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DialogService } from '@core/services/dialog.service';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { ManagedPostProvider } from '@core/dialogs/post-manager/enums/managed-post-provider.enum';

@Component({
  selector: 'core-auth-integration-empty-dialog',
  templateUrl: './integration-empty-dialog.component.html',
  styleUrls: ['./integration-empty-dialog.component.scss'],
})
export class IntegrationEmptyDialogComponent implements OnDestroy {
  selectedStore = this.globalFilterService.store;

  isContactSupportVisible = this.intercomService.available;

  redirectLink = '';

  title = '';

  text = '';

  destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IntegrationDialogConfig,
    private integrationDialogService: IntegrationDialogService,
    private intercomService: IntercomService,
    private globalFilterService: GlobalFilterService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {
    this.getRedirectLink();
    this.initDialogText();
  }

  /**
   * Sets the provider-specific texts or the default fallback texts if none is defined
   */
  initDialogText(): void {
    this.translateService
      .get(`INTEGRATION_DIALOG.CONNECT_DIALOG_EMPTY`)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const providerTitle = `INTEGRATION_DIALOG.CONNECT_DIALOG_EMPTY.TITLE.${this.data.provider}`;
        const providerText = `INTEGRATION_DIALOG.CONNECT_DIALOG_EMPTY.TEXT_PROVIDER.${this.data.provider}`;

        this.title =
          providerTitle !== this.translateService.instant(providerTitle)
            ? this.translateService.instant(providerTitle)
            : this.translateService.instant('INTEGRATION_DIALOG.CONNECT_DIALOG_EMPTY.TITLE.DEFAULT');

        if (this.isOAuthProvider) {
          this.text =
            providerText !== this.translateService.instant(providerText)
              ? this.translateService.instant(providerText, { redirectLink: this.redirectLink })
              : this.translateService.instant('INTEGRATION_DIALOG.CONNECT_DIALOG_EMPTY.TEXT', {
                  redirectLink: this.redirectLink,
                });
        } else {
          this.text = this.translateService.instant('INTEGRATION_DIALOG.CONNECT_DIALOG_EMPTY.TEXT_NONE_OAUTH');
        }
      });
  }

  getRedirectLink() {
    this.redirectLink = `${this.data.integration?.authenticationMethod.link}${this.data.queryParamsString?.replace(
      '?',
      '&',
    )}`;
  }

  get isOAuthProvider() {
    switch (this.data.provider) {
      case ManagedPostProvider.Facebook:
      case ManagedPostProvider.Google:
      case ManagedPostProvider.Instagram:
        return true;
      default:
        return false;
    }
  }

  contactSupport(): void {
    this.intercomService.show();
  }

  onCancel(): void {
    this.integrationDialogService.closeDialog();
    this.dialogService.closeDialog();
  }

  onSubmit(): void {
    this.integrationDialogService.closeDialog();
    this.dialogService.closeDialog();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
