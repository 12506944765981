import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import { PostDateFormat } from '../../types/post-date.type';

@Component({
  selector: 'core-post-date',
  templateUrl: './post-date.component.html',
  styleUrls: ['./post-date.component.scss'],
})
export class PostDateComponent {
  private _date = '';

  today = false;

  @Input()
  set date(value: string) {
    if (value && value !== this.date) {
      this._date = value;
      const date = dayjs(value);
      const now = dayjs();
      this.today = date.isSame(now, 'day');
    }
  }

  get date(): string {
    return this._date;
  }

  @Input()
  format: PostDateFormat = 'datetime';

  locale = dayjs().locale();
}
