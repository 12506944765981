import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@core/types/nullable.type';
import { CardRoundness } from '@core/ui/card/types/common.type';
import { CarouselOptions, MediaConfig } from '@core/ui/media/types/carousel.type';
import { ImageFit } from '@core/ui/media/types/image.type';
import { MediaErrorVariant, MediaVariant } from '@core/ui/media/types/media.type';
import isEqual from 'lodash/isEqual';

enum ResourceType {
  Photo = 'PHOTO',
  Video = 'VIDEO',
}

type Resource = {
  media: {
    url: string;
    thumbnail: Nullable<string>;
  };
  meta: {
    size: Nullable<number>;
    width: Nullable<number>;
    height: Nullable<number>;
  };
  type: ResourceType;
};

const defaultCarouselOptions: CarouselOptions = {
  pagination: {
    dynamicBullets: true,
    clickable: true,
  },
  autoplay: {
    disableOnInteraction: true,
    delay: 5000,
  },
  loop: true,
};

@Component({
  selector: 'core-post-media',
  templateUrl: './post-media.component.html',
  styleUrls: ['./post-media.component.scss'],
})
export class PostMediaComponent {
  @Input()
  slides: MediaConfig[] = [];

  @Input()
  slideIndex = 0;

  @Input()
  set resources(value: Resource[]) {
    if (value?.length && value?.every(({ media }, index) => media.url !== this.slides?.[index]?.src)) {
      this.slides = value.map((item) => ({
        src: item.media.url,
        thumbnail: item.media.thumbnail!,
        variant: item.type === ResourceType.Video ? 'VIDEO' : 'IMAGE',
      }));
    }
  }

  @Input()
  variant?: MediaVariant;

  @Input()
  errorVariant?: MediaErrorVariant;

  @Input()
  autoplayVideo = false;

  @Input()
  imageFit: ImageFit = 'contain';

  @Input()
  set carouselOptions(value: Nullable<CarouselOptions>) {
    const carouselOptions = {
      ...defaultCarouselOptions,
      ...(value ?? {}),
    };
    if (value && !isEqual(carouselOptions, this._carouselOptions)) {
      this._carouselOptions = carouselOptions;
    }
  }

  get carouselOptions(): Nullable<CarouselOptions> {
    return this._carouselOptions;
  }

  private _carouselOptions: Nullable<CarouselOptions> = {
    ...defaultCarouselOptions,
  };

  @Input()
  roundness?: CardRoundness;

  @Output()
  loaded = new EventEmitter();

  @Output()
  fallbackLoaded = new EventEmitter();

  @Output()
  error = new EventEmitter();

  @Output()
  actionChange = new EventEmitter();

  @Output()
  slideChange = new EventEmitter();
}
