import { Nullable } from '@core/types/nullable.type';
import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';

/**
 * Frontend model for Expected Revenue Per Visit
 */
export class ExpectedRevenuePerVisit {
  expectedRevenuePerVisit!: Nullable<number>;

  constructor(object?: Partial<ExpectedRevenuePerVisit>) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for Expected Revenue Per Visit
 */
@Injectable({
  providedIn: 'root',
})
export class ExpectedRevenuePerVisitAdapter implements Adapter<ExpectedRevenuePerVisit> {
  clientAdapt(object: any): ExpectedRevenuePerVisit {
    return new ExpectedRevenuePerVisit(object);
  }

  serverAdapt(object: ExpectedRevenuePerVisit): ExpectedRevenuePerVisit {
    return object;
  }
}
