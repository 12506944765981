import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { DocumentBase } from '@core/interfaces/document.interface';
import { Nullable } from '@core/types/nullable.type';

export interface InsightsGeneralMetrics {
  costs: InsightsCostsMetrics;
  exposure: InsightsExposureMetrics;
  clicks: InsightsClicksMetrics;
}

// NOTE: Non existing cost values (e.g. there are no clicks so there is no CPC) are handled as null instead of 0 to allow proper aggregations in the future
export interface InsightsCostsMetrics {
  spend: number;
  cpc: Nullable<number>; // cost per click
  cpm: Nullable<number>; // cost per thousand impressions
  cpp: Nullable<number>; // cost per thousand impressions
  cpi: Nullable<number>; // cost per interaction
  cpe: Nullable<number>; // cost per engagement (video)
  cpv: Nullable<number>; // cost per view (video)
}

export interface InsightsExposureMetrics {
  impressions: number;
  reach: Nullable<number>;
  frequency: Nullable<number>;
}

export interface InsightsClicksMetrics {
  clicks: number;
  ctr: number;
  uniqueClicks: Nullable<number>;
  uniqueCtr: Nullable<number>;
  urlClicks: Nullable<number>; // Click on Headline
  calls: Nullable<number>; // Mobile clicks-to-call
  sitelinks: Nullable<number>; // Click on sitelink
  getDirections: Nullable<number>; // Click on driving direction
  locationExpansion: Nullable<number>; // Click on location details
  storeLocator: Nullable<number>; // Click on show nearby locations.
  appDeeplink: Nullable<number>; // App engagement ad deep link
  unknown: Nullable<number>; // Clicks that are not categorized due to different api versions
  uncategorized: Nullable<number>; // All other clicks that do not fall in the categories above
}

/**
 * Frontend model for general campaign insights
 */
export class CampaignInsightsGeneral<T = unknown> implements DocumentBase {
  _id!: string;

  createdAt!: string;

  updatedAt!: string;

  date!: string;

  provider!: string;

  providerCampaignId!: string;

  metrics!: InsightsGeneralMetrics;

  raw!: Nullable<T>;

  constructor(object: Partial<CampaignInsightsGeneral>) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for general campaign insights
 */
@Injectable({
  providedIn: 'root',
})
export class CampaignInsightsGeneralAdapter implements Adapter<CampaignInsightsGeneral> {
  clientAdapt(object: any): CampaignInsightsGeneral {
    return new CampaignInsightsGeneral(object);
  }

  serverAdapt(object: any): CampaignInsightsGeneral {
    return new CampaignInsightsGeneral(object);
  }
}
