import { DateAdapter } from '@angular/material/core';
import dayjs, { Dayjs } from 'dayjs';
import deLocale from 'dayjs/locale/de';
import enLocale from 'dayjs/locale/en-gb';

export const MAT_DAYJS_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class MaterialDayjsDateAdapter extends DateAdapter<any> {
  private localeData?;

  constructor(dateLocale: string) {
    super();
    this.setLocale(dateLocale);
  }

  override setLocale(locale: string) {
    super.setLocale(locale);
    switch (locale) {
      case 'de':
      case 'de-DE':
        this.loadLocaleDataModule(deLocale);
        break;
      case 'en-US':
      case 'en-GB':
      case 'en':
        this.loadLocaleDataModule(enLocale);
        break;
      default:
        throw Error(`${locale} locale loading error`);
    }
  }

  loadLocaleDataModule(mod) {
    this.localeData = {
      firstDayOfWeek: mod.weekStart,
      longMonths: mod.months,
      shortMonths: mod.monthsShort,
      dates: this.range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: mod.weekdays,
      shortDaysOfWeek: mod.weekdaysShort,
      narrowDaysOfWeek: mod.weekdaysMin,
    };
  }

  getHour(date: Dayjs) {
    return date.get('hour');
  }

  override deserialize(value: string) {
    if (!value) {
      return null;
    }

    const valueConverted = dayjs(value);

    if (!valueConverted || (this.isDateInstance(valueConverted) && this.isValid(valueConverted))) {
      return valueConverted;
    }

    return this.invalid();
  }

  getMinute(date: Dayjs) {
    return date.get('minute');
  }

  getSecond(date: Dayjs) {
    return date.get('second');
  }

  getYear(date: Dayjs) {
    return date.get('year');
  }

  getMonth(date: Dayjs) {
    return date.get('month');
  }

  getDate(date: Dayjs) {
    return date.get('date');
  }

  getDayOfWeek(date: Dayjs) {
    return date.get('day');
  }

  getMonthNames(style: 'long' | 'short' | 'narrow') {
    return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
  }

  getDateNames() {
    return this.localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    if (style === 'long') {
      return this.localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this.localeData.shortDaysOfWeek;
    }

    return this.localeData.narrowDaysOfWeek;
  }

  getYearName(date: Dayjs) {
    return this.getYear(date).toString();
  }

  getFirstDayOfWeek() {
    return this.localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: Dayjs) {
    return date.daysInMonth();
  }

  clone(date: Dayjs) {
    return dayjs(
      `${this.getYear(date)}-${this.getMonth(date) + 1}-${this.getDate(date)} ${this.getHour(date)}:${this.getMinute(
        date,
      )}:${this.getSecond(date)}`,
    );
  }

  createDate(year: number, month: number, date: number) {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = dayjs(`${year}-${month + 1}-${date}`);

    if (!dayjs(`${year}-${month + 1}-${date}`).isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  today() {
    return dayjs();
  }

  parse(value: any, parseFormat: string) {
    if (value && typeof value === 'string') {
      if (value.length === parseFormat.length) {
        return dayjs(value, parseFormat);
      }

      return null;
    }

    return value ? dayjs(value) : null;
  }

  format(date: Dayjs, displayFormat) {
    if (!this.isValid(date)) {
      throw Error('DateAdapter: Cannot format invalid date.');
    }

    return date.format(displayFormat);
  }

  addCalendarYears(date: Dayjs, years: number) {
    return date.add(years, 'year');
  }

  addCalendarMonths(date: Dayjs, months: number) {
    return date.add(months, 'month');
  }

  addCalendarDays(date: Dayjs, days: number) {
    return date.add(days, 'day');
  }

  toIso8601(date: Dayjs) {
    return date.toISOString();
  }

  isDateInstance(obj) {
    return dayjs.isDayjs(obj);
  }

  isValid(date: Dayjs) {
    return date.isValid();
  }

  invalid() {
    return dayjs('Invalid Date');
  }

  range(length, valueFunction) {
    return Array.from({ length }, (_, i) => valueFunction(i));
  }
}
