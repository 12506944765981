import { TranslateService } from '@ngx-translate/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, first } from 'rxjs/operators';
import { IntegrationDialogConfig } from '@core/dialogs/integration-workflow/interfaces/dialog-config.interface';
import { IntegrationDialogService } from '@core/dialogs/integration-workflow/services/integration-dialog.service';
import { IntegrationDataService } from '@core/dialogs/integration-workflow/services/integration-data.service';
import { AuthType } from '@core/enums/provider.enum';
import { SnackBarService } from '@core/services/snack-bar.service';
import { EMPTY } from 'rxjs';
import { AccountDetails, Credential } from '@core/interfaces/integration.interface';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { SegmentService } from '@core/services/segment.service';
import { TRACK_INTEGRATIONS } from '@core/dialogs/integration-workflow/variables/tracking.variable';
import { AuthService } from '@core/services/auth.service';
import { GlobalFilterService } from '@core/modules/filter/services/global-filter.service';
import { ActivatedRoute } from '@angular/router';
import { Nullable } from '@core/types/nullable.type';
import { Store } from '@core/models/store.model';
import { IntegrationDataHelperService } from '@core/dialogs/integration-workflow/services/integration-data-helper.service';

@Component({
  selector: 'core-auth-integration-connect-dialog',
  templateUrl: './auth-integration-connect-dialog.component.html',
  styleUrls: ['./auth-integration-connect-dialog.component.scss'],
})
export class AuthIntegrationConnectDialogComponent {
  selectedStore: Nullable<Store> = null;

  showErrorMessage = false;

  actionInProgress = false;

  accounts: AccountDetails[] = [];

  selectableAccounts: AccountDetails[] = [];

  selectedAccounts: AccountDetails[] = [];

  showSelectable = true;

  loading = false;

  providerId = '';

  selfConnected = !this.authService.isSessionLogin();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IntegrationDialogConfig,
    private integrationDataService: IntegrationDataService,
    private integrationDataHelperService: IntegrationDataHelperService,
    private integrationDialogService: IntegrationDialogService,
    private globalFilterService: GlobalFilterService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private segmentService: SegmentService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const storeId = this.activatedRoute.snapshot.queryParamMap.get('store_id');
    const store = this.globalFilterService.storeList.find(({ _id }) => _id === storeId);
    if (store) {
      this.globalFilterService.store = store;
    }
    this.selectedStore = this.globalFilterService.store;
    this.loading = true;
    this.createAuthentication();
  }

  showErrorSnackbar(showErrorMessage: string) {
    this.snackBarService.openSnackBar({
      variant: 'error',
      icon: 'danger',
      text: this.translateService.instant(showErrorMessage),
      duration: 10000,
      dismissable: true,
    });
  }

  authErrorHandler(error: { code: number }) {
    this.loading = false;
    this.segmentService.track(TRACK_INTEGRATIONS.failedToConnect(this.data.integration?.provider!), {
      provider: this.data.integration?.provider,
      storeId: this.selectedStore?._id,
      error,
      selfConnected: this.selfConnected,
    });
    if (error?.code === 403) {
      this.integrationDialogService.navigateToDialogRoute(AuthType.OAUTH, this.data.provider, 'unauthorized');
    } else {
      this.integrationDialogService.navigateToDialogRoute(AuthType.OAUTH, this.data.provider, 'empty');
    }

    return EMPTY;
  }

  showSelectableLocation(show: boolean) {
    this.showSelectable = show;
    this.selectedAccounts = show ? this.selectableAccounts : this.accounts;
  }

  createAuthData(authType: string) {
    let authTokenId: string | null = '';
    let credential: Credential[] | null = null;
    let decodedJWT: any;
    const cookie = this.cookieService.get(
      `${this.data.integration?.authenticationMethod.provider.name}_${this.data.integration?.authenticationMethod.provider.client}_jwt`,
    );
    switch (authType) {
      case 'OAUTH':
        if (cookie) {
          decodedJWT = jwtDecode(cookie);
          authTokenId = decodedJWT.tokenId;
        }
        credential = null;
        break;

      default:
        authTokenId = null;
        credential = [
          {
            key: 'API_KEY',
            value: {
              id: '',
              type: '',
            },
          },
        ];
        break;
    }

    return { authTokenId, credential };
  }

  checkJwtToken() {
    const cookieName = `${this.data.integration?.authenticationMethod.provider.name}_${this.data.integration?.authenticationMethod.provider.client}_jwt`;
    const jwtCookie = this.cookieService.get(cookieName);
    if (!jwtCookie) {
      this.loading = false;
      this.integrationDialogService.navigateToDialogRoute(AuthType.OAUTH, this.data.provider, 'empty');
      this.segmentService.track(TRACK_INTEGRATIONS.failedToConnect(this.data.integration?.provider!), {
        provider: this.data.integration?.provider,
        storeId: this.selectedStore?._id,
        error: 'JWT cookie not found',
        selfConnected: this.selfConnected,
      });
    }
  }

  createAuthentication() {
    this.checkJwtToken();
    const authenticationData = this.createAuthData(this.data.integration?.authenticationMethod.type || '');
    this.integrationDataService
      .createAuthenticationforUser(this.data.integration?._id || '', this.selectedStore?._id!, authenticationData)
      .pipe(
        first(),
        catchError((err) => this.authErrorHandler(err)),
      )
      .subscribe(() => {
        this.getConnectedAccounts();
      });
  }

  getConnectedAccounts() {
    this.integrationDataService
      .getUserAccounts(this.data.integrationId!, this.selectedStore?._id!)
      .pipe(
        first(),
        catchError((err) => this.authErrorHandler(err)),
      )
      .subscribe((accounts) => {
        if (!accounts.length) {
          this.segmentService.track(TRACK_INTEGRATIONS.hasNoAccounts(this.data.integration?.provider!), {
            provider: this.data.integration?.provider,
            storeId: this.selectedStore?._id,
            selfConnected: this.selfConnected,
          });
          this.integrationDialogService.navigateToDialogRoute(AuthType.OAUTH, this.data.provider, 'empty');
        }
        this.accounts = this.integrationDataHelperService.getSortedAccounts(accounts);
        this.selectableAccounts = this.accounts.filter(this.isSelectableAccount);
        this.selectedAccounts = this.selectableAccounts;
        this.providerId = this.selectableAccounts?.[0]?.providerId || '';
        this.loading = false;
      });
  }

  verifyAccount() {
    const link = this.translateService.instant('INTEGRATION_DIALOG.VERIFY.GOOGLE');
    window.open(link, '_blank');
  }

  isSelectableAccount(account: AccountDetails) {
    return account?.state?.selectable;
  }

  createConnection() {
    this.integrationDataService
      .connectAccountForIntegration(this.data.integrationId!, this.selectedStore?._id!, {
        configuration: null,
        providerId: this.providerId,
      })
      .pipe(
        first(),
        catchError((err) => this.authErrorHandler(err)),
      )
      .subscribe(() => {
        this.segmentService.track(TRACK_INTEGRATIONS.connectedAccount(this.data.integration?.provider!), {
          provider: this.data.integration?.provider,
          storeId: this.selectedStore?._id,
          selfConnected: this.selfConnected,
        });
        this.actionInProgress = true;
        this.showErrorMessage = false;
        this.integrationDialogService.closeDialog();
        if (this.data.onConnect) {
          this.data.onConnect();
        }
      });
  }

  onCancel(): void {
    this.integrationDialogService.closeDialog();
  }

  locationChangeHandler(locationId: string): void {
    this.providerId = locationId;
    this.showErrorMessage = !this.providerId;
  }

  onSubmit(): void {
    if (this.accounts.length === 0) {
      return;
    }
    if (!this.selectedStore || !this.providerId) {
      this.showErrorMessage = true;
    } else {
      this.actionInProgress = true;
      this.createConnection();
    }
  }

  onChangeAccount(): void {
    const redirectUrl: any = `${this.data.integration?.authenticationMethod.link}${this.data.queryParamsString?.replace(
      '?',
      '&',
    )}`;
    window!.location = redirectUrl;
  }
}
