import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

// https://docs.imgix.com/apis/url/size/fit
export type ImgixFit = 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'fillmax' | 'max' | 'min' | 'scale' | 'faces';

// https://docs.imgix.com/apis/url/size/crop
export type ImgixCrop = 'top' | 'bottom' | 'left' | 'right' | 'faces' | 'focalpoint' | 'edges' | 'entropy';

// https://docs.imgix.com/apis/url/format/cs
export enum ImgixColorSpace {
  srgb = 'srgb',
  adobergb1998 = 'adobergb1998',
  tinysrgb = 'tinysrgb',
  strip = 'strip',
}

// https://docs.imgix.com/apis/url/auto
export type ImgixAuto = Partial<Record<'compress' | 'enhance' | 'format' | 'redeye', boolean>>;

// https://docs.imgix.com/apis/url/format/ch
export type ImgixClientHints = Partial<Record<'width' | 'dpr' | 'saveData', boolean>>;

// https://docs.imgix.com/apis/url/size/ar
export type ImgixAspectRatio = {
  w: number;
  h: number;
};

// https://docs.imgix.com/apis/url
export type ImgixUrlQueryParams = {
  ar?: ImgixAspectRatio;
  auto?: ImgixAuto;
  q?: number;
  h?: number;
  w?: number;
  fit?: ImgixFit;
  dpr?: number;
  crop?: ImgixCrop;
  bg?: string;
  ch?: ImgixClientHints;
  blur?: number;
  cs?: ImgixColorSpace;
  faceindex?: number;
  facepad?: number;
};

/**
 * Service for handling Image optimization via Imgix
 */
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private webProxy = 'https://freachly.imgix.net';

  private token = 'ABMX9Ja5vqKracsN';

  private buildParams(params: ImgixUrlQueryParams) {
    const queryParams = Object.entries(params)
      .map(([key, value]) => {
        if (key === 'ar') {
          const ar = value as ImgixAspectRatio;

          return `ar=${ar?.w}:${ar?.h}`;
        }

        return [key, value].map(encodeURI as any).join('=');
      })
      .join('&');

    return `?${queryParams}`;
  }

  public getURL(url: string, options: ImgixUrlQueryParams): string {
    const queryParams = this.buildParams(options);
    const imageUrl = encodeURIComponent(url);
    const signatureBase = `${this.token}/${imageUrl}${queryParams}`;
    const signature = Md5.hashStr(signatureBase);

    return `${this.webProxy}/${imageUrl}${queryParams}&s=${signature}`;
  }
}
