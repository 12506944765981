import { Nullable } from '@core/types/nullable.type';
import { Injectable } from '@angular/core';
import { Adapter } from '@core/interfaces/adapter.interface';
import { PostOwner } from '@core/modules/post/types/post-owner.type';
import { MediaVariant } from '@core/ui/media/types/media.type';
import { MediaConfig } from '@core/ui/media/types/carousel.type';
import { ManagedPostType } from '../enums/managed-post-type.enum';
import { ManagedPostStatus } from '../enums/managed-post-status.enum';

export class Post<T> {
  id!: string;

  date!: string;

  owner?: PostOwner;

  counts?: {
    likes: Nullable<number>;
    comments: Nullable<number>;
    views: Nullable<number>;
  };

  caption: Nullable<string> = null;

  mediaVariant: Nullable<MediaVariant> = null;

  slides: MediaConfig[] = [];

  status: ManagedPostStatus = ManagedPostStatus.Published;

  type: ManagedPostType = ManagedPostType.Feed;

  raw?: T;

  constructor(object: Post<T>) {
    Object.assign(this, object);
  }
}

/**
 * Adapter for the Post
 */
@Injectable({
  providedIn: 'root',
})
export class PostAdapter<T> implements Adapter<Post<T>> {
  clientAdapt(object: any): Post<T> {
    return new Post(object);
  }

  serverAdapt(object: Post<T>): any {
    return object;
  }
}
