import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';

import { Observable, Subject, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _isMatch from 'lodash/isMatch';

import { PeriodUnit } from '@core/enums/period.enum';
import { PaginatedResult } from '@core/interfaces/pagination.interface';
import { Store } from '@core/models/store.model';
import {
  DateFilter,
  DateRangeFilter,
  GlobalFilter,
  GlobalFilterUrlParams,
  RelativeDateFilter,
} from '@core/modules/filter/models/global-filter.model';
import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';
import { StoreService } from '@core/services/store.service';
import { CURRENT_FILTER } from '@core/variables/storage-keys.variable';
import { MessageEventService } from '@core/services/message-event.service';
import { DATE_EVENT, STORE_EVENT } from '@core/variables/webview-events.variable';

@Injectable({
  providedIn: 'root',
})
export class GlobalFilterService {
  private _filter!: GlobalFilter;

  private _storeList: Store[] = [];

  set filter(value: GlobalFilter) {
    const newFilter = new GlobalFilter(value);
    if (_isEqual(this._filter, newFilter)) {
      return;
    }
    const params = GlobalFilter.toUrlParams(newFilter);

    // We don't need to update the url on the initial load
    // If the query params are invalid, the GlobalFilterGuard will take care of it
    // Async update of query parameters to avoid routing conflict
    if (this._filter) {
      this.setFilterInLocalStorage(params);

      setTimeout(() => {
        this.updateQueryParams(params, this.activatedRoute.snapshot.queryParams, this.activatedRoute.snapshot.url);
      }, 0);
    }

    this._filter = newFilter;
  }

  get filter(): GlobalFilter {
    return this._filter;
  }

  get storeList(): Store[] {
    return this._storeList;
  }

  set store(value: Store) {
    this._storeChanged$.next(value._id);
    this.filter = {
      ...this.filter,
      store: value,
    };
    this.messageEventService.postMessageToWebView(STORE_EVENT.changed, { storeId: value._id });
  }

  get store(): Store {
    return this._filter.store;
  }

  set date(value: DateFilter) {
    this.filter = {
      ...this.filter,
      date: value,
    };
    this.messageEventService.postMessageToWebView(DATE_EVENT.changed, { date: value });
  }

  get date(): DateFilter {
    return this._filter.date;
  }

  private _storeChanged$ = new Subject<string>();

  storeChanged$ = this._storeChanged$.asObservable().pipe(distinctUntilChanged());

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private messageEventService: MessageEventService,
    private router: Router,
  ) {}

  /**
   * Gets a valid filter using query params, local storage or default values
   * @param params query params
   */
  resolve(params: Params = this.activatedRoute.snapshot.queryParams): Observable<GlobalFilter> {
    return this.storeService.getStoreList().pipe(
      switchMap((paginatedStoreList: PaginatedResult<Store>) => {
        this._storeList = paginatedStoreList.data || [];
        const defaultParams = GlobalFilter.toUrlParams(new GlobalFilter());

        const storageParams: GlobalFilterUrlParams = this.authService.isSessionLogin()
          ? this.storageService.getSessionItem(CURRENT_FILTER) ?? {}
          : this.storageService.getItem(CURRENT_FILTER) ?? {};

        const urlParams: GlobalFilterUrlParams = this.parseFilterParamsFromUrl(params);

        let validatedFilterParams: GlobalFilterUrlParams = {
          ...(this.hasAccessToStore(storageParams.store_id) ? _omitBy(storageParams, _isNil) : {}),
          ...(this.hasAccessToStore(urlParams.store_id) ? _omitBy(urlParams, _isNil) : {}),
        };

        if (!validatedFilterParams.period_unit && !validatedFilterParams.since && !validatedFilterParams.until) {
          validatedFilterParams = {
            ...defaultParams,
            ...validatedFilterParams,
          };
        }

        return of(
          new GlobalFilter({
            date: validatedFilterParams.period_unit
              ? (_pick(validatedFilterParams, ['period_unit', 'period_value']) as RelativeDateFilter)
              : (_pick(validatedFilterParams, ['since', 'until']) as DateRangeFilter),
            store: this.getInitialStore(validatedFilterParams.store_id),
          }),
        );
      }),
      distinctUntilChanged(),
    );
  }

  /**
   * Gets the store using the given id or returns the first store in the list
   * @param storeId the store id to check
   */
  private getInitialStore(storeId = ''): Store {
    return this.storeList.find(({ _id }) => _id === storeId) ?? this.storeList?.[0];
  }

  /**
   * Checks the list of stores accessible by the user for a given id
   * Returns true if a store is found, false otherwise
   * @param storeId the store id to check
   */
  private hasAccessToStore(storeId = ''): boolean {
    return !!this.storeList.find(({ _id }) => _id === storeId);
  }

  /**
   * Parses the url params into filter params
   * Most importantly, the period_unit is converted to a number
   * @param urlParams the query params from the url
   */
  parseFilterParamsFromUrl(urlParams: Params = this.activatedRoute.snapshot.queryParams): GlobalFilterUrlParams {
    return {
      since: urlParams.since ?? undefined,
      until: urlParams.until ?? undefined,
      period_unit: (urlParams.period_unit as PeriodUnit) ?? undefined,
      period_value: Number(urlParams.period_value) || undefined,
      store_id: urlParams.store_id ?? undefined,
    };
  }

  /**
   * Sets the filter in local storage
   * @param filterParams the filter params to set
   */
  setFilterInLocalStorage(filterParams: GlobalFilterUrlParams): void {
    if (this.authService.isSessionLogin()) {
      this.storageService.setSessionItem(CURRENT_FILTER, filterParams);
    } else {
      this.storageService.setItem(CURRENT_FILTER, filterParams);
    }
  }

  /**
   * Updates the url if required
   * @param filterParams the validated filter params
   * @param urlParams all query params from the url
   * @param urlSegments the url segments used to navigate to the same location when updating the url params
   */
  updateQueryParams(
    filterParams: GlobalFilterUrlParams = {},
    urlParams: Params = {},
    urlSegments: UrlSegment[] = [],
  ): void {
    const urlFilterParams = this.parseFilterParamsFromUrl(urlParams);
    if (_isMatch(urlFilterParams, filterParams)) {
      return;
    }

    this.router.navigate(urlSegments || [], {
      queryParams: {
        ..._omit(urlParams, ['period_value', 'period_unit', 'since', 'until']),
        ...filterParams,
      },
    });
  }
}
