@if (avatarSrc) {
  <core-avatar class="chip-icon" [src]="avatarSrc" size="xs" variant="store" (avatarError)="avatarSrc = ''"></core-avatar>
} @else {
  @if (icon) {
    <core-icon class="chip-icon" [icon]="icon" size="xs"> </core-icon>
  }
}
<div class="chip-label">
  <div *ngIf="title; else contentTemplate" class="d-flex flex-column">
    <div class="chip-label__title" [style.opacity]="titleOpacity">{{ title }}</div>
    <div class="chip-label__text">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>
  <ng-template #contentTemplate>
    <ng-content></ng-content>
  </ng-template>
</div>

@if (dropdown) {
  <core-icon *ngIf="dropdown" [@indicatorRotate]="highlighted ? 'expanded' : 'collapsed'" icon="chevron_down" size="xs"></core-icon>
}
