import { Component } from '@angular/core';
import { ReviewWorkflowService } from '@review/common/services/review-workflow.service';

@Component({
  selector: 'review-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent {
  constructor(private reviewWorkflowService: ReviewWorkflowService) {}

  onClose() {
    this.reviewWorkflowService.clearStage();
  }
}
