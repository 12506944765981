import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { ReviewPipesModule } from '@review/common/pipes/review-pipes.module';
import { CardSerialNumberDirective } from './card-serial-number.directive';
import { CardSerialNumberPipe } from '../pipes/card-serial-number.pipe';

@NgModule({
  declarations: [CardSerialNumberDirective],
  imports: [CommonModule, CoreModule, ReviewPipesModule],
  exports: [CardSerialNumberDirective],
  providers: [CardSerialNumberPipe],
})
export class ReviewDirectivesModule {}
