<div class="d-flex flex-column justify-content-center flex-grow-1">
  <core-spinner diameter="48" *ngIf="actionInProgress; else showConnectedAccounts" variant="primary"></core-spinner>
  <ng-template #showConnectedAccounts>
    <div class="row m-n2">
      <ng-container *ngFor="let element of activeConnections">
        <div class="col-12 col-sm-6 px-2 py-1 py-sm-2" [style.min-width.px]="155">
          <core-card
            [style.min-height.px]="68"
            [style.height.%]="100"
            [style.border-color]="hasError ? 'rgb(var(--error))' : null"
            class="flex-grow-1"
            [shadow]="false"
            *ngIf="providersForm.controls?.providers?.get(element.provider) as control"
            [selectable]="!disabled[element.provider]" [border]="true" padding="none">
            <core-checkbox
              [style.height.%]="100"
              variant="success"
              [value]="control?.value"
              (change)="control?.setValue($event)" 
              class="w-100"
              [labelPadding]="{ left: 'none', right: 'md', y: 'none' }"
              [disabled]="element.status === 'INACTIVE' || disabled[element.provider]">
              <div class="pl-3 py-2 pr-0 d-flex align-items-center flex-wrap">
                <core-avatar
                  class="my-1 mr-3"
                  variant="store"
                  size="md"
                  badgePosition="bottom-left"
                  [src]="avatars?.[element.provider]">
                  <core-platform-avatar
                    size="xxs"
                    [style.border-width.px]="0" 
                    [url]="platformIcons?.[disabled[element.provider] ? 'INACTIVE' : element.status]?.[element.provider]">
                  </core-platform-avatar>
                </core-avatar>
                <div class="my-1 d-flex flex-column flex-fill">
                  <span
                    class="account-name"
                    [class.account-name--disabled]="element.status === 'INACTIVE' || disabled[element.provider]">
                    {{ element.name }}
                    <strong *ngIf="element.providerAccountUsername" class="account-name__username">{{ element.providerAccountUsername  }}</strong>
                    <strong *ngIf="disabled[element.provider] && disabledReason">
                      <span class="provider-info-text d-inline-flex align-items-center">
                        <core-icon class="d-inline-flex mr-1" icon="info" size="xs"></core-icon>
                        {{ disabledReason | translate }}
                      </span>
                    </strong>
                  </span>
                </div>
              </div>
            </core-checkbox>
          </core-card>
        </div>
      </ng-container>

      <ng-container *ngFor="let element of inactiveConnections">
        <div class="col-12 col-sm-6 px-2 py-1 py-sm-2" [style.min-width.px]="155">
          <core-card
            [style.min-height.px]="68"
            [style.height.%]="100"
            class="flex-grow-1"
            [shadow]="false"
            [border]="true"
            padding="none">
            <div class="px-3 py-2 d-flex align-items-center flex-wrap">
              <core-avatar
                class="my-1 mr-3"
                variant="store"
                size="md"
                badgePosition="bottom-left"
                [src]="avatars?.[element.provider]">
                <core-platform-avatar
                  size="xxs"
                  [style.border-width.px]="0" 
                  [url]="platformIcons?.[disabled[element.provider] ? 'INACTIVE' : element.status]?.[element.provider]">
                </core-platform-avatar>
              </core-avatar>
              <div class="my-1 mr-2 d-flex flex-column flex-fill">
                <span
                  class="account-name"
                  [class.account-name--disabled]="element.status === 'INACTIVE' || disabled[element.provider]">
                  {{ element.name }}
                  <strong *ngIf="element.providerAccountUsername" class="account-name__username">{{ element.providerAccountUsername  }}</strong>
                  <strong *ngIf="element.status === 'INACTIVE'">
                    <span class="provider-error-text d-inline-flex align-items-center">
                      <core-icon class="d-inline-flex mr-1" icon="error" size="xs"></core-icon>
                      {{ 'POST_MANAGER.ACCOUNT.INACTIVE_BADGE' | translate }}
                    </span>
                  </strong>
                </span>
              </div>
              <div class="ml-auto my-1">
                <button coreButton size="sm" type="button" (click)="connect(element.provider)">{{ 'ACTION.RECONNECT' | translate }}</button>
              </div>
            </div>
          </core-card>
        </div>
      </ng-container>

      <ng-container *ngFor="let element of connectableProviders">
        <div class="col-12 col-sm-6 px-2 py-1 py-sm-2" [style.min-width.px]="155">
          <core-card
            [style.min-height.px]="68"
            [style.height.%]="100"
            class="flex-grow-1"
            [shadow]="false"
            [border]="true"
            padding="none">
            <div class="px-3 py-2 d-flex align-items-center flex-wrap">
              <core-platform-avatar
                size="md"
                class="my-1 mr-3"
                [url]="platformIcons?.INACTIVE?.[element]">
              </core-platform-avatar>
              <div class="my-1 mr-2 d-flex flex-column flex-fill">
                <span
                  class="account-name account-name--disabled">
                  {{ element | titlecase }}
                </span>
              </div>
              <div class="ml-auto my-1">
                <button coreButton size="sm" type="button" (click)="connect(element)">{{ 'ACTION.CONNECT' | translate }}</button>
              </div>
            </div>
          </core-card>
        </div>
      </ng-container>

      <div *ngIf="showRefresh" class="col-12 col-sm-6 px-2 py-1 py-sm-2" [style.min-width.px]="155">
        <core-card
          [style.min-height.px]="68"
          [style.height.%]="100"
          class="flex-grow-1"
          [shadow]="false"
          [border]="false"
          padding="none">
          <div class="px-3 py-2 d-flex align-items-center justify-content-center flex-fill">
            <button coreButton icon="refresh" size="sm" type="button" variant="text" (click)="initConnections(store)">{{ 'POST_MANAGER.ACCOUNT.REFRESH_ALL' | translate }}</button>
          </div>
        </core-card>
      </div>
    </div>
  </ng-template>
</div>
