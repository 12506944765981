import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Post } from '@core/dialogs/post-manager/models/post.model';

import { PostCardVariant } from '@core/modules/post/types/post-card.type';
import { Nullable } from '@core/types/nullable.type';
import { InfluencerPost } from '@influencer/common/models/influencer-post.model';

@Component({
  selector: 'influencer-post',
  templateUrl: './influencer-post.component.html',
  styleUrls: ['./influencer-post.component.scss'],
  host: {
    '[id]': 'id',
  },
})
export class InfluencerPostComponent implements OnChanges {
  @Input()
  index = 0;

  @Input()
  post?: Post<InfluencerPost>;

  @Input()
  ctaLoadingForPost: Nullable<string> = null;

  @Output()
  updateLayout = new EventEmitter();

  @Output()
  ctaClick = new EventEmitter();

  @Output()
  feedbackChange = new EventEmitter();

  slideIndex = 0;

  hasError = false;

  cardVariant?: PostCardVariant;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post && changes.post.currentValue !== changes.post.previousValue) {
      this.slideIndex = 0;
      this.hasError = false;
      this.cardVariant = this.getCardVariant()!;
    }
  }

  getCardVariant(): PostCardVariant {
    switch (this.post?.type) {
      case 'STORY':
        return 'content-overlay';
      case 'FEED':
      default:
        return 'default';
    }
  }
}
