<core-dialog
  [canGoBack]="true"
  [headerConfig]="{
    title: 'REVIEW.MANAGE_CARDS.MANAGE.TITLE' | translate,
    showSupport: false,
    showClose: true
    }"
  [footerConfig]="{
    backLabel: 'REVIEW.BACK.BUTTON' | translate,
    cancelLabel: 'ACTION.CANCEL' | translate,
    submitLabel: 'ACTION.SAVE' | translate
  }"
  [actionInProgress]="actionInProgress"
  (dismiss)="dismiss()"
  (back)="goBack()"
  (submit)="assignCard()">
  <div class="row my-auto mx-n2">
    <div class="col-12 col-sm-5 px-2 d-flex flex-column align-items-center align-self-center">
      <core-svg
        svg="img_card_assign">
      </core-svg>
    </div>
    <div class="col-12 col-sm-7 px-2 d-flex flex-column align-items-stretch align-self-center">
      <div class="main">
        <p class="mb-4 mr-sm-3">
          {{ 'REVIEW.MANAGE_CARDS.ASSIGN.' + (scanned ? 'SCANNED' : 'MANUAL') | translate }}
        </p>
      </div>
      <form [formGroup]="cardForm!" *ngIf="cardForm; else showSpinner">
        <core-form-field class="flex-grow-1 d-flex flex-column">
          <span coreFormFieldLabel>{{ 'REVIEW.MANAGE_CARDS.ASSIGN.CARD_ID' | translate }}</span>
          <input
            coreInput
            reviewCardSerialNumber
            required
            [placeholder]="'REVIEW.MANAGE_CARDS.ASSIGN.PLACEHOLDER.CARD' | translate"
            (input)="validateCard()"
            formControlName="cardId"
            type="text"
          />
          <div
            class="d-flex align-items-center"
            coreFormFieldSuffix
            *ngIf="(
              !cardForm?.get('cardId')?.errors?.required
                && !cardForm?.get('cardId')?.errors?.pattern
                && !cardForm?.get('cardId')?.errors?.cardIdInvalid
                && cardForm?.get('cardId')?.dirty
            ) || editMode">
            <core-spinner
              *ngIf="validating; else badge"
              variant="primary"
              diameter="16">
            </core-spinner>
            <ng-template #badge>
              <core-icon
                *ngIf="duplicate; else valid"
                icon="danger"
                size="xs"
                class="main__validation-badge--error">
              </core-icon>
              <ng-template #valid>
                <core-icon
                  icon="check_circle"
                  size="xs"
                  class="main__validation-badge--success">
                </core-icon>
              </ng-template>
            </ng-template>
          </div>
          <span
            coreFormFieldError
            *ngIf="cardForm?.get('cardId')?.errors && (cardForm?.get('cardId')?.dirty || cardForm?.get('cardId')?.touched!)">
            <ng-container *ngIf="cardForm?.get('cardId')?.errors.required; else checkPattern">
              {{ 'REVIEW.MANAGE_CARDS.ASSIGN.ERROR.CARD.REQUIRED' | translate }}
            </ng-container>
            <ng-template #checkPattern>
              <ng-container *ngIf="cardForm?.get('cardId')?.errors.pattern; else checkLength">
                {{ 'REVIEW.MANAGE_CARDS.ASSIGN.ERROR.FORMAT' | translate }}
              </ng-container>
            </ng-template>
            <ng-template #checkLength>
              <ng-container *ngIf="cardForm?.get('cardId')?.errors.cardIdInvalid; else checkDuplicate">
                {{ 'REVIEW.MANAGE_CARDS.ASSIGN.ERROR.LENGTH' | translate }}
              </ng-container>
            </ng-template>
            <ng-template #checkDuplicate>
              <ng-container *ngIf="cardForm?.get('cardId')?.errors.duplicate">
                {{ 'REVIEW.MANAGE_CARDS.ASSIGN.ERROR.DUPLICATE' | translate }}
              </ng-container>
            </ng-template>
          </span>
        </core-form-field>


        <core-form-field class="flex-grow-1 d-flex flex-column">
          <span coreFormFieldLabel>{{ 'REVIEW.MANAGE_CARDS.ASSIGN.NAME' | translate }}</span>
          <input
            coreInput
            required
            [placeholder]="'REVIEW.MANAGE_CARDS.ASSIGN.NAME' | translate"
            formControlName="name"
            type="text"
          />
          <span
            coreFormFieldError
            *ngIf="cardForm?.get('name')?.errors?.required
            && (cardForm?.get('name')?.dirty || cardForm?.get('name')?.touched!)">
            {{ 'REVIEW.MANAGE_CARDS.ASSIGN.ERROR.NAME.REQUIRED' | translate }}
          </span>
        </core-form-field>
      </form>
      <ng-template #showSpinner>
        <core-spinner class="p-4" [fullHeight]="true" variant="primary"></core-spinner>
      </ng-template>
    </div>
  </div>
</core-dialog>
