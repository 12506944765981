<core-video-thumbnail
  *ngIf="!isPlaying; else showVideo"
  (click)="playVideo(true)"
  [src]="thumbnailSrc"
  [variant]="thumbnailVariant"
  (error)="onThumbnailError()"
  (loaded)="onThumbnailLoaded($event)">
</core-video-thumbnail>
<ng-template #showVideo>
  <video
    #video
    class="core-video"
    [attr.poster]="isPlaying ? null : thumbnailSrc"
    [attr.crossorigin]="crossOrigin"
    [autoplay]="autoplay"
    name="video"
    type="video/mp4"
    (loadstart)="loaded.emit($event)"
    (loadedmetadata)="setAspectRatio($event?.currentTarget); captureThumbnail(video); loaded.emit($event)"
    (error)="error.emit($event)"
    (play)="playVideo(true); actionChange.emit('play')"
    (ended)="playVideo(false)">
    <source [attr.src]="safeUrl" />
  </video>
</ng-template>
