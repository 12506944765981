import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { storeSetupOutlet } from '@core/dialogs/store-setup-workflow/variables/outlet.variable';
import { Observable, of } from 'rxjs';
import { StoreService } from '@core/services/store.service';
import { switchMap } from 'rxjs/operators';
import { StoreSetupRoute } from '@core/dialogs/store-setup-workflow/enums/store-setup-route.enum';

@Component({
  selector: 'core-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent {
  hasOneStoreAssigned$: Observable<boolean> = this.storeService
    .getStoreList()
    .pipe(switchMap((value) => of(!!value?.data?.length)));

  constructor(
    private router: Router,
    private storeService: StoreService,
  ) {}

  submit(): void {
    this.router.navigate([{ outlets: { [storeSetupOutlet]: StoreSetupRoute.Form } }], { queryParamsHandling: 'merge' });
  }
}
