<core-dialog
  [style.height]="'unset'"
  [actionInProgress]="actionInProgress"
  [canGoBack]="true"
  [headerConfig]="{
    title: 'AUTH.MODULE.REGISTER.STORE.TITLE' | translate,
    showSupport: (hasOneStoreAssigned$ | async),
    showClose: (hasOneStoreAssigned$ | async)
  }"
  [footerConfig]="{
    submitLabel: 'ACTION.CONTINUE' | translate,
    backLabel: 'ACTION.BACK' | translate
  }"
  (back)="goBack()"
  (dismiss)="removeOutlet()"
  (submit)="submit()">
  <core-store-setup-header-options *ngIf="(hasOneStoreAssigned$ | async) === false" coreDialogHeader></core-store-setup-header-options>
  <core-linear-progress-bar variant="emphasis" [value]="0.3" [total]="1"></core-linear-progress-bar>
  <div class="row mt-4 mx-n2">
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-start align-items-center mb-3 mb-sm-0">
      <core-svg
        svg="img_store">
      </core-svg>
    </div>
    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <p>{{ 'AUTH.MODULE.REGISTER.STORE.INTRO_TEXT' | translate }}</p>
      <form
        [formGroup]="storeForm"
        class="d-flex flex-column w-100">
        <div>
          <core-form-field
            class="flex-grow-1 d-flex flex-column">
            <span coreFormFieldLabel>{{ 'AUTH.FIELD.COUNTRY' | translate }}</span>
            <core-select formControlName="country" required>
              <core-option *ngFor="let option of countryQuickOptions" [value]="option">
                <span coreOptionIcon>{{ option.flag }}</span>
                {{ option.name[language || 'en'] }}
              </core-option>
              <core-divider></core-divider>
              <core-option *ngFor="let option of countryOptions" [value]="option">
                <span coreOptionIcon>{{ option.flag }}</span>
                {{ option.name[language || 'en'] }}
              </core-option>
            </core-select>
          </core-form-field>
        </div>
        <div>
          <core-form-field
            class="flex-grow-1 d-flex flex-column">
            <span coreFormFieldLabel>{{ 'AUTH.FIELD.STORE_NAME' | translate }}</span>
            <input
              coreInput
              required
              formControlName="storeName"
              [matAutocomplete]="autocompleteStoreName"
              [matAutocompleteDisabled]="!storeForm.controls.storeName?.value || storeForm.controls.enterManually?.value"
              (keyup)="isStoreValid = storeForm.controls.enterManually?.value; storeForm.controls.storeName?.setErrors(null);"
            />
            <div coreFormFieldSuffix class="d-flex align-items-center">
              @if (isStoreValid && !storeForm.controls?.enterManually.value) {
                <core-icon class="success-icon" size="sm" [style.pointer-events]="'none'" icon="check_circle"></core-icon>
              } 
              @if (storeForm.controls.storeName?.value) {
                <core-icon size="sm" [style.cursor]="'pointer'" (click)="clearStoreValue()" icon="close"></core-icon>
              } @else {
                <core-icon size="sm" [style.pointer-events]="'none'" [icon]="resultsLoading ? 'loading' : 'search'"></core-icon>
              }
            </div>
            <span coreFormFieldError *ngIf="storeForm.controls.storeName.errors">
              @if (storeForm.controls.storeName.errors?.autocomplete) {
                {{ 'VALIDATION.REGISTER.AUTOCOMPLETE_REQUIRED' | translate }}
              } @else {
                {{ 'VALIDATION.REGISTER.STORE_NAME' | translate }}
              }
            </span>

            <mat-autocomplete
              class="core-autocomplete-panel results"
              #autocompleteStoreName="matAutocomplete">
              <ng-container *ngIf="!resultsLoading">
                <mat-option *ngFor="let option of options" [value]="option.name" (click)="setValue(option)">
                  <div class="results__option">
                    <core-icon size="xs" icon="location"></core-icon>
                    <div class="results__option__text">
                      <div class="results__option__name">{{ option?.name }}</div>
                      <div class="results__option__address">{{ option?.address }}</div>
                    </div>
                  </div>
                </mat-option>
                <core-divider *ngIf="options?.length" class="my-2"></core-divider>
                <mat-option (click)="setManualAddressChecked(true)" [value]="storeForm.controls.storeName?.value">
                  <div class="results__option">
                    <core-icon size="xs" icon="add"></core-icon>
                    <div class="results__option__text">
                      <div class="results__option__name">{{ 'AUTH.MODULE.REGISTER.STORE.ADD_MANUALLY' | translate }}</div>
                      <div class="results__option__description">{{ 'AUTH.MODULE.REGISTER.STORE.TEXT' | translate }}</div>
                    </div>
                  </div>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </core-form-field>
        </div>

        <div *ngIf="storeForm.controls.enterManually?.value">
          <div class="my-3">
            <core-checkbox
              labelPosition="after"
              variant="primary"
              formControlName="enterManually">
              <p class="ml-1 mb-0">{{ 'AUTH.MODULE.REGISTER.STORE.ADD_MANUALLY' | translate }}</p>
            </core-checkbox>
          </div>
          <div>
            <core-form-field
              class="flex-grow-1 d-flex flex-column">
              <span coreFormFieldLabel>{{ 'AUTH.FIELD.ADDRESS' | translate }}</span>
              <input
                coreInput
                required
                formControlName="street"
                autocomplete="street-address"
              />
              <span coreFormFieldError *ngIf="storeForm.controls.street.errors">
                {{ 'VALIDATION.REGISTER.ADDRESS' | translate }}
              </span>
            </core-form-field>
          </div>

          <div class="row mx-n2">
            <div class="col-12 col-md-6 px-2">
              <core-form-field
                class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'AUTH.FIELD.POSTAL_CODE' | translate }}</span>
                <input
                  coreInput
                  required
                  formControlName="zip"
                  autocomplete="postal-code"
                />
                <span coreFormFieldError *ngIf="storeForm.controls.zip.errors">
                  {{ 'VALIDATION.REGISTER.POSTAL_CODE' | translate }}
                </span>
              </core-form-field>
            </div>

            <div class="col-12 col-md-6 px-2">
              <core-form-field
                class="flex-grow-1 d-flex flex-column">
                <span coreFormFieldLabel>{{ 'AUTH.FIELD.CITY' | translate }}</span>
                <input
                  coreInput
                  required
                  formControlName="city"
                  autocomplete="address-level2"
                />
                <span coreFormFieldError *ngIf="storeForm.controls.city.errors">
                  {{ 'VALIDATION.REGISTER.CITY' | translate }}
                </span>
              </core-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</core-dialog>
