import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';
import { CardSerialNumberPipe } from '../pipes/card-serial-number.pipe';

@Directive({ selector: '[reviewCardSerialNumber]' })
export class CardSerialNumberDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private cardSerialNumberPipe: CardSerialNumberPipe,
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.cardSerialNumberPipe.transform(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    this.el.value = this.cardSerialNumberPipe.parse(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.el.value = this.cardSerialNumberPipe.transform(value);
  }
}
