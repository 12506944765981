<core-dialog
  [unsavedChanges]="false"
  [headerConfig]="{
    title: selectedStore?.name,
    avatarSrc: selectedStore?.images?.[0]?.file
  }"
  [footerConfig]="{
    submitLabel: 'INTEGRATION_DIALOG.NAVIGATION.GOT_IT' | translate
  }"
  [actionInProgress]="false"
  (dismiss)="onCancel()"
  (submit)="onSubmit()">
  <div class="row mx-n2 flex-grow-1">
    <div
      class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-0">
      <core-svg svg="img_connect_provider"></core-svg>
    </div>

    <div class="col-12 col-sm-6 px-2 d-flex flex-column justify-content-center">
      <h5>{{ title }}</h5>
      <p [innerHTML]="text"></p>
    </div>
  </div>
  <ng-container *ngIf="isContactSupportVisible" coreDialogFooter>
    <button class="flex-grow-1 flex-md-grow-0" coreButton (click)="contactSupport()">
      {{ 'INTEGRATION_DIALOG.NAVIGATION.CUSTOMER_SUPPORT' | translate }}
    </button>

    <div class="spacer"></div>
  </ng-container>
</core-dialog>
