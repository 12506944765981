<ng-container [ngSwitch]="variant">
  <core-media-carousel
    *ngSwitchCase="'CAROUSEL'"
    [slides]="slides"
    [slideIndex]="slideIndex"
    [errorVariant]="errorVariant"
    [autoplayVideo]="autoplayVideo"
    [autoplay]="carouselOptions?.autoplay"
    [pagination]="carouselOptions?.pagination"
    [loop]="carouselOptions?.loop"
    [aspectRatio]="carouselOptions?.aspectRatio"
    [imageFit]="imageFit"
    (loaded)="loaded.emit($event)"
    (fallbackLoaded)="fallbackLoaded.emit($event)"
    (error)="error.emit($event)"
    (slideChange)="slideChange.emit($event)">
  </core-media-carousel>
  <core-media-item
    *ngSwitchDefault
    [src]="slides[0]?.src"
    [roundness]="roundness"
    [errorVariant]="errorVariant"
    [thumbnail]="slides[0]?.thumbnail"
    [imageFit]="imageFit"
    [variant]="slides[0]?.variant"
    [imageSrcVariant]="slides[0]?.imageSrcVariant"
    [imageUrlOptions]="slides[0]?.imageUrlOptions"
    [autoplayVideo]="autoplayVideo"
    [crossOrigin]="slides[0]?.crossOrigin"
    (loaded)="loaded.emit($event)"
    (fallbackLoaded)="fallbackLoaded.emit($event)"
    (error)="error.emit($event)"
    (actionChange)="actionChange.emit($event)">
  </core-media-item>
</ng-container>
