import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomBreakpoints } from '@core/variables/custom-breakpoints.variable';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {
  private destroyed$ = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {}

  observeBreakpoints(breakpoints: string[] | string, callback: (state: BreakpointState) => void) {
    if (!breakpoints || !breakpoints?.length) {
      return;
    }

    this.breakpointObserver
      .observe(this.getBreakpoints(breakpoints))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(callback);
  }

  private getBreakpoints(breakpoints: string | string[]) {
    if (!Array.isArray(breakpoints)) {
      return [this.mapBreakpointSize(breakpoints)];
    }

    return breakpoints.map((item) => this.mapBreakpointSize(item));
  }

  private mapBreakpointSize(size: string) {
    switch (size) {
      case 'xs': {
        return CustomBreakpoints.XSmall;
      }
      case 'sm': {
        return CustomBreakpoints.Small;
      }
      case 'md': {
        return CustomBreakpoints.Medium;
      }
      case 'lg': {
        return CustomBreakpoints.Large;
      }
      case 'xl': {
        return CustomBreakpoints.XLarge;
      }
      case 'xxl': {
        return CustomBreakpoints.XXLarge;
      }
      default: {
        const sizeTitleCase = size[0].toUpperCase() + size.slice(1);

        return CustomBreakpoints[sizeTitleCase] || size;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
