<core-dialog
  [canGoBack]="true"
  [headerConfig]="{
    title: 'GOAL_SETTING.PRODUCT_SELECTION_DIALOG.HEADER_TITLE' | translate
  }"
  [footerConfig]="{
    backLabel: 'ACTION.BACK' | translate
  }"
  class="dialog"
  (back)="goBack()">
    <core-linear-progress-bar
      variant="emphasis"
      [value]="1"
      [total]="1">
    </core-linear-progress-bar>
    <h6 class="pt-4 pb-2 intro">{{ 'GOAL_SETTING.PRODUCT_SELECTION_DIALOG.INTRO_TEXT' | translate }}</h6>
    <span class="error" *ngIf="showError">
      {{ 'GOAL_SETTING.PRODUCT_SELECTION_DIALOG.ERROR_NOTHING_SELECTED' | translate }}
    </span>
    <div class="row gx-5 pt-4 pb-2 justify-content-center">
      <div
        *ngFor="let product of products; let isLast = last"
        class="col col-12 col-md-6 mb-md-0"
        [ngClass]="{'mb-4': !isLast}">
        <core-card
          class="h-100"
          padding="lg">
          <div class="d-flex justify-content-center image-container">
            <core-svg svg="{{ product.imageId }}"></core-svg>
          </div>
          <h2 class="header">
            <core-icon icon="{{ product.iconId }}" size="sm"></core-icon>
            <span>{{ product.label | translate }}</span>
          </h2>
          <span class="description mb-4">{{ product.description | translate }}</span>
          <button
            coreButton
            class="mt-auto"
            variant="primary"
            (click)="onSelectHandler(product.key)">
            {{ 'GOAL_SETTING.PRODUCT_SELECTION_DIALOG.SUBMIT_BUTTON' | translate: { product: product.label | translate } }}
          </button>
        </core-card>
      </div>
    </div>
</core-dialog>
