import { environment } from '@env/environment';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { LoginGuard } from '@core/guards/login.guard';
import { BrandResolver } from '@core/resolvers/brand.resolver';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@core/modules/auth/auth.module').then((m) => m.AuthModule),
    data: { title: 'AUTH.NAVIGATION.AUTH' },
    canActivate: [LoginGuard],
    resolve: { brandConfig: BrandResolver },
  },
  {
    path: 'error',
    loadChildren: () => import('@core/modules/generic-error/generic-error.module').then((m) => m.GenericErrorModule),
    data: {
      title: 'APP.ERROR',
    },
  },
  {
    path: '',
    loadChildren: () => import('@app/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard],
    resolve: { brandConfig: BrandResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: !!environment.routerUseHash })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
