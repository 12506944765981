import { RouterModule, Routes } from '@angular/router';

import { UpdatePostComponent } from './screens/update-post/update-post.component';
import { RejectPostComponent } from './screens/reject-post/reject-post.component';
import { postManagerOutlet } from './variables/outlet.variable';
import { PostManagerRoute } from './enums/post-manager-route.enum';

const outlet = postManagerOutlet;

export const makePostManagerRoutes = () => [
  {
    path: PostManagerRoute.Create,
    outlet,
    loadChildren: () => import('./screens/create-post/create-post.module').then((m) => m.CreatePostModule),
  },
  {
    path: PostManagerRoute.Crop,
    outlet,
    loadChildren: () => import('./screens/crop-post-media/crop-post-media.module').then((m) => m.CropPostMediaModule),
  },
  {
    path: `${PostManagerRoute.Update}/:postId`,
    outlet,
    component: UpdatePostComponent,
  },
  {
    path: `${PostManagerRoute.Reject}/:postId`,
    outlet,
    component: RejectPostComponent,
  },
];

const routes: Routes = [...makePostManagerRoutes()];

export const PostManagerRoutingModule = RouterModule.forChild(routes);
