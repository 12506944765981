import { Pipe, PipeTransform } from '@angular/core';
import _castArray from 'lodash/castArray';
import _orderBy from 'lodash/orderBy';

type Order = 'asc' | 'desc';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string | string[], order: Order | Order[] = 'desc'): any[] {
    return _orderBy(array, _castArray(sortBy), _castArray(order) as Order[]);
  }
}
