import { Nullable } from '@core/types/nullable.type';
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TagLargeConfig } from '@core/ui/tag/types/tag-large.type';
import { AlertVariant, AlertCtaPosition, AlertFontSize } from '../../types/alert.type';

@Component({
  selector: 'core-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  host: {
    '[class]': `"alert"
      + " cta-position-"+ctaPosition
      + " font-size-"+fontSize
      + " variant-"+variant`,
    '[class.alert--action]': 'ctaLabel || link',
    '[class.alert--has-title]': 'title',
    '[class.alert--dismissable]': 'dismissable',
  },
})
export class AlertComponent implements OnChanges {
  @Input()
  variant: AlertVariant = 'info';

  @Input()
  fontSize: AlertFontSize = 'md';

  @Input()
  title = '';

  @Input()
  link = '';

  @Input()
  ctaLabel = '';

  @Input()
  ctaPosition: AlertCtaPosition = 'responsive';

  @Input()
  tagConfig: Nullable<TagLargeConfig> = null;

  @Input()
  actionInProgress = false;

  @Input()
  dismissable = false;

  @Output()
  dismiss = new EventEmitter();

  @Output()
  ctaClick = new EventEmitter();

  icon?: string;

  cardVariant = this.getCardVariant();

  ngOnChanges(): void {
    this.icon = this.getIcon();
    this.cardVariant = this.getCardVariant();
  }

  private getIcon(): string {
    switch (this.variant) {
      case 'danger':
        return 'warning';
      case 'success':
        return 'check_circle';
      case 'info':
      default:
        return 'info';
    }
  }

  private getCardVariant(): string {
    switch (this.variant) {
      case 'danger':
        return 'error';
      default:
        return this.variant;
    }
  }
}
